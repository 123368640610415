/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useHistory} from 'react-router-dom'
import {Controller, useForm} from 'react-hook-form'
import {joiResolver} from '@hookform/resolvers'
import _ from 'lodash'
import {KooperForm, KooperFormDropdown, KooperFormField} from 'kooper-library'

import {UPDATE_ACCOUNT_CURRENCY, UPDATE_ACCOUNT_CURRENCY_BASE} from 'actions/types'
import SettingLayout from 'layouts/settingLayout'
import {decimalPlaces, decimalSeparator, thousandsSeparator} from 'constants/variables'
import {updateAccountCurrency} from 'actions/setting_currency'
import {updateCurrencySchema} from 'validation/Settings/sales/currencies.schema'
import useUserPermissions from 'hooks/pure/useUserPermissions'

const CurrenciesDetails = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  const {state: data} = history.location
  const {successLabels = [], isLoadingData, type} = useSelector(state => state.apiReducer)
  const [dataObj, setDataObj] = useState({})

  const {salesPermissions} = useUserPermissions()
  const manageCurrenciesPermissions = salesPermissions?.manage_currencies

  const initial = {
    decimalPlaces: _.get(data, 'decimalPlaces', 0),
    thousandSeperator: _.get(data, 'thousandSeperator'),
    decimalSeperator: _.get(data, 'decimalSeperator'),
    symbol: _.get(data, 'symbol', 'INR'),
  }

  const {
    control,

    reset,
    handleSubmit,
    watch,
    formState: {isDirty},
  } = useForm({
    shouldFocusError: true,
    mode: 'onTouched',
    reValidateMode: 'onChange',
    defaultValues: initial,
    resolver: joiResolver(updateCurrencySchema),
  })

  const WatchThousandSep = watch('thousandSeperator')
  const WatchDecimalPlace = watch('decimalPlaces')
  const WatchDecimalSep = watch('decimalSeperator')
  const WatchSymbol = watch('symbol')

  const [state, setState] = useState('123456789')

  const previewThousandAndDecimalLogic = val => {
    switch (val) {
      case 'Comma':
        return ','
      case 'Period':
        return '.'
      case 'Space':
        return ' '
      default:
        return ''
      // code block
    }
  }
  const previewAndDecimalPlaceLogic = val => {
    switch (val) {
      case 0:
        return ''
      case 1:
        return '0'
      case 2:
        return '00'
      case 3:
        return '000'
      default:
        return ''
      // code block
    }
  }

  useEffect(() => {
    if (!data) {
      history.push('/setting/currencies')
    }
    setDataObj(data)
    reset(initial)
  }, [data])

  useEffect(() => {
    if (successLabels.includes(UPDATE_ACCOUNT_CURRENCY) || successLabels.includes(UPDATE_ACCOUNT_CURRENCY_BASE)) {
      setDataObj({})
      history.push('/setting/currencies')
    }
  }, [successLabels])

  useEffect(() => {
    const str = `${WatchSymbol} 123${previewThousandAndDecimalLogic(
      WatchThousandSep
    )}456${previewThousandAndDecimalLogic(WatchThousandSep)}789${
      WatchDecimalPlace ? previewThousandAndDecimalLogic(WatchDecimalSep) : ''
    }${WatchDecimalPlace ? previewAndDecimalPlaceLogic(WatchDecimalPlace) : ''}`
    setState(str)
  }, [WatchThousandSep, WatchDecimalPlace, WatchDecimalSep, WatchSymbol])

  return (
    <SettingLayout
      header={_.get(dataObj, 'base.name')}
      headerbackbtn={{
        onClick: () => history.push(`/settings/currencies`),
      }}
      actionButton={{
        cancel: {
          content: 'Cancel',
          disabled:
            isLoadingData && (type.includes(UPDATE_ACCOUNT_CURRENCY) || type.includes(UPDATE_ACCOUNT_CURRENCY_BASE)),
          onClick: () => {
            reset(initial)
            history.push('/settings/currencies')
          },
        },
        success: {
          content: 'Update',
          loading:
            isLoadingData && (type.includes(UPDATE_ACCOUNT_CURRENCY) || type.includes(UPDATE_ACCOUNT_CURRENCY_BASE)),
          ...(manageCurrenciesPermissions && {
            onClick: handleSubmit(data => {
              const obj = {
                ...data,
                id: _.get(dataObj, 'id', null),
                currencyId: _.get(dataObj, 'currencyId', null),
              }
              dispatch(updateAccountCurrency(obj))
            }),
          }),
          disabled:
            !isDirty ||
            (isLoadingData && (type.includes(UPDATE_ACCOUNT_CURRENCY) || type.includes(UPDATE_ACCOUNT_CURRENCY_BASE))),
        },
      }}
      lockRole={!manageCurrenciesPermissions}
    >
      <KooperForm>
        <p>
          <b>Preview:</b> <span className="kooper-lead">{state}</span>
        </p>
        <KooperFormField>
          <label>Symbol</label>
          <p className="kooper-lead ">Set symbol for the currency</p>
          <Controller
            control={control}
            name="symbol"
            render={({onChange, value}) => (
              <KooperFormDropdown
                width="6"
                selection
                placeholder="Select"
                value={value}
                onChange={(e, {value}) => onChange(value)}
                options={data.base.symbols.map(list => ({
                  key: list,
                  value: list,
                  text: list,
                }))}
              />
            )}
          />
        </KooperFormField>
        <KooperFormField>
          <label>Thousands Separator</label>
          <p className="kooper-lead ">Select separator for thousand place</p>
          <Controller
            control={control}
            name="thousandSeperator"
            render={({onChange, value}) => (
              <KooperFormDropdown
                width="6"
                selection
                placeholder="Select"
                value={value}
                onChange={(e, {value}) => onChange(value)}
                options={thousandsSeparator}
              />
            )}
          />
        </KooperFormField>
        <KooperFormField>
          <label>Decimal Places</label>
          <p className="kooper-lead ">Select how many numbers to show after decimal place</p>
          <Controller
            control={control}
            name="decimalPlaces"
            render={({onChange, value}) => (
              <KooperFormDropdown
                width="6"
                selection
                placeholder="Select"
                value={value}
                onChange={(e, {value}) => onChange(value)}
                options={decimalPlaces}
              />
            )}
          />
        </KooperFormField>
        <KooperFormField>
          <label>Decimal Separator</label>
          <p className="kooper-lead ">Select seperator for decimal place</p>
          <Controller
            control={control}
            name="decimalSeperator"
            render={({onChange, value}) => (
              <KooperFormDropdown
                width="6"
                selection
                placeholder="Select"
                value={value}
                onChange={(e, {value}) => onChange(value)}
                options={decimalSeparator}
              />
            )}
          />
        </KooperFormField>
      </KooperForm>
    </SettingLayout>
  )
}
export default CurrenciesDetails
