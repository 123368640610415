import React from 'react'
import {KooperButton, KooperTab} from 'kooper-library'
import {useParams} from 'react-router'
import {useHistory} from 'react-router-dom'
import SvgIcon from 'components/common/SvgIcon'
import SequenceContact from './Insights/SequenceContacts'
import SequenceEmail from './Insights/SequenceEmail'
import SequenceOverview from './Insights/SequenceOverview'

const SequencesInsights = () => {
  const {id} = useParams()
  const history = useHistory()

  const sequences = [
    {
      menuItem: {
        key: 'contacts',
        icon: 'user',
        content: 'Contacts',
      },
      pane: {
        content: <SequenceContact id={id} />,
      },
    },
    {
      menuItem: {key: 'emails', icon: 'envelope', content: 'Emails'},
      pane: {
        content: <SequenceEmail id={id} />,
      },
    },
    {
      menuItem: {
        key: 'overview',
        icon: 'eye',
        content: 'Overview',
      },
      pane: {
        content: <SequenceOverview id={id} />,
      },
    },
  ]
  return (
    <div className="sequenceTab">
      <KooperButton className="seqBackBtn" onClick={() => history.push('/sequences')}>
        <SvgIcon path="common/left" /> Back to Sequences
      </KooperButton>
      <KooperTab
        menu={{
          secondary: true,
          pointing: true,
          color: 'blue',
        }}
        panes={sequences}
        renderActiveOnly={false}
      />
    </div>
  )
}

export default SequencesInsights
