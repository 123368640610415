import React from 'react'
import {useHistory, useLocation, useParams} from 'react-router-dom/cjs/react-router-dom.min'
import {
  KooperButton,
  KooperCard,
  KooperCardContent,
  KooperContainer,
  KooperImage,
  KooperTable,
  KooperTableBody,
  KooperTableCell,
  KooperTableHeader,
  KooperTableHeaderCell,
  KooperTableRow,
} from 'kooper-library'

import {getSingleInvoice} from 'actions/billing'
import logo from 'assets/logo.svg'
import {get, getFormattedDate, startCase} from 'utils/helper'
import SvgIcon from 'components/common/SvgIcon'
import useApiResponse from 'hooks/impure/useApiResponse'

const InvoiceDetail = () => {
  const {invoiceId} = useParams()
  const location = useLocation()
  const history = useHistory()

  const {data: getSingleInvoiceDetailsData = []} = useApiResponse({
    action: getSingleInvoice,
    payload: invoiceId,
    dependency: [invoiceId],
    enabled: invoiceId,
    storePath: 'billing.getSingleInvoiceDetailsData',
  })

  const renderListOfItems = () => {
    return getSingleInvoiceDetailsData?.data?.map(({plan = '', addon = '', quantity = 0, amount = 0}, index) => (
      <KooperTableRow key={index}>
        <KooperTableCell>{index + 1}</KooperTableCell>
        <KooperTableCell>{startCase(plan || addon)}</KooperTableCell>
        <KooperTableCell>{get(['metadata', 'name'], getSingleInvoiceDetailsData, '-')}</KooperTableCell>
        <KooperTableCell>{getFormattedDate(getSingleInvoiceDetailsData.endAt)}</KooperTableCell>
        <KooperTableCell>{quantity || 0}</KooperTableCell>
        <KooperTableCell className="price">$ {amount / 100}</KooperTableCell>
        <KooperTableCell>$ {((quantity * amount) / 100).toFixed(2)}</KooperTableCell>
      </KooperTableRow>
    ))
  }

  const renderDiscountItems = () => {
    return get(['discounts'], getSingleInvoiceDetailsData, []).map(val => (
      <KooperTableRow>
        <KooperTableCell colSpan="5" className="text-right">
          {get(['description'], val, '')} {get(['couponCode'], val) ? `(${get(['couponCode'], val)})` : ''}
        </KooperTableCell>
        <KooperTableCell className="price">- ${get(['amount'], val, '')}</KooperTableCell>
      </KooperTableRow>
    ))
  }

  return (
    <div className="set-layout set-layout-full">
      <KooperContainer fluid>
        <KooperButton
          content="Back"
          transparent
          compact
          icon="chevron left"
          style={{background: 'none'}}
          onClick={() => {
            if (location.state) {
              history.push('/settings/invoices')
            } else {
              history.push('/settings/my-plan')
            }
          }}
        />
        <KooperCard fluid className="invoiceCard">
          <KooperCardContent className="invoiceBody">
            <div className="invHeader">
              <div className="invLeft">
                <KooperImage src={logo} alt="img" size="small" />
                <h3 className="mb-0 mt-5">
                  Invoices : &nbsp;
                  {startCase(
                    get(
                      ['data', [getSingleInvoiceDetailsData?.data?.length - 1], 'plan'],
                      getSingleInvoiceDetailsData,
                      '-'
                    )
                  )}
                </h3>
                <h5 className="mt-1">
                  Date :{' '}
                  {get(['paidAt'], getSingleInvoiceDetailsData)
                    ? getFormattedDate(get(['paidAt'], getSingleInvoiceDetailsData, ''))
                    : getFormattedDate(get(['createdAt'], getSingleInvoiceDetailsData, ''))}
                </h5>
              </div>
              <div className="InvoiceTo">
                <h6>Invoice To</h6>
                <h4 className="clientName">
                  {`${get(['billingProfile', 'firstName'], getSingleInvoiceDetailsData, '')} ${get(
                    ['billingProfile', 'lastName'],
                    getSingleInvoiceDetailsData,
                    ''
                  )}`}
                  <br />
                  <small className="ml-1">
                    {get(['billingProfile', 'companyName'], getSingleInvoiceDetailsData, '')}
                  </small>
                </h4>
                <p className="contactInfo">
                  {/* <SvgIcon path="settings/email" />  */}
                  {get(['billingProfile', 'email'], getSingleInvoiceDetailsData, '')}
                </p>
                <p className="contactInfo">
                  <SvgIcon path="settings/phone" />
                  {`${get(['billingProfile', 'phoneCode'], getSingleInvoiceDetailsData, '-')} ${get(
                    ['billingProfile', 'phone'],
                    getSingleInvoiceDetailsData,
                    '-'
                  )}`}
                </p>
              </div>
            </div>
            <KooperTable basic selectable className="my-5">
              <KooperTableHeader>
                <KooperTableRow>
                  <KooperTableHeaderCell>#</KooperTableHeaderCell>
                  <KooperTableHeaderCell>Name</KooperTableHeaderCell>
                  <KooperTableHeaderCell>Description</KooperTableHeaderCell>
                  <KooperTableHeaderCell>Validity</KooperTableHeaderCell>
                  <KooperTableHeaderCell>Users</KooperTableHeaderCell>
                  <KooperTableHeaderCell>Unit Price</KooperTableHeaderCell>
                  <KooperTableHeaderCell>Total</KooperTableHeaderCell>
                </KooperTableRow>
              </KooperTableHeader>
              <KooperTableBody>
                {renderListOfItems()}
                <KooperTableRow>
                  <KooperTableCell colSpan="6" style={{textAlign: 'right'}}>
                    <h5 className="mb-0">Sub-Total</h5>
                  </KooperTableCell>
                  <KooperTableCell className="price">
                    <h5>
                      $
                      {get(['data'], getSingleInvoiceDetailsData, [])
                        .reduce((acc, el) => (el.amount / 100) * el.quantity + acc, 0)
                        .toFixed(2)}
                    </h5>
                  </KooperTableCell>
                </KooperTableRow>
                {renderDiscountItems()}
                <KooperTableRow>
                  <KooperTableCell colSpan="6" style={{textAlign: 'right'}}>
                    <h5 className="mb-0">Total</h5>
                  </KooperTableCell>
                  <KooperTableCell>
                    <h4 style={{color: '#1d89ff'}}>
                      ${(get(['total'], getSingleInvoiceDetailsData, 0) / 100).toFixed(2)}
                    </h4>
                  </KooperTableCell>
                </KooperTableRow>
              </KooperTableBody>
            </KooperTable>
            <div className="invoiceFooter">
              <h5>Centum Technologies</h5>
              <p className="kooper-lead">807, Gala Empire, Opp. Doordarshan Tower, DriveIn Road, Ahmedabad - 380054</p>
              <p className="contactInfo">
                {/* <SvgIcon path="settings/email" />  */}
                info@centum.com
              </p>
              <p className="contactInfo">
                <SvgIcon path="settings/phone" /> +91 81245 54785
              </p>
            </div>
          </KooperCardContent>
        </KooperCard>
      </KooperContainer>
    </div>
  )
}

export default InvoiceDetail
