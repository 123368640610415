import React, {useState} from 'react'
import {useHistory} from 'react-router-dom'
import {FiChevronRight} from 'react-icons/fi'
import {KooperMenu, KooperMenuItem, KooperAccordion, KooperAccordionTitle, KooperAccordionContent} from 'kooper-library'
import AddSegment from 'components/pages/Customers/Segment/AddSegment'
import {useDispatch} from 'react-redux'
import {getCompanyList} from 'actions/company'
import SvgIcon from 'components/common/SvgIcon'

const CompaniesMenu = props => {
  const dispatch = useDispatch()
  const history = useHistory()

  const [activeCompanies, setActiveCompanies] = useState(true)
  return (
    <>
      <KooperMenu vertical secondary className="kooper-menu">
        <KooperAccordion className="mt-0">
          <KooperAccordionTitle
            style={{justifyContent: 'flex-start'}}
            onClick={() => setActiveCompanies(!activeCompanies)}
          >
            {activeCompanies ? <SvgIcon path="common/down" /> : <FiChevronRight />}
            Companies
          </KooperAccordionTitle>
          <KooperAccordionContent active={activeCompanies}>
            <KooperMenuItem
              active={window.location.pathname === `/companies`}
              onClick={() => {
                history.push(`/companies`)
                dispatch(getCompanyList())
              }}
            >
              <SvgIcon path="clients/companies/all-companies" /> All Companies
            </KooperMenuItem>
            {/* <KooperMenuItem>
              <FiBriefcase /> New
            </KooperMenuItem>
            <KooperMenuItem>
              <FiBriefcase /> Active
            </KooperMenuItem>
            <KooperMenuItem>
              <FiBriefcase /> Slipping Away
            </KooperMenuItem>
            <KooperMenuItem>
              <FiBriefcase /> Unengaged Leads
            </KooperMenuItem> */}
          </KooperAccordionContent>
        </KooperAccordion>
        <AddSegment type="companies" {...props} />
      </KooperMenu>
    </>
  )
}

export default CompaniesMenu
