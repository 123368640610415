/* eslint-disable camelcase */
import React, {useEffect, useState, useCallback} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import {useHistory, useParams} from 'react-router'
import {useForm} from 'react-hook-form'
import {joiResolver} from '@hookform/resolvers'
import {ContentState, convertFromHTML, EditorState} from 'draft-js'
import {stateToHTML} from 'draft-js-export-html'
import _ from 'lodash'

import {GET_CAMPAIGN_DATA, UPDATE_CAMPAIGN} from 'actions/types'
import {getCampaignData, getCampaignFilters, updateCampaign} from 'actions/campaign'
import {extractTextContentFromHTML, get} from 'utils/helper'
import {KooperButton, KooperTab, kooperToast} from 'kooper-library'
import {oneTimeMessageSchema} from 'validation/Marketing/campaign.schema'
import Configuration from './OneTimeMessageForm/Configuration'
import UserAudience from './OneTimeMessageForm/UserAudience'
import Messages from './OneTimeMessageForm/Messages'

const steps = [
  {name: 'Configuration', icon: 'cogs'},
  {name: 'User Audience', icon: 'users'},
  {name: 'Messages', icon: 'comments'},
]

const OneTimeMessageTab = props => {
  const {setWarningModal} = props
  const dispatch = useDispatch()
  const history = useHistory()
  const {id} = useParams()

  const [activeIndex, setActiveIndex] = useState(0)
  const [messageStep, setMessageStep] = useState([])

  const {isLoadingData, successLabels = [], errorLabels = []} = useSelector(state => state.apiReducer)
  const {campaignData = {}} = useSelector(state => state.campaign)

  const initialValue = {
    name: get(['name'], campaignData, ''),
    userId: Number(get(['userId'], campaignData, 0)),
    visibility: Number(get(['visibility'], campaignData, 1)),
    type: Number(get(['type'], campaignData, 1)),
    matchCase: get(['matchCase'], campaignData, 'or'),
    rules: get(['rules'], campaignData, []).map(obj => ({
      filters: obj.filters,
      operator: obj.operator,
    })),
  }

  const {
    handleSubmit,
    control,
    reset,
    errors,
    clearErrors,
    setError,
    setValue,
    watch,
    getValues,
    formState: {isDirty},
  } = useForm({
    mode: 'onTouched',
    shouldFocusError: true,
    reValidateMode: 'onChange',
    shouldUnregister: false,
    defaultValues: initialValue,
    resolver: joiResolver(oneTimeMessageSchema),
  })

  useEffect(() => {
    dispatch(getCampaignFilters())
  }, [dispatch])

  useEffect(() => {
    if (id) {
      dispatch(getCampaignData(id))
    }
  }, [dispatch, id])

  useEffect(() => {
    if (successLabels.includes(GET_CAMPAIGN_DATA)) {
      reset({
        name: get(['name'], campaignData, ''),
        userId: Number(get(['userId'], campaignData, 0)),
        visibility: Number(get(['visibility'], campaignData, 1)),
        type: Number(get(['type'], campaignData, 1)),
        matchCase: get(['matchCase'], campaignData, 'or'),
        rules: (campaignData?.rules || []).map(obj => ({
          filters: obj.filters,
          operator: obj.operator,
        })),
      })

      setMessageStep(
        get(['campaign_message'], campaignData, []).map(obj => {
          if (obj.messageText || obj.messageHtml) {
            const blocksFromHTML = EditorState.createWithContent(
              ContentState.createFromBlockArray(convertFromHTML(obj.messageHtml))
            )
            return {type: 'text', messageHtml: blocksFromHTML}
          }
          if (obj?.mime?.split('/')[0] === 'image') {
            return {
              type: 'image',
              ..._.omit(obj, ['messageText', 'messageHtml']),
            }
          }
          if (obj?.mime?.split('/')[0] === 'video') {
            return {
              type: 'video',
              ..._.omit(obj, ['messageText', 'messageHtml']),
            }
          }
          return null
        })
      )
    }
  }, [successLabels, campaignData, reset])

  useEffect(() => {
    if (errorLabels.includes(GET_CAMPAIGN_DATA)) {
      setWarningModal(false)
      history.push(`/campaigns`)
    }
  }, [errorLabels, history, setWarningModal])

  useEffect(() => {
    if (successLabels.includes(UPDATE_CAMPAIGN)) {
      history.push(`/campaigns`)
    }
  }, [history, setWarningModal, successLabels])

  const handleUnSaved = useCallback(
    _isDirty1 => {
      if (_isDirty1) {
        setWarningModal(true)
      } else {
        setWarningModal(false)
      }
    },
    [setWarningModal]
  )

  useEffect(() => {
    handleUnSaved(isDirty)
    return () => {
      setWarningModal(false)
    }
  }, [handleUnSaved, isDirty, setWarningModal])

  const submitData = data => {
    setWarningModal(false)
    if (messageStep.length <= 0) {
      setError('message', {
        type: 'manual',
        message: 'Please add minimum one message to proceed',
      })
    } else {
      if (messageStep.some(val => val.messageHtml?.getCurrentContent()?.getPlainText()?.trim().length === 0)) {
        kooperToast.error('Editor value is required')
        return
      }
      if (messageStep.some(val => val?.mediaUrl?.length === 0)) {
        kooperToast.error('URl is required')
        return
      }
      const msgs = messageStep?.map(obj => {
        if (obj && obj.type === 'text') {
          return {
            messageHtml: stateToHTML(obj?.messageHtml?.getCurrentContent()),
            messageText: extractTextContentFromHTML(stateToHTML(obj?.messageHtml?.getCurrentContent())),
          }
        }
        return _.omit(obj, ['type'])
      })
      const modify_rules = data?.rules?.map(obj => {
        return _.hasIn(obj, 'operator') ? obj : {...obj, operator: 'or'}
      })

      if (campaignData.id) {
        dispatch(
          updateCampaign(campaignData.id, {
            ...data,
            campaign_message: msgs,
            rules: modify_rules,
            type: campaignData.type,
            status: campaignData.status,
            inboxId: campaignData.inboxId,
          })
        )
      }
    }
  }

  return (
    <div className="kooper-full-page">
      <div className="campaignNavbar">
        <KooperButton primary onClick={() => history.push('/campaigns')}>
          Exit
        </KooperButton>

        <KooperTab
          key={activeIndex}
          panes={steps.map((item, i) => ({
            menuItem: {
              key: item.name,
              icon: item.icon,
              content: item.name,
            },
            render: null,
          }))}
          menu={{
            secondary: true,
            pointing: true,
            color: 'blue',
          }}
          activeIndex={activeIndex}
          onTabChange={(e, {activeIndex}) => {
            setActiveIndex(activeIndex)
          }}
          renderActiveOnly={false}
        />

        <KooperButton primary loading={isLoadingData} disabled={isLoadingData} onClick={handleSubmit(submitData)}>
          Save
        </KooperButton>
      </div>

      {activeIndex === 0 && <Configuration control={control} errors={errors} />}

      {activeIndex === 1 && (
        <UserAudience control={control} errors={errors} clearErrors={clearErrors} watch={watch} setValue={setValue} />
      )}

      {activeIndex === 2 && (
        <Messages
          {...props}
          errors={errors}
          setError={setError}
          messageStep={messageStep}
          setMessageStep={setMessageStep}
          getValuesForm1={getValues}
        />
      )}
    </div>
  )
}

export default OneTimeMessageTab
