/* eslint-disable no-nested-ternary */
import React, {useState, useEffect, useRef} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useHistory, useParams} from 'react-router'
import {Link} from 'react-router-dom'
import {Controller, useForm} from 'react-hook-form'
import {joiResolver} from '@hookform/resolvers'
import _ from 'lodash'
import moment from 'moment'
import {FiChevronRight, FiDollarSign} from 'react-icons/fi'
import {
  KooperCardContent,
  KooperForm,
  KooperFormField,
  KooperGrid,
  KooperGridColumn,
  KooperInput,
  KooperCard,
  KooperAccordionContent,
  KooperAccordion,
  KooperAccordionTitle,
  KooperHeaderSubheader,
  KooperHeader,
  KooperTooltip,
  KooperIcon,
  KooperFormTextArea,
  KooperDatePicker,
  KooperFeed,
  KooperFeedEvent,
  KooperFeedLabel,
  KooperFeedContent,
  KooperFeedSummary,
  KooperFormInput,
  KooperFormSelect,
  KooperRating,
  KooperDropdown,
  KooperButton,
  KooperListDescription,
  KooperListHeader,
  KooperListContent,
  KooperCheckbox,
  KooperListItem,
  KooperList,
} from 'kooper-library'

import {
  ADD_CONTACT_DEAL,
  ATTACHMENT_POST_DEAL,
  CREATE_TASK,
  GET_ACCOUNT_CURRENCY,
  GET_SINGLE_DEALS,
  UNLINK_PRODUCT,
  EDIT_DEAL,
  ADD_NOTE_DEAL,
  GET_USERS,
  GET_SOURCE_DEALS,
} from 'actions/types'
import {getTagLabel} from 'actions/multiple_fields'

import {getContactCompanyList, getContactList} from 'actions/contacts'
import {
  addInteractionDeal,
  addNoteDeal,
  editDeal,
  getSingleDeals,
  getSourceDeals,
  getStageDeals,
  primaryContactChanger,
  unlinkContact,
  unlinkProduct,
  uploadAttachmentsDeals,
} from 'actions/deals'
import {
  get,
  GetFormattedDate,
  abbreviateNumber,
  removeDoubleQuotes,
  wrapText,
  startCase,
  getPermissionTooltipContent,
  getTimeDifferenceFromNow,
} from 'utils/helper'
import {priorityList} from 'constants/variables'
import AccountUserImage from 'components/common/UserImage'
import {getAccountCurrency} from 'actions/setting_currency'
import Category from 'components/common/Category'
import TimeLine from 'components/common/TimeLine'
import Tags from 'components/common/Tags'
import EmailEditorBox from 'components/common/EmailEditorBox'
import {editTaskActivities, getTaskActivities} from 'actions/activities'
import {updateDealSchema} from 'validation/Sales/deals.schema'
import CreateTask from 'components/pages/Projects/Tasks/CreateTask'
import ViewTask from 'components/pages/Projects/Tasks/ViewTask'
import useUserPermissions from 'hooks/pure/useUserPermissions'
import LockPermissionTooltip from 'components/common/LockPermissionTooltip'
import SvgIcon from 'components/common/SvgIcon'
import {getUsers} from 'actions/settings'
import useApiResponse from 'hooks/impure/useApiResponse'
import AddContactModal from './AddContactModal'
import AddProductModel from './AddProductModel'

const DealsDetails = props => {
  const {dealId} = useParams()
  const refForm = useRef()
  const dispatch = useDispatch()
  const history = useHistory()

  const [addContact, setAddContact] = useState(false)
  const [addProduct, setAddProduct] = useState(false)
  const [description, setDescription] = useState('')
  const [stageListData, setStageListData] = useState([])
  const [sourceListData, setSourceListData] = useState([])
  const [assigneeData, setAssigneeData] = useState([])
  const [currencyListData, setcurrencyListData] = useState([])
  const [isCreateTaskOpen, setIsCreateTaskOpen] = useState(false)
  const [toMailBoxList, setToMailBox] = useState([])
  const [isOpenMailComposer, setIsOpenMailComposer] = useState(false)
  const [isViewTaskOpen, setIsViewTaskOpen] = useState(false)
  const [noteText, setNoteText] = useState('')

  const initialFormData = {
    name: '',
    stageId: null,
    sourceId: null,
    priority: '',
    win: null,
    monetaryValue: null,
    currencyId: null,
    description: '',
    closeDate: null,
    isFollowed: false,
    assigneeId: null,
    labelIds: [],
  }

  const {
    handleSubmit,
    errors,
    control,
    reset,
    getValues,
    formState: {dirtyFields},
  } = useForm({
    mode: 'onTouched',
    shouldFocusError: true,
    reValidateMode: 'onChange',
    resolver: joiResolver(updateDealSchema),
    defaultValues: initialFormData,
  })
  const {getStageDeals: getStageDealsList} = useSelector(state => state.deals)
  const {inbox: mailboxes} = useSelector(state => state.mailInbox)
  const {successLabels = []} = useSelector(state => state.apiReducer)

  const {conversationsPermissions, dealsPermissions, tasksPermissions} = useUserPermissions()
  const manageConversationPermissions = conversationsPermissions?.manage_conversations
  const manageDealsPermissions = dealsPermissions?.manage_deals
  const manageTasksPermissions = tasksPermissions?.manage_tasks

  const {data: singleDeals = {}} = useApiResponse({
    action: getSingleDeals,
    payload: dealId,
    dependency: [dealId],
    enabled: dealId,
    label: GET_SINGLE_DEALS,
    storePath: 'deals.singleDeals',
    onSuccess: singleDeals => {
      reset({
        name: get(['name'], singleDeals, ''),
        closeDate: get(['closeDate'], singleDeals, null),
        priority: get(['priority'], singleDeals, ''),
        win: get(['win'], singleDeals, ''),
        description: get(['description'], singleDeals, ''),
        stageId: get(['stageId'], singleDeals, ''),
        sourceId: get(['sourceId'], singleDeals),
        monetaryValue: get(['monetaryValue'], singleDeals, ''),
        currencyId: get(['currencyId'], singleDeals, null),
        assigneeId: get(['assigneeId'], singleDeals, null),
        isFollowed: get(['isFollowed'], singleDeals),
      })
    },
  })

  const {
    // labels: getSingleLeadTags,
    allActivities = [],
    notes: getAllNotesData = [],
    interactions: getAllInterActionsData = [],
    updates: getAllUpdatesData = [],
    interactionTime: getInteractionTime,
  } = singleDeals

  useEffect(() => {
    if (dealId) {
      dispatch(getTagLabel())
      dispatch(getStageDeals())
    }
  }, [dealId, dispatch])

  useEffect(() => {
    if (successLabels.includes(ADD_CONTACT_DEAL)) {
      setAddContact(false)
    }
    if (successLabels.includes(EDIT_DEAL)) {
      setAddProduct(false)
    }
    if (successLabels.includes(UNLINK_PRODUCT)) {
      dispatch(getSingleDeals(dealId))
    }
    if (successLabels.includes(ATTACHMENT_POST_DEAL) || successLabels.includes(CREATE_TASK)) {
      dispatch(getSingleDeals(dealId))
    }
    if (successLabels.includes(ADD_NOTE_DEAL)) {
      dispatch(getSingleDeals(dealId))
      setNoteText('')
    }
  }, [successLabels, dealId, dispatch])

  useApiResponse({
    action: getUsers,
    enabled: true,
    label: GET_USERS,
    storePath: 'settings.getUsersList',
    onSuccess: getUsersList => {
      const list = getUsersList?.users?.map(user => ({
        key: user.id,
        value: user.id,
        text: `${user.firstName} ${user.lastName}`,
      }))
      setAssigneeData(list || [])
    },
  })

  useApiResponse({
    action: getSourceDeals,
    enabled: true,
    label: GET_SOURCE_DEALS,
    storePath: 'deals.getSourceDeals',
    onSuccess: getSourceDealsList => {
      const sourceList = getSourceDealsList.map(list => ({
        key: list.id,
        value: list.id,
        text: list.source,
      }))
      setSourceListData(sourceList)
    },
  })

  useEffect(() => {
    if (getStageDealsList) {
      const stageList = getStageDealsList.map(list => ({
        key: list.id,
        value: list.id,
        text: startCase(list.stage),
      }))
      setStageListData(stageList)
    }
  }, [getStageDealsList])

  useApiResponse({
    label: GET_ACCOUNT_CURRENCY,
    enabled: true,
    storePath: 'settingCurrency.accountCurrencyList',
    action: getAccountCurrency,
    onSuccess: accountCurrencyList => {
      const currList = accountCurrencyList.map(list => ({
        key: list.base.id,
        value: list.currencyId,
        text: list.symbol,
      }))
      setcurrencyListData(currList)
    },
  })

  const renderComposeMail = () => {
    if (isOpenMailComposer)
      return (
        <EmailEditorBox
          isOpenMailComposer={isOpenMailComposer}
          stopDraftOnClose
          setIsOpenMailComposer={setIsOpenMailComposer}
          mailboxes={mailboxes}
          // defaultTo={toMailBoxList}
        />
      )
    return null
  }

  const renderCreateTaskModal = () => {
    if (isCreateTaskOpen) {
      return (
        <CreateTask
          setIsCreateTaskOpen={setIsCreateTaskOpen}
          isCreateTaskOpen={isCreateTaskOpen}
          conversation={get(['conversation'], props)}
          dealId={dealId}
          isDataAvailabel
        />
      )
    }
  }
  const formStateVal = dirtyFields

  const updateDeal = data => {
    const getNewData = _.pick(data, Object.keys(formStateVal))
    if (!_.isEmpty(getNewData)) {
      dispatch(editDeal(getNewData, dealId))
    }
  }

  const renderNotes = () => {
    const notesList = get(['notes'], singleDeals, [])

    if (notesList.length > 0) {
      return notesList
        .filter((list, index) => index < 4)
        .map(list => (
          <KooperListItem>
            <KooperListContent floated="right">
              <span>{getTimeDifferenceFromNow(get(['createdAt'], list, ''))}</span>
            </KooperListContent>

            <KooperListHeader>{get(['note'], list, '')}</KooperListHeader>
            <KooperListDescription>By : {get(['agent'], list, '')}</KooperListDescription>
          </KooperListItem>
        ))
    }
  }

  const renderSummary = () => {
    return (
      <KooperForm
        ref={refForm}
        className="editForm errorLabel"
        {...(manageDealsPermissions && {
          onSubmit: handleSubmit(updateDeal),
        })}
      >
        <KooperFormField>
          <h5>Last Contacted</h5>
          <label className="kooper-lead">{getInteractionTime ? GetFormattedDate(getInteractionTime) : '-'}</label>
        </KooperFormField>
        <KooperFormField>
          <h5>Interactions</h5>
          <label className="kooper-lead">
            {getAllInterActionsData.length > 0 ? getAllInterActionsData.length : '-'}
          </label>
        </KooperFormField>
        <KooperFormField>
          <h5>Inactive For</h5>
          <label className="kooper-lead">{getInteractionTime ? moment(getInteractionTime).fromNow(true) : '-'}</label>
        </KooperFormField>
        <KooperFormField>
          <div className="info-header">
            <label>Stage</label>
            <KooperTooltip
              trigger={<SvgIcon path="common/question" />}
              content="Set initial stage of the deal"
              size="mini"
              psoition="top center"
            />
          </div>
          <Controller
            control={control}
            name="stageId"
            render={({value, onChange}) => (
              <KooperFormSelect
                selection
                fluid
                placeholder="Stage"
                options={stageListData}
                value={value}
                onChange={(e, {value}) => {
                  onChange(value)
                  refForm.current.handleSubmit()
                }}
                disabled={!manageDealsPermissions}
                error={
                  errors.stageId && {
                    content: errors.stageId.message,
                  }
                }
              />
            )}
          />
        </KooperFormField>
        <KooperFormField>
          <div className="info-header">
            <label>Source</label>
            <KooperTooltip
              trigger={<SvgIcon path="common/question" />}
              content="Set source of the deal"
              size="mini"
              psoition="top center"
            />
          </div>
          <Controller
            control={control}
            name="sourceId"
            render={({value, onChange}) => (
              <KooperFormSelect
                selection
                fluid
                options={sourceListData}
                value={value}
                onChange={(e, {value}) => {
                  onChange(value)
                  refForm.current.handleSubmit()
                }}
                disabled={!manageDealsPermissions}
                error={
                  errors.sourceId && {
                    content: errors.sourceId.message,
                  }
                }
              />
            )}
          />
        </KooperFormField>

        <KooperFormField>
          <div className="info-header">
            <label>Value</label>
            <KooperTooltip
              trigger={<SvgIcon path="common/question" />}
              content="Set value of the deal"
              size="mini"
              psoition="top center"
            />
          </div>
          <Controller
            control={control}
            name="monetaryValue"
            render={({value, onChange, onBlur}) => (
              <KooperFormInput
                type="number"
                value={value}
                onChange={(e, {value}) => onChange(value)}
                onBlur={e => {
                  onBlur(e)
                  refForm.current.handleSubmit()
                }}
                error={
                  errors.monetaryValue && {
                    content: errors.monetaryValue.message,
                  }
                }
                onKeyPress={e => {
                  if (e.key === 'Enter') {
                    e.target.blur()
                  }
                }}
                disabled={!manageDealsPermissions}
              />
            )}
          />
        </KooperFormField>
        <KooperFormField>
          <div className="info-header">
            <label>Currency</label>
            <KooperTooltip
              trigger={<SvgIcon path="common/question" />}
              content="Set currency in which the deal will evaluate"
              size="mini"
              psoition="top center"
            />
          </div>
          <Controller
            name="currencyId"
            control={control}
            render={({value, onChange}) => (
              <KooperFormSelect
                selection
                fluid
                search
                placeholder="Select Currency"
                options={currencyListData}
                value={value}
                onChange={(e, {value}) => {
                  onChange(value)
                  refForm.current.handleSubmit()
                }}
                disabled={!manageDealsPermissions}
                error={
                  errors.currencyId && {
                    content: errors.currencyId.message,
                  }
                }
              />
            )}
          />
        </KooperFormField>

        <KooperFormField>
          <div className="info-header">
            <label>Assignee</label>
            <KooperTooltip
              trigger={<SvgIcon path="common/question" />}
              content="Assign an agent for this deal"
              size="mini"
              psoition="top center"
            />
          </div>
          <Controller
            control={control}
            name="assigneeId"
            render={({value, onChange}) => (
              <KooperFormSelect
                placeholder="Assignee"
                selection
                search
                fluid
                clearable
                options={assigneeData}
                value={value}
                onChange={(e, {value}) => {
                  onChange(value)
                  refForm.current.handleSubmit()
                }}
                disabled={!manageDealsPermissions}
                error={
                  errors.assigneeId && {
                    content: errors.assigneeId.message,
                  }
                }
              />
            )}
          />
        </KooperFormField>
      </KooperForm>
    )
  }

  // accordion
  const [activeIndex, setActiveIndex] = useState(0)
  return (
    <div style={{display: 'flex'}}>
      <div className="customerProfilePage">
        <KooperCard className="userProfileInfo">
          <KooperCardContent>
            <KooperTooltip
              size="mini"
              position="bottom center"
              content={
                manageDealsPermissions
                  ? singleDeals.isFollowed
                    ? 'Remove Favorite'
                    : 'Add Favorite'
                  : getPermissionTooltipContent({roleAccess: manageDealsPermissions})
              }
              trigger={
                <KooperRating
                  style={{float: 'right'}}
                  icon="star"
                  className="favStar"
                  onClick={() =>
                    manageDealsPermissions && dispatch(editDeal({isFollowed: !singleDeals.isFollowed}, dealId))
                  }
                  rating={singleDeals.isFollowed ? 1 : 0}
                />
              }
            />
            <KooperButton className="backBtn" onClick={() => history.push('/deals')}>
              <SvgIcon path="common/left" /> Back To Deals
            </KooperButton>

            <KooperHeader className="contName">
              {_.get(singleDeals, 'name') || ''}
              <KooperHeaderSubheader>
                {_.get(singleDeals, 'primaryContact.company.name') || ''}{' '}
                {`${abbreviateNumber(_.get(singleDeals, 'monetaryValue') || '')} ${
                  get(['currency'], singleDeals) || ''
                }`}
              </KooperHeaderSubheader>
            </KooperHeader>

            <div className="userActBtn">
              <KooperTooltip
                size="mini"
                position="bottom center"
                content={
                  manageConversationPermissions
                    ? 'Create Conversation'
                    : getPermissionTooltipContent({roleAccess: manageConversationPermissions})
                }
                trigger={
                  <KooperButton
                    className="mr-3"
                    onClick={() => {
                      if (manageConversationPermissions) {
                        setToMailBox(singleDeals.email && [get(['email'], singleDeals, '-')])
                        setIsOpenMailComposer(true)
                      }
                    }}
                    icon={<SvgIcon path="common/mail" id="TooltipProductInbox" />}
                  />
                }
              />

              <KooperTooltip
                size="mini"
                position="bottom center"
                content={
                  manageTasksPermissions
                    ? 'Create Tasks'
                    : getPermissionTooltipContent({roleAccess: manageTasksPermissions})
                }
                trigger={
                  <KooperButton
                    className="mr-3"
                    icon={<SvgIcon path="common/calendar" />}
                    onClick={() => manageTasksPermissions && setIsCreateTaskOpen(true)}
                  />
                }
              />

              {/* <KooperTooltip
                size="mini"
                position="bottom center"
                content="Send SMS"
                trigger={
                  <KooperButton
                    icon={<FiSend />}
                    // Cant Find a Function, So commented below functions
                    // onClick={() => setIsCreateTaskOpen(true)}
                  />
                }
              /> */}
            </div>

            <div className="staticData">
              <div className="dataOne">
                <p>Deal Score</p>
                <h4>{get(['entityScore'], singleDeals)}</h4>
              </div>
              {/* <div className="dataOne">
                <p>Email Insight Score</p>
                <h4>{get(["emailInsightScore"], singleDeals)}</h4>
              </div> */}
            </div>
          </KooperCardContent>

          <KooperCardContent>
            <KooperForm className="errorLabel" ref={refForm} onSubmit={handleSubmit(updateDeal)}>
              <KooperFormField className="mutiple">
                <label>Name</label>
                <Controller
                  control={control}
                  name="name"
                  render={({value, onChange, onBlur}) => (
                    <KooperFormInput
                      maxLength={20}
                      defaultValue={value}
                      placeholder="Name"
                      onChange={(e, {value}) => onChange(value)}
                      onBlur={e => {
                        onBlur(e)
                        refForm.current.handleSubmit()
                      }}
                      error={
                        errors.name && {
                          content: removeDoubleQuotes(errors.win.message),
                        }
                      }
                      onKeyPress={e => {
                        if (e.key === 'Enter') {
                          e.target.blur()
                        }
                      }}
                      disabled={!manageDealsPermissions}
                    />
                  )}
                />
              </KooperFormField>
              {_.get(singleDeals, 'primaryContact') !== null && (
                <KooperFormField>
                  <div className="info-header">
                    <label>Primary Contact</label>
                    <KooperTooltip
                      trigger={<SvgIcon path="common/question" />}
                      content="Set primary contact for the deal"
                      size="mini"
                      psoition="top center"
                    />
                  </div>
                  <p>
                    {`${_.get(singleDeals, 'primaryContact.firstName') || ''} ${
                      _.get(singleDeals, 'primaryContact.lastName') || ''
                    }`}
                  </p>
                </KooperFormField>
              )}
              <KooperFormField>
                <div className="info-header">
                  <label>Closed Date</label>
                  <KooperTooltip
                    trigger={<SvgIcon path="common/question" />}
                    content="Specify the number of agent’s support you want"
                    size="mini"
                    psoition="top center"
                  />
                </div>
                <Controller
                  control={control}
                  name="closeDate"
                  render={({value, onChange}) => (
                    <KooperDatePicker
                      style={{width: '100%'}}
                      inputProps={{readOnly: true}}
                      value={typeof value === 'string' ? new Date(value) : value}
                      onChange={value => {
                        onChange(value)
                        refForm.current.handleSubmit()
                      }}
                      min={new Date()}
                      time={false}
                      disabled={!manageDealsPermissions}
                    />
                  )}
                />
              </KooperFormField>
              <KooperFormField>
                <div className="info-header">
                  <label>Company</label>
                  <KooperTooltip
                    trigger={<SvgIcon path="common/question" />}
                    content="Choose company to which the deal belong"
                    size="mini"
                    psoition="top center"
                  />
                </div>
                <KooperInput
                  disabled
                  value={_.get(singleDeals, 'primaryContact.company.name') || ''}
                  placeholder="Company"
                />
              </KooperFormField>
              <KooperFormField>
                <div className="info-header">
                  <label>Priority</label>
                  <KooperTooltip
                    trigger={<SvgIcon path="common/question" />}
                    content="Set priority of the deal"
                    size="mini"
                    psoition="top center"
                  />
                </div>
                <Controller
                  control={control}
                  name="priority"
                  render={({value, onChange}) => (
                    <KooperFormSelect
                      options={priorityList}
                      value={value}
                      placeholder="priority"
                      onChange={(e, {value}) => {
                        onChange(value)
                        refForm.current.handleSubmit()
                      }}
                      error={
                        errors.priority && {
                          content: errors.priority.message,
                        }
                      }
                      selection
                      disabled={!manageDealsPermissions}
                    />
                  )}
                />
              </KooperFormField>

              <KooperFormField>
                <div className="info-header">
                  <label>Win Percentage</label>
                  <KooperTooltip
                    trigger={<SvgIcon path="common/question" />}
                    content="Mention deal winning probability"
                    size="mini"
                    psoition="top center"
                  />
                </div>
                <Controller
                  control={control}
                  name="win"
                  render={({value, onChange, onBlur}) => (
                    <KooperFormInput
                      defaultValue={value}
                      placeholder="Win Percentage"
                      onChange={(e, {value}) => onChange(value)}
                      onBlur={e => {
                        onBlur(e)
                        refForm.current.handleSubmit()
                      }}
                      error={
                        errors.win && {
                          content: removeDoubleQuotes(errors.win.message),
                        }
                      }
                      onKeyPress={e => {
                        if (e.key === 'Enter') {
                          e.target.blur()
                        }
                      }}
                      disabled={!manageDealsPermissions}
                      // onKeyPress={(e) => e.charCode >= 48 && e.charCode <= 57}
                    />
                  )}
                />
              </KooperFormField>

              <KooperFormField>
                <label>Description</label>
                <Controller
                  control={control}
                  name="description"
                  render={({value, onChange, onBlur}) => (
                    <KooperFormTextArea
                      style={{resize: 'none'}}
                      defaultValue={value}
                      placeholder="Write description"
                      onChange={(e, {value}) => onChange(value)}
                      onBlur={e => {
                        onBlur(e)
                        refForm.current.handleSubmit()
                      }}
                      error={
                        errors.description && {
                          content: errors.description.message,
                        }
                      }
                      onKeyPress={e => {
                        if (e.key === 'Enter' && e.shiftKey) {
                          setDescription(`${description}\t`)
                        } else if (e.key === 'Enter') {
                          e.target.blur()
                        }
                      }}
                      disabled={!manageDealsPermissions}
                    />
                  )}
                />
              </KooperFormField>
            </KooperForm>
          </KooperCardContent>
        </KooperCard>

        <div className="userTimeLine">
          <KooperCard fluid className="mb-0">
            <Category
              id={dealId}
              addNote={addNoteDeal}
              addLogInteration={addInteractionDeal}
              rolePermissions={manageDealsPermissions}
            />
          </KooperCard>
          <div className="page-header px-0 mt-4" style={{background: 'transparent'}}>
            <div className="page-title">
              <div className="page-titleIcon">
                <h4 className="m-0">Timeline</h4>
              </div>
            </div>
            <div className="page-action">
              <KooperTooltip
                content="Agent"
                size="mini"
                position="top center"
                trigger={
                  <div className="mailActivity">
                    <KooperDropdown
                      icon="chevron down"
                      button
                      selection
                      header="User"
                      style={{float: 'right'}}
                      options={[{value: 'all', text: 'All', key: 'all'}, ...assigneeData]}
                      onChange={(e, {value}) => dispatch(getSingleDeals(dealId, value))}
                      defaultValue="all"
                    />
                  </div>
                }
              />
            </div>
          </div>
          <KooperCard fluid className="m-0">
            <TimeLine
              allActivities={allActivities}
              getAllInterActionsData={getAllInterActionsData}
              getAllNotesData={getAllNotesData}
              getAllUpdatesData={getAllUpdatesData}
            />
          </KooperCard>
        </div>
      </div>
      <div
        className="customerProfile pl-0"
        style={{
          // height: "max-content",
          border: 'none',
        }}
      >
        <div className="callwidget-accord">
          <KooperCard fluid>
            <KooperCardContent className="p-0">
              <KooperAccordion styled className="kooper-accordion">
                <KooperAccordionTitle
                  active={activeIndex === 0}
                  index={0}
                  onClick={(e, titleProps) => {
                    const {index} = titleProps
                    const newIndex = activeIndex === index ? -1 : index
                    setActiveIndex(newIndex)
                  }}
                >
                  Summary
                  <span>{!activeIndex ? <SvgIcon path="common/down" /> : <FiChevronRight />}</span>
                </KooperAccordionTitle>
                <KooperAccordionContent active={activeIndex === 0}>{renderSummary()}</KooperAccordionContent>
              </KooperAccordion>
            </KooperCardContent>
          </KooperCard>

          <KooperCard fluid>
            <KooperCardContent className="p-0">
              <KooperAccordion styled className="kooper-accordion">
                <KooperAccordionTitle
                  active={activeIndex === 1}
                  index={1}
                  onClick={(e, titleProps) => {
                    const {index} = titleProps
                    const newIndex = activeIndex === index ? -1 : index
                    setActiveIndex(newIndex)
                  }}
                >
                  People ({_.size(_.get(singleDeals, 'contacts', 0))})
                  <span>{activeIndex === 1 ? <SvgIcon path="common/down" /> : <FiChevronRight />}</span>
                </KooperAccordionTitle>
                <KooperAccordionContent active={activeIndex === 1}>
                  <LockPermissionTooltip isRoleAccessDenied={!manageDealsPermissions}>
                    <KooperIcon
                      name="user plus"
                      circular
                      className="mb-3"
                      color="blue"
                      onClick={() => {
                        if (manageDealsPermissions) {
                          setAddContact(!addContact)
                          dispatch(getContactList())
                          dispatch(getContactCompanyList())
                        }
                      }}
                    />
                  </LockPermissionTooltip>
                  {_.size(_.get(singleDeals, 'contacts', 0)) === 0 ? (
                    <p className="kooper-lead mb-0">No People</p>
                  ) : (
                    _.get(singleDeals, 'contacts', 0).map(list => (
                      <>
                        <KooperGrid>
                          <KooperGridColumn width={12}>
                            <Link to={list.contactId && `/contacts/${list.contactId}`} target="_blank">
                              <KooperCard link target="_blank">
                                <KooperCardContent>
                                  <KooperFeed>
                                    <KooperFeedEvent>
                                      <KooperFeedLabel>
                                        <AccountUserImage profile={list} />
                                      </KooperFeedLabel>
                                      <KooperFeedContent>
                                        <KooperFeedSummary>
                                          {`${_.get(list, 'firstName') || ''}  ${_.get(list, 'lastName') || ''}`}
                                          <br />
                                          {`${_.get(list, 'title') || ''} at ${_.get(list, 'company.name') || ''}`}
                                        </KooperFeedSummary>
                                      </KooperFeedContent>
                                    </KooperFeedEvent>
                                  </KooperFeed>
                                  {_.get(singleDeals, 'primaryContact.id') === _.get(list, 'contactId') && (
                                    <span>Primary Contact</span>
                                  )}
                                </KooperCardContent>
                              </KooperCard>
                            </Link>
                          </KooperGridColumn>
                          <KooperGridColumn width={4}>
                            {_.get(singleDeals, 'primaryContact.id') !== _.get(list, 'contactId') ? (
                              <KooperTooltip
                                content={
                                  manageDealsPermissions
                                    ? 'Remove'
                                    : getPermissionTooltipContent({roleAccess: manageDealsPermissions})
                                }
                                size="mini"
                                position="right center"
                                trigger={
                                  <SvgIcon
                                    path="common/close"
                                    onClick={() => manageDealsPermissions && dispatch(unlinkContact(dealId, list.id))}
                                  />
                                }
                              />
                            ) : (
                              <span>Change</span>
                            )}
                            <br />
                            {_.get(singleDeals, 'primaryContact.id') !== _.get(list, 'contactId') && (
                              <>
                                <KooperTooltip
                                  content={
                                    manageDealsPermissions
                                      ? 'Update Primary Contact'
                                      : getPermissionTooltipContent({roleAccess: manageDealsPermissions})
                                  }
                                  size="mini"
                                  position="right center"
                                  trigger={
                                    <SvgIcon
                                      path="settings/users"
                                      onClick={() =>
                                        manageDealsPermissions && dispatch(primaryContactChanger(dealId, list.id))
                                      }
                                    />
                                  }
                                />
                                <br />
                              </>
                            )}
                            <FiDollarSign />
                          </KooperGridColumn>
                        </KooperGrid>
                      </>
                    ))
                  )}
                </KooperAccordionContent>
              </KooperAccordion>
            </KooperCardContent>
          </KooperCard>
          <KooperCard fluid>
            <KooperCardContent className="p-0">
              <KooperAccordion styled className="kooper-accordion">
                <KooperAccordionTitle
                  active={activeIndex === 2}
                  index={2}
                  onClick={(e, titleProps) => {
                    const {index} = titleProps
                    const newIndex = activeIndex === index ? -1 : index
                    setActiveIndex(newIndex)
                  }}
                >
                  Product ({_.size(_.get(singleDeals, 'products', 0))})
                  <span>{activeIndex === 2 ? <SvgIcon path="common/down" /> : <FiChevronRight />}</span>
                </KooperAccordionTitle>
                <KooperAccordionContent active={activeIndex === 2}>
                  <LockPermissionTooltip isRoleAccessDenied={!manageDealsPermissions}>
                    <KooperIcon
                      name="user plus"
                      circular
                      className="mb-3"
                      color="blue"
                      onClick={() => manageDealsPermissions && setAddProduct(!addProduct)}
                    />
                  </LockPermissionTooltip>
                  {_.size(_.get(singleDeals, 'products', 0)) === 0 ? (
                    <p className="kooper-lead mb-0">No Product</p>
                  ) : (
                    _.get(singleDeals, 'products', 0).map(list => (
                      <>
                        <KooperGrid>
                          <KooperGridColumn width={12}>
                            <KooperCard link target="_blank">
                              <KooperCardContent>
                                <KooperFeed>
                                  <KooperFeedEvent>
                                    <KooperFeedContent>
                                      <KooperFeedSummary>
                                        ProductName-{`${_.get(list, 'productName') || ''}`}
                                        <br />
                                        Quantity-{`${_.get(list, 'quantity') || ''}`}
                                        <br />
                                        UnitPrice-{`${_.get(list, 'unitPrice') || ''}`}
                                        <br />
                                        UnitTotal-{`${_.get(list, 'unitTotal') || ''}`}
                                      </KooperFeedSummary>
                                    </KooperFeedContent>
                                  </KooperFeedEvent>
                                </KooperFeed>
                              </KooperCardContent>
                            </KooperCard>
                          </KooperGridColumn>
                          <KooperGridColumn width={4}>
                            {_.get(list, 'productId') && (
                              <KooperTooltip
                                content={
                                  manageDealsPermissions
                                    ? 'Remove'
                                    : getPermissionTooltipContent({roleAccess: manageDealsPermissions})
                                }
                                size="mini"
                                position="right center"
                                trigger={
                                  <SvgIcon
                                    path="common/close"
                                    onClick={() => manageDealsPermissions && dispatch(unlinkProduct(dealId, list.id))}
                                  />
                                }
                              />
                            )}
                          </KooperGridColumn>
                        </KooperGrid>
                      </>
                    ))
                  )}
                </KooperAccordionContent>
              </KooperAccordion>
            </KooperCardContent>
          </KooperCard>

          <KooperCard fluid>
            <KooperCardContent className="p-0">
              <KooperAccordion styled className="kooper-accordion">
                <KooperAccordionTitle
                  active={activeIndex === 3}
                  index={3}
                  onClick={(e, titleProps) => {
                    const {index} = titleProps
                    const newIndex = activeIndex === index ? -1 : index
                    setActiveIndex(newIndex)
                  }}
                >
                  Tags ({_.size(_.get(singleDeals, 'labelIds'), 0)})
                  <span>{activeIndex === 3 ? <SvgIcon path="common/down" /> : <FiChevronRight />}</span>
                </KooperAccordionTitle>
                <KooperAccordionContent active={activeIndex === 3}>
                  {_.size(_.get(singleDeals, 'labelIds'), 0).length === 0 ? (
                    <p className="kooper-lead mb-0">No Tags</p>
                  ) : null}

                  <Tags
                    id={dealId}
                    data={singleDeals}
                    tagsUpdate={editDeal}
                    Controller={Controller}
                    control={control}
                    errors={errors}
                    getValues={getValues}
                    rolePermissions={manageDealsPermissions}
                  />
                </KooperAccordionContent>
              </KooperAccordion>
            </KooperCardContent>
          </KooperCard>

          <KooperCard fluid>
            <KooperCardContent className="p-0">
              <KooperAccordion styled className="kooper-accordion">
                <KooperAccordionTitle
                  active={activeIndex === 4}
                  index={4}
                  onClick={(e, titleProps) => {
                    const {index} = titleProps
                    const newIndex = activeIndex === index ? -1 : index
                    setActiveIndex(newIndex)
                  }}
                >
                  Tasks ({get(['tasks'], singleDeals, []).length})
                  <span>{activeIndex === 4 ? <SvgIcon path="common/down" /> : <FiChevronRight />}</span>
                </KooperAccordionTitle>
                <KooperAccordionContent active={activeIndex === 4}>
                  <KooperList className="recentConv">
                    {get(['tasks'], singleDeals, []).length > 0
                      ? get(['tasks'], singleDeals, [])
                          .slice(0, 3)
                          .map(task => (
                            <KooperListItem>
                              <KooperIcon
                                style={{
                                  paddingTop: '4px',
                                }}
                              >
                                <KooperCheckbox
                                  onClick={() => {
                                    if (!manageDealsPermissions) return
                                    let data = {}

                                    if (task.status === 'closed') {
                                      data = {
                                        status: 'open',
                                      }
                                    } else {
                                      data = {
                                        status: 'closed',
                                      }
                                    }
                                    dispatch(editTaskActivities(task.id, data))
                                  }}
                                  checked={task.status === 'closed'}
                                />
                              </KooperIcon>
                              <KooperListContent>
                                <KooperListHeader
                                  as="a"
                                  style={
                                    task.status === 'closed'
                                      ? {
                                          textDecoration: 'line-through',
                                        }
                                      : null
                                  }
                                  onClick={() => {
                                    if (manageDealsPermissions) {
                                      dispatch(getTaskActivities(task.id))
                                      setIsViewTaskOpen(true)
                                    }
                                  }}
                                >
                                  {task.subject ? wrapText(task.subject, 20) : ''}
                                  <span
                                    style={{
                                      float: 'right',
                                      fontSize: 'small',
                                    }}
                                  >
                                    {get(['assignee', 'firstName'], task, '')} {get(['assignee', 'lastName'], task, '')}
                                  </span>
                                </KooperListHeader>
                                {task.status === 'closed' ? null : (
                                  <KooperListDescription>
                                    Due:
                                    {GetFormattedDate(get(['endAt'], task, ''))}
                                  </KooperListDescription>
                                )}
                              </KooperListContent>
                            </KooperListItem>
                          ))
                      : ''}
                  </KooperList>
                  {get(['tasks'], singleDeals, []).length === 0 ? <p className="kooper-lead mb-0">No Task</p> : null}
                  <KooperTooltip
                    content={
                      manageDealsPermissions
                        ? 'Add Task'
                        : getPermissionTooltipContent({roleAccess: manageDealsPermissions})
                    }
                    size="mini"
                    position="top center"
                    trigger={
                      <KooperButton
                        className="mt-3 ml-0 delete-forecast"
                        icon
                        onClick={() => manageDealsPermissions && setIsCreateTaskOpen(!isCreateTaskOpen)}
                      >
                        <SvgIcon path="common/plus" />
                      </KooperButton>
                    }
                  />
                </KooperAccordionContent>
              </KooperAccordion>
            </KooperCardContent>
          </KooperCard>

          <KooperCard fluid>
            <KooperCardContent className="p-0">
              <KooperAccordion styled className="kooper-accordion">
                <KooperAccordionTitle
                  active={activeIndex === 5}
                  index={5}
                  onClick={(e, titleProps) => {
                    const {index} = titleProps
                    const newIndex = activeIndex === index ? -1 : index
                    setActiveIndex(newIndex)
                  }}
                >
                  Notes ({_.size(_.get(singleDeals, 'notes'))})
                  <span>{activeIndex === 5 ? <SvgIcon path="common/down" /> : <FiChevronRight />}</span>
                </KooperAccordionTitle>
                <KooperAccordionContent active={activeIndex === 5}>
                  <KooperForm className="mx-3 mb-2" size="tiny">
                    <KooperFormInput
                      placeholder="Write note here..."
                      disabled={!manageDealsPermissions}
                      onChange={e => manageDealsPermissions && setNoteText(e.target.value)}
                      value={noteText}
                      onKeyPress={e => {
                        if (e.key === 'Enter') {
                          if (!manageDealsPermissions) return
                          const data = {
                            note: e.target.value,
                          }

                          if (e.target.value.length !== 0 && e.target.value.trim() !== '') {
                            dispatch(addNoteDeal(dealId, data))
                          }
                        }
                      }}
                    />
                  </KooperForm>
                  {/* {get(["notes"], singleContact, []).length === 0 ? (
                <p className="kooper-lead px-4 mb-0">No Notes </p>
              ) : null} */}
                  <KooperList selection className="recentConv">
                    {renderNotes()}
                  </KooperList>
                </KooperAccordionContent>
              </KooperAccordion>
            </KooperCardContent>
          </KooperCard>

          <KooperCard fluid>
            <KooperCardContent className="p-0">
              <KooperAccordion styled className="kooper-accordion">
                <KooperAccordionTitle
                  active={activeIndex === 6}
                  index={6}
                  onClick={(e, titleProps) => {
                    const {index} = titleProps
                    const newIndex = activeIndex === index ? -1 : index
                    setActiveIndex(newIndex)
                  }}
                >
                  Files ({_.size(_.get(singleDeals, 'attachments'))})
                  <span>{activeIndex === 6 ? <SvgIcon path="common/down" /> : <FiChevronRight />}</span>
                </KooperAccordionTitle>

                <KooperAccordionContent active={activeIndex === 6} className="pt-0">
                  {get(['attachments'], singleDeals, []).map(item => (
                    <li>{item.name}</li>
                  ))}
                  {_.size(_.get(singleDeals, 'attachments')) === 0 && <p className="kooper-lead">No Files</p>}
                  <LockPermissionTooltip isRoleAccessDenied={!manageDealsPermissions}>
                    <KooperInput
                      fluid
                      id="fileUpload"
                      type="file"
                      multiple
                      disabled={!manageDealsPermissions}
                      onChange={e => {
                        const data = {
                          file: e.target.files,
                        }
                        dispatch(uploadAttachmentsDeals(dealId, data))
                      }}
                    />
                  </LockPermissionTooltip>
                </KooperAccordionContent>
              </KooperAccordion>
            </KooperCardContent>
          </KooperCard>
        </div>
      </div>
      {addContact && <AddContactModal isModalOpen={addContact} setIsModalOpen={setAddContact} dealId={dealId} />}
      {addProduct && <AddProductModel isModalOpen={addProduct} setIsModalOpen={setAddProduct} dealId={dealId} />}
      {renderCreateTaskModal()}
      {renderComposeMail()}
      {isViewTaskOpen && <ViewTask setIsViewTaskOpen={setIsViewTaskOpen} isViewTaskOpen={isViewTaskOpen} />}
    </div>
  )
}

export default DealsDetails
