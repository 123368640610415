/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {createRef, useEffect, useRef, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useHistory} from 'react-router'
import {Controller, useForm} from 'react-hook-form'
import {joiResolver} from '@hookform/resolvers'
import {MdAutorenew} from 'react-icons/md'
import {
  KooperButton,
  KooperCard,
  KooperCardContent,
  KooperDivider,
  KooperDropdown,
  KooperEditorSmall,
  KooperForm,
  KooperFormField,
  KooperFormGroup,
  KooperFormInput,
  KooperFormRadio,
  KooperFormSelect,
  KooperInput,
  KooperList,
  KooperListItem,
  KooperMessage,
  KooperMessageHeader,
  KooperTab,
  KooperTable,
  KooperTableBody,
  KooperTableCell,
  KooperTableHeader,
  KooperTableHeaderCell,
  KooperTableRow,
  KooperTabPane,
  KooperTooltip,
} from 'kooper-library'
import {
  ADD_NEW_INVITEE,
  DELETE_USER,
  GET_USERS,
  GET_MAIL_BOX,
  GET_TEAMS_LIST,
  UPDATE_INBOX,
  GET_ALL_ROLES,
} from 'actions/types'
import {addNewInvitee, getMailBox, resendInvitation, setSMTPSetting} from 'actions'
import {deleteUser, getUsers} from 'actions/settings'
import {getAllTeams} from 'actions/settings_organisation'
import {updateInbox} from 'actions/inbox'
import {copyToClipboard, get, removeDoubleQuotes} from 'utils/helper'
import {fontOptions, fontSizeOptions} from 'constants/variables'
import SettingLayout from 'layouts/settingLayout'
import {KOOPER_ATTACHMENTS} from 'actions/endpoint'
import axios from 'axios'
import {getAppToken} from 'utils/local-storage'
import {inviteeValidationSchmena, validationSchema} from 'validation/Settings/channels/emailInbox.schema'
import useUserPermissions from 'hooks/pure/useUserPermissions'
import LockPermissionTooltip from 'components/common/LockPermissionTooltip'
import useLearnMoreUrl from 'hooks/pure/useLearnMoreUrl'
import SvgIcon from 'components/common/SvgIcon'
import useApiResponse from 'hooks/impure/useApiResponse'
import {getAllRoles} from 'actions/roles'

export const InboxEdit = props => {
  const {match, warningModal, setWarningModal} = props
  const dispatch = useDispatch()
  const history = useHistory()

  const internalEmail = useRef(null)
  const textAreaRef = useRef(null)

  const {inboxId} = match.params

  const [userRoles, setUserRoles] = useState([])
  const [allAssigness, setAllAssigness] = useState([])
  const [teamOptions, setTeamOptions] = useState([])
  const [activeIndex, setActiveIndex] = useState(0)
  const [copyMsg, setCopyMsg] = useState('Copy')
  const [addInvitee, setAddInvitee] = useState(false)
  const [pendingInviteeView, setPendingInviteeView] = useState(false)
  const [signatureEditorState, setSignatureEditorState] = useState('')
  const [autoRespondEditorState, setAutoRespondEditorState] = useState('')

  const {isLoadingData, type, successLabels = []} = useSelector(state => state.apiReducer)
  const {getSingleInbox = {}} = useSelector(state => state.mailInbox)

  const {settingsLearnMoreUrl} = useLearnMoreUrl()
  const emailUpdateLearnMore = settingsLearnMoreUrl?.channels?.email?.updateLearnMore

  const {channelsPermissions} = useUserPermissions()
  const manageEmailsPermissions = channelsPermissions?.manage_emails

  const {
    handleSubmit,
    errors,
    control,
    watch,
    reset,
    formState: {isDirty},
  } = useForm({
    mode: 'onTouched',
    shouldFocusError: true,
    reValidateMode: 'onChange',
    submitFocusError: true,
    shouldUnregister: false,
    resolver: joiResolver(validationSchema),
    defaultValues: {
      name: '',
      email: '',
      internalEmail: '',
      settings: {
        fontSize: 16,
        fontFamily: 'Roboto',
        scheduleAs: 'broadcast',
        signatureEnabled: false,
        autoRespond: false,
        smtpEnabled: false,
      },
      smtp: {serverName: '', userName: '', password: '', port: 0},
    },
  })
  const watchedValue = watch()

  const {
    handleSubmit: handleSubmitInvitee,
    errors: errorsInvitee,
    control: controlInvitee,
    reset: resetInvitee,
  } = useForm({
    mode: 'onTouched',
    shouldFocusError: true,
    reValidateMode: 'onChange',
    submitFocusError: true,
    shouldUnregister: false,
    resolver: joiResolver(inviteeValidationSchmena),
    defaultValues: {
      firstName: '',
      lastName: '',
      inviteeEmail: '',
      role: 'agent',
    },
  })

  const {data: getUsersList = {}} = useApiResponse({
    action: getUsers,
    enabled: true,
    label: GET_USERS,
    storePath: 'settings.getUsersList',
    onSuccess: getUsersList => {
      const list = getUsersList?.users?.map(user => ({
        key: user.id,
        value: user.id,
        text: `${user.firstName} ${user.lastName}`,
        content: (
          <>
            {`${user.firstName} ${user.lastName}`}
            <small className="ml-2 kooper-lead">({user.email})</small>
          </>
        ),
      }))
      setAllAssigness(list || [])
    },
  })
  const {pendingUsers: pendingAssignees = []} = getUsersList || {}

  useApiResponse({
    action: getAllTeams,
    enabled: true,
    label: GET_TEAMS_LIST,
    storePath: 'settingsOrganisation.teamsList',
    onSuccess: teamsList => {
      const list = teamsList.map(team => ({
        key: team.id,
        value: team.id,
        text: team.name,
      }))
      setTeamOptions(list || [])
    },
  })

  const {data: getRoleList = []} = useApiResponse({
    action: getAllRoles,
    enabled: true,
    storePath: 'roles.getRoleList',
  })

  useEffect(() => {
    dispatch(getMailBox(inboxId))
  }, [inboxId, dispatch])

  const resetData = () => {
    reset({
      name: get(['name'], getSingleInbox, ''),
      email: get(['email'], getSingleInbox, ''),
      internalEmail: get(['internalEmail'], getSingleInbox, ''),
      settings: {
        fontSize: get(['settings', 'fontSize'], getSingleInbox, 16),
        fontFamily: get(['settings', 'fontFamily'], getSingleInbox, 'Roboto'),
        scheduleAs: get(['settings', 'scheduleAs'], getSingleInbox, 'round_robin'),
        signatureEnabled: get(['settings', 'signatureEnabled'], getSingleInbox, false),
        autoRespond: get(['settings', 'autoRespond'], getSingleInbox, false),
        smtpEnabled: get(['settings', 'smtpEnabled'], getSingleInbox, false),
      },
      smtp: {
        serverName: get(['smtp', 'serverName'], getSingleInbox, ''),
        username: get(['smtp', 'username'], getSingleInbox, ''),
        password: get(['smtp', 'password'], getSingleInbox, ''),
        port: get(['smtp', 'port'], getSingleInbox, ''),
      },
      assigneeIds: get(['assigneeIds'], getSingleInbox, []),
      teamIds: get(['teamIds'], getSingleInbox, []),
    })
  }

  useEffect(() => {
    if (successLabels.includes(GET_MAIL_BOX)) {
      resetData()
      setAutoRespondEditorState(get(['settings', 'autoResponseEmail'], getSingleInbox, ''))

      setSignatureEditorState(get(['signature', 'body'], getSingleInbox, ''))
    }
  }, [successLabels, getSingleInbox])

  useEffect(() => {
    if (successLabels.includes(UPDATE_INBOX)) {
      history.push('/settings/emails')
      const {inboxId} = match.params
      dispatch(getMailBox(inboxId))
    }
    if (successLabels.includes(DELETE_USER)) {
      dispatch(getMailBox(inboxId))
    }
  }, [successLabels, history, dispatch, inboxId, match])

  useEffect(() => {
    if (successLabels.includes(ADD_NEW_INVITEE)) {
      resetInvitee({
        firstName: '',
        lastName: '',
        inviteeEmail: '',
        role: 'agent',
      })

      dispatch(getMailBox(inboxId))
    }
  }, [successLabels, dispatch, inboxId, resetInvitee])

  useEffect(() => {
    if (successLabels.includes(GET_ALL_ROLES)) {
      const list = getRoleList.map(role => ({
        value: role.id,
        text: role.role,
        id: role.id,
      }))
      list.shift()
      setUserRoles(list)
    }
  }, [successLabels, getRoleList])

  const handleImageUploadBefore = (files, info, uploadHandler) => {
    if (files && files[0]) {
      const formData = new FormData()
      formData.append('files[0]', files[0])
      axios({
        method: 'post',
        url: `${KOOPER_ATTACHMENTS}/inserted-image`,
        data: formData,
        headers: {'Content-Type': 'multipart/form-data', Authorization: `Bearer ${getAppToken()}`},
      }).then(function (res) {
        const response = {
          result: [
            {
              url: res.data.payload,
            },
          ],
        }
        uploadHandler(response)
      })
    }
  }

  const renderInvitees = () => {
    if (pendingAssignees.length !== 0) {
      return (
        <KooperFormField width={8}>
          <KooperTable basic>
            <KooperTableHeader>
              <KooperTableRow>
                <KooperTableHeaderCell>#</KooperTableHeaderCell>
                <KooperTableHeaderCell>Name</KooperTableHeaderCell>
                <KooperTableHeaderCell>Email ID</KooperTableHeaderCell>
                <KooperTableHeaderCell>Action</KooperTableHeaderCell>
              </KooperTableRow>
            </KooperTableHeader>
            <KooperTableBody>
              {pendingAssignees.map((invitee, index) => (
                <KooperTableRow key={index}>
                  <KooperTableCell scope="row">{index + 1}</KooperTableCell>
                  <KooperTableCell>{`${invitee.firstName} ${invitee.lastName}`}</KooperTableCell>
                  <KooperTableCell>{invitee.email}</KooperTableCell>
                  <KooperTableCell style={{display: 'flex'}}>
                    <KooperButton
                      icon={<MdAutorenew />}
                      color="light"
                      onClick={() => {
                        dispatch(resendInvitation(invitee.id))
                      }}
                    />
                    <KooperButton
                      icon={<SvgIcon path="common/delete" />}
                      color="light"
                      onClick={() => {
                        dispatch(deleteUser(invitee.id))
                      }}
                    />
                  </KooperTableCell>
                </KooperTableRow>
              ))}
            </KooperTableBody>
          </KooperTable>
        </KooperFormField>
      )
    }
    return (
      <KooperMessage>
        <KooperMessageHeader>No Pending Invites</KooperMessageHeader>
        <p className="kooper-lead">
          All the pending invites will be shown below. Invite users by sending them an email invitation
        </p>
      </KooperMessage>
    )
  }

  const addToInviteeList = data => {
    const payload = [
      {
        firstName: data.firstName,
        lastName: data.lastName,
        email: data.inviteeEmail,
        roleId: data.role,
        inboxIds: [Number(inboxId)],
      },
    ]
    dispatch(addNewInvitee(payload))
  }

  const renderInviteeFields = () => {
    return (
      <>
        <KooperFormField width={8}>
          <KooperForm className="errorLabel">
            <KooperFormGroup widths="equal">
              <Controller
                name="firstName"
                control={controlInvitee}
                render={({onChange, value, onBlur}) => (
                  <KooperFormInput
                    type="text"
                    fluid
                    value={value}
                    placeholder="First Name"
                    onChange={e => {
                      onChange(e.target.value)
                    }}
                    onBlur={onBlur}
                    error={
                      errorsInvitee.firstName && {
                        content: removeDoubleQuotes(errorsInvitee.firstName.message),
                      }
                    }
                  />
                )}
              />
              <Controller
                name="lastName"
                control={controlInvitee}
                render={({onChange, value, onBlur}) => (
                  <KooperFormInput
                    type="text"
                    fluid
                    value={value}
                    placeholder="Last Name"
                    onChange={e => {
                      onChange(e.target.value)
                    }}
                    onBlur={onBlur}
                    error={
                      errorsInvitee.lastName && {
                        content: removeDoubleQuotes(errorsInvitee.lastName.message),
                      }
                    }
                  />
                )}
              />
            </KooperFormGroup>
            <KooperFormGroup widths="equal">
              <Controller
                name="inviteeEmail"
                control={controlInvitee}
                render={({onChange, value, onBlur}) => (
                  <KooperFormInput
                    type="text"
                    fluid
                    value={value}
                    placeholder="Email"
                    onChange={e => {
                      onChange(e.target.value)
                    }}
                    onBlur={onBlur}
                    error={
                      errorsInvitee.inviteeEmail && {
                        content: removeDoubleQuotes(errorsInvitee.inviteeEmail.message),
                      }
                    }
                  />
                )}
              />
              <Controller
                name="role"
                control={controlInvitee}
                render={({onChange, value, onBlur}) => (
                  <KooperFormSelect
                    selection
                    fluid
                    value={value}
                    options={userRoles}
                    placeholder="Role"
                    onBlur={onBlur}
                    onChange={(e, {value}) => {
                      onChange(value)
                    }}
                    error={
                      errorsInvitee.role && {
                        content: removeDoubleQuotes(errorsInvitee.role.message),
                      }
                    }
                  />
                )}
              />
            </KooperFormGroup>
            <KooperButton primary onClick={handleSubmitInvitee(addToInviteeList)}>
              Send
            </KooperButton>
          </KooperForm>
        </KooperFormField>
      </>
    )
  }

  /// Signature Editor
  const renderSignatureEditor = () => {
    if (watchedValue.settings.signatureEnabled) {
      return (
        <KooperCardContent className="setEditor">
          <KooperEditorSmall
            setContents={signatureEditorState}
            onChange={content => {
              setSignatureEditorState(content)
            }}
            width="100%"
            setOptions={{
              buttonList: [['font', 'fontSize', 'bold', 'underline', 'italic', 'fontColor', 'hiliteColor', 'indent']],
            }}
          />
        </KooperCardContent>
      )
    }
    return null
  }

  // Auto Response Editor
  const renderAutoRespondEditor = () => {
    return (
      watchedValue.settings.autoRespond && (
        <KooperCardContent className="setEditor">
          <KooperEditorSmall
            setContents={autoRespondEditorState}
            onChange={content => {
              setAutoRespondEditorState(content)
            }}
            onImageUploadBefore={handleImageUploadBefore}
            width="100%"
            setOptions={{
              fontSize: [10, 12, 16, 18, 100],

              height: 200,
              buttonList: [['font', 'fontSize', 'bold', 'underline', 'italic', 'fontColor', 'hiliteColor', 'indent']],
            }}
          />
        </KooperCardContent>
      )
    )
  }

  const renderDns = () => {
    if (getSingleInbox !== null) {
      if (get(['domain', 'dns'], getSingleInbox)) {
        return getSingleInbox.domain.dns.map(dns => {
          const valueRef = createRef(null)
          return (
            <tr>
              <td>{dns.host}</td>
              <td>{dns.type}</td>
              <td>
                <KooperFormInput
                  type="text"
                  value={dns.data}
                  ref={valueRef}
                  action={{
                    color: 'blue',
                    labelPosition: 'right',
                    icon: 'copy',
                    content: copyMsg,
                    onClick: e => copyToClipboard(e, valueRef, setCopyMsg),
                  }}
                />
              </td>
            </tr>
          )
        })
      }
      return null
    }
    return null
  }

  const panes = [
    {
      menuItem: {key: 'general', icon: <SvgIcon path="settings/general" />, content: 'General'},
      render: () => (
        <KooperTabPane>
          <KooperForm className="errorLabel mt-4">
            <KooperFormGroup widths="equal">
              <KooperFormField required>
                <div className="info-header">
                  <label htmlFor="inobxName">Name</label>
                  <KooperTooltip
                    content="Specify name of the inbox"
                    size="mini"
                    position="top center"
                    trigger={<SvgIcon path="common/question" />}
                  />
                </div>
                <Controller
                  name="name"
                  control={control}
                  render={({onChange, value, onBlur}) => (
                    <KooperFormInput
                      maxLength={25}
                      id="name"
                      value={value}
                      onChange={e => onChange(e.target.value)}
                      onBlur={onBlur}
                      error={
                        errors.name && {
                          content: removeDoubleQuotes(errors.name.message),
                        }
                      }
                    />
                  )}
                />
              </KooperFormField>
              <KooperFormField>
                <div className="info-header">
                  <label htmlFor="inobxEmail">Email</label>
                  <KooperTooltip
                    content="Specify email address to link with inbox"
                    size="mini"
                    position="top center"
                    trigger={<SvgIcon path="common/question" />}
                  />
                </div>
                <Controller
                  name="email"
                  control={control}
                  render={({onChange, value, onBlur}) => (
                    <KooperFormInput
                      id="inboxEmail"
                      value={value}
                      onChange={e => onChange(e.target.value)}
                      onBlur={onBlur}
                      error={
                        errors.email && {
                          content: removeDoubleQuotes(errors.email.message),
                        }
                      }
                      readOnly={!!get(['email'], getSingleInbox)}
                    />
                  )}
                />
              </KooperFormField>
            </KooperFormGroup>
            <KooperFormGroup>
              <KooperFormField width={8}>
                <div className="info-header">
                  <label>Address</label>
                  <KooperTooltip
                    content="Address to your email inbox"
                    size="mini"
                    position="top center"
                    trigger={<SvgIcon path="common/question" />}
                  />
                </div>
                <Controller
                  name="internalEmail"
                  render={({value}) => (
                    <KooperInput
                      value={value}
                      ref={internalEmail}
                      action={{
                        color: 'blue',
                        labelPosition: 'right',
                        icon: 'copy',
                        content: copyMsg,
                        onClick: e => copyToClipboard(e, internalEmail, setCopyMsg),
                      }}
                      error={
                        errors.internalEmail && {
                          content: errors.internalEmail.message,
                        }
                      }
                    />
                  )}
                  control={control}
                />
              </KooperFormField>
            </KooperFormGroup>
            <KooperFormGroup>
              <KooperFormField width={8}>
                <div className="info-header">
                  <label htmlFor="inobxAdd">Assignee</label>
                  <KooperTooltip
                    content="Assign agents for the inbox"
                    size="mini"
                    position="top center"
                    trigger={<SvgIcon path="common/question" />}
                  />
                </div>
                <Controller
                  name="assigneeIds"
                  render={({value, onChange, onBlur}) => (
                    <KooperFormSelect
                      selection
                      fluid
                      multiple
                      value={value}
                      options={allAssigness}
                      onChange={(e, {value}) => {
                        onChange(value)
                      }}
                      onBlur={onBlur}
                      error={
                        errors.assigneeIds && {
                          content: errors.assigneeIds.message,
                        }
                      }
                    />
                  )}
                  control={control}
                />
              </KooperFormField>
              <KooperFormField width={8}>
                <div className="info-header">
                  <label>Teams</label>
                  <KooperTooltip
                    content="Assign team for the inbox"
                    size="mini"
                    position="top center"
                    trigger={<SvgIcon path="common/question" />}
                  />
                </div>
                <Controller
                  name="teamIds"
                  control={control}
                  render={({value, onChange}) => (
                    <KooperFormSelect
                      selection
                      fluid
                      multiple
                      value={value}
                      options={teamOptions}
                      onChange={(e, {value}) => onChange(value)}
                      error={
                        errors.teamIds && {
                          content: errors.teamIds.message,
                        }
                      }
                    />
                  )}
                />
              </KooperFormField>
            </KooperFormGroup>
            <KooperFormField width={8}>
              <KooperList horizontal style={{marginTop: '61px'}}>
                <LockPermissionTooltip isRoleAccessDenied={!manageEmailsPermissions}>
                  <KooperListItem
                    style={{cursor: 'pointer'}}
                    onClick={() => {
                      if (manageEmailsPermissions) {
                        setAddInvitee(!addInvitee)
                        if (pendingInviteeView) setPendingInviteeView(false)
                      }
                    }}
                  >
                    Add Invite
                  </KooperListItem>
                </LockPermissionTooltip>
                <LockPermissionTooltip isRoleAccessDenied={!manageEmailsPermissions}>
                  <KooperListItem
                    as="a"
                    style={{cursor: 'pointer'}}
                    onClick={() => {
                      if (manageEmailsPermissions) {
                        setPendingInviteeView(!pendingInviteeView)
                        if (addInvitee) setAddInvitee(false)
                      }
                    }}
                  >
                    View Pending Invite
                  </KooperListItem>
                </LockPermissionTooltip>
              </KooperList>
            </KooperFormField>
            <KooperFormGroup>
              {addInvitee && renderInviteeFields()}
              {pendingInviteeView && renderInvitees()}
            </KooperFormGroup>
            <KooperFormGroup widths="equal">
              <KooperFormField>
                <div className="info-header">
                  <label>Schedule As</label>
                  <KooperTooltip
                    content="Set the pattern of receiving incoming tickets from customers"
                    size="mini"
                    position="top center"
                    trigger={<SvgIcon path="common/question" />}
                  />
                </div>
                <Controller
                  name="settings.scheduleAs"
                  control={control}
                  render={({value, onChange, onBlur}) => (
                    <KooperFormSelect
                      selection
                      value={value}
                      options={[
                        {
                          value: 'broadcast',
                          key: 'broadcast',
                          text: 'Broadcast',
                        },
                        {
                          value: 'round_robin',
                          key: 'round_robin',
                          text: 'Round Robin',
                        },
                      ]}
                      onChange={(e, {value}) => {
                        onChange(value)
                      }}
                      onBlur={onBlur}
                      error={
                        get(['settings', 'scheduleAs'], errors) && {
                          content: get(['settings', 'scheduleAs'], errors).message,
                        }
                      }
                    />
                  )}
                />
              </KooperFormField>
              <KooperFormField>
                <div className="info-header">
                  <label>Font Family</label>
                  <KooperTooltip
                    content="Select font which you would like your Inbox messages to have"
                    size="mini"
                    position="top center"
                    trigger={<SvgIcon path="common/question" />}
                  />
                </div>
                <Controller
                  name="settings.fontFamily"
                  control={control}
                  render={({value, onChange, onBlur}) => (
                    <KooperFormSelect
                      selection
                      value={value}
                      options={fontOptions}
                      onChange={(e, {value}) => onChange(value)}
                      onBlur={onBlur}
                      error={
                        get(['settings', 'fontFamily'], errors) && {
                          content: get(['settings', 'fontFamily'], errors).message,
                        }
                      }
                    />
                  )}
                />
              </KooperFormField>
            </KooperFormGroup>
            <KooperFormGroup>
              <KooperFormField width={8}>
                <div className="info-header">
                  <label>Font Size</label>
                  <KooperTooltip
                    content="Select a font size for all the content inside your email inbox"
                    size="mini"
                    position="top center"
                    trigger={<SvgIcon path="common/question" />}
                  />
                </div>
                <Controller
                  name="settings.fontSize"
                  control={control}
                  render={({value, onChange, onBlur}) => (
                    <KooperFormSelect
                      selection
                      value={value}
                      options={fontSizeOptions}
                      onChange={(e, {value}) => onChange(value)}
                      onBlur={onBlur}
                      error={
                        get(['settings', 'fontSize'], errors) && {
                          content: get(['settings', 'fontSize'], errors).message,
                        }
                      }
                    />
                  )}
                />
              </KooperFormField>
            </KooperFormGroup>
          </KooperForm>

          <KooperCard fluid className="switchBox">
            <KooperCardContent>
              <div className="d-flex">
                <div>
                  <h5>Signature</h5>
                  <p className="kooper-lead">
                    Enable and setup your personal signature for every email or messages you send
                  </p>
                </div>
                <Controller
                  name="settings.signatureEnabled"
                  control={control}
                  render={({value, onChange}) => (
                    <KooperFormRadio
                      toggle
                      checked={value}
                      onChange={(e, {checked}) => onChange(checked)}
                      error={
                        get(['settings', 'signatureEnabled'], errors) && {
                          content: get(['settings', 'signatureEnabled'], errors).message,
                        }
                      }
                    />
                  )}
                />
              </div>
            </KooperCardContent>
            {renderSignatureEditor()}
          </KooperCard>

          <KooperCard fluid className="switchBox">
            <KooperCardContent>
              <div className="d-flex">
                <div>
                  <h5>Auto Respond</h5>
                  <p className="kooper-lead">Setup a auto respond any emails without entertaining manually</p>
                </div>
                <Controller
                  name="settings.autoRespond"
                  control={control}
                  render={({value, onChange}) => {
                    return (
                      <KooperFormRadio
                        toggle
                        checked={value}
                        onChange={(e, {checked}) => onChange(checked)}
                        error={
                          get(['settings', 'autoRespond'], errors) && {
                            content: get(['settings', 'autoRespond'], errors).message,
                          }
                        }
                      />
                    )
                  }}
                />
              </div>
            </KooperCardContent>
            {renderAutoRespondEditor()}
          </KooperCard>
        </KooperTabPane>
      ),
    },
    {
      menuItem: {
        key: 'incoming',
        icon: <SvgIcon path="settings/incoming-email" />,
        content: 'Incoming Emails',
      },
      render: () => (
        <KooperTabPane>
          <h4 className="mt-4">Receive emails to your Kooper Inbox by following below instructions</h4>
          <p className="kooper-lead">
            Forward a copy of all <b>{getSingleInbox.email}</b> to this address :
          </p>
          <KooperInput
            type="text"
            value={getSingleInbox.internalEmail}
            ref={textAreaRef}
            action={{
              color: 'blue',
              labelPosition: 'right',
              icon: 'copy',
              content: copyMsg,
              onClick: e => copyToClipboard(e, textAreaRef, setCopyMsg),
            }}
          />
          <p className="lead mt-3">
            Email the details to your IT admin: <a href="">Email to IT Admin</a>
          </p>
          <h5 className="mt-5">Checkout below links toturial for How to forward your email inbox :</h5>
          <ul className="mt-3">
            <li>
              For Gmail : <a href="">Setup your GMAIL Account</a>
            </li>
            <li>
              For Outlook : <a href="">Setup your OUTLOOK Account</a>
            </li>
            <li>
              For Yahoo : <a href="">Setup your YAHOO Account</a>
            </li>
          </ul>
        </KooperTabPane>
      ),
    },
    {
      menuItem: {
        key: 'outgoing',
        icon: <SvgIcon path="settings/outgoing-email" />,
        content: 'Outgoing Emails',
      },
      render: () => (
        <KooperTabPane>
          <KooperForm className="mt-4">
            <KooperFormField>
              <p className="kooper-lead">
                Make sure your emails go straight to the inbox by using these authentication tools.
              </p>
              <Controller
                name="settings.smtpEnabled"
                control={control}
                render={({value, onChange}) => (
                  <KooperFormRadio
                    label="Kooper will send my emails"
                    onChange={() => {
                      onChange(!value)
                    }}
                    checked={value}
                  />
                )}
              />
            </KooperFormField>
            <KooperTable basic selectable>
              <KooperTableHeader>
                <KooperTableRow>
                  <KooperTableHeaderCell>Host</KooperTableHeaderCell>
                  <KooperTableHeaderCell>Type</KooperTableHeaderCell>
                  <KooperTableHeaderCell>Value</KooperTableHeaderCell>
                  <KooperTableHeaderCell>Action</KooperTableHeaderCell>
                </KooperTableRow>
              </KooperTableHeader>
              <KooperTableBody>{renderDns()}</KooperTableBody>
            </KooperTable>
            <KooperDivider hidden />
            <KooperButton primary basic>
              Test settings
            </KooperButton>
            <KooperButton>Step-by-Step Instruction</KooperButton>
            <KooperDivider hidden />
            <KooperFormField>
              <Controller
                name="smtpEnabled"
                control={control}
                render={({value, onChange}) => (
                  <KooperFormRadio
                    label="Use my custom outgoing (SMTP) setting"
                    onChange={(e, {value}) => {
                      onChange(value)
                    }}
                    checked={!value}
                  />
                )}
              />
            </KooperFormField>
            <KooperFormField inline>
              <label htmlFor="Outgoing">Outgoing Server</label>
              <Controller
                name="serverName"
                control={control}
                render={({value, onChange, onBlur}) => (
                  <KooperFormInput
                    id="Outgoing"
                    value={value}
                    onChange={(e, {value}) => {
                      onChange(value)
                    }}
                    onBlur={onBlur}
                  />
                )}
              />
            </KooperFormField>
            <KooperFormField inline>
              <label>Username</label>
              <Controller
                name="username"
                control={control}
                render={({value, onChange, onBlur}) => (
                  <KooperFormInput
                    value={value}
                    onChange={(e, {value}) => {
                      onChange(value)
                    }}
                    onBlur={onBlur}
                    error={
                      errorsInvitee.username && {
                        content: errorsInvitee.username.message,
                      }
                    }
                  />
                )}
              />
            </KooperFormField>
            <KooperFormField inline>
              <label>Password</label>
              <Controller
                name="password"
                control={control}
                render={({value, onChange}) => (
                  <KooperFormInput
                    value={value}
                    onChange={(e, {value}) => {
                      onChange(value)
                    }}
                    error={
                      errorsInvitee.password && {
                        content: errorsInvitee.password.message,
                      }
                    }
                  />
                )}
              />
            </KooperFormField>
            <KooperFormField inline>
              <label>Port</label>
              <Controller
                name="port"
                control={control}
                render={({value, onChange}) => (
                  <KooperFormInput
                    value={value}
                    onChange={(e, {value}) => {
                      onChange(value)
                    }}
                    error={
                      errorsInvitee.port && {
                        content: errorsInvitee.port.message,
                      }
                    }
                  />
                )}
              />
            </KooperFormField>
            <KooperFormField inline>
              <label>Security</label>
              <KooperDropdown />
            </KooperFormField>
          </KooperForm>
        </KooperTabPane>
      ),
    },
  ]

  // Update Inbox with autorespond,signature,acknowledge wiht body, flag and name of inbox
  const updateMailInbox = data => {
    const signatureStringfied = signatureEditorState
    const autoResonseStringfied = autoRespondEditorState

    data.signature = {body: signatureStringfied}
    data.settings.autoResponseEmail = autoResonseStringfied

    const {id} = getSingleInbox

    dispatch(updateInbox(data, id))
    setWarningModal(false)
  }

  const changeSMTPSettings = data => {
    const payload = {
      server: data.smtp.serverName,
      userName: data.smtp.userName,
      password: data.smtp.password,
      port: data.smtp.port,
    }

    dispatch(setSMTPSetting(payload, getSingleInbox.id))
  }

  const actionButton = () => {
    if (activeIndex === 0) {
      return {
        cancel: {
          content: 'Cancel',
          disabled: isLoadingData && type.includes(UPDATE_INBOX),
          onClick: () => {
            if (!warningModal) {
              setWarningModal(false)
              history.push('/settings/emails')
              resetData()
            } else if (warningModal) {
              history.push('/settings/emails')
            }
          },
        },
        success: {
          content: 'Update',
          disabled: !isDirty || (isLoadingData && type.includes(UPDATE_INBOX)),
          loading: isLoadingData && type.includes(UPDATE_INBOX),
          ...(manageEmailsPermissions && {
            onClick: handleSubmit(updateMailInbox),
          }),
        },
      }
    }

    if (activeIndex === 2) {
      return {
        success: {
          content: 'Update',
          loading: isLoadingData && type.includes(UPDATE_INBOX),
          disabled: isLoadingData && type.includes(UPDATE_INBOX),
          ...(manageEmailsPermissions && {
            onClick: handleSubmit(changeSMTPSettings),
          }),
        },
      }
    }

    return null
  }

  if (getSingleInbox !== null) {
    return (
      <SettingLayout
        header={[
          `${getSingleInbox.name ? getSingleInbox.name : '-'}`,
          <small className="ml-3">({getSingleInbox.email ? getSingleInbox.email : '-'})</small>,
        ]}
        subHeader="Create email inbox by mentioning all the required information"
        learnMoreUrl={emailUpdateLearnMore}
        table={false}
        headerDivider
        actionButton={actionButton()}
        headerbackbtn={{
          onClick: () => history.push('/settings/emails'),
        }}
        lockRole={!manageEmailsPermissions}
      >
        <KooperTab
          panes={panes}
          activeIndex={activeIndex}
          menu={{color: 'blue', secondary: true, pointing: true}}
          onTabChange={(e, {activeIndex}) => {
            setActiveIndex(activeIndex)
            setCopyMsg('Copy')
          }}
        />
      </SettingLayout>
    )
  }
  return null
}
