/* eslint-disable no-nested-ternary */
import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useHistory} from 'react-router'
import CreateFirst from 'components/common/CreateFirst'
import _ from 'lodash'
import {
  KooperButton,
  KooperDivider,
  KooperDropdown,
  KooperGrid,
  KooperGridColumn,
  KooperGridRow,
  KooperHeader,
  KooperTable,
  KooperTableBody,
  KooperTableCell,
  KooperTableHeader,
  KooperTableHeaderCell,
  KooperTablePlaceholder,
  KooperTableRow,
  KooperTooltip,
} from 'kooper-library'
import {DELETE_EXPENSES, GET_ALL_EXPENSES, GET_EXPENSES_CATEGORY} from 'actions/types'
import {deleteExpenses, getAllExpenses, getExpensesCategory} from 'actions/expenses'
import create from 'assets/create.svg'
import {getFormattedDate, startCase} from 'utils/helper'
import {expenseStatus} from 'constants/hr/expense'
import SearchComponent from 'components/common/SearchComponent'
import DeleteModal from 'components/common/DeleteModal'
import ExpenseSidemenu from 'navigation/sub-routes/Hr/Expenses/ExpenseMenu'
import PageLoader from 'components/common/PageLoader'
import useApiLoader from 'hooks/pure/useApiLoader'
import SettingLayout from 'layouts/settingLayout'
import SvgIcon from 'components/common/SvgIcon'
import useApiResponse from 'hooks/impure/useApiResponse'

const summary = [
  {color: 'green', time: '120h', name: 'Estimated time'},
  {color: 'blue', time: '6h 25m', name: 'Total logged time'},
  {color: 'orange', time: '20m', name: 'Billed time'},
  {color: 'red', time: '6h 5m', name: 'Billable time'},
  {color: 'purple', time: '0h', name: 'Non-billable'},
]

const Expenses = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  const [deleteModal, setDeleteModal] = useState(false)
  const [editId, setEditId] = useState()
  const [categoryList, setCategoryList] = useState([])
  const [currentListParams, setCurrentListParams] = useState({
    page: 1,
    status: '',
    category: '',
    sortBy: '',
    sortType: '',
    text: '',
  })

  const {isLoadingData, type, successLabels = []} = useSelector(state => state.apiReducer)

  const {isLoading} = useApiLoader({label: GET_ALL_EXPENSES})

  const {data: allExpensesList = []} = useApiResponse({
    action: getAllExpenses,
    enabled: true,
    label: GET_ALL_EXPENSES,
    storePath: 'expenses.allExpensesList',
  })

  useApiResponse({
    action: getExpensesCategory,
    enabled: true,
    label: GET_EXPENSES_CATEGORY,
    storePath: 'expenses.getExpensesCategoryData',
    onSuccess: getExpensesCategoryData => {
      setCategoryList(
        getExpensesCategoryData.map(({category, id}) => {
          return {key: id, value: id, text: category}
        })
      )
    },
  })

  useEffect(() => {
    if (successLabels.includes(DELETE_EXPENSES)) {
      dispatch(getAllExpenses())
    }
  }, [successLabels, dispatch])

  useEffect(() => {
    const newCurrentListParams = _.omitBy(currentListParams, value => {
      if (value === 'all' || value === '') {
        return true
      }
      return false
    })

    dispatch(getAllExpenses(newCurrentListParams))
  }, [currentListParams, dispatch])

  const renderTableBody = () => {
    return allExpensesList.map(({id, name, expenseDate, employee, totalAmount, paidBy, status}, index) => {
      return (
        <KooperTableRow
          key={id}
          className="tableLink"
          onClick={() => {
            history.push(`/expenses/update/${id}`)
          }}
        >
          <KooperTableCell>{index + 1}</KooperTableCell>
          <KooperTableCell>
            <KooperHeader as="h5">{startCase(name)}</KooperHeader>
          </KooperTableCell>
          <KooperTableCell>{getFormattedDate(expenseDate)}</KooperTableCell>
          <KooperTableCell>{startCase(employee?.name)}</KooperTableCell>
          <KooperTableCell>{totalAmount}</KooperTableCell>
          <KooperTableCell>{startCase(paidBy)}</KooperTableCell>
          <KooperTableCell className="tag-label">{startCase(status)}</KooperTableCell>
          <KooperTableCell>
            <KooperTooltip
              content="Delete"
              size="mini"
              position="top center"
              trigger={
                <KooperButton
                  icon={<SvgIcon path="common/delete" />}
                  onClick={e => {
                    e.stopPropagation()
                    setEditId(id)
                    setDeleteModal(true)
                  }}
                />
              }
            />
          </KooperTableCell>
        </KooperTableRow>
      )
    })
  }

  if (isLoading) {
    return <PageLoader />
  }

  return (
    <>
      {allExpensesList?.length > 0 ||
      currentListParams.text ||
      currentListParams.status ||
      currentListParams.category ? (
        <>
          <ExpenseSidemenu />

          <div className="kooper-side-wrap">
            <div className="page-header pb-0">
              <div className="page-title">
                <div className="page-titleIcon">
                  <SvgIcon path="common/file" />
                  <h5>Expenses List ({allExpensesList ? allExpensesList.length : 0})</h5>
                </div>
              </div>
              <div className="page-action">
                <SearchComponent onSuccess={value => setCurrentListParams({text: value})} />
                <KooperTooltip
                  content="Status"
                  size="mini"
                  position="top center"
                  trigger={
                    <KooperDropdown
                      icon="chevron down"
                      button
                      className="dropdown-ellipsis"
                      defaultValue="all"
                      options={[{key: 'all', value: 'all', text: 'All Status'}, <KooperDivider />, ...expenseStatus]}
                      onChange={(e, {value}) => {
                        setCurrentListParams({
                          ...currentListParams,
                          status: value,
                        })
                      }}
                    />
                  }
                />
                <KooperTooltip
                  content="Category"
                  size="mini"
                  position="top center"
                  trigger={
                    <KooperDropdown
                      icon="chevron down"
                      button
                      className="dropdown-ellipsis"
                      defaultValue="all"
                      options={[
                        {key: 'all', value: 'all', text: 'All Category'},
                        {
                          key: 'No Category',
                          value: 'No Category',
                          text: 'No Category',
                        },
                        <KooperDivider />,
                        ...categoryList,
                      ]}
                      onChange={(e, {value}) => {
                        setCurrentListParams({
                          ...currentListParams,
                          category: value,
                        })
                      }}
                    />
                  }
                />
                <KooperButton content="Add" primary onClick={() => history.push('/expenses/create')} />
              </div>
            </div>

            <KooperGrid style={{margin: '24px 0'}}>
              <KooperGridRow columns={5} className="p-0 m-0">
                {summary.map(e => (
                  <KooperGridColumn className="summary-grid">
                    <div className="m-0" style={{'--summary-color': e.color}}>
                      <span>{e.name}</span>
                      <span>{e.time}</span>
                    </div>
                  </KooperGridColumn>
                ))}
              </KooperGridRow>
            </KooperGrid>

            <KooperTable sortable basic selectable>
              <KooperTableHeader>
                <KooperTableRow>
                  <KooperTableHeaderCell>#</KooperTableHeaderCell>
                  <KooperTableHeaderCell>Expense Name</KooperTableHeaderCell>
                  <KooperTableHeaderCell>Expenses Date</KooperTableHeaderCell>
                  <KooperTableHeaderCell>Employee</KooperTableHeaderCell>
                  <KooperTableHeaderCell>Amount</KooperTableHeaderCell>
                  <KooperTableHeaderCell>Paid By</KooperTableHeaderCell>
                  <KooperTableHeaderCell>Status</KooperTableHeaderCell>
                  <KooperTableHeaderCell>Actions</KooperTableHeaderCell>
                </KooperTableRow>
              </KooperTableHeader>
              <KooperTableBody>
                {isLoadingData && type.includes(GET_ALL_EXPENSES) ? (
                  <KooperTablePlaceholder columns={8} />
                ) : (allExpensesList || []).length > 0 ? (
                  renderTableBody()
                ) : (
                  <tr>
                    <td colSpan={8} className="emptyValue">
                      No Data Available
                    </td>
                  </tr>
                )}
              </KooperTableBody>
            </KooperTable>
            {deleteModal && (
              <DeleteModal
                isModalOpen={deleteModal}
                setIsModalOpen={setDeleteModal}
                modalText="Are you sure you want to delete ?"
                deleteAction={deleteExpenses}
                idTobeDeleted={editId}
                type={DELETE_EXPENSES}
              />
            )}
          </div>
        </>
      ) : (
        <SettingLayout>
          <CreateFirst
            header="Expenses"
            subHeader="Manage and track expenses incurred by employees for various purposes"
            addFirst={() => history.push('/expenses/create')}
            buttonText="Create Expenses"
            Feature1="Expense Tracking"
            Feature2="Receipt Attachments"
            Feature3="Expense Categories"
            list1="Employees can log and track their expenses"
            list2="Attach receipt of expenses for proper verification."
            list3="Create categories for different types of expenses"
            imageSrc={create}
          />
        </SettingLayout>
      )}
    </>
  )
}

export default Expenses
