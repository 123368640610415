/* eslint-disable no-nested-ternary */
/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useHistory, useParams} from 'react-router'
import {Controller, useFieldArray, useForm} from 'react-hook-form'
import {joiResolver} from '@hookform/resolvers'
import {FiCalendar, FiChevronLeft, FiMail} from 'react-icons/fi'
import _ from 'lodash'
import {
  KooperCardContent,
  KooperCard,
  KooperDropdown,
  KooperHeader,
  KooperTooltip,
  KooperHeaderSubheader,
  KooperRating,
  KooperButton,
  KooperMenuItem,
} from 'kooper-library'

import {
  ADD_COMPANY_WEBSITE,
  ADD_EMAIL_COMPANY,
  ADD_NOTE_COMPANIES,
  ADD_PHONE_COMPANY,
  ATTACHMENT_POST_COMPANY,
  CREATE_TASK,
  DELETE_COMPANY_EMAIL,
  DELETE_COMPANY_PHONE,
  DELETE_COMPANY_WEBSITE,
  EDIT_COMPANY,
  EDIT_TASK,
  GET_SINGLE_COMPANY,
  GET_USERS,
  UPDATE_COMPANY_WEBSITE,
  UPDATE_EMAIL_COMPANY,
  UPDATE_PHONE_COMPANY,
} from 'actions/types'
import {getSingleCompany, editCompany, addNoteCompany, addInteractionCompany} from 'actions/company'
import {
  updateCompanyEmail,
  addCompanyEmail,
  updateCompanyPhone,
  addCompanyPhone,
  updateCompanyWebsite,
  addCompanyWebsite,
  getTagLabel,
} from 'actions/multiple_fields'
import {getContactType} from 'actions/contacts'
import {get, getPermissionTooltipContent} from 'utils/helper'
import {commonEmail, commonPhone, commonWebsite} from 'components/common/CommonDispatch'
import TimeLine from 'components/common/TimeLine'
import Category from 'components/common/Category'
import SvgIcon from 'components/common/SvgIcon'
import EmailEditorBox from 'components/common/EmailEditorBox'
import CreateTask from 'components/pages/Projects/Tasks/CreateTask'
import {updateCompanySchema} from 'validation/Customers/company.schema'
import useUserPermissions from 'hooks/pure/useUserPermissions'
import {getUsers} from 'actions/settings'
import useApiResponse from 'hooks/impure/useApiResponse'
import CompaniesSummary from './CompaniesSummary'
import CompaniesInfo from './CompaniesInfo'

const CompaniesDetail = props => {
  const {id: companyId} = useParams()
  const dispatch = useDispatch()
  const history = useHistory()

  const [toMailBoxList, setToMailBox] = useState([])
  const [isOpenMailComposer, setIsOpenMailComposer] = useState(false)
  const [isCreateTaskOpen, setIsCreateTaskOpen] = useState(false)
  const [noteText, setNoteText] = useState('')

  const {singleCompany = {}} = useSelector(state => state.companies)
  const {
    allActivities = [],
    interactions: getAllInterActionsData = [],
    notes: getAllNotesData = [],
    updates: getAllUpdatesData = [],
  } = singleCompany
  const {successLabels = []} = useSelector(state => state.apiReducer)
  const {singleContact: companyDetails = []} = useSelector(state => state.contacts)
  const {inbox: mailboxes} = useSelector(state => state.mailInbox)

  const {conversationsPermissions, contactsAndCompaniesPermissions, tasksPermissions} = useUserPermissions()
  const manageConversationPermissions = conversationsPermissions?.manage_conversations
  const manageContactsCompanyPermissions = contactsAndCompaniesPermissions?.manage_contacts_company
  const manageTasksPermissions = tasksPermissions?.manage_tasks

  const initialCreateInfo = {
    name: '',
    domain: '',
    emails: [],
    phone: [],
    websites: [],
    description: '',
    assigneeId: null,
    contactTypeId: null,
    address: {
      streetAddress: '',
      locality: '',
      state: '',
      postalCode: null,
      country: '',
    },
    labelIds: [],
  }

  const {
    handleSubmit,
    errors,
    control,
    formState: {dirtyFields},
    reset,
    getValues,
  } = useForm({
    mode: 'onTouched',
    reValidateMode: 'onChange',
    shouldFocusError: true,
    resolver: joiResolver(updateCompanySchema),
    defaultValues: initialCreateInfo,
  })
  const {
    fields: emailFields,
    append: appendEmail,
    remove: removeEmail,
  } = useFieldArray({
    control,
    name: 'emails',
  })
  const {
    fields: phoneFields,
    append: appendPhone,
    remove: removePhone,
  } = useFieldArray({
    control,
    name: 'phones',
  })
  const {
    fields: websiteFields,
    append: appendwebsite,
    remove: removeWebsite,
  } = useFieldArray({
    control,
    name: 'websites',
  })
  const [userOption, setUserOption] = useState([])

  useApiResponse({
    action: getUsers,
    enabled: true,
    label: GET_USERS,
    storePath: 'settings.getUsersList',
    onSuccess: getUsersList => {
      const list = getUsersList?.users?.map(user => ({
        key: user.id,
        value: user.id,
        text: `${user.firstName} ${user.lastName}`,
      }))
      setUserOption(list || [])
    },
  })

  useEffect(() => {
    if (companyId) {
      dispatch(getTagLabel())
      dispatch(getSingleCompany(companyId))
      dispatch(getContactType())
      // dispatch(getCompanyList());
    }
  }, [])

  const renderComposeMail = () => {
    if (isOpenMailComposer)
      return (
        <EmailEditorBox
          isOpenMailComposer={isOpenMailComposer}
          stopDraftOnClose
          setIsOpenMailComposer={setIsOpenMailComposer}
          mailboxes={mailboxes}
          defaultTo={toMailBoxList}
        />
      )
    return null
  }

  const renderCreateTaskModal = () => {
    if (isCreateTaskOpen) {
      return (
        <CreateTask
          setIsCreateTaskOpen={setIsCreateTaskOpen}
          isCreateTaskOpen={isCreateTaskOpen}
          conversation={get(['conversation'], companyDetails)}
          companyId={companyId}
          isDataAvailabel
        />
      )
    }
  }

  const resetVal = {
    name: get(['name'], singleCompany, ''),
    domain: get(['domain'], singleCompany, ''),
    emails: get(['emails'], singleCompany, [{category: 'work', email: ''}]),
    phones: get(['phones'], singleCompany, [{category: 'work', phone: ''}]),
    websites: get(['websites'], singleCompany, [{category: 'work', url: ''}]),
    description: get(['description'], singleCompany, ''),
    address: {
      streetAddress: get(['address', 'streetAddress'], singleCompany, ''),
      locality: get(['address', 'locality'], singleCompany, ''),
      state: get(['address', 'state'], singleCompany, ''),
      country: get(['address', 'country'], singleCompany, ''),
      postalCode: get(['address', 'postalCode'], singleCompany, ''),
    },
    assigneeId: get(['assigneeId'], singleCompany),
    contactTypeId: get(['contactTypeId'], singleCompany),
  }

  useEffect(() => {
    if (successLabels.includes(GET_SINGLE_COMPANY)) {
      reset(resetVal)
    }
  }, [successLabels, reset])

  useEffect(() => {
    if (
      successLabels.includes(ADD_EMAIL_COMPANY) ||
      successLabels.includes(UPDATE_EMAIL_COMPANY) ||
      successLabels.includes(DELETE_COMPANY_EMAIL)
    ) {
      removeEmail()
      reset(resetVal, {dirtyFields: false})
    }
    if (successLabels.includes(ADD_NOTE_COMPANIES)) {
      dispatch(getSingleCompany(companyId))
      setNoteText('')
    }
    if (
      successLabels.includes(ADD_PHONE_COMPANY) ||
      successLabels.includes(UPDATE_PHONE_COMPANY) ||
      successLabels.includes(DELETE_COMPANY_PHONE)
    ) {
      removePhone()
      reset(resetVal, {dirtyFields: false})
    }
    if (
      successLabels.includes(ADD_COMPANY_WEBSITE) ||
      successLabels.includes(UPDATE_COMPANY_WEBSITE) ||
      successLabels.includes(DELETE_COMPANY_WEBSITE)
    ) {
      removeWebsite()
      reset(resetVal, {dirtyFields: false})
    }
    if (
      successLabels.includes(ATTACHMENT_POST_COMPANY) ||
      successLabels.includes(CREATE_TASK) ||
      successLabels.includes(EDIT_TASK)
    ) {
      dispatch(getSingleCompany(companyId))
    }
    if (successLabels.includes(EDIT_COMPANY)) {
      reset(resetVal, {dirtyFields: false})
    }
  }, [successLabels, reset])

  const formStateVal = dirtyFields

  const updateCompany = data => {
    const dirtyFields = _.pick(data, Object.keys(formStateVal))

    if (dirtyFields.emails) {
      commonEmail({
        dispatch,
        id: companyId,
        data,
        singleData: singleCompany,
        formStateVal,
        addApi: addCompanyEmail,
        updateApi: updateCompanyEmail,
        removeEmail,
      })
    }
    if (dirtyFields.phones) {
      commonPhone({
        dispatch,
        id: companyId,
        data,
        singleData: singleCompany,
        formStateVal,
        addApi: addCompanyPhone,
        updateApi: updateCompanyPhone,
        removePhone,
      })
    }
    if (dirtyFields.websites) {
      commonWebsite({
        dispatch,
        id: companyId,
        data,
        singleData: singleCompany,
        formStateVal,
        addApi: addCompanyWebsite,
        updateApi: updateCompanyWebsite,
        removeWebsite,
      })
    }
    if (dirtyFields.emails || dirtyFields.phones || dirtyFields.websites) {
      delete dirtyFields.emails
      delete dirtyFields.phones
      delete dirtyFields.websites
    }
    if (!_.isEmpty(dirtyFields)) {
      dispatch(editCompany(dirtyFields, companyId))
    }
  }
  return (
    <div style={{display: 'flex'}}>
      <div className="customerProfilePage">
        <KooperCard className="userProfileInfo">
          <KooperCardContent>
            <KooperTooltip
              size="mini"
              position="bottom center"
              content={
                manageContactsCompanyPermissions
                  ? singleCompany.isFollowed
                    ? 'Remove Favorite'
                    : 'Add Favorite'
                  : getPermissionTooltipContent({roleAccess: manageContactsCompanyPermissions})
              }
              trigger={
                <KooperRating
                  style={{float: 'right'}}
                  icon="star"
                  className="favStar"
                  onClick={() =>
                    manageContactsCompanyPermissions &&
                    dispatch(editCompany({isFollowed: !singleCompany.isFollowed}, companyId))
                  }
                  rating={singleCompany.isFollowed ? 1 : 0}
                />
              }
            />
            <KooperButton className="backBtn" onClick={() => history.push('/companies')}>
              <SvgIcon path="common/left" /> Back to companies
            </KooperButton>
            <KooperHeader className="contName">
              {_.get(singleCompany, 'name') || ''}
              <KooperHeaderSubheader>
                {_.size(_.get(singleCompany, 'contacts', 0))} Contacts
                {_.get(singleCompany, 'domain') || ''}
                {`${_.get(singleCompany, 'address.state') || ''} ${_.get(singleCompany, 'address.country') || ''}`}
              </KooperHeaderSubheader>
            </KooperHeader>

            <div className="userActBtn">
              <KooperTooltip
                size="mini"
                position="bottom center"
                content={
                  manageConversationPermissions
                    ? 'Create Conversation'
                    : getPermissionTooltipContent({roleAccess: manageConversationPermissions})
                }
                trigger={
                  <KooperButton
                    className="mr-3"
                    onClick={() => {
                      if (manageConversationPermissions) {
                        setToMailBox(companyDetails.email && [get(['email'], companyDetails)])
                        setIsOpenMailComposer(true)
                      }
                    }}
                    icon={<SvgIcon path="clients/contacts/contacts-dashboard/create-conversations" />}
                  />
                }
              />

              <KooperTooltip
                size="mini"
                position="bottom center"
                content={
                  manageTasksPermissions
                    ? 'Create Tasks'
                    : getPermissionTooltipContent({roleAccess: manageTasksPermissions})
                }
                trigger={
                  <KooperButton
                    className="mr-3"
                    icon={<SvgIcon path="clients/contacts/contacts-dashboard/create-task" />}
                    onClick={() => manageTasksPermissions && setIsCreateTaskOpen(true)}
                  />
                }
              />

              {/* <KooperTooltip
                size="mini"
                position="bottom center"
                content="Send SMS"
                trigger={
                  <KooperButton
                    icon={<FiSend />}
                    // Cant Find a Function, So commented below functions
                    // onClick={() => setIsCreateTaskOpen(true)}
                  />
                }
              /> */}
            </div>

            <div className="staticData">
              <div className="dataOne">
                <p>Company Score</p>
                <h4>{get(['entityScore'], singleCompany)}</h4>
              </div>
              {/* <div className="dataOne">
                <p>Email Insight Score</p>
                <h4>{get(["emailInsightScore"], singleCompany)}</h4>
              </div> */}
            </div>
          </KooperCardContent>
          <KooperCardContent>
            <CompaniesInfo
              companyId={companyId}
              errors={errors}
              control={control}
              Controller={Controller}
              handleSubmit={handleSubmit}
              updateCompany={updateCompany}
              emailFields={emailFields}
              appendEmail={appendEmail}
              removeEmail={removeEmail}
              phoneFields={phoneFields}
              appendPhone={appendPhone}
              removePhone={removePhone}
              websiteFields={websiteFields}
              appendwebsite={appendwebsite}
              removeWebsite={removeWebsite}
              singleCompany={singleCompany}
            />
          </KooperCardContent>
        </KooperCard>

        <div className="userTimeLine">
          <div style={{marginRight: '16px'}}>
            <KooperCard fluid className="mb-0">
              <Category
                id={companyId}
                addNote={addNoteCompany}
                addLogInteration={addInteractionCompany}
                rolePermissions={manageContactsCompanyPermissions}
              />
            </KooperCard>

            <div className="page-header px-0 mt-4" style={{background: 'transparent'}}>
              <div className="page-title">
                <div className="page-titleIcon">
                  <KooperMenuItem>
                    {/* <SvgIcon path="clients/contacts/contacts-dashboard/timeline" /> */}
                    <h4 className="m-0">Timeline</h4>
                  </KooperMenuItem>
                </div>
              </div>
              <div className="page-action">
                <KooperTooltip
                  content="Agent"
                  size="mini"
                  position="top center"
                  trigger={
                    <div className="mailActivity">
                      <KooperDropdown
                        icon="chevron down"
                        button
                        selection
                        header="User"
                        style={{float: 'right'}}
                        options={[{value: 'all', text: 'All', key: 'all'}, ...userOption]}
                        onChange={(e, {value}) => dispatch(getSingleCompany(companyId, value))}
                        defaultValue="all"
                      />
                    </div>
                  }
                />
              </div>
            </div>
            <KooperCard fluid className="m-0">
              <TimeLine
                allActivities={allActivities}
                getAllInterActionsData={getAllInterActionsData}
                getAllNotesData={getAllNotesData}
                getAllUpdatesData={getAllUpdatesData}
              />
            </KooperCard>
          </div>
        </div>
      </div>
      <div
        className="customerProfile pl-0"
        style={{
          // height: "max-content",
          border: 'none',
        }}
      >
        <CompaniesSummary
          companyId={companyId}
          getValues={getValues}
          errors={errors}
          control={control}
          Controller={Controller}
          updateCompany={updateCompany}
          handleSubmit={handleSubmit}
          noteText={noteText}
          setNoteText={setNoteText}
          {...props}
        />
      </div>
      {renderComposeMail()}
      {renderCreateTaskModal()}
    </div>
  )
}

export default CompaniesDetail
