import React, {useState, useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {Controller} from 'react-hook-form'
import {useParams} from 'react-router'
import {
  KooperCard,
  KooperCardContent,
  KooperContainer,
  KooperForm,
  KooperFormField,
  KooperFormGroup,
  KooperFormSelect,
  KooperFormInput,
  KooperDatePicker,
  KooperFormTextArea,
  KooperGridColumn,
  KooperTooltip,
  KooperGrid,
  KooperCheckbox,
} from 'kooper-library'
import {GET_USERS, GET_VENDORS_LIST, GET_PREFIX, GET_ALL_CONTACTS_IDS} from 'actions/types'
import {getAllContactsIds} from 'actions/contacts'
import {getNewPoNumber, getPrefixes} from 'actions/PurchaseOrders'
import {getVendorslist} from 'actions/vendors'
import {removeDoubleQuotes, KooperCountryList} from 'utils/helper'
import {Carrier, Status} from 'constants/variables'
import SvgIcon from 'components/common/SvgIcon'
import {getUsers} from 'actions/settings'
import useApiResponse from 'hooks/impure/useApiResponse'
import PreviewPurchaseOrder from '../Templates/PreviewPurchaseOrder'

const Setup = props => {
  const {
    errors,
    control,
    watch,
    setValue,
    style,
    poDate,
    setPoDate,
    dueDate,
    setDueDate,
    prefixOptions,
    setPrefixOptions,
  } = props
  const dispatch = useDispatch()
  const {id: PurchaseOrdersId} = useParams()

  const [userOption, setUserOption] = useState([])
  const [contactOption, setcontactOption] = useState([])
  const [vendorList, setVendorList] = useState([])
  const [isShippingAddressChecked, setIsShippingAddressChecked] = useState(false)

  const {
    PurchaseOrderForm: {poNumber},
  } = useSelector(state => state.purchaseOrders)

  const watchPrefixId = watch('prefixId')
  const watchAll = watch()

  useEffect(() => {
    if (!PurchaseOrdersId && !poNumber && watchPrefixId) {
      dispatch(getNewPoNumber(watchPrefixId))
    }
  }, [watchPrefixId, PurchaseOrdersId, poNumber, dispatch])

  useEffect(() => {
    if (poNumber) {
      setValue('poNumber', poNumber, {
        shouldDirty: true,
        shouldValidate: true,
      })
    }
  }, [poNumber, setValue])

  useApiResponse({
    action: getPrefixes,
    enabled: true,
    label: GET_PREFIX,
    storePath: 'purchaseOrders.prefixListData',
    onSuccess: prefixListData => {
      const prefixes = prefixListData?.map(({id, prefix}) => ({
        key: id,
        value: id,
        text: prefix,
      }))
      setPrefixOptions(prefixes)
    },
  })

  useApiResponse({
    action: getUsers,
    enabled: true,
    label: GET_USERS,
    storePath: 'settings.getUsersList',
    onSuccess: getUsersList => {
      const list = getUsersList?.users?.map(user => ({
        key: user.id,
        value: user.id,
        text: `${user.firstName} ${user.lastName}`,
      }))
      setUserOption(list)
    },
  })

  useApiResponse({
    action: getAllContactsIds,
    enabled: true,
    label: GET_ALL_CONTACTS_IDS,
    storePath: 'contacts.contactsIdsList',
    onSuccess: contactsIdsList => {
      const list = contactsIdsList.map(contact => ({
        value: contact.id,
        text: contact.name,
        id: contact.id,
      }))
      setcontactOption(list)
    },
  })

  useApiResponse({
    action: getVendorslist,
    enabled: true,
    label: GET_VENDORS_LIST,
    storePath: 'vendors.vendorsList',
    dataToExtract: 'vendors',
    onSuccess: vendorsListData => {
      setVendorList(
        vendorsListData?.vendors?.map(({name, id}) => {
          return {key: id, value: id, text: name}
        })
      )
    },
  })

  const handleShippingAddressCheckboxChange = (e, {checked}) => {
    setIsShippingAddressChecked(checked)

    if (checked) {
      // Copy billing address to shipping address
      const billingAddress = {
        streetAddress: watch('billingAddress.streetAddress'),
        locality: watch('billingAddress.locality'),
        state: watch('billingAddress.state'),
        postalCode: watch('billingAddress.postalCode'),
        country: watch('billingAddress.country'),
      }

      setValue('shippingAddress.streetAddress', billingAddress.streetAddress)
      setValue('shippingAddress.locality', billingAddress.locality)
      setValue('shippingAddress.state', billingAddress.state)
      setValue('shippingAddress.postalCode', billingAddress.postalCode)
      setValue('shippingAddress.country', billingAddress.country)
    } else {
      // Clear shipping address fields
      setValue('shippingAddress.streetAddress', '')
      setValue('shippingAddress.locality', '')
      setValue('shippingAddress.state', '')
      setValue('shippingAddress.postalCode', '')
      setValue('shippingAddress.country', '')
    }
  }

  return (
    <KooperContainer style={style}>
      <KooperCard fluid>
        <KooperCardContent style={{padding: '18px'}}>
          <h3 className="mb-0">Purchase Order Information</h3>
          <p className="mt-0 mb-4 card-description">Create purchase order by mentioning all the required information</p>
          <KooperForm className="errorLabel">
            <KooperFormGroup>
              <KooperFormField width={8}>
                <div className="info-header">
                  <label>Purchase Order Owner</label>
                  <KooperTooltip
                    trigger={<SvgIcon path="common/question" />}
                    content="Set ownership of the purchase order"
                    size="mini"
                    psoition="top center"
                  />
                </div>
                <Controller
                  name="assigneeId"
                  render={({onChange, value, onBlur}) => (
                    <KooperFormSelect
                      fluid
                      options={userOption}
                      selection
                      placeholder="Owner"
                      onBlur={onBlur}
                      value={value}
                      onChange={(e, {value}) => {
                        onChange(value)
                      }}
                      error={
                        errors?.assigneeId && {
                          content: removeDoubleQuotes(errors?.assigneeId?.message),
                        }
                      }
                    />
                  )}
                  control={control}
                />
              </KooperFormField>

              <KooperFormField width={4} style={{paddingLeft: '0'}}>
                <div className="info-header ml-3">
                  <label>Prefix Id</label>
                  <KooperTooltip
                    trigger={<SvgIcon path="common/question" />}
                    content="Select a prefix id"
                    size="mini"
                    psoition="top center"
                  />
                </div>

                <Controller
                  control={control}
                  name="prefixId"
                  render={({value, onChange}) => (
                    <KooperFormSelect
                      width={16}
                      placeholder="ID Prefix"
                      options={prefixOptions}
                      value={value}
                      search
                      clearable
                      noResultsMessage={
                        setPrefixOptions.length ? 'No prefixes found' : 'You have not created any Prefixes.'
                      }
                      onChange={(e, {value}) => onChange(value)}
                    />
                  )}
                />
              </KooperFormField>

              <KooperFormField width={4} required>
                <div className="info-header">
                  <label className="label-class">PO Number</label>
                  <KooperTooltip
                    trigger={<SvgIcon path="common/question" />}
                    content="Specify purchase order number"
                    size="mini"
                    psoition="top center"
                  />
                </div>
                <Controller
                  name="poNumber"
                  control={control}
                  render={({onChange, value}) => (
                    <KooperFormInput
                      maxLength={16}
                      placeholder="PO Number"
                      onChange={(e, {value}) => onChange(value)}
                      value={value}
                      error={
                        errors?.poNumber && {
                          content: removeDoubleQuotes(errors?.poNumber.message),
                        }
                      }
                    />
                  )}
                />
              </KooperFormField>
            </KooperFormGroup>

            <KooperFormGroup widths="equal">
              <KooperFormField required>
                <div className="info-header">
                  <label className="label-class">Subject</label>
                  <KooperTooltip
                    trigger={<SvgIcon path="common/question" />}
                    content="Specify subject of purchase order"
                    size="mini"
                    psoition="top center"
                  />
                </div>
                <Controller
                  name="subject"
                  control={control}
                  render={({onChange, value}) => (
                    <KooperFormInput
                      type="text"
                      maxLength={20}
                      placeholder="Subject"
                      onChange={(e, {value}) => onChange(value)}
                      value={value}
                      error={
                        errors.subject && {
                          content: removeDoubleQuotes(errors.subject.message),
                        }
                      }
                    />
                  )}
                />
              </KooperFormField>

              <KooperFormField>
                <div className="info-header">
                  <label>Vendor Name</label>
                  <KooperTooltip
                    trigger={<SvgIcon path="common/question" />}
                    content="Mention the vendor name for the purchase"
                    size="mini"
                    psoition="top center"
                  />
                </div>
                <Controller
                  name="vendorId"
                  render={({onChange, value}) => (
                    <KooperFormSelect
                      fluid
                      selection
                      options={vendorList}
                      placeholder="Vendor Name"
                      value={value}
                      onChange={(e, {value}) => {
                        onChange(value)
                      }}
                      error={
                        errors?.vendorId && {
                          content: removeDoubleQuotes(errors?.vendorId?.message),
                        }
                      }
                    />
                  )}
                  control={control}
                />
              </KooperFormField>
            </KooperFormGroup>

            <KooperFormGroup widths="equal">
              <KooperFormField>
                <div className="info-header">
                  <label>Requisition Number</label>
                  <KooperTooltip
                    trigger={<SvgIcon path="common/question" />}
                    content="Enter purchase order requisition number"
                    size="mini"
                    psoition="top center"
                  />
                </div>
                <Controller
                  name="requisitionNumber"
                  control={control}
                  render={({onChange, value}) => (
                    <KooperFormInput
                      type="text"
                      maxLength={20}
                      placeholder="Requisition Number"
                      onChange={(e, {value}) => onChange(value)}
                      value={value}
                      error={
                        errors.requisitionNumber && {
                          content: removeDoubleQuotes(errors.requisitionNumber.message),
                        }
                      }
                    />
                  )}
                />
              </KooperFormField>

              <KooperFormField>
                <div className="info-header">
                  <label>Tracking Number</label>
                  <KooperTooltip
                    trigger={<SvgIcon path="common/question" />}
                    content="Mention the tracking number for purchase order"
                    size="mini"
                    psoition="top center"
                  />
                </div>
                <Controller
                  name="trackingNumber"
                  control={control}
                  render={({onChange, value}) => (
                    <KooperFormInput
                      type="text"
                      maxLength={20}
                      placeholder="Tracking Number"
                      onChange={(e, {value}) => onChange(value)}
                      value={value}
                      error={
                        errors.trackingNumber && {
                          content: removeDoubleQuotes(errors.trackingNumber.message),
                        }
                      }
                    />
                  )}
                />
              </KooperFormField>
            </KooperFormGroup>

            <KooperFormGroup widths="equal">
              <KooperFormField>
                <div className="info-header">
                  <label>Contact Name</label>
                  <KooperTooltip
                    trigger={<SvgIcon path="common/question" />}
                    content="mention the contact name for the purchase"
                    size="mini"
                    psoition="top center"
                  />
                </div>
                <Controller
                  name="contactId"
                  render={({onChange, value}) => (
                    <KooperFormSelect
                      fluid
                      selection
                      options={contactOption}
                      placeholder="Contact Name"
                      value={value}
                      onChange={(e, {value}) => {
                        onChange(value)
                      }}
                      error={
                        errors?.contactId && {
                          content: removeDoubleQuotes(errors?.contactId?.message),
                        }
                      }
                    />
                  )}
                  control={control}
                />
              </KooperFormField>

              <KooperFormField>
                <div className="info-header">
                  <label>PO Date</label>
                  <KooperTooltip
                    trigger={<SvgIcon path="common/question" />}
                    content="Mention the purchase order date"
                    size="mini"
                    psoition="top center"
                  />
                </div>
                <Controller
                  name="poDate"
                  render={({onChange, value}) => (
                    <KooperDatePicker
                      type="date"
                      value={typeof value === 'string' ? new Date(poDate) : poDate}
                      inputProps={{readOnly: true}}
                      time={false}
                      onChange={value => {
                        onChange(value)
                        setPoDate(value)
                      }}
                      error={
                        errors?.poDate && {
                          content: removeDoubleQuotes(errors?.poDate?.message),
                        }
                      }
                    />
                  )}
                  control={control}
                />
              </KooperFormField>
            </KooperFormGroup>

            <KooperFormGroup widths="equal">
              <KooperFormField>
                <div className="info-header">
                  <label>Due Date</label>
                  <KooperTooltip
                    trigger={<SvgIcon path="common/question" />}
                    content="Set deadline for the purchase order"
                    size="mini"
                    psoition="top center"
                  />
                </div>
                <Controller
                  name="dueDate"
                  render={({onChange, value}) => (
                    <KooperDatePicker
                      type="date"
                      value={typeof value === 'string' ? new Date(dueDate) : dueDate}
                      inputProps={{readOnly: true}}
                      time={false}
                      onChange={value => {
                        onChange(value)
                        setDueDate(value)
                      }}
                      error={
                        errors?.dueDate && {
                          content: removeDoubleQuotes(errors?.dueDate?.message),
                        }
                      }
                    />
                  )}
                  control={control}
                />
              </KooperFormField>

              <KooperFormField>
                <div className="info-header">
                  <label>Carrier</label>
                  <KooperTooltip
                    trigger={<SvgIcon path="common/question" />}
                    content="Set carrier of the purchase order"
                    size="mini"
                    psoition="top center"
                  />
                </div>
                <Controller
                  name="carrier"
                  render={({onChange, value}) => (
                    <KooperFormSelect
                      fluid
                      selection
                      options={Carrier}
                      placeholder="Carrier"
                      value={value}
                      onChange={(e, {value}) => {
                        onChange(value)
                      }}
                      error={
                        errors?.carrier && {
                          content: removeDoubleQuotes(errors?.carrier?.message),
                        }
                      }
                    />
                  )}
                  control={control}
                />
              </KooperFormField>
            </KooperFormGroup>

            <KooperFormGroup widths="equal">
              <KooperFormField>
                <div className="info-header">
                  <label>Excise Duty</label>
                  <KooperTooltip
                    trigger={<SvgIcon path="common/question" />}
                    content="Set the excise duty"
                    size="mini"
                    psoition="top center"
                  />
                </div>
                <Controller
                  name="exciseDuty"
                  control={control}
                  render={({onChange, value}) => (
                    <KooperFormInput
                      type="number"
                      placeholder="00.00"
                      onChange={(e, {value}) => onChange(value)}
                      value={value}
                      error={
                        errors.exciseDuty && {
                          content: removeDoubleQuotes(errors.exciseDuty.message),
                        }
                      }
                    />
                  )}
                />
              </KooperFormField>

              <KooperFormField>
                <div className="info-header">
                  <label>Extra Commission</label>
                  <KooperTooltip
                    trigger={<SvgIcon path="common/question" />}
                    content="Mention if there is any extra commission"
                    size="mini"
                    psoition="top center"
                  />
                </div>
                <Controller
                  name="salesCommission"
                  control={control}
                  render={({onChange, value}) => (
                    <KooperFormInput
                      type="number"
                      onChange={(e, {value}) => onChange(value)}
                      value={value}
                      error={
                        errors.salesCommission && {
                          content: removeDoubleQuotes(errors.salesCommission.message),
                        }
                      }
                    />
                  )}
                />
              </KooperFormField>
            </KooperFormGroup>

            <KooperFormGroup>
              <KooperFormField width={8}>
                <div className="info-header">
                  <label>Status</label>
                  <KooperTooltip
                    trigger={<SvgIcon path="common/question" />}
                    content="Set current status of the purchase order"
                    size="mini"
                    psoition="top center"
                  />
                </div>
                <Controller
                  name="status"
                  render={({onChange, value}) => (
                    <KooperFormSelect
                      fluid
                      selection
                      options={Status}
                      placeholder="Status"
                      value={value}
                      onChange={(e, {value}) => {
                        onChange(value)
                      }}
                      error={
                        errors?.status && {
                          content: removeDoubleQuotes(errors?.status?.message),
                        }
                      }
                    />
                  )}
                  control={control}
                />
              </KooperFormField>

              <KooperFormField width={8}>
                <div className="info-header">
                  <label>Description</label>
                  <KooperTooltip
                    trigger={<SvgIcon path="common/question" />}
                    content="write brief description about purchase order"
                    size="mini"
                    psoition="top center"
                  />
                </div>
                <Controller
                  name="description"
                  render={({onChange, value}) => (
                    <KooperFormTextArea
                      type="text"
                      value={value}
                      maxLength={160}
                      placeholder="Description"
                      onChange={e => {
                        onChange(e.target.value)
                      }}
                      error={
                        errors.description && {
                          content: removeDoubleQuotes(errors.description.message),
                        }
                      }
                    />
                  )}
                  control={control}
                />
              </KooperFormField>
            </KooperFormGroup>
          </KooperForm>
        </KooperCardContent>
      </KooperCard>
      <KooperCard fluid>
        <KooperCardContent>
          <h3 className="mb-0">Address Information</h3>
          <p className="mt-0 mb-4 card-description">
            Add billing and shipping address to display on the purchase order
          </p>
          <KooperForm className="errorLabel">
            <KooperGrid>
              <div style={{width: '50%'}}>
                <p>Billing Address</p>
                <KooperFormField>
                  <div className="info-header">
                    <label>Street</label>
                    <KooperTooltip
                      trigger={<SvgIcon path="common/question" />}
                      content="Mention billing street to show on the purchase order"
                      size="mini"
                      psoition="top center"
                    />
                  </div>
                  <Controller
                    name="billingAddress.streetAddress"
                    render={({onChange, value}) => (
                      <KooperFormInput
                        type="text"
                        fluid
                        maxLength={20}
                        value={value}
                        placeholder="Street"
                        onChange={e => {
                          onChange(e.target.value)
                        }}
                        error={
                          errors?.billingAddress?.streetAddress && {
                            content: removeDoubleQuotes(errors?.billingAddress?.streetAddress?.message),
                          }
                        }
                      />
                    )}
                    control={control}
                  />
                </KooperFormField>
                <KooperFormField>
                  <div className="info-header">
                    <label>City</label>
                    <KooperTooltip
                      trigger={<SvgIcon path="common/question" />}
                      content="Mention billing city"
                      size="mini"
                      psoition="top center"
                    />
                  </div>
                  <Controller
                    name="billingAddress.locality"
                    render={({onChange, value}) => (
                      <KooperFormInput
                        type="text"
                        maxLength={20}
                        fluid
                        value={value}
                        placeholder="Locality"
                        onChange={e => {
                          onChange(e.target.value)
                        }}
                        error={
                          errors?.billingAddress?.locality && {
                            content: removeDoubleQuotes(errors?.billingAddress?.locality?.message),
                          }
                        }
                      />
                    )}
                    control={control}
                  />
                </KooperFormField>
                <KooperFormField>
                  <div className="info-header">
                    <label>State</label>
                    <KooperTooltip
                      trigger={<SvgIcon path="common/question" />}
                      content="Mention billing state to show on the purchase order"
                      size="mini"
                      psoition="top center"
                    />
                  </div>
                  <Controller
                    name="billingAddress.state"
                    render={({onChange, value}) => (
                      <KooperFormInput
                        type="text"
                        fluid
                        value={value}
                        maxLength={20}
                        placeholder="State"
                        onChange={e => {
                          onChange(e.target.value)
                        }}
                        error={
                          errors?.billingAddress?.state && {
                            content: removeDoubleQuotes(errors?.billingAddress?.state?.message),
                          }
                        }
                      />
                    )}
                    control={control}
                  />
                </KooperFormField>
                <KooperFormField>
                  <div className="info-header">
                    <label>Postal Code</label>
                    <KooperTooltip
                      trigger={<SvgIcon path="common/question" />}
                      content="Mention the billing postal code to show on the purchase order"
                      size="mini"
                      psoition="top center"
                    />
                  </div>
                  <Controller
                    name="billingAddress.postalCode"
                    render={({onChange, value, onBlur}) => (
                      <KooperFormInput
                        type="text"
                        maxLength={10}
                        fluid
                        placeholder="Postal Code"
                        onBlur={onBlur}
                        value={value}
                        onChange={e => {
                          onChange(e.target.value)
                        }}
                        error={
                          errors?.billingAddress?.postalCode && {
                            content: removeDoubleQuotes(errors?.billingAddress?.postalCode?.message),
                          }
                        }
                      />
                    )}
                    control={control}
                  />
                </KooperFormField>
                <KooperFormField>
                  <div className="info-header">
                    <label>Country</label>
                    <KooperTooltip
                      trigger={<SvgIcon path="common/question" />}
                      content="Mention billing country to show on the purchase order"
                      size="mini"
                      psoition="top center"
                    />
                  </div>
                  <Controller
                    name="billingAddress.country"
                    render={({onChange, value, onBlur}) => (
                      <KooperFormSelect
                        search
                        fluid
                        options={KooperCountryList}
                        selection
                        placeholder="Country"
                        onBlur={onBlur}
                        value={value}
                        onChange={(e, {value}) => {
                          onChange(value)
                        }}
                        error={
                          errors?.billingAddress?.country && {
                            content: removeDoubleQuotes(errors?.billingAddress?.country?.message),
                          }
                        }
                      />
                    )}
                    control={control}
                  />
                </KooperFormField>
              </div>
              <div style={{width: '50%'}}>
                <KooperCheckbox
                  label="Shipping Address (Enable if Same Billing Address)"
                  onChange={handleShippingAddressCheckboxChange}
                  checked={isShippingAddressChecked}
                />
                <div className="mt-2">
                  <KooperFormField>
                    <div className="info-header">
                      <label>Street</label>
                      <KooperTooltip
                        trigger={<SvgIcon path="common/question" />}
                        content="Mention shipping street to show on the purchase order"
                        size="mini"
                        psoition="top center"
                      />
                    </div>
                    <Controller
                      name="shippingAddress.streetAddress"
                      render={({onChange, value}) => (
                        <KooperFormInput
                          maxLength={20}
                          type="text"
                          fluid
                          value={value}
                          placeholder="Street"
                          onChange={e => {
                            onChange(e.target.value)
                          }}
                          error={
                            errors?.shippingAddress?.streetAddress && {
                              content: removeDoubleQuotes(errors?.shippingAddress?.streetAddress?.message),
                            }
                          }
                        />
                      )}
                      control={control}
                    />
                  </KooperFormField>
                  <KooperFormField>
                    <div className="info-header">
                      <label>City</label>
                      <KooperTooltip
                        trigger={<SvgIcon path="common/question" />}
                        content="Mention shipping city"
                        size="mini"
                        psoition="top center"
                      />
                    </div>
                    <Controller
                      name="shippingAddress.locality"
                      render={({onChange, value}) => (
                        <KooperFormInput
                          type="text"
                          maxLength={20}
                          fluid
                          value={value}
                          placeholder="Locality"
                          onChange={e => {
                            onChange(e.target.value)
                          }}
                          error={
                            errors?.shippingAddress?.locality && {
                              content: removeDoubleQuotes(errors?.shippingAddress?.locality?.message),
                            }
                          }
                        />
                      )}
                      control={control}
                    />
                  </KooperFormField>
                  <KooperFormField>
                    <div className="info-header">
                      <label>State</label>
                      <KooperTooltip
                        trigger={<SvgIcon path="common/question" />}
                        content="Mention shipping state to show on the purchase order"
                        size="mini"
                        psoition="top center"
                      />
                    </div>
                    <Controller
                      name="shippingAddress.state"
                      render={({onChange, value}) => (
                        <KooperFormInput
                          type="text"
                          maxLength={20}
                          fluid
                          value={value}
                          placeholder="State"
                          onChange={e => {
                            onChange(e.target.value)
                          }}
                          error={
                            errors?.shippingAddress?.state && {
                              content: removeDoubleQuotes(errors?.shippingAddress?.state?.message),
                            }
                          }
                        />
                      )}
                      control={control}
                    />
                  </KooperFormField>
                  <KooperFormField>
                    <div className="info-header">
                      <label>Postal Code</label>
                      <KooperTooltip
                        trigger={<SvgIcon path="common/question" />}
                        content="Mention shipping postal code to show on the purchase order"
                        size="mini"
                        psoition="top center"
                      />
                    </div>
                    <Controller
                      name="shippingAddress.postalCode"
                      render={({onChange, value, onBlur}) => (
                        <KooperFormInput
                          type="text"
                          fluid
                          maxLength={10}
                          placeholder="Postal Code"
                          onBlur={onBlur}
                          value={value}
                          onChange={e => {
                            onChange(e.target.value)
                          }}
                          error={
                            errors?.shippingAddress?.postalCode && {
                              content: removeDoubleQuotes(errors?.shippingAddress?.postalCode?.message),
                            }
                          }
                        />
                      )}
                      control={control}
                    />
                  </KooperFormField>
                  <KooperFormField>
                    <div className="info-header">
                      <label>Country</label>
                      <KooperTooltip
                        trigger={<SvgIcon path="common/question" />}
                        content="Mention shipping country to show on the purchase order"
                        size="mini"
                        psoition="top center"
                      />
                    </div>
                    <Controller
                      name="shippingAddress.country"
                      render={({onChange, value, onBlur}) => (
                        <KooperFormSelect
                          search
                          fluid
                          options={KooperCountryList}
                          selection
                          placeholder="Country"
                          onBlur={onBlur}
                          value={value}
                          onChange={(e, {value}) => {
                            onChange(value)
                          }}
                          error={
                            errors?.shippingAddress?.country && {
                              content: removeDoubleQuotes(errors?.shippingAddress?.country?.message),
                            }
                          }
                        />
                      )}
                      control={control}
                    />
                  </KooperFormField>
                </div>
              </div>
            </KooperGrid>
          </KooperForm>
        </KooperCardContent>
      </KooperCard>
      <KooperGridColumn width={8} className="quotePreBox" style={{display: 'none'}}>
        <PreviewPurchaseOrder watch={{setupData: watchAll}} />
      </KooperGridColumn>
    </KooperContainer>
  )
}

export default Setup
