import React, {useState} from 'react'
import {MdEdit} from 'react-icons/md'
import {useHistory} from 'react-router'
import {KooperButton} from 'kooper-library'
import {get} from 'utils/helper'
import LockPermissionTooltip from 'components/common/LockPermissionTooltip'
import useUserPermissions from 'hooks/pure/useUserPermissions'
import AutomationTitle from './AutomationTitle'

const AutomationNavbar = ({automationData, setAutomationData, onSubmitAutomation}) => {
  const history = useHistory()
  const [isModalOpen, setIsModalOpen] = useState(false)

  const {workflowsPermissions} = useUserPermissions()
  const manageWorkflowsPermissions = workflowsPermissions?.manage_workflows

  return (
    <>
      <div className="page-header" style={{boxShadow: 'none'}}>
        <div className="top">
          <div className="title">
            <KooperButton primary onClick={() => history.push('/automations')}>
              Exit
            </KooperButton>
          </div>
          <div className="center d-flex">
            <h5 className="pt-3">{get(['name'], automationData, '')}</h5>
            <MdEdit className="ml-2" size={18} cursor="pointer" onClick={() => setIsModalOpen(true)} />
          </div>
          <div className="action">
            <LockPermissionTooltip isRoleAccessDenied={!manageWorkflowsPermissions}>
              <KooperButton
                primary
                className={!manageWorkflowsPermissions ? 'disabled-button' : ''}
                onClick={() => manageWorkflowsPermissions && onSubmitAutomation()}
              >
                Save
              </KooperButton>
            </LockPermissionTooltip>
          </div>
        </div>
      </div>
      {isModalOpen && (
        <AutomationTitle
          open={isModalOpen}
          toggle={setIsModalOpen}
          automationData={automationData}
          setAutomationData={setAutomationData}
        />
      )}
    </>
  )
}

export default AutomationNavbar
