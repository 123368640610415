/* eslint-disable react-hooks/exhaustive-deps */
import React, {createRef, useEffect, useState} from 'react'
import {useSelector} from 'react-redux'
import {Redirect} from 'react-router'
import {Link} from 'react-router-dom'
import {KooperContainer, KooperDivider, KooperImage, KooperInput, KooperLoader, KooperProgress} from 'kooper-library'
import {copyToClipboard, get} from 'utils/helper'
import logo from 'assets/omni24-full-logo.png'
import 'styles/semantic.css'
import 'styles/common.css'

const ThankYou = () => {
  const [progressPercentage, setProgressPercentage] = useState(0)
  const [copyMsg, setCopyMsg] = useState('Copy')

  const valueRef = createRef(null)

  const {verificationOTPData} = useSelector(state => {
    return get(['verifyUser'], state)
  })

  useEffect(() => {
    const interval = setInterval(() => {
      setProgressPercentage(progressPercentage => progressPercentage + 1)
    }, 70)

    return () => {
      clearInterval(interval)
    }
  }, [])

  if (progressPercentage === 100) {
    if (
      !(
        get(['login', 'account', 'metadata', 'emailInboxStatus'], verificationOTPData, '') &&
        get(['login', 'account', 'metadata', 'docsStatus'], verificationOTPData, '') &&
        get(['login', 'account', 'metadata', 'inviteStatus'], verificationOTPData, '') &&
        get(['login', 'account', 'metadata', 'ticketStatus'], verificationOTPData, '') &&
        get(['login', 'account', 'metadata', 'widgetStatus'], verificationOTPData, '') &&
        get(['login', 'account', 'metadata', 'facebookStatus'], verificationOTPData, '')
      )
    ) {
      return <Redirect to="/getting-started" />
    }
    return <Redirect to="/inbox" />
  }

  return (
    <>
      <KooperContainer>
        <div className="signInPage">
          <KooperImage src={logo} className="kooper-logo" />
          <h1>Thank you for signing up</h1>
          <p className="kooper-lead">
            We are setting up your account as fast as we can, If you are not redirected, Please
            <Link to="/getting-started">click here</Link>
          </p>
          <div className="signInBody">
            <KooperDivider hidden />
            <KooperDivider hidden />
            <div className="loginCard">
              <KooperInput
                size="large"
                fluid
                ref={valueRef}
                action={{
                  color: 'blue',
                  labelPosition: 'right',
                  icon: 'copy',
                  content: copyMsg,
                  onClick: e => copyToClipboard(e, valueRef, setCopyMsg),
                }}
                value={get(['login', 'domainURL'], verificationOTPData, '')}
              />
              <KooperDivider hidden />
              <KooperDivider hidden />
              <KooperLoader size="large" active inline="centered" />
              <KooperDivider hidden />
              <KooperDivider hidden />
              <KooperProgress percent={progressPercentage} indicating />
            </div>
            <KooperDivider hidden />
            <p className="kooper-lead">Setting up your Portal...</p>
          </div>
        </div>
      </KooperContainer>
    </>
  )
}

export default ThankYou
