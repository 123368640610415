import React, {useEffect} from 'react'
import {useHistory, useLocation} from 'react-router'
import {Controller, useForm} from 'react-hook-form'
import {joiResolver} from '@hookform/resolvers'
import {
  KooperButton,
  KooperForm,
  KooperFormField,
  KooperFormInput,
  KooperFormTextArea,
  KooperModal,
  KooperModalActions,
  KooperModalContent,
  KooperModalHeader,
  KooperTooltip,
} from 'kooper-library'
import {removeDoubleQuotes} from 'utils/helper'
import {automationTitleSchema} from 'validation/automation.schema'
import SvgIcon from 'components/common/SvgIcon'

const AutomationTitle = ({open, toggle, automationData, setAutomationData}) => {
  const location = useLocation()
  const history = useHistory()

  const {handleSubmit, control, errors, reset} = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    resolver: joiResolver(automationTitleSchema),
    defaultValues: {
      name: '',
      description: '',
    },
  })

  useEffect(() => {
    if (automationData) {
      reset({name: automationData.name, description: automationData.description})
    }
  }, [automationData, reset])

  const submitForm = data => {
    if (automationData) {
      setAutomationData(automationData => ({...automationData, ...data}))
    }
    if (location.pathname === '/automations') {
      history.push({
        pathname: 'automations/builder',
        state: data,
      })
    } else {
      history.replace({state: data})
      toggle(false)
    }
  }

  return (
    <KooperModal
      closeIcon={<SvgIcon path="common/close" className="closeIcon" />}
      size="tiny"
      open={open}
      onClose={() => toggle(false)}
    >
      <KooperModalHeader>Create Automation</KooperModalHeader>
      <KooperModalContent>
        <KooperForm className="errorLabel">
          <KooperFormField required>
            <div className="info-header">
              <label>Title</label>
              <KooperTooltip
                content="Specify title of the workflow"
                trigger={<SvgIcon path="common/question" />}
                size="mini"
                position="top center"
              />
            </div>

            <Controller
              name="name"
              control={control}
              render={({value, onChange}) => (
                <KooperFormInput
                  maxLength={50}
                  value={value}
                  onChange={(e, {value}) => onChange(value)}
                  error={errors.name && {content: removeDoubleQuotes(errors.name.message)}}
                />
              )}
            />
          </KooperFormField>
          <KooperFormField>
            <div className="info-header">
              <label>Description</label>
              <KooperTooltip
                content="Specify a brief description about the workflow"
                trigger={<SvgIcon path="common/question" />}
                size="mini"
                position="top center"
              />
            </div>
            <Controller
              name="description"
              control={control}
              render={({value, onChange}) => (
                <KooperFormTextArea
                  maxLength={250}
                  style={{resize: 'none'}}
                  value={value}
                  onChange={(e, {value}) => onChange(value)}
                  error={errors.description && {content: removeDoubleQuotes(errors.description.message)}}
                />
              )}
            />
          </KooperFormField>
        </KooperForm>
      </KooperModalContent>
      <KooperModalActions>
        <KooperButton basic onClick={() => toggle(false)}>
          Cancel
        </KooperButton>
        <KooperButton primary onClick={handleSubmit(submitForm)}>
          Create
        </KooperButton>
      </KooperModalActions>
    </KooperModal>
  )
}

export default AutomationTitle
