import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useHistory} from 'react-router'
import {
  KooperButton,
  KooperHeader,
  KooperTable,
  KooperTableCell,
  KooperTableHeader,
  KooperTableHeaderCell,
  KooperTablePlaceholder,
  KooperTableRow,
  KooperTooltip,
} from 'kooper-library'
import {DELETE_CHANNEL, GET_CHANNELS_ALL, GET_TWITTER_CODE} from 'actions/types'
import {deleteChannel, getChannels, getSocialCode} from 'actions/settings_channels'
import {setMetaData} from 'actions'
import {KOOPER_CHANNEL} from 'actions/endpoint'
import CreateFirst from 'components/common/CreateFirst'
import DeleteModal from 'components/common/DeleteModal'
import SettingLayout from 'layouts/settingLayout'
import create from 'assets/create.svg'
import {get, getPermissionTooltipContent, startCase, wrapText} from 'utils/helper'
import {getMetadataInfo, getRouteState} from 'utils/local-storage'
import useApiLoader from 'hooks/pure/useApiLoader'
import PageLoader from 'components/common/PageLoader'
import useUserPermissions from 'hooks/pure/useUserPermissions'
import SvgIcon from 'components/common/SvgIcon'

const Facebook = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
  const [currentSelectedActivity, setCurrentlySelectedActivity] = useState('')

  const {isLoadingData, type = [], successLabels = []} = useSelector(state => state.apiReducer)
  const {getAllChannelList = [], twitterCodeData} = useSelector(state => state.settingsChannels)

  const {isLoading} = useApiLoader({label: GET_CHANNELS_ALL})
  const {channelsPermissions} = useUserPermissions()
  const manageFacebookPermissions = channelsPermissions?.manage_facebook

  useEffect(() => {
    dispatch(getChannels({type: 'facebook'}))
  }, [dispatch])

  useEffect(() => {
    if (getAllChannelList && getAllChannelList.length > 0 && !get(['facebookStatus'], getMetadataInfo())) {
      const data = {
        facebookStatus: true,
      }
      dispatch(setMetaData(data))
    }
  }, [getAllChannelList, dispatch])

  useEffect(() => {
    if (successLabels.includes(GET_TWITTER_CODE)) {
      window.location.replace(`${KOOPER_CHANNEL}/auth/facebook?code=${twitterCodeData.token}`)
    }
  }, [successLabels, twitterCodeData])

  useEffect(() => {
    if (successLabels.includes(DELETE_CHANNEL)) {
      dispatch(getChannels({type: 'facebook'}))
    }
  }, [successLabels, dispatch])

  const renderListOfChannelList = () => {
    return (getAllChannelList || []).map((channel, index) => (
      <KooperTableRow
        className="tableLink"
        key={index + get(['inbox', 'name'], channel, '-')}
        onClick={() => history.push(`/settings/facebook/${get(['id'], channel, 0)}`)}
        to={{
          state: {
            ...getRouteState(),
            fbName: get(['displayName'], channel),
          },
        }}
      >
        <KooperTableCell>{index + 1}</KooperTableCell>
        <KooperTableCell>
          <KooperHeader as="h5">{startCase(get(['username'], channel, '-'))}</KooperHeader>
        </KooperTableCell>
        <KooperTableCell>{get(['displayName'], channel, '-')}</KooperTableCell>
        <KooperTableCell>{get(['inbox', 'name'], channel, '-')}</KooperTableCell>
        <KooperTableCell>
          {wrapText(
            get(['assignees'], channel, [])
              .map(ass => get(['firstName'], ass, '-'))
              .join(', '),
            15
          )}
        </KooperTableCell>
        <KooperTableCell>
          <KooperTooltip
            content={
              manageFacebookPermissions
                ? 'Delete'
                : getPermissionTooltipContent({roleAccess: manageFacebookPermissions})
            }
            position="top center"
            size="mini"
            trigger={
              <KooperButton
                icon
                onClick={e => {
                  e.stopPropagation()
                  if (manageFacebookPermissions) {
                    setIsDeleteModalOpen(true)
                    setCurrentlySelectedActivity(get(['id'], channel, 0))
                  }
                }}
              >
                <SvgIcon path="common/delete" />
              </KooperButton>
            }
          />
        </KooperTableCell>
      </KooperTableRow>
    ))
  }

  if (isLoading) {
    return <PageLoader />
  }

  if (getAllChannelList.length === 0) {
    return (
      <div className="setEmpty-wrap">
        <CreateFirst
          header="Facebook"
          subHeader="Manage all customer conversations from Facebook channel in unified platform"
          addFirst={() => manageFacebookPermissions && dispatch(getSocialCode())}
          Feature1="Synchronization"
          Feature2="Real-time notifications"
          Feature3="Automation"
          list1="Sync all messages from Facebook directly into shared inbox"
          list2="Instant alerts for inquiries to ensure timely responses to customer queries"
          list3="Automate responses based on facebook interactions to save time"
          buttonText="Add Facebook Account"
          tooltip={!manageFacebookPermissions}
          tooltipContent={getPermissionTooltipContent({roleAccess: manageFacebookPermissions})}
          imageSrc={create}
        />
      </div>
    )
  }

  return (
    <SettingLayout
      icon={<SvgIcon path="settings/facebook" />}
      header="Facebook pages"
      subHeader="Associate your Facebook company pages with your helpdesk to manage all
        queries coming in from there along with your support tickets."
      headerButton={{
        onClick: () => manageFacebookPermissions && dispatch(getSocialCode()),
        content: 'Add',
      }}
      table
      headerDivider
      lockRole={!manageFacebookPermissions}
    >
      <KooperTable basic selectable>
        <KooperTableHeader>
          <KooperTableRow>
            <KooperTableHeaderCell>#</KooperTableHeaderCell>
            <KooperTableHeaderCell>Facebook Username</KooperTableHeaderCell>
            <KooperTableHeaderCell>Associate Username</KooperTableHeaderCell>
            <KooperTableHeaderCell>Inbox Name</KooperTableHeaderCell>
            <KooperTableHeaderCell>Assigne</KooperTableHeaderCell>
            <KooperTableHeaderCell>Action</KooperTableHeaderCell>
          </KooperTableRow>
        </KooperTableHeader>
        {isLoadingData && type.includes(GET_CHANNELS_ALL) ? (
          <KooperTablePlaceholder columns={6} rows={3} />
        ) : (
          renderListOfChannelList()
        )}
      </KooperTable>
      {isDeleteModalOpen && (
        <DeleteModal
          idTobeDeleted={currentSelectedActivity}
          isModalOpen={isDeleteModalOpen}
          setIsModalOpen={setIsDeleteModalOpen}
          deleteAction={deleteChannel}
          modalText="Are you sure you want to delete this page ?"
          type={DELETE_CHANNEL}
        />
      )}
    </SettingLayout>
  )
}

export default Facebook
