import React, {useEffect, useState} from 'react'
import {useDispatch} from 'react-redux'
import _ from 'lodash'
import {
  KooperDropdown,
  KooperLabel,
  KooperTable,
  KooperTableBody,
  KooperTableCell,
  KooperTableHeader,
  KooperTableHeaderCell,
  KooperTablePlaceholder,
  KooperTableRow,
  KooperTooltip,
} from 'kooper-library'

import {GET_TRAIL_LOGS, GET_USERS} from 'actions/types'
import {getUsers} from 'actions/settings'
import {getTrailLogs} from 'actions/settings_productivity'
import SettingLayout from 'layouts/settingLayout'
import {
  get,
  getFormattedDate,
  getFormattedTime,
  getPermissionTooltipContent,
  getSortType,
  startCase,
} from 'utils/helper'
import KooperCustomPagination from 'components/common/KooperCustomPagination'
import useUserPermissions from 'hooks/pure/useUserPermissions'
import auditLogsBlur from 'assets/audit-logs-blur.png'
import useLearnMoreUrl from 'hooks/pure/useLearnMoreUrl'
import SvgIcon from 'components/common/SvgIcon'
import useApiResponse from 'hooks/impure/useApiResponse'

const categoryList = [
  {
    key: 'inbox',
    value: 'inbox',
    text: 'Inbox',
  },
  {key: 'customer', value: 'customer', text: 'Customer'},
  {key: 'deal', value: 'deal', text: 'Deal'},
  {key: 'forecast', value: 'forecast', text: 'Forecast'},
  {key: 'sequence', value: 'sequence', text: 'Sequence'},
  {key: 'meeting', value: 'meeting', text: 'Meeting'},
  {key: 'quote', value: 'quote', text: 'Quote'},
  {key: 'workflow', value: 'workflow', text: 'Workflow'},
  {key: 'setting', value: 'setting', text: 'Setting'},
]

const AuditLogs = () => {
  const dispatch = useDispatch()

  const [usersList, setUserList] = useState([])
  const [currentListParams, setCurrentListParams] = useState({
    agent: '',
    category: 'inbox',
    event: '',
    sortBy: '',
    sortType: '',
  })

  const {settingsLearnMoreUrl} = useLearnMoreUrl()
  const auditLogsLearnMore = settingsLearnMoreUrl?.productivity?.auditLogs?.learnMore

  const {
    isLoading: isLoadingAuditLogs,
    data: auditLogList = {},
    totalPages,
    currentPage,
    pageSize,
  } = useApiResponse({label: GET_TRAIL_LOGS, storePath: 'settingsProductivity.auditLogList'})

  const {workflowAndAutomationAccess, productivityPermissions} = useUserPermissions()
  const auditLogsAccess = workflowAndAutomationAccess?.auditLogs
  const auditLogsPermissions = productivityPermissions?.audit_logs

  useApiResponse({
    action: getUsers,
    enabled: auditLogsAccess && auditLogsPermissions,
    dependency: [auditLogsAccess, auditLogsPermissions],
    label: GET_USERS,
    storePath: 'settings.getUsersList',
    onSuccess: getUsersList => {
      const list = getUsersList?.users?.map(user => ({
        key: user.id,
        value: user.id,
        text: `${user.firstName} ${user.lastName}`,
      }))
      setUserList(list || [])
    },
  })

  useEffect(() => {
    if (!auditLogsAccess || !auditLogsPermissions) return

    const newCurrentListParams = _.omitBy(currentListParams, value => {
      if (value === 'all' || value === '') {
        return true
      }
      return false
    })

    const data = {...newCurrentListParams}

    dispatch(getTrailLogs(data))
  }, [auditLogsAccess, auditLogsPermissions, currentListParams, dispatch])

  const handleSort = clickedColumn => () => {
    if (currentListParams.sortBy !== clickedColumn) {
      setCurrentListParams({
        ...currentListParams,
        sortType: 'asc',
        sortBy: clickedColumn,
      })
      return
    }
    setCurrentListParams({
      ...currentListParams,
      sortType: currentListParams.sortType === 'asc' ? 'desc' : 'asc',
    })
  }

  const getLabelcolor = data => {
    const labelName = data?.event
    if (labelName === 'create') return 'green'
    if (labelName === 'update') return 'olive'
    if (labelName === 'delete') return 'red'
    return null
  }

  const renderLogTable = () => {
    if (isLoadingAuditLogs) {
      return <KooperTablePlaceholder columns={6} />
    }
    if (!isLoadingAuditLogs && get(['data'], auditLogList, []).length === 0) {
      return (
        <KooperTableRow>
          <KooperTableCell colSpan="6" className="emptyValue">
            No Data Available
          </KooperTableCell>
        </KooperTableRow>
      )
    }
    return get(['data'], auditLogList, []).map((log, index) => (
      <KooperTableRow key={index}>
        <KooperTableCell className="pl-4">{pageSize * (currentPage - 1) + index + 1}</KooperTableCell>
        <KooperTableCell>
          <h5 className="mb-0">{getFormattedDate(log.ts)}</h5>
          <span className="kooper-lead  mb-0" style={{fontSize: '12px', fontWeight: '500'}}>
            {getFormattedTime(log.ts)}
          </span>
        </KooperTableCell>
        <KooperTableCell>{get(['agent', 'name'], log, '-')}</KooperTableCell>
        <KooperTableCell>
          <KooperLabel className="kooper-label" color={getLabelcolor(log)}>
            {startCase(get(['event'], log, '-'))}
          </KooperLabel>
        </KooperTableCell>
        <KooperTableCell>{get(['category'], log, '-')}</KooperTableCell>
        <KooperTableCell>{get(['description'], log, '-')}</KooperTableCell>
      </KooperTableRow>
    ))
  }

  return (
    <SettingLayout
      icon={<SvgIcon path="settings/audit-logs" />}
      header="Audit/Logs"
      subHeader="Track user actions performed and system changes for accountability and troubleshooting purposes"
      learnMoreUrl={auditLogsLearnMore}
      headerDivider
      fullWidth
    >
      {auditLogsAccess && auditLogsPermissions ? (
        <>
          <div style={{display: 'flex', justifyContent: 'flex-end', gap: 'var(--gap-medium)'}}>
            <KooperTooltip
              content="Performed By"
              size="mini"
              position="top center"
              trigger={
                <KooperDropdown
                  selection
                  defaultValue="all"
                  options={[{value: 'all', text: 'All', key: 'all'}, ...usersList]}
                  onChange={(e, {value}) => {
                    setCurrentListParams({
                      ...currentListParams,
                      page: 1,
                      agent: value,
                    })
                  }}
                />
              }
            />

            <KooperTooltip
              content="Category"
              size="mini"
              position="top center"
              trigger={
                <KooperDropdown
                  selection
                  value={currentListParams?.category || 'inbox'}
                  options={categoryList}
                  onChange={(e, value) => {
                    setCurrentListParams({
                      ...currentListParams,
                      page: 1,
                      category: value.value,
                    })
                  }}
                />
              }
            />

            <KooperTooltip
              content="Event"
              size="mini"
              position="top center"
              trigger={
                <KooperDropdown
                  selection
                  defaultValue="all"
                  options={[
                    {value: 'all', text: 'All Event', key: 'all'},
                    {value: 'update', text: 'Update', key: 'update'},
                    {value: 'create', text: 'Create', key: 'create'},
                    {value: 'delete', text: 'Delete', key: 'delete'},
                  ]}
                  onChange={(e, value) => {
                    setCurrentListParams({
                      ...currentListParams,
                      page: 1,
                      event: value.value,
                    })
                  }}
                />
              }
            />
          </div>

          <KooperTable basic selectable sortable className="mt-2">
            <KooperTableHeader>
              <KooperTableRow>
                <KooperTableHeaderCell className="pl-4">#</KooperTableHeaderCell>
                <KooperTableHeaderCell
                  sorted={currentListParams.sortBy === 'ts' ? getSortType(currentListParams.sortType) : null}
                  onClick={handleSort('ts')}
                >
                  Date & Time
                </KooperTableHeaderCell>
                <KooperTableHeaderCell
                  sorted={currentListParams.sortBy === 'agent' ? getSortType(currentListParams.sortType) : null}
                  onClick={handleSort('agent')}
                >
                  Performed by
                </KooperTableHeaderCell>
                <KooperTableHeaderCell
                  sorted={currentListParams.sortBy === 'Event' ? getSortType(currentListParams.sortType) : null}
                  onClick={handleSort('Event')}
                >
                  Event
                </KooperTableHeaderCell>
                <KooperTableHeaderCell
                  sorted={currentListParams.sortBy === 'Category' ? getSortType(currentListParams.sortType) : null}
                  onClick={handleSort('Category')}
                >
                  Category
                </KooperTableHeaderCell>
                <KooperTableHeaderCell>Actions</KooperTableHeaderCell>
              </KooperTableRow>
            </KooperTableHeader>
            <KooperTableBody>{renderLogTable()}</KooperTableBody>
          </KooperTable>

          {totalPages > 1 && (
            <KooperCustomPagination
              activePage={currentPage}
              totalPages={totalPages}
              onPageChange={page =>
                setCurrentListParams(currentListParams => ({
                  ...currentListParams,
                  page,
                }))
              }
            />
          )}
        </>
      ) : (
        <div className="blur-bg-container">
          <img src={auditLogsBlur} alt="survey blur" />
          <div className="blur-bg">
            {getPermissionTooltipContent({featureAccess: auditLogsAccess, roleAccess: auditLogsPermissions})}
          </div>
        </div>
      )}
    </SettingLayout>
  )
}

export default AuditLogs
