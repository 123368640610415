import React, {useState} from 'react'
import {useSelector} from 'react-redux'
import {useHistory, useRouteMatch} from 'react-router-dom'
import {KooperMenu, KooperMenuItem, KooperAccordion, KooperAccordionTitle, KooperAccordionContent} from 'kooper-library'
import {FiChevronRight, FiList, FiLayers} from 'react-icons/fi'
import {isActiveNavlink} from 'utils/helper'
import SvgIcon from 'components/common/SvgIcon'

const ProductsMenu = () => {
  const history = useHistory()
  const {url} = useRouteMatch()

  const [expand, setExpand] = useState([])

  const {productList = []} = useSelector(state => state.productLibrary)

  const menus = [
    {
      key: 'settings',
      title: 'Settings',
      items: [
        {
          path: '/products-settings',
          title: 'Settings',
          icon: <FiList />,
        },
      ],
    },
  ]

  const handleMenuExpand = key => {
    const menus = [...expand]
    if (menus.includes(key)) {
      menus.splice(menus.indexOf(key), 1)
      setExpand(menus)
    } else {
      setExpand([...menus, key])
    }
  }

  const isActive = key => !expand.includes(key)

  return (
    <>
      {productList.length > 0 && (
        <KooperMenu vertical secondary className="kooper-menu">
          <KooperMenuItem active={isActiveNavlink('/products/all')} onClick={() => history.push('/products/all')}>
            <FiLayers /> All products
          </KooperMenuItem>

          {menus.map(({title, key, items}) => (
            <KooperAccordion key={key}>
              <KooperAccordionTitle onClick={() => handleMenuExpand(key)}>
                {title}
                {isActive(key) ? <SvgIcon path="common/down" /> : <FiChevronRight />}
              </KooperAccordionTitle>
              <KooperAccordionContent active={isActive(key)}>
                {items.map(({path, title, icon}) => (
                  <KooperMenuItem key={path} active={isActiveNavlink(path)} onClick={() => history.push(`${url}${path}`)}>
                    {icon} {title}
                  </KooperMenuItem>
                ))}
              </KooperAccordionContent>
            </KooperAccordion>
          ))}
        </KooperMenu>
      )}
    </>
  )
}

export default ProductsMenu
