/* eslint-disable no-nested-ternary */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, {useState, useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useHistory} from 'react-router'
import Board from 'react-trello'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import {FiSettings, FiStar, FiList, FiSliders, FiGitMerge, FiExternalLink, FiFileText} from 'react-icons/fi'
import {FaStar} from 'react-icons/fa'
import _ from 'lodash'
import moment from 'moment'
import {
  KooperButton,
  KooperDropdown,
  KooperCardContent,
  KooperCardMeta,
  KooperCard,
  KooperTableHeader,
  KooperTableRow,
  KooperTableHeaderCell,
  KooperTableBody,
  KooperTableCell,
  KooperTable,
  KooperCheckbox,
  KooperList,
  KooperListItem,
  KooperDropdownMenu,
  KooperDropdownItem,
  KooperTooltip,
  KooperLabel,
  KooperButtonGroup,
  KooperTablePlaceholder,
  KooperRating,
  KooperHeader,
} from 'kooper-library'

import {
  ABANDON_DEAL,
  ADD_DEAL,
  ADD_NOTE_DEAL,
  COLUMNS_SELECTION_DEALS,
  DELETE_DEAL,
  EDIT_DEAL,
  GET_ALL_DEALS,
  GET_ALL_PIPELINES,
  LOSS_DEAL,
  MULTIPLE_UPDATE_DEAL,
  WIN_DEAL,
} from 'actions/types'
import {
  getAllDeals,
  getColumnsSelectionDeals,
  getStageDeals,
  dealMultipleUpdate,
  editDeal,
  deleteDeal,
  getPipelines,
} from 'actions/deals'

import {getContactType, getContactList} from 'actions/contacts'
import CustomizeTableColumn from 'components/common/CustomizeTableColumn'
import DeleteModal from 'components/common/DeleteModal'
import UserImage from 'components/common/UserImage'
import AddNote from 'components/common/AddNote'
import SearchComponent from 'components/common/SearchComponent'
import {
  abbreviateNumber,
  get,
  getFormattedDate,
  GetFormattedDate,
  getPermissionTooltipContent,
  getSortType,
  startCase,
} from 'utils/helper'
import KooperCustomPagination from 'components/common/KooperCustomPagination'
import useUserPermissions from 'hooks/pure/useUserPermissions'
import LockPermissionTooltip from 'components/common/LockPermissionTooltip'
import SvgIcon from 'components/common/SvgIcon'
import useApiResponse from 'hooks/impure/useApiResponse'
import WonModal from './Won'
import LostModal from './LostModal'
import AbandonedModal from './AbandonedModal'
import CreateDealModal from './CreateModal'
import EditDealModal from './EditModal'

const Deals = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  const [isEditModal, setIsEditModal] = useState(false)
  const [dealId, setDealId] = useState()
  const [isNoteOpen, setIsNoteOpen] = useState(false)
  const [isMergeModal, setIsMergeModal] = useState(false)
  const [checked, setChecked] = useState([])
  const [addNewDeal, setAddNewDeal] = useState(false)
  const [previewModal, setPreviewModal] = useState(false)
  const [isCustomizeColumn, setIsCustomizeColumn] = useState(false)
  const [wonDealId, setWonDealId] = useState(0)
  const [lossDealId, setLossDealId] = useState(0)
  const [abandonDealId, setAbandonDealId] = useState(0)
  const [winDealType, setWinDealType] = useState('')
  const [wonModal, setWonModal] = useState(false)
  const [lostModal, setLostModal] = useState(false)
  const [abandonedModal, setAbandonedModal] = useState(false)
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
  const [numberOfPage, setNumberOfPage] = useState(0)
  const [currentPage, setCurrentPage] = useState(0)
  const [dealPipelineList, setDealPipelineList] = useState([])

  const [sort, setSort] = useState({
    page: null,
    sortBy: '',
    sortType: '',
    text: '',
  })

  const {isLoadingData, type, successLabels = []} = useSelector(state => state.apiReducer)
  const getAllDealsColumnsList = useSelector(state => {
    return get(['deals', 'getAllDealsColumnsList', 'columns'], state, [])
  })
  const AllDealsData = useSelector(state => {
    return get(['deals', 'getAllDealsList', 'deals'], state, [])
  })
  const pages = useSelector(state => {
    return get(['deals', 'getAllDealsList', 'page'], state, [])
  })
  const getAllStages = useSelector(state => {
    return get(['deals', 'getAllDealsList', 'allStages'], state, {})
  })
  const getSingleStages = useSelector(state => {
    return get(['deals', 'getAllDealsList', 'stages'], state, [])
  })
  const [isDefaultView, setDefaultView] = useState(false)

  const [kanBoardData, setKanBoardData] = useState({
    lanes: [
      {
        id: 1,
        label: '0/0',
        title: 'Qualified',
        cards: [],
        abandoned: 0,
        won: 0,
        open: 0,
        lost: 0,
        total: 0,
      },
      {
        id: 2,
        label: '0/0',
        title: 'Follow up',
        cards: [],
        abandoned: 0,
        won: 0,
        open: 0,
        lost: 0,
        total: 0,
      },
      {
        id: 3,
        title: 'Presentation',
        label: '0/0',
        cards: [],
        abandoned: 0,
        won: 0,
        open: 0,
        lost: 0,
        total: 0,
      },
      {
        id: 4,
        title: 'Contract Sent',
        label: '0/0',
        cards: [],
        abandoned: 0,
        won: 0,
        open: 0,
        lost: 0,
        total: 0,
      },
      {
        id: 5,
        title: 'Negotiation',
        label: '0/0',
        cards: [],
        abandoned: 0,
        won: 0,
        open: 0,
        lost: 0,
        total: 0,
      },
    ],
  })

  const {salesAndCrmAccess, dealsPermissions} = useUserPermissions()
  const importAndExportAccess = salesAndCrmAccess?.importAndExport
  const manageDealsPermissions = dealsPermissions?.manage_deals
  const bulkActionsDealsPermissions = dealsPermissions?.bulk_actions_deals
  const manageImportExportDealsPermissions = dealsPermissions?.manage_import_export_deals

  useEffect(() => {
    if (successLabels.includes(WIN_DEAL)) {
      dispatch(getAllDeals(sort))
      setWonModal(false)
    }
    if (successLabels.includes(LOSS_DEAL)) {
      dispatch(getAllDeals(sort))
      setLostModal(false)
    }
    if (successLabels.includes(ABANDON_DEAL)) {
      setAbandonedModal(false)
      dispatch(getAllDeals(sort))
    }
    if (successLabels.includes(ADD_DEAL)) {
      setAddNewDeal(false)
      dispatch(getAllDeals(sort))
    }
    if (successLabels.includes(EDIT_DEAL)) {
      dispatch(getAllDeals(sort))
    }
    if (successLabels.includes(DELETE_DEAL)) {
      setChecked([])
      dispatch(getAllDeals(sort))
    }
    if (successLabels.includes(MULTIPLE_UPDATE_DEAL)) {
      setChecked([])
      setIsEditModal(false)
    }
    if (successLabels.includes(COLUMNS_SELECTION_DEALS)) {
      setIsCustomizeColumn(false)
    }
    if (successLabels.includes(ADD_NOTE_DEAL)) {
      setIsNoteOpen(false)
    }
  }, [successLabels])

  useEffect(() => {
    if (successLabels.includes(GET_ALL_DEALS)) {
      const boardData = {
        lanes: getSingleStages.map((lane, index) => {
          return {
            id: lane.id,
            title: lane.stage,
            label: `${index + 1}/${getSingleStages.length}`,
            abandoned: `${lane.abandoned}($${abbreviateNumber(lane.abandonedValue)})`,
            won: `${lane.won}($${abbreviateNumber(lane.wonValue)})`,
            lost: `${lane.lost}($${abbreviateNumber(lane.lostValue)})`,
            open: `${lane.open}($${abbreviateNumber(lane.openValue)})`,
            total: `${lane.total}($${abbreviateNumber(lane.totalValue)})`,

            cards: _.get(getSingleStages[index], 'deals').map(cards => {
              return {
                id: cards.id,
                title: cards.name,
                metadata: {
                  priority: cards.priority,
                  owner: cards.owner,
                  interactionTime: cards.interactionTime,
                  monetaryValue: abbreviateNumber(cards.monetaryValue),
                  wonValue: _.isEmpty(cards.monetaryValue) ? 0 : cards.monetaryValue,
                  currency: cards.currency,
                  closeDate: cards.closeDate,
                  type: {
                    contacts: _.get(cards, 'contacts'),
                    leads: _.get(cards, 'leads'),
                  },
                  status: cards.status,
                  primaryContact: cards.primaryContact,
                  company: cards.company,
                },
              }
            }),
          }
        }),
      }

      setKanBoardData(boardData)

      const data = {}
      getSingleStages.map((lane, index) => {
        get([index, 'deals'], getSingleStages, []).map(cards => {
          data[cards.id] = false
        })
      })
      setNumberOfPage(pages.pages)
      setCurrentPage(pages.page)
      dispatch({
        type: 'CLEAR_ALL_DEALS_MSG',
      })
    }
  }, [successLabels])

  useApiResponse({
    action: getPipelines,
    enabled: true,
    label: GET_ALL_PIPELINES,
    storePath: 'deals.getAllPipelineData',
    onSuccess: getAllPipelineData => {
      const pipeline = getAllPipelineData.map(list => ({
        key: list.id,
        text: list.pipeline,
        value: list.id,
      }))
      setDealPipelineList(pipeline)
      setSort({...sort, pipelineId: pipeline[0]?.value})
      dispatch(getStageDeals(pipeline[0]?.value))
    },
  })

  const handleSort = clickedColumn => () => {
    if (sort.sortBy !== clickedColumn) {
      setSort({
        ...sort,
        sortType: 'asc',
        sortBy: clickedColumn,
      })
      return
    }
    setSort({
      ...sort,
      sortType: sort.sortType === 'asc' ? 'desc' : 'asc',
    })
  }

  useEffect(() => {
    dispatch({type: 'CLEAR_ALL_DEALS_DATA'})
    dispatch(getAllDeals(sort))
  }, [sort])

  useEffect(() => {
    dispatch(getContactType())
    if (
      get(['dealView'], JSON.parse(window.localStorage.getItem('sales'))) !== undefined &&
      get(['dealView'], JSON.parse(window.localStorage.getItem('sales'))) === 'true'
    ) {
      setDefaultView(get(['dealView'], JSON.parse(window.localStorage.getItem('sales'))))
      setSort({...sort, page: 1})
    }
  }, [])

  useEffect(() => {
    if (isDefaultView) {
      dispatch(getColumnsSelectionDeals())
    }
  }, [isDefaultView])

  const handleCheck = id => {
    const isChecked = checked.some(lead => lead === id)

    if (isChecked) {
      const removeChecked = checked.filter(lead => lead !== id)
      setChecked(removeChecked)
    } else {
      setChecked([...checked, id])
    }
  }

  const isChecked = id => {
    const check = checked.find(lead => lead === id)
    if (check) {
      return true
    }
    return false
  }

  const isCheckedAll = () => {
    const allIds = AllDealsData.map(lead => lead.id)

    const checkAll = _.xor(allIds, checked).length === 0 && allIds.length !== 0
    if (checkAll) {
      return true
    }
    return false
  }

  const handleCheckAll = () => {
    const allIds = AllDealsData.map(lead => lead.id)

    if (_.xor(allIds, checked).length === 0) {
      setChecked([])
    } else setChecked(allIds)
  }

  const customizeColumnLogic = (item, key) => {
    const conditionalObject = {
      company: get(['company', 'name'], item, '--'),
      owner: startCase(get(['owner', 'firstName'], item, '--')),
      status: startCase(get(['status'], item, '--')),
      source: get(['source', 'source'], item, '--'),
      inactiveDays: get(['interactionTime'], item) ? moment(get(['interactionTime'], item)).fromNow(true) : '--',
      stage: startCase(get(['stage', 'stage'], item, '--')),
      stageDuration: get(['stageTime'], item) ? moment(get(['stageTime'], item)).fromNow(true) : '--',
      stageUpdated: get(['stageTime'], item) ? moment(get(['stageTime'], item)).fromNow('ll') : '--',
      // contactType: get(["contactType", "type"], item),
      updatedAt: getFormattedDate(item.updatedAt),
      createdAt: getFormattedDate(item.createdAt),
      lastContact: get(['interactionTime'], item) ? GetFormattedDate(get(['interactionTime'], item)) : '--',
      closeDate: get(['closeDate'], item) ? getFormattedDate(get(['closeDate'], item)) : '--',
      priority: get(['priority'], item, '--'),
      win: get(['win'], item, '--'),
      monetaryValue: get(['monetaryValue'], item) || '',
      primaryContact: `${startCase(get(['primaryContact', 'firstName'], item) || '')} ${startCase(
        get(['primaryContact', 'lastName'], item) || ''
      )}`,
      tags: get(['labels'], item, [])
        .slice(0, 2)
        .map(tag => {
          return (
            <>
              {tag.label}
              <br />
            </>
          )
        }),
    }

    if (Object.keys(conditionalObject).includes(key)) {
      return get([key], conditionalObject)
    }
    return item[key]
  }

  const renderOptionList = () => {
    if (checked.length > 0) {
      return (
        <KooperButtonGroup basic compact className="ml-3">
          <LockPermissionTooltip isRoleAccessDenied={!bulkActionsDealsPermissions}>
            <KooperTooltip
              size="mini"
              position="bottom center"
              content="Compose Bulk Mail"
              trigger={
                <KooperButton>
                  <SvgIcon path="common/mail" />
                </KooperButton>
              }
            />
          </LockPermissionTooltip>
          {checked.some(item => {
            return AllDealsData.some(list => list.id === item && !list.isFollowed)
          }) ? (
            <KooperTooltip
              size="mini"
              position="bottom center"
              content={
                bulkActionsDealsPermissions
                  ? 'Add Selected Favorite'
                  : getPermissionTooltipContent({roleAccess: bulkActionsDealsPermissions})
              }
              trigger={
                <KooperButton
                  color="blue"
                  onClick={() =>
                    bulkActionsDealsPermissions &&
                    dispatch(
                      dealMultipleUpdate({
                        ids: checked,
                        isFollowed: true,
                      })
                    )
                  }
                >
                  <FiStar />
                </KooperButton>
              }
            />
          ) : (
            <KooperTooltip
              size="mini"
              position="bottom center"
              content={
                bulkActionsDealsPermissions
                  ? 'Remove Selected Favorite'
                  : getPermissionTooltipContent({roleAccess: bulkActionsDealsPermissions})
              }
              trigger={
                <KooperButton
                  color="light"
                  onClick={() =>
                    bulkActionsDealsPermissions &&
                    dispatch(
                      dealMultipleUpdate({
                        ids: checked,
                        isFollowed: false,
                      })
                    )
                  }
                >
                  <FaStar style={{color: '#ecd60e'}} />
                </KooperButton>
              }
            />
          )}
          <KooperTooltip
            size="mini"
            position="bottom center"
            content={
              bulkActionsDealsPermissions
                ? 'Edit Selected Deals'
                : getPermissionTooltipContent({roleAccess: bulkActionsDealsPermissions})
            }
            trigger={
              <KooperButton onClick={() => bulkActionsDealsPermissions && setIsEditModal(!isEditModal)}>
                <SvgIcon path="common/edit2" />
              </KooperButton>
            }
          />
          <KooperTooltip
            size="mini"
            position="bottom center"
            content={
              bulkActionsDealsPermissions
                ? 'Merge Selected Deals'
                : getPermissionTooltipContent({roleAccess: bulkActionsDealsPermissions})
            }
            trigger={
              <KooperButton onClick={() => bulkActionsDealsPermissions && setIsMergeModal(!isMergeModal)}>
                <FiGitMerge />
              </KooperButton>
            }
          />
          <KooperTooltip
            size="mini"
            position="bottom center"
            content={
              bulkActionsDealsPermissions
                ? 'Export Selected Deals'
                : getPermissionTooltipContent({roleAccess: bulkActionsDealsPermissions})
            }
            trigger={
              <KooperButton>
                <FiExternalLink />
              </KooperButton>
            }
          />
          <KooperTooltip
            size="mini"
            position="bottom center"
            content={
              bulkActionsDealsPermissions
                ? 'Delete Selected Deals'
                : getPermissionTooltipContent({roleAccess: bulkActionsDealsPermissions})
            }
            trigger={
              <KooperButton onClick={() => bulkActionsDealsPermissions && setIsDeleteModalOpen(!isDeleteModalOpen)}>
                <SvgIcon path="common/delete" />
              </KooperButton>
            }
          />
        </KooperButtonGroup>
      )
    }
  }

  const getTextColor = priority => {
    if (priority === 'default') {
      return 'text-secondary'
    }
    if (priority === 'low') {
      return 'text-success'
    }
    if (priority === 'medium') {
      return 'text-info'
    }
    if (priority === 'high') {
      return 'text-danger'
    }
    if (priority === 'urgent') {
      return 'text-danger'
    }
  }

  const MyCard = e => {
    return (
      <KooperCard className="dealCard">
        <KooperCardContent>
          <h5
            onClick={() => {
              history.push(`/deals/${e.id}`)
            }}
          >
            {e.title}
            <span>
              <b>
                {_.get(e, 'metadata.monetaryValue') && _.get(e, 'metadata.currency') && (
                  <>
                    {_.get(e, 'metadata.monetaryValue') || ''}
                    {_.get(e, 'metadata.currency') || ''}
                  </>
                )}
              </b>
              <KooperTooltip
                size="mini"
                position="top center"
                content={
                  manageDealsPermissions ? 'Delete' : getPermissionTooltipContent({roleAccess: manageDealsPermissions})
                }
                className="d-inline-block"
                trigger={
                  <KooperButton
                    icon
                    onClick={event => {
                      event.preventDefault()
                      event.stopPropagation()
                      if (manageDealsPermissions) {
                        setDealId(e.id)
                        setIsDeleteModalOpen(!isDeleteModalOpen)
                      }
                    }}
                  >
                    <SvgIcon path="common/delete" />
                  </KooperButton>
                }
              />
              {/* <KooperTooltip
                size="mini"
                position="top center"
                content="Edit"
                className="d-inline-block"
                trigger={
                  <KooperButton
                    icon
                    onClick={(e) => {
                      e.preventDefault();
                      history.push(`/deals/${e.id}`);
                    }}
                  >
                    <SvgIcon path="common/edit2" />
                  </KooperButton>
                }
              /> */}
              <KooperTooltip
                size="mini"
                position="top center"
                content="Mail"
                className="d-inline-block"
                trigger={
                  <KooperButton icon>
                    <SvgIcon path="common/mail" />
                  </KooperButton>
                }
              />
              <KooperTooltip
                size="mini"
                position="top center"
                content={
                  manageDealsPermissions ? 'Notes' : getPermissionTooltipContent({roleAccess: manageDealsPermissions})
                }
                className="d-inline-block"
                trigger={
                  <KooperButton
                    icon
                    onClick={event => {
                      event.preventDefault()
                      event.stopPropagation()
                      if (manageDealsPermissions) {
                        setDealId(e.id)
                        setIsNoteOpen(!isNoteOpen)
                      }
                    }}
                  >
                    <FiFileText />
                  </KooperButton>
                }
              />
            </span>
          </h5>
          <div className="deal-metadata canbanMiddle d-flex">
            <KooperCardMeta className="kooper-card-meta">
              {`${_.get(e, 'metadata.primaryContact.firstName') || ''} ${
                _.get(e, 'metadata.primaryContact.lastName') || ''
              } `}
              {_.get(e, 'metadata.company.name') && `(${_.get(e, 'metadata.company.name') || ''})`}
            </KooperCardMeta>
          </div>

          <span style={{fontSize: '13px'}} className="time">
            {_.get(e, 'metadata.closeDate') ? <>Due Date :{getFormattedDate(get(['metadata', 'closeDate'], e))}</> : ''}
          </span>
          <div className="canbanFooter d-flex">
            <KooperTooltip
              size="mini"
              position="top center"
              content="Priority"
              className="d-inline-block"
              trigger={
                <KooperLabel className={`kooper-label ${getTextColor(e.metadata.priority)}`}>
                  {get(['metadata', 'priority'], e)}
                </KooperLabel>
              }
            />
            {_.get(e, 'metadata.interactionTime') ? (
              <span>{GetFormattedDate(get(['metadata', 'interactionTime'], e))}</span>
            ) : (
              ''
            )}
            {get(['metadata', 'owner'], e) && (
              <KooperTooltip
                size="mini"
                position="top center"
                content={`${get(['metadata', 'owner', 'firstName'], e)} ${get(['metadata', 'owner', 'lastName'], e)}`}
                className="d-inline-block"
                trigger={
                  <span>
                    <UserImage profile={get(['metadata', 'owner'], e)} />
                  </span>
                }
              />
            )}
          </div>

          <div className="kanBanCardAct">
            <LockPermissionTooltip isRoleAccessDenied={!manageDealsPermissions}>
              <KooperButton
                color="red"
                size="mini"
                onClick={() => {
                  if (manageDealsPermissions) {
                    setLossDealId(e.id)
                    setLostModal(!lostModal)
                  }
                }}
              >
                Lost
              </KooperButton>
            </LockPermissionTooltip>
            <LockPermissionTooltip isRoleAccessDenied={!manageDealsPermissions}>
              <KooperButton
                size="mini"
                basic
                onClick={() => {
                  if (manageDealsPermissions) {
                    setAbandonedModal(!abandonedModal)
                    setAbandonDealId(e.id)
                  }
                }}
              >
                Abandoned
              </KooperButton>
            </LockPermissionTooltip>
            <LockPermissionTooltip isRoleAccessDenied={!manageDealsPermissions}>
              <KooperButton
                color="green"
                size="mini"
                onClick={() => {
                  if (manageDealsPermissions) {
                    setWonModal(!wonModal)
                    setWonDealId(e.id)
                    setWinDealType(_.get(e, 'metadata'))
                  }
                }}
              >
                Won
              </KooperButton>
            </LockPermissionTooltip>
          </div>
        </KooperCardContent>
      </KooperCard>
    )
  }

  const CustomLaneHeader = ({won, lost, open, abandoned, total, cards, title}) => {
    return (
      <div>
        <header
          style={{
            marginTop: 0,
            display: 'block',
            textAlign: 'center',
          }}
        >
          <div
            style={{
              fontSize: 13,
              fontWeight: '500',
              textAlign: 'center',
              paddingTop: '10px',
              borderTop: '1px solid rgba(27, 50, 90, 0.08)',
              color: '#1b325a',
              letterSpacing: '0.2px',
              paddingBottom: '10px',
              borderBottom: '1px solid rgba(27, 50, 90, 0.08)',
              background: '#f5faff',
            }}
          >
            {startCase(title)} ({cards.length})<p className="kooper-lead mb-1">{total}</p>
            <div className="viewData viewDatainPipe">
              <KooperList horizontal>
                <KooperListItem>
                  <label>Open</label>
                  <p>{open}</p>
                </KooperListItem>
                <KooperListItem>
                  <label>Won</label>
                  <p>{won}</p>
                </KooperListItem>
                <KooperListItem>
                  <label>Lost</label>
                  <p>{lost}</p>
                </KooperListItem>
                <KooperListItem>
                  <label>Abandoned</label>
                  <p>{abandoned}</p>
                </KooperListItem>
              </KooperList>
            </div>
          </div>
        </header>
      </div>
    )
  }

  const renderLeadList = () => {
    return AllDealsData.map(item => (
      <KooperTableRow
        className="tableLink"
        onClick={() => {
          history.push({
            pathname: `/deals/${get(['id'], item, '')}`,
            // state: {
            //   ...get(
            //     ["routeState"],
            //     JSON.parse(window.localStorage.getItem("sales"))
            //   ),
            //   companyName: get(["name"], item, ""),
            // },
          })
        }}
        key={item.id}
      >
        <KooperTableCell onClick={e => e.stopPropagation()} className="pl-4">
          <KooperTooltip
            size="mini"
            position="top center"
            content="Select"
            className="d-inline-block"
            trigger={
              <KooperCheckbox
                id={`${item.id}label`}
                label=""
                onChange={() => handleCheck(item.id)}
                checked={isChecked(item.id)}
              />
            }
          />
          <KooperTooltip
            size="mini"
            position="top center"
            content={item.isFollowed ? 'Remove Favorite' : 'Add Favorite'}
            trigger={
              <KooperRating
                icon="star"
                className="ml-4"
                onClick={() =>
                  dispatch(
                    dealMultipleUpdate({
                      ids: [item.id],
                      isFollowed: !item.isFollowed,
                    })
                  )
                }
                rating={item.isFollowed ? 1 : 0}
              />
            }
          />
        </KooperTableCell>
        <KooperTableCell className="prevCell">
          <KooperHeader as="h5">{startCase(get(['name'], item, ''))}</KooperHeader>
          <div className="PrevActPart">
            <KooperLabel
              as="a"
              color="blue"
              onClick={() => {
                setPreviewModal(!previewModal)
              }}
            >
              Preview
            </KooperLabel>
          </div>
        </KooperTableCell>

        {getAllDealsColumnsList.map(key => {
          return <KooperTableCell key={key}>{customizeColumnLogic(item, key)}</KooperTableCell>
        })}
        <KooperTableCell>
          <KooperTooltip
            content={
              manageDealsPermissions ? 'Delete' : getPermissionTooltipContent({roleAccess: manageDealsPermissions})
            }
            size="mini"
            position="top center"
            trigger={
              <KooperButton
                icon={<SvgIcon path="common/delete" />}
                onClick={e => {
                  e.stopPropagation()
                  e.preventDefault()
                  if (manageDealsPermissions) {
                    setDealId(item.id)
                    setIsDeleteModalOpen(!isDeleteModalOpen)
                  }
                }}
              />
            }
          />
        </KooperTableCell>
      </KooperTableRow>
    ))
  }
  const onCardDrag = (cardId, stageLandId, targetLaneId) => {
    if (targetLaneId === stageLandId) {
      return
    }

    const data = {
      stageId: targetLaneId,
    }

    dispatch(editDeal(data, cardId))
  }

  const renderDefaultView = () => {
    if (isDefaultView)
      return (
        <div className="pageBodyWrap" style={{height: 'calc(100vh - 20.02vh)'}}>
          <KooperTable selectable sortable basic>
            <KooperTableHeader>
              <KooperTableRow>
                <KooperTableHeaderCell className="pl-4">
                  <KooperTooltip
                    size="mini"
                    position="top center"
                    content="Select All"
                    trigger={
                      <KooperCheckbox type="checkbox" label="" checked={isCheckedAll()} onChange={handleCheckAll} />
                    }
                  />
                </KooperTableHeaderCell>
                <KooperTableHeaderCell
                  sorted={sort.sortBy === 'name' ? getSortType(sort.sortType) : null}
                  onClick={handleSort('name')}
                >
                  Name
                </KooperTableHeaderCell>
                {getAllDealsColumnsList.map(col => {
                  return (
                    <KooperTableHeaderCell
                      key={col}
                      sorted={sort.sortBy === col ? getSortType(sort.sortType) : null}
                      onClick={handleSort(col)}
                    >
                      {startCase(col)}
                    </KooperTableHeaderCell>
                  )
                })}
                <KooperTableHeaderCell>Action</KooperTableHeaderCell>
              </KooperTableRow>
            </KooperTableHeader>
            <KooperTableBody>
              {isLoadingData && type.includes(GET_ALL_DEALS) ? (
                <KooperTablePlaceholder columns={7} rows={5} />
              ) : AllDealsData.length > 0 ? (
                renderLeadList()
              ) : (
                <tr>
                  <td colSpan={getAllDealsColumnsList.length + 3} className="emptyValue">
                    No Data Available
                  </td>
                </tr>
              )}
            </KooperTableBody>
          </KooperTable>
          <KooperCustomPagination
            totalPages={numberOfPage}
            activePage={currentPage}
            onPageChange={page => {
              setSort(sort => ({...sort, page}))
            }}
          />
        </div>
      )
    return (
      <Board
        components={{
          Card: MyCard,
          LaneHeader: CustomLaneHeader,
        }}
        handleDragEnd={onCardDrag}
        laneStyle={{
          width: '20%',
          margin: '0',
          borderRight: '1px solid #ebedf1',
          backgroundColor: 'rgb(249, 251, 254)',
          padding: '0',
          paddingBottom: 10,
          height: 'calc(100vh - 139px)',
        }}
        data={kanBoardData}
        className="canBanView"
        cardDraggable={manageDealsPermissions}
      />
    )
  }

  return (
    <>
      <div className="pageHeader" style={{background: '#fff'}}>
        <div className="pageTitle">
          <h5>
            <KooperDropdown
              selection
              options={dealPipelineList}
              value={sort.pipelineId}
              placeholder="Select pipeline"
              onChange={(e, {value}) => {
                setSort({...sort, pipelineId: value})
                dispatch(getStageDeals(value))
              }}
            />
          </h5>
        </div>
        {isDefaultView && <div className="pageCenter">{renderOptionList()}</div>}
        {!isDefaultView && (
          <div className="viewData">
            <KooperList horizontal>
              <KooperListItem>
                <label>Total Value</label>
                <p>{abbreviateNumber(getAllStages.totalValue)}</p>
              </KooperListItem>
              <KooperListItem>
                <label>Open</label>
                <p>{getAllStages.open}</p>
              </KooperListItem>
              <KooperListItem>
                <label>Won</label>
                <p>{getAllStages.won}</p>
              </KooperListItem>
              <KooperListItem>
                <label>Lost</label>
                <p>{getAllStages.lost}</p>
              </KooperListItem>
              <KooperListItem>
                <label>Abandoned</label>
                <p>{getAllStages.abandoned}</p>
              </KooperListItem>
            </KooperList>
          </div>
        )}

        <div className="pageAction">
          {isDefaultView && (
            <>
              <SearchComponent onSuccess={value => setSort({text: value})} />

              <KooperTooltip
                size="mini"
                position="top center"
                content="Settings"
                trigger={
                  <KooperDropdown floating button className="icon" icon={<FiSettings />}>
                    <KooperDropdownMenu className="left">
                      <KooperDropdownItem onClick={() => setIsCustomizeColumn(!isCustomizeColumn)}>
                        Customize Columns
                      </KooperDropdownItem>
                      <LockPermissionTooltip
                        isFeatureAccessDenied={!importAndExportAccess}
                        isRoleAccessDenied={!manageImportExportDealsPermissions}
                      >
                        <KooperDropdownItem
                          text="Import from Excel or CSV file"
                          onClick={() =>
                            importAndExportAccess &&
                            manageImportExportDealsPermissions &&
                            history.push('/settings/import')
                          }
                        />
                      </LockPermissionTooltip>
                    </KooperDropdownMenu>
                  </KooperDropdown>
                }
              />
            </>
          )}
          {!isDefaultView ? (
            <KooperTooltip
              content="List View"
              position="top center"
              size="mini"
              trigger={
                <KooperButton
                  id="second-step-joyride"
                  icon
                  active={isDefaultView}
                  onClick={() => {
                    setSort({...sort, page: 1})
                    setDefaultView(true)
                    const oldSales = JSON.parse(window.localStorage.getItem('sales') || '{}')
                    window.localStorage.setItem(
                      'sales',
                      JSON.stringify({
                        ...oldSales,
                        dealView: true,
                      })
                    )
                    dispatch({
                      type: 'CLEAR_ALL_DEALS_DATA',
                    })
                  }}
                >
                  <FiSliders />
                </KooperButton>
              }
            />
          ) : (
            <KooperTooltip
              content="Board View"
              position="top center"
              size="mini"
              trigger={
                <KooperButton
                  icon
                  id="boardViewTooltip"
                  active={!isDefaultView}
                  onClick={() => {
                    setSort({
                      ...sort,
                      page: null,
                    })
                    const oldSales = JSON.parse(window.localStorage.getItem('sales') || '{}')
                    window.localStorage.setItem(
                      'sales',
                      JSON.stringify({
                        ...oldSales,
                        dealView: true,
                      })
                    )
                    setDefaultView(false)
                    dispatch({
                      type: 'CLEAR_ALL_DEALS_DATA',
                    })
                  }}
                >
                  <FiList />
                </KooperButton>
              }
            />
          )}
          <LockPermissionTooltip isRoleAccessDenied={!manageDealsPermissions}>
            <KooperButton
              primary
              // disabled={_.size(getSingleStages) !== 0 ? false : true}
              content="Create Deal"
              className={!manageDealsPermissions ? 'disabled-button' : ''}
              onClick={() => {
                if (manageDealsPermissions) {
                  setAddNewDeal(!addNewDeal)
                  dispatch(getContactList())
                }
              }}
            />
          </LockPermissionTooltip>
        </div>
      </div>
      {renderDefaultView()}
      {wonModal && <WonModal isModalOpen={wonModal} setIsModalOpen={setWonModal} type={winDealType} Id={wonDealId} />}
      {lostModal && <LostModal isModalOpen={lostModal} setIsModalOpen={setLostModal} Id={lossDealId} />}
      {abandonedModal && (
        <AbandonedModal isModalOpen={abandonedModal} setIsModalOpen={setAbandonedModal} Id={abandonDealId} />
      )}
      {isDeleteModalOpen && (
        <DeleteModal
          isModalOpen={isDeleteModalOpen}
          setIsModalOpen={setIsDeleteModalOpen}
          modalText="Are you sure you want to delete ?"
          deleteAction={deleteDeal}
          idTobeDeleted={checked.length > 0 ? [...checked].join(',') : dealId}
          type={DELETE_DEAL}
        />
      )}
      {addNewDeal && (
        <CreateDealModal setIsModalOpen={setAddNewDeal} isModalOpen={addNewDeal} pipelineId={sort.pipelineId} />
      )}
      {isCustomizeColumn && (
        <CustomizeTableColumn
          isModalOpen={isCustomizeColumn}
          setIsModalOpen={setIsCustomizeColumn}
          identifier="deals"
        />
      )}
      {isEditModal && <EditDealModal isModalOpen={isEditModal} setIsModalOpen={setIsEditModal} checked={checked} />}
      {isNoteOpen && <AddNote isNoteOpen={isNoteOpen} dealId={dealId} setIsNoteOpen={setIsNoteOpen} />}
    </>
  )
}
export default Deals
