import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useParams} from 'react-router'

import {KooperButton, KooperForm, KooperIcon, KooperTab, KooperTabPane, KooperTooltip} from 'kooper-library'
import {FiPenTool} from 'react-icons/fi'
import {FaBrush} from 'react-icons/fa'
import {useForm} from 'react-hook-form'
import {joiResolver} from '@hookform/resolvers'
import {updateFormsSchema} from 'validation/Projects/projects/forms.schema'
import {editProjectForm, getAllProjectForms, getSingleProjectForm, publishProjectForm} from 'actions/projects'
import {EDIT_PROJECT_FORM, GET_SINGLE_PROJECT_FORM, PROJECT_FORM_PUBLISHED} from 'actions/types'
import Questions from './QuestionsTab/Questions'
import Design from './DesignTab/Design'
import Settings from './SettingsTab/Settings'
import FormPreview from './PreviewForm/FormPreview'

function UpdateFormPage() {
  const dispatch = useDispatch()
  const {projectId, id} = useParams()

  const [selectedQuestion, setSelectedQuestion] = useState(0)
  const [questionType, setQuestionType] = useState('')

  const {getSingleFormData = {}} = useSelector(state => state.projects)
  const {successLabels = []} = useSelector(state => state.apiReducer)

  const {title, description, questions, status} = getSingleFormData

  const {
    handleSubmit,
    errors,
    control,
    watch,
    reset,
    setValue,
    formState: {isDirty},
  } = useForm({
    mode: 'onTouched',
    shouldFocusError: true,
    shouldUnregister: false,
    reValidateMode: 'onChange',
    resolver: joiResolver(updateFormsSchema),
    defaultValues: {
      settings: {
        title: '',
        description: '',
        redirectUrl: '',
        confirmMessage: 'Thank you, your form has been submitted',
        showQuestionNumber: false,
        indicateMandatory: true,
        showBranding: true,
        showProgressBar: true,
      },
      design: {
        submitButtonText: 'Submit Form',
        submitButtonColor: 'var(--primary-color)',
        backgroundColor: 'var(--primary-color)',
        submitButtonMargin: '-30px',
      },
      questions: [],
    },
  })

  const watchFormData = watch()

  useEffect(() => {
    dispatch(getSingleProjectForm({formId: id, projectId}))
  }, [dispatch, id, projectId])

  useEffect(() => {
    if (successLabels.includes(EDIT_PROJECT_FORM) || successLabels.includes(PROJECT_FORM_PUBLISHED)) {
      window.history.back()
      dispatch(getAllProjectForms({projectId}))
    }
  }, [successLabels, dispatch, id, projectId])

  useEffect(() => {
    if (successLabels.includes(GET_SINGLE_PROJECT_FORM)) {
      if (questions) {
        reset({
          questions: getSingleFormData?.questions,
          settings: getSingleFormData?.settings,
          design: getSingleFormData?.design,
        })
      }
    }
  }, [successLabels, getSingleFormData])

  const submitForm = data => {
    dispatch(editProjectForm({data, formId: id, projectId}))
  }

  return (
    <div className="kooper-full-page">
      <div className="page-header" style={{boxShadow: 'none'}}>
        <div className="top">
          <div className="title">
            <KooperButton basic inverted onClick={() => window.history.back()}>
              Exit
            </KooperButton>
          </div>
          <div className="center">
            <h5 style={{color: '#fff'}}>{title}</h5>
          </div>
          <div className="action">
            {status !== 'published' && (
              <KooperButton basic inverted onClick={handleSubmit(submitForm)}>
                Save
              </KooperButton>
            )}
            <KooperButton
              primary
              disabled={isDirty}
              onClick={() => {
                const data = {
                  status: 'published',
                }
                dispatch(publishProjectForm({projectId, formId: id, data}))
              }}
            >
              Publish
            </KooperButton>
          </div>
        </div>
      </div>
      <div>
        <KooperForm className="errorLabel">
          <div className="fromsContent">
            <div style={{width: '40%'}}>
              <KooperTab
                renderActiveOnly={false}
                className="design-vert-tab"
                menu={{fluid: true, vertical: true, tabular: true}}
                panes={[
                  {
                    menuItem: {
                      key: 'Choose Components',
                      icon: (
                        <KooperTooltip
                          trigger={
                            <span>
                              <FiPenTool />
                            </span>
                          }
                          content="Choose Components"
                          size="mini"
                          position="right center"
                        />
                      ),
                    },
                    pane: (
                      <KooperTabPane>
                        <Questions
                          selectedQuestion={selectedQuestion}
                          setSelectedQuestion={setSelectedQuestion}
                          control={control}
                          setValue={setValue}
                          setQuestionType={setQuestionType}
                        />
                      </KooperTabPane>
                    ),
                  },
                  {
                    menuItem: {
                      key: 'Style Form',
                      icon: (
                        <KooperTooltip
                          trigger={
                            <span>
                              <FaBrush />
                            </span>
                          }
                          content="Style Form"
                          size="mini"
                          position="right center"
                        />
                      ),
                    },
                    pane: (
                      <KooperTabPane>
                        <Design control={control} setValue={setValue} />
                      </KooperTabPane>
                    ),
                  },
                  {
                    menuItem: {
                      key: 'Settings',
                      icon: (
                        <KooperTooltip
                          trigger={
                            <span>
                              <KooperIcon name="settings" />
                            </span>
                          }
                          content="Settings"
                          size="mini"
                          position="right center"
                        />
                      ),
                    },
                    pane: (
                      <KooperTabPane>
                        <Settings
                          control={control}
                          setValue={setValue}
                          title={title}
                          description={description}
                          errors={errors}
                        />
                      </KooperTabPane>
                    ),
                  },
                ]}
              />
            </div>
            <div className="design-preview">
              <FormPreview watchFormData={watchFormData} />
            </div>
          </div>
        </KooperForm>
      </div>
    </div>
  )
}

export default UpdateFormPage
