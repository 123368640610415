/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useHistory} from 'react-router'
import _ from 'lodash'
import {
  KooperButton,
  KooperForm,
  KooperFormDropdown,
  KooperFormField,
  KooperFormGroup,
  KooperHeader,
  KooperTable,
  KooperTableBody,
  KooperTableCell,
  KooperTableHeader,
  KooperTableHeaderCell,
  KooperTableRow,
  KooperTooltip,
} from 'kooper-library'

import {
  ADD_ACCOUNT_CURRENCY,
  DELETE_ACCOUNT_CURRENCY,
  GET_ACCOUNT_CURRENCY,
  GET_ACCOUNT_CURRENCY_NOTE_ADDED,
} from 'actions/types'
import {
  addAccountCurrency,
  deleteAccountCurrency,
  getAccountCurrency,
  getAccountCurrencyNoteAdded,
  getAllCurrency,
} from 'actions/setting_currency'
import SettingLayout from 'layouts/settingLayout'
import DeleteModal from 'components/common/DeleteModal'
import useUserPermissions from 'hooks/pure/useUserPermissions'
import {getPermissionTooltipContent} from 'utils/helper'
import LockPermissionTooltip from 'components/common/LockPermissionTooltip'
import useLearnMoreUrl from 'hooks/pure/useLearnMoreUrl'
import SvgIcon from 'components/common/SvgIcon'
import useApiResponse from 'hooks/impure/useApiResponse'
import CurrencyBaseModal from './CurrencyBaseModal'

const Currencies = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  const [isDelete, setIsDelete] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [baseCurrency, setBaseCurrency] = useState({})
  const [currencyList, setCurrencyList] = useState([])
  const [addSelectedCurrency, setAddSelectedCurrency] = useState([])
  const [currencyId, setCurrencyId] = useState(null)

  const {successLabels = []} = useSelector(state => state.apiReducer)
  const {settingsLearnMoreUrl} = useLearnMoreUrl()
  const currenciesLearnMore = settingsLearnMoreUrl?.sales?.currencies?.learnMore

  const {salesPermissions} = useUserPermissions()
  const manageCurrenciesPermissions = salesPermissions?.manage_currencies

  useEffect(() => {
    dispatch(getAccountCurrencyNoteAdded())
    dispatch(getAllCurrency())
  }, [])

  const {data: accountCurrencyList = []} = useApiResponse({
    label: GET_ACCOUNT_CURRENCY,
    enabled: true,
    storePath: 'settingCurrency.accountCurrencyList',
    action: getAccountCurrency,
    onSuccess: accountCurrencyList => {
      const temp = accountCurrencyList.find(l => l.isBase && l)
      setBaseCurrency(temp)
    },
  })

  useApiResponse({
    label: GET_ACCOUNT_CURRENCY_NOTE_ADDED,
    enabled: true,
    storePath: 'settingCurrency.noteAddedCurrencyList',
    action: getAccountCurrencyNoteAdded,
    onSuccess: noteAddedCurrencyList => {
      const currlist = noteAddedCurrencyList.map(list => ({
        key: list.id,
        text: `${list.currency} ${list.name}`,
        value: list.id,
      }))
      setCurrencyList(currlist)
    },
  })

  useEffect(() => {
    if (successLabels.includes(ADD_ACCOUNT_CURRENCY) || successLabels.includes(DELETE_ACCOUNT_CURRENCY)) {
      dispatch(getAccountCurrency())
      dispatch(getAccountCurrencyNoteAdded())
      setAddSelectedCurrency({})
      setCurrencyList(null)
    }
  }, [successLabels])

  return (
    <SettingLayout
      icon={<SvgIcon path="settings/currencies" />}
      header="Currency Settings"
      subHeader="Customize currency options for accurate financial tracking across global transactions"
      learnMoreUrl={currenciesLearnMore}
      headerDivider
      // actionButton={{
      //   cancel: {
      //     content: "Reset",
      //   },
      //   success: {
      //     content: "Save",
      //   },
      // }}
    >
      {/* <KooperCard
        fluid
        className="Card-detail"
        onClick={() => props.history.push(`currencies/detail`)}
      >
        <KooperCardContent>
          <KooperCardHeader className="m-0">
            United States Dollar
            <div className="action">
              <KooperTooltip
                size="mini"
                position="top center"
                content="ISO Code"
                trigger={<h5>USD</h5>}
              />
              <KooperTooltip
                size="mini"
                position="top center"
                content="Symbol"
                trigger={<h5>$</h5>}
              />
            </div>
          </KooperCardHeader>
        </KooperCardContent>
      </KooperCard> */}

      <KooperForm>
        <KooperFormField>
          <div className="info-header">
            <label>Company Base Currency</label>
            <KooperTooltip
              content="Select currency in which your company operates"
              size="mini"
              position="top center"
              trigger={<SvgIcon path="common/question" />}
            />
          </div>
        </KooperFormField>

        <KooperTable basic selectable>
          <KooperTableHeader>
            <KooperTableRow>
              <KooperTableHeaderCell>ISO Code</KooperTableHeaderCell>
              <KooperTableHeaderCell>Name</KooperTableHeaderCell>
              <KooperTableHeaderCell>Symbol</KooperTableHeaderCell>
              <KooperTableHeaderCell>Action</KooperTableHeaderCell>
            </KooperTableRow>
          </KooperTableHeader>
          <KooperTableBody>
            {baseCurrency && (
              <KooperTableRow
                key={baseCurrency.id}
                onClick={() =>
                  history.push({
                    pathname: `currencies/${baseCurrency.id}`,
                    state: baseCurrency,
                  })
                }
                className="tableLink"
              >
                <KooperTableCell className="prevCell">
                  <KooperHeader as="h5">{_.get(baseCurrency, 'base.currency')}</KooperHeader>
                </KooperTableCell>
                <KooperTableCell>{_.get(baseCurrency, 'base.name')}</KooperTableCell>
                <KooperTableCell>{baseCurrency.symbol}</KooperTableCell>
                <KooperTableCell>
                  <KooperTooltip
                    content={
                      manageCurrenciesPermissions
                        ? 'Edit'
                        : getPermissionTooltipContent({roleAccess: manageCurrenciesPermissions})
                    }
                    position="top center"
                    size="mini"
                    trigger={
                      <KooperButton
                        icon
                        onClick={e => {
                          e.stopPropagation()
                          if (manageCurrenciesPermissions) {
                            setIsModalOpen(!isModalOpen)
                          }
                        }}
                      >
                        <SvgIcon path="common/edit2" />
                      </KooperButton>
                    }
                  />
                </KooperTableCell>
              </KooperTableRow>
            )}
          </KooperTableBody>
        </KooperTable>

        <KooperFormField>
          <div className="info-header" style={{marginTop: 12}}>
            <label>Additional Currencies</label>
            <KooperTooltip
              content="Add other currencies for any global transactions"
              size="mini"
              position="top center"
              trigger={<SvgIcon path="common/question" />}
            />
          </div>
        </KooperFormField>

        <KooperTable basic selectable>
          <KooperTableHeader>
            <KooperTableRow>
              <KooperTableHeaderCell>ISO Code</KooperTableHeaderCell>
              <KooperTableHeaderCell>Name</KooperTableHeaderCell>
              <KooperTableHeaderCell>Symbol</KooperTableHeaderCell>
              <KooperTableHeaderCell>
                Conversion to &nbsp;
                {accountCurrencyList.find(l => l.isBase && l)?.base.currency}
              </KooperTableHeaderCell>
              <KooperTableHeaderCell>Action</KooperTableHeaderCell>
            </KooperTableRow>
          </KooperTableHeader>
          <KooperTableBody>
            {accountCurrencyList && accountCurrencyList.length === 1 ? (
              <tr>
                <td colSpan="6" className="emptyValue">
                  No Additional Currencies Yet
                </td>
              </tr>
            ) : (
              <>
                {accountCurrencyList
                  .filter(currency => !currency.isBase)
                  .map((data, index) => (
                    <KooperTableRow
                      key={data.id}
                      onClick={() =>
                        history.push({
                          pathname: `currencies/${baseCurrency.id}`,
                          state: data,
                        })
                      }
                      className="tableLink"
                    >
                      <KooperTableCell>
                        <KooperHeader as="h5">{_.get(data, 'base.currency')}</KooperHeader>
                      </KooperTableCell>
                      <KooperTableCell>{_.get(data, 'base.name')}</KooperTableCell>
                      <KooperTableCell>{data.symbol}</KooperTableCell>
                      <KooperTableCell>{data.convToBase}</KooperTableCell>
                      <KooperTableCell>
                        <KooperTooltip
                          content={
                            manageCurrenciesPermissions
                              ? 'Delete'
                              : getPermissionTooltipContent({roleAccess: manageCurrenciesPermissions})
                          }
                          size="mini"
                          position="top center"
                          trigger={
                            <KooperButton
                              icon
                              onClick={e => {
                                e.stopPropagation()
                                if (manageCurrenciesPermissions) {
                                  setIsDelete(true)
                                  setCurrencyId(data.id)
                                }
                              }}
                            >
                              <SvgIcon path="common/delete" />
                            </KooperButton>
                          }
                        />
                      </KooperTableCell>
                    </KooperTableRow>
                  ))}
              </>
            )}
          </KooperTableBody>
        </KooperTable>

        <KooperFormGroup className="mt-3">
          <KooperFormDropdown
            width="6"
            fluid
            search
            selection
            placeholder="Select Currency"
            options={currencyList}
            value={addSelectedCurrency.currencyId}
            onChange={(e, {value}) => setAddSelectedCurrency({currencyId: value})}
          />
          <LockPermissionTooltip isRoleAccessDenied={!manageCurrenciesPermissions}>
            <KooperButton
              primary
              disabled={_.size(addSelectedCurrency) === 0}
              type="button"
              onClick={() => manageCurrenciesPermissions && dispatch(addAccountCurrency(addSelectedCurrency))}
              className="ml-3"
            >
              Create
            </KooperButton>
          </LockPermissionTooltip>
        </KooperFormGroup>
      </KooperForm>
      {isDelete && (
        <DeleteModal
          deleteAction={deleteAccountCurrency}
          idTobeDeleted={currencyId}
          isModalOpen={isDelete}
          setIsModalOpen={setIsDelete}
          modalText="Are you sure you want to delete currency?"
          type={DELETE_ACCOUNT_CURRENCY}
        />
      )}
      {isModalOpen && (
        <CurrencyBaseModal
          baseCurrency={baseCurrency}
          isModalOpen={isModalOpen}
          setIsModalOpen={() => setIsModalOpen(!isModalOpen)}
        />
      )}
    </SettingLayout>
  )
}
export default Currencies
