import SvgIcon from 'components/common/SvgIcon'
import React, {useState} from 'react'
import {FiChevronDown, FiChevronRight, FiPenTool} from 'react-icons/fi'
import {useHistory} from 'react-router'
import {KooperAccordion, KooperAccordionContent, KooperAccordionTitle, KooperMenu, KooperMenuItem} from 'kooper-library'

const AppriasalSidemenu = () => {
  const [active, setActive] = useState({
    first: true,
    second: true,
    allExpense: true,
    myExpense: false,
    approval: false,
  })

  const history = useHistory()

  return (
    <>
      <KooperMenu vertical secondary className="kooper-menu">
        <KooperAccordion>
          <KooperAccordionTitle onClick={() => setActive({...active, first: !active.first})}>
            <div className="d-flex-ac">
              {active.first ? <SvgIcon path="common/down" /> : <FiChevronRight />}
              Appraisals
            </div>
          </KooperAccordionTitle>
          <KooperAccordionContent active={active.first}>
            <KooperMenuItem
              active={window.location.pathname === '/appraisal/templates'}
              onClick={() => history.push('/appraisal/templates')}
            >
              <FiPenTool />
              All Appraisal Templates
            </KooperMenuItem>
            {/* <KooperMenuItem onClick={() => history.push('/appraisal')}>
              <FiFileText /> All Appraisals
            </KooperMenuItem> */}
            {/* <KooperMenuItem onClick={() => history.push('/appraisal/manage')}>
              <FiLayers /> Manage Appraisals
            </KooperMenuItem> */}
          </KooperAccordionContent>
        </KooperAccordion>

        {/* <KooperAccordion>
          <KooperAccordionTitle onClick={() => setActive({...active, second: !active.first})}>
            <div className="d-flex-ac">
              {active.second ? <SvgIcon path="common/down" /> : <FiChevronRight />}
              Settings
            </div>
          </KooperAccordionTitle>
          <KooperAccordionContent active={active.second}>
            <KooperMenuItem
              active={window.location.pathname.includes('/expenses/categories')}
              onClick={() => history.push('/expenses/categories')}
            >
              <FiSettings /> General Settings
            </KooperMenuItem>
          </KooperAccordionContent>
        </KooperAccordion> */}
      </KooperMenu>
    </>
  )
}

export default AppriasalSidemenu
