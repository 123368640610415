import React, {useEffect, useMemo, useState} from 'react'
import {useSelector} from 'react-redux'
import {useHistory} from 'react-router'
import {
  KooperButton,
  KooperHeader,
  KooperTable,
  KooperTableBody,
  KooperTableCell,
  KooperTableHeader,
  KooperTableHeaderCell,
  KooperTablePlaceholder,
  KooperTableRow,
  KooperTooltip,
} from 'kooper-library'
import {DELETE_TEMPLATE, GET_TEMPLATE} from 'actions/types'
import {deleteEmailTemplate, getEmailTemplate} from 'actions/settings'
import {get, getFormattedDate, getPermissionTooltipContent, startCase} from 'utils/helper'
import img from 'assets/create.svg'
import SettingLayout from 'layouts/settingLayout'
import DeleteModal from 'components/common/DeleteModal'
import CreateFirst from 'components/common/CreateFirst'
import useApiLoader from 'hooks/pure/useApiLoader'
import PageLoader from 'components/common/PageLoader'
import useUserPermissions from 'hooks/pure/useUserPermissions'
import useLearnMoreUrl from 'hooks/pure/useLearnMoreUrl'
import SvgIcon from 'components/common/SvgIcon'
import useApiResponse from 'hooks/impure/useApiResponse'
import EmailTemplateForm from './EmailTemplateForm'

const EmailTemplate = () => {
  const history = useHistory()

  const [idToEdit, setIdToEdit] = useState(0)
  const [deleteModal, setDeleteModal] = useState(false)
  const [createCanResponse, setCreateCanResponse] = useState()

  const {type, isLoadingData, successLabels = []} = useSelector(state => state.apiReducer)
  const {settingsLearnMoreUrl} = useLearnMoreUrl()
  const cannedResponsesLearnMore = settingsLearnMoreUrl?.productivity?.cannedResponses?.learnMore

  const {isLoading} = useApiLoader({label: GET_TEMPLATE})
  const {workflowAndAutomationAccess, productivityPermissions} = useUserPermissions()
  const cannedResponsesAccess = workflowAndAutomationAccess?.cannedResponses
  const cannedResponsesLimitAccess = workflowAndAutomationAccess?.cannedResponsesLimit
  const manageCannedReponsesPermissions = productivityPermissions?.manage_canned_reponses

  const {data: responseList = [], callAction: callGetEmailTemplateAction} = useApiResponse({
    action: getEmailTemplate,
    enabled: cannedResponsesAccess,
    storePath: 'settings.getResponseList',
  })

  const isFeatureLocked = useMemo(() => {
    return !cannedResponsesAccess || (cannedResponsesLimitAccess && responseList.length >= +cannedResponsesLimitAccess)
  }, [cannedResponsesAccess, cannedResponsesLimitAccess, responseList.length])

  useEffect(() => {
    if (successLabels.includes(DELETE_TEMPLATE)) {
      callGetEmailTemplateAction()
    }
  }, [successLabels, callGetEmailTemplateAction])

  const RenderCannedResponseList = () => {
    return responseList.map((response, index) => (
      <KooperTableRow
        onClick={() =>
          history.push({
            pathname: `/settings/email-templates/${response.id}`,
            // state: {
            //   ...get(
            //     ["routeState"],
            //     JSON.parse(window.localStorage.getItem("sales"))
            //   ),
            //   emailTmptName: get(["subject"], response),
            // },
          })
        }
        key={response.id}
        className="tableLink"
      >
        <KooperTableCell className="pl-4">{index + 1}</KooperTableCell>
        <KooperTableCell>
          <KooperHeader as="h5">{startCase(get(['title'], response, '-'))}</KooperHeader>
        </KooperTableCell>
        <KooperTableCell>
          {get(['createdBy'], response, '-')}
          <br />
          {getFormattedDate(response.createdAt)}
        </KooperTableCell>
        <KooperTableCell>{get(['updatedBy'], response, '-')}</KooperTableCell>
        <KooperTableCell>{get(['sendCount'], response, '-')}</KooperTableCell>
        <KooperTableCell>{get(['openCount'], response, '-')}</KooperTableCell>
        <KooperTableCell>{get(['replyCount'], response, '-')}</KooperTableCell>
        <KooperTableCell>
          <KooperTooltip
            content={
              manageCannedReponsesPermissions
                ? 'Delete'
                : getPermissionTooltipContent({roleAccess: manageCannedReponsesPermissions})
            }
            size="mini"
            position="top center"
            trigger={
              <KooperButton
                icon
                onClick={e => {
                  e.stopPropagation()
                  if (manageCannedReponsesPermissions) {
                    setIdToEdit(response.id)
                    setDeleteModal(true)
                  }
                }}
              >
                <SvgIcon path="common/delete" />
              </KooperButton>
            }
          />
        </KooperTableCell>
      </KooperTableRow>
    ))
  }

  if (isLoading && cannedResponsesAccess) {
    return <PageLoader />
  }

  if (createCanResponse) {
    return <EmailTemplateForm setCreateCanResponse={setCreateCanResponse} />
  }

  // Note :- temporary we make "email templates" to "canned response"
  if (responseList.length === 0 || !cannedResponsesAccess) {
    return (
      <div className="setEmpty-wrap">
        <CreateFirst
          src={<SvgIcon path="settings/canned-response" />}
          Feature1="Customizable Templates"
          Feature2="Template Library"
          Feature3="Rich Text Editing"
          list1="Create impactful email templates based on brand’s voice and style"
          list2="Centralized library of templates for various communications in one place"
          list3="Utilize formatting tools to adjust fonts & colors for a polished look"
          addFirst={() => cannedResponsesAccess && manageCannedReponsesPermissions && setCreateCanResponse(true)}
          tooltip={!cannedResponsesAccess || !manageCannedReponsesPermissions}
          tooltipContent={getPermissionTooltipContent({
            featureAccess: cannedResponsesAccess,
            roleAccess: manageCannedReponsesPermissions,
          })}
          // buttonText="Add Email Templates"
          buttonText="Create Email Template"
          img={img}
          // header="Email Templates"
          // subHeader="Email templates are pre-designed email formats that can be reused for various purposes"
          header="Email Templates"
          subHeader="Email templates are pre-defined email formats that can be reused to ensure consistency in communication"
        />
      </div>
    )
  }

  return (
    <SettingLayout
      icon={<SvgIcon path="settings/canned-response" />}
      // header="Email Templates"
      // subHeader="Email templates are pre-written set of reply templates frequently used for daily customers  interactions"
      header="Email Templates"
      subHeader="Create and insert pre-written responses to common inquiries and save your valuable time"
      learnMoreUrl={cannedResponsesLearnMore}
      headerButton={{
        onClick: () => !isFeatureLocked && manageCannedReponsesPermissions && setCreateCanResponse(true),
        content: 'Create',
      }}
      lockFeature={isFeatureLocked}
      lockRole={!manageCannedReponsesPermissions}
      table
      headerDivider
    >
      <KooperTable basic selectable>
        <KooperTableHeader>
          <KooperTableRow>
            <KooperTableHeaderCell className="pl-4">#</KooperTableHeaderCell>
            <KooperTableHeaderCell>Title</KooperTableHeaderCell>
            <KooperTableHeaderCell>Created By</KooperTableHeaderCell>
            <KooperTableHeaderCell>Updated By</KooperTableHeaderCell>
            <KooperTableHeaderCell>Send</KooperTableHeaderCell>
            <KooperTableHeaderCell>Open</KooperTableHeaderCell>
            <KooperTableHeaderCell>Reply</KooperTableHeaderCell>
            <KooperTableHeaderCell>Action</KooperTableHeaderCell>
          </KooperTableRow>
        </KooperTableHeader>
        <KooperTableBody>
          {isLoadingData && (type.includes(GET_TEMPLATE) || type.includes('GET_ALL_MAIL_INBOX')) ? (
            <KooperTablePlaceholder columns={5} />
          ) : (
            responseList && <RenderCannedResponseList />
          )}
        </KooperTableBody>
      </KooperTable>
      {deleteModal && (
        <DeleteModal
          idTobeDeleted={idToEdit}
          deleteAction={deleteEmailTemplate}
          isModalOpen={deleteModal}
          setIsModalOpen={setDeleteModal}
          modalText="Are you sure you want to delete ?"
          type={DELETE_TEMPLATE}
        />
      )}
    </SettingLayout>
  )
}
export default EmailTemplate
