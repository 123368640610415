/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {FiBookmark} from 'react-icons/fi'
import {MdOutlineMoreVert} from 'react-icons/md'
import _ from 'lodash'
import {KooperDropdown, KooperDropdownDivider, KooperDropdownItem, KooperDropdownMenu} from 'kooper-library'

import {
  COPY_PROJECTS_NOTEBOOK_NOTE,
  CREATE_PROJECTS_NOTEBOOK_NOTE,
  CREATE_PROJECTS_NOTEBOOK_NOTE_COMMENT,
  DELETE_PROJECTS_NOTEBOOK_NOTE,
  MOVE_PROJECTS_NOTEBOOK_NOTE,
  UPDATE_PROJECTS_NOTEBOOK_NOTE,
  UPDATE_PROJECTS_NOTEBOOK_NOTE_DESCRIPTION,
  UPDATE_PROJECTS_NOTEBOOK_NOTE_SUBSCRIBERS,
} from 'actions/types'
import {deleteProjectsNotebookNote, getProjectsNotebookNotes} from 'actions/projects'
import DeleteModal from 'components/common/DeleteModal'
import {extractTextContentFromHTML, GetFormattedDate} from 'utils/helper'
import LockPermissionTooltip from 'components/common/LockPermissionTooltip'
import SvgIcon from 'components/common/SvgIcon'
import NoteModal from './NoteModal'
import ManagePeopleModal from './ManagePeopleModal'
import CopyNoteModal from './CopyNoteModal'
import MoveNoteModal from './MoveNoteModal'
import ViewNote from './ViewNote'

const NotesDetails = props => {
  const {projectId, notebookId, currentListParams, rolePermissions} = props
  const dispatch = useDispatch()

  const [isNoteModalOpen, setIsNoteModalOpen] = useState(false)
  const [noteId, setNoteId] = useState(null)
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
  const [isManagePeopleModalOpen, setIsManagePeopleModalOpen] = useState(false)
  const [noteTitle, setNoteTitle] = useState('')
  const [isCopyModalOpen, setIsCopyModalOpen] = useState(false)
  const [isMoveModalOpen, setIsMoveModalOpen] = useState(false)
  const [isViewNoteModalOpen, setIsViewNoteModalOpen] = useState(false)

  const {successLabels = []} = useSelector(state => state.apiReducer)
  const {allNotes = []} = useSelector(state => state.projects)

  useEffect(() => {
    if (
      successLabels.includes(CREATE_PROJECTS_NOTEBOOK_NOTE) ||
      successLabels.includes(UPDATE_PROJECTS_NOTEBOOK_NOTE_SUBSCRIBERS) ||
      successLabels.includes(UPDATE_PROJECTS_NOTEBOOK_NOTE) ||
      successLabels.includes(DELETE_PROJECTS_NOTEBOOK_NOTE) ||
      successLabels.includes(COPY_PROJECTS_NOTEBOOK_NOTE) ||
      successLabels.includes(MOVE_PROJECTS_NOTEBOOK_NOTE) ||
      successLabels.includes(UPDATE_PROJECTS_NOTEBOOK_NOTE_DESCRIPTION) ||
      successLabels.includes(CREATE_PROJECTS_NOTEBOOK_NOTE_COMMENT)
    ) {
      const newList = _.omitBy(currentListParams, value => value === 'all')
      dispatch(getProjectsNotebookNotes({projectId, notebookId, params: newList}))
    }
  }, [successLabels, dispatch, projectId, notebookId, currentListParams])

  const renderDropdown = note => {
    return (
      <KooperDropdown icon={<MdOutlineMoreVert />}>
        <KooperDropdownMenu>
          <KooperDropdownItem
            onClick={() => {
              setNoteId(note.id)
              setIsManagePeopleModalOpen(true)
            }}
          >
            <SvgIcon path="settings/users" /> Manage People
          </KooperDropdownItem>
          <KooperDropdownItem
            onClick={() => {
              setNoteId(note.id)
              setIsNoteModalOpen(true)
            }}
          >
            <SvgIcon path="common/edit2" /> Edit
          </KooperDropdownItem>
          <KooperDropdownItem
            onClick={() => {
              setNoteId(note.id)
              setIsDeleteModalOpen(true)
            }}
          >
            <SvgIcon path="common/delete" /> Delete
          </KooperDropdownItem>
          <KooperDropdownDivider />
          <KooperDropdownItem
            onClick={() => {
              setNoteId(note.id)
              setNoteTitle(note.title)
              setIsCopyModalOpen(true)
            }}
          >
            <SvgIcon path="common/clone" /> Create a copy
          </KooperDropdownItem>
          <KooperDropdownItem
            onClick={() => {
              setNoteId(note.id)
              setNoteTitle(note.title)
              setIsMoveModalOpen(true)
            }}
          >
            <SvgIcon path="common/move" /> Move
          </KooperDropdownItem>
          <KooperDropdownItem>
            <SvgIcon path="common/download" /> Download as PDF
          </KooperDropdownItem>
          <KooperDropdownItem>
            <FiBookmark /> Bookmark
          </KooperDropdownItem>
        </KooperDropdownMenu>
      </KooperDropdown>
    )
  }

  return (
    <>
      <div className="project-notes-body">
        <LockPermissionTooltip isRoleAccessDenied={!rolePermissions}>
          <div
            className="project-add-nnote-div"
            onClick={() => {
              if (rolePermissions) {
                setNoteId(null)
                setIsNoteModalOpen(true)
              }
            }}
          >
            <SvgIcon path="common/plus" />
          </div>
        </LockPermissionTooltip>

        {allNotes.map(note => (
          <div className="project-notes-div" key={note.id}>
            <div>
              <h5
                // style={{color: note.color}}
                className="mt-0 mb-2"
                onClick={() => {
                  setNoteId(note.id)
                  setIsViewNoteModalOpen(true)
                }}
              >
                {note.title}
              </h5>
              <p className="kooper-lead">{extractTextContentFromHTML(note.description)}</p>
            </div>
            <div>
              <p className="numofComments">Comments - {note.commentCount}</p>
              <div className="d-flex">
                <p className="noofDays">{GetFormattedDate(note.createdAt)}</p>
                {rolePermissions && <div className="project-notes-more">{renderDropdown(note)}</div>}
              </div>
            </div>
          </div>
        ))}
      </div>

      {isNoteModalOpen && (
        <NoteModal
          open={isNoteModalOpen}
          toggle={setIsNoteModalOpen}
          projectId={projectId}
          notebookId={notebookId}
          noteId={noteId}
        />
      )}

      {isManagePeopleModalOpen && (
        <ManagePeopleModal
          open={isManagePeopleModalOpen}
          toggle={setIsManagePeopleModalOpen}
          projectId={projectId}
          noteId={noteId}
        />
      )}

      {isDeleteModalOpen && (
        <DeleteModal
          isModalOpen={isDeleteModalOpen}
          setIsModalOpen={setIsDeleteModalOpen}
          onDelete={() => dispatch(deleteProjectsNotebookNote({projectId, noteId}))}
          type={DELETE_PROJECTS_NOTEBOOK_NOTE}
        />
      )}

      {isCopyModalOpen && (
        <CopyNoteModal
          open={isCopyModalOpen}
          toggle={setIsCopyModalOpen}
          projectId={projectId}
          notebookId={notebookId}
          noteId={noteId}
          noteTitle={noteTitle}
        />
      )}

      {isMoveModalOpen && (
        <MoveNoteModal
          open={isMoveModalOpen}
          toggle={setIsMoveModalOpen}
          projectId={projectId}
          notebookId={notebookId}
          noteId={noteId}
          noteTitle={noteTitle}
        />
      )}

      {isViewNoteModalOpen && (
        <ViewNote
          open={isViewNoteModalOpen}
          toggle={setIsViewNoteModalOpen}
          projectId={projectId}
          noteId={noteId}
          {...props}
        />
      )}
    </>
  )
}

export default NotesDetails
