import React from 'react'
import {useHistory, useRouteMatch} from 'react-router-dom'
import {KooperMenu, KooperMenuItem} from 'kooper-library'
import SvgIcon from 'components/common/SvgIcon'
import {isActiveNavlink} from 'utils/helper'

const DashboardsMenu = () => {
  const history = useHistory()
  const {url} = useRouteMatch()

  const menus = [
    {
      path: '/overview',
      title: 'Overview',
      icon: <SvgIcon path="analytics/reports/overview" />,
    },
    {
      path: '/marketing',
      title: 'Marketing',
      icon: <SvgIcon path="analytics/dashboards/marketing" />,
    },
    {
      path: '/sales',
      title: 'Sales',
      icon: <SvgIcon path="analytics/dashboards/sales" />,
    },
    {
      path: '/support',
      title: 'Support',
      icon: <SvgIcon path="analytics/dashboards/support" />,
    },
    {
      path: '/projects',
      title: 'Projects',
      icon: <SvgIcon path="analytics/dashboards/project" />,
    },
    {
      path: '/finance',
      title: 'Finance',
      icon: <SvgIcon path="analytics/dashboards/finance" />,
    },
    // {
    //   path: '/human-resources',
    //   title: 'HR',
    //   icon: <SvgIcon path="analytics/dashboards/hr" />,
    // },
  ]

  return (
    <KooperMenu vertical secondary className="kooper-menu scrollbar-setting">
      {menus.map(({path, title, icon}) => (
        <KooperMenuItem
          key={path}
          active={isActiveNavlink(`${url}${path}`)}
          onClick={() => history.push(`${url}${path}`)}
        >
          {icon} {title}
        </KooperMenuItem>
      ))}
    </KooperMenu>
  )
}

export default DashboardsMenu
