import React, {useState} from 'react'
import {useSelector} from 'react-redux'
import {useHistory} from 'react-router'
import {
  KooperHeader,
  KooperTable,
  KooperTableBody,
  KooperTableCell,
  KooperTableHeader,
  KooperTableHeaderCell,
  KooperTablePlaceholder,
  KooperTableRow,
} from 'kooper-library'
import {GET_SMS_CHANNEL_LIST} from 'actions/types'
import {getSMSList} from 'actions/settings_channels'
import CreateFirst from 'components/common/CreateFirst'
import SettingLayout from 'layouts/settingLayout'
import create from 'assets/create-user.svg'
import {getFormattedDate, getPermissionTooltipContent, startCase} from 'utils/helper'
import useApiLoader from 'hooks/pure/useApiLoader'
import PageLoader from 'components/common/PageLoader'
import useUserPermissions from 'hooks/pure/useUserPermissions'
import useLearnMoreUrl from 'hooks/pure/useLearnMoreUrl'
import SvgIcon from 'components/common/SvgIcon'
import useApiResponse from 'hooks/impure/useApiResponse'
import SmsDetail from './SmsDetail'

const Sms = props => {
  const history = useHistory()

  const [createSms, setCreateSms] = useState(false)

  const {isLoadingData, type = []} = useSelector(state => state.apiReducer)
  const {settingsLearnMoreUrl} = useLearnMoreUrl()
  const smsLearnMore = settingsLearnMoreUrl?.channels?.sms?.learnMore

  const {isLoading} = useApiLoader({label: GET_SMS_CHANNEL_LIST})

  const {channelsPermissions} = useUserPermissions()
  const manageSmsPermissions = channelsPermissions?.manage_sms

  const {data: getSmsChannelList = []} = useApiResponse({
    action: getSMSList,
    enabled: true,
    storePath: 'settingsChannels.getSmsChannelList',
  })

  // Main Components

  if (isLoading) {
    return <PageLoader />
  }
  if (createSms) {
    return <SmsDetail setCreateSms={setCreateSms} {...props} />
  }

  if (getSmsChannelList.length < 1) {
    return (
      <div className="setEmpty-wrap">
        <CreateFirst
          header="SMS"
          subHeader="Enable quick and direct communication with customers in form of text message"
          addFirst={() => manageSmsPermissions && setCreateSms(true)}
          buttonText="Create SMS Inbox"
          tooltip={!manageSmsPermissions}
          tooltipContent={getPermissionTooltipContent({roleAccess: manageSmsPermissions})}
          Feature1="Easy to set-up"
          Feature2="Two-Way Communication"
          Feature3="Multiple Channels"
          list1="Simple and easy way to add SMS channels"
          list2="Enable interactive communication between  customers"
          list3="Manage Multiple SMS channel at one place"
          imageSrc={create}
        />
      </div>
    )
  }

  return (
    <SettingLayout
      icon={<SvgIcon path="settings/sms" />}
      header="SMS"
      subHeader="All your created SMS channels"
      learnMoreUrl={smsLearnMore}
      headerButton={{
        onClick: () => manageSmsPermissions && setCreateSms(!createSms),
        content: 'Create SMS Inbox',
      }}
      table
      headerDivider
      lockRole={!manageSmsPermissions}
    >
      <KooperTable basic selectable>
        <KooperTableHeader>
          <KooperTableRow>
            <KooperTableHeaderCell>#</KooperTableHeaderCell>
            <KooperTableHeaderCell>Name</KooperTableHeaderCell>
            <KooperTableHeaderCell>Number</KooperTableHeaderCell>
            <KooperTableHeaderCell>Created Date</KooperTableHeaderCell>
          </KooperTableRow>
        </KooperTableHeader>
        <KooperTableBody>
          {isLoadingData && type.includes(GET_SMS_CHANNEL_LIST) ? (
            <KooperTablePlaceholder columns={4} />
          ) : (
            getSmsChannelList.map((sms, index) => (
              <KooperTableRow key={index} className="tableLink" onClick={() => history.push(`/settings/sms/${sms.id}`)}>
                <KooperTableCell>{index + 1}</KooperTableCell>
                <KooperTableCell>
                  <KooperHeader as="h5">{startCase(sms.name)}</KooperHeader>
                </KooperTableCell>
                <KooperTableCell>{sms.phoneNumber}</KooperTableCell>
                <KooperTableCell>{getFormattedDate(sms.createdAt)}</KooperTableCell>
              </KooperTableRow>
            ))
          )}
        </KooperTableBody>
      </KooperTable>
    </SettingLayout>
  )
}

export default Sms
