import React from 'react'
import {FiFileText} from 'react-icons/fi'
import {useHistory} from 'react-router'
import {KooperMenu, KooperMenuItem} from 'kooper-library'

const AnnouncementSidemenu = () => {
  const history = useHistory()

  return (
    <KooperMenu vertical secondary className="kooper-menu">
      <KooperMenuItem
        active={window.location.pathname === `/announcements`}
        onClick={() => history.push('/announcements')}
      >
        <FiFileText /> All Announcements
      </KooperMenuItem>
    </KooperMenu>
  )
}

export default AnnouncementSidemenu
