/* eslint-disable no-plusplus */
/* eslint-disable no-const-assign */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import {addComment, deleteComment, getSingleProof} from 'actions/proofs'
import React, {useEffect, useState} from 'react'
import {FiCalendar, FiChevronRight} from 'react-icons/fi'
import {useDispatch, useSelector} from 'react-redux'
import {useParams} from 'react-router'
import {get, getFormattedDate, removeDoubleQuotes, startCase, wrapText} from 'utils/helper'
import {
  KooperAccordion,
  KooperAccordionContent,
  KooperAccordionTitle,
  KooperButton,
  KooperCard,
  KooperCardContent,
  KooperCardHeader,
  KooperForm,
  KooperFormField,
  KooperFormTextArea,
  KooperGrid,
  KooperGridColumn,
  KooperHeader,
  KooperLabel,
  KooperTable,
  KooperTableBody,
  KooperTableCell,
  KooperTableRow,
  KooperTooltip,
} from 'kooper-library'
import msgBox from 'assets/msg-box.png'
import {ADD_COMMENT, ADD_NEW_VERSION, DELETE_COMMENT, UPDATE_APPROVAL_STATUS} from 'actions/types'
import {Controller, useForm} from 'react-hook-form'
import {joiResolver} from '@hookform/resolvers'
import {proofCommentSchema} from 'validation/Projects/projects/proofs.schema'
import DeleteModal from 'components/common/DeleteModal'
import SvgIcon from 'components/common/SvgIcon'
import ApproveStatusModal from './ApproveStatusModal'
import AddNewVersionModal from './AddNewVersionModal'

function ApprovePage() {
  const dispatch = useDispatch()
  const {id: editId} = useParams()

  const [approveStatus, setApproveStatus] = useState('')
  const [isApproveStatusModal, setIsApproveStatusModal] = useState(false)
  const [isVersionModalOpen, setIsVersionModalOpen] = useState(false)
  const [activeIndex, setActiveIndex] = useState(0)
  const [idToDelete, setIdToDelete] = useState(null)
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
  const [activeAccordion, setActiveAccordion] = useState(0)

  const {successLabels = []} = useSelector(state => state.apiReducer)
  const {
    getSingleProofData,
    getCommentsList,
    getApproverDetails,
    getVersionsList,
    getRemarksDetails,
    getReviewerDetails,
  } = useSelector(state => state.proofs)

  const {handleSubmit, errors, control, reset} = useForm({
    mode: 'onTouched',
    shouldFocusError: true,
    reValidateMode: 'onChange',
    resolver: joiResolver(proofCommentSchema),
    defaultValues: {
      comment: '',
    },
  })

  useEffect(() => {
    if (editId) {
      dispatch(getSingleProof(editId))
    }
  }, [editId, dispatch])

  useEffect(() => {
    if (successLabels.includes(UPDATE_APPROVAL_STATUS)) {
      setIsApproveStatusModal(false)
      dispatch(getSingleProof(editId))
      window.history.back()
    }
    if (successLabels.includes(ADD_NEW_VERSION)) {
      setIsVersionModalOpen(false)
      dispatch(getSingleProof(editId))
    }
    if (successLabels.includes(ADD_COMMENT) || successLabels.includes(DELETE_COMMENT)) {
      reset({comment: ''})
      dispatch(getSingleProof(editId))
    }
  }, [successLabels, dispatch])

  return (
    <div className="kooper-full-page">
      {/* {getSingleProofData?.approvalStatus.includes('need_change') &&
        Number(ObjKeys?.slice(-1)?.pop()) !== versionNumber + 1 && (
          <div className="needChanges">
            <AiOutlineFieldTime /> This version was replaced
          </div>
        )} */}
      {getSingleProofData?.approvalStatus.includes('approved') && (
        <div className="approveSuccess">
          <SvgIcon path="common/approve" />
          {`This proof was approved on ${getFormattedDate(getSingleProofData?.approvedAt)} by ${get(
            ['firstName'],
            getSingleProofData?.approvedBy
          )} ${get(['lastName'], getSingleProofData?.approvedBy)}`}
          <KooperTooltip
            content="Close"
            position="top center"
            size="mini"
            trigger={<SvgIcon path="common/close" className="closeIcon" onClick={() => window.history.back()} />}
          />
        </div>
      )}
      {!getSingleProofData?.approvalStatus.includes('approved') && (
        <div className="proof-page-header">
          <div className="ProofsAction">
            <>
              <KooperButton
                // style={{marginLeft: '0.75em'}}
                onClick={() => {
                  setApproveStatus('approved')
                  setIsApproveStatusModal(true)
                }}
                primary
              >
                <SvgIcon path="common/approve" /> Approve
              </KooperButton>

              <KooperButton
                basic
                onClick={() => {
                  setApproveStatus('need_change')
                  setIsApproveStatusModal(true)
                }}
              >
                <SvgIcon path="common/question" /> Need Changes
              </KooperButton>
            </>
            <KooperTooltip
              content="Close"
              position="top center"
              size="mini"
              trigger={<SvgIcon path="common/close" className="closeIcon" onClick={() => window.history.back()} />}
            />
          </div>
        </div>
      )}
      <KooperGrid className="m-0" style={{height: 'calc(100vh - 3.75rem)'}}>
        <KooperGridColumn width={10} style={{borderRight: '1px solid var(--border-color)', background: '#fff'}}>
          <div style={{display: 'flex', justifyContent: 'flex-end', marginTop: '5px'}}>
            <KooperButton
              basic
              className="ml-2"
              onClick={() => {
                setIsVersionModalOpen(true)
              }}
              disabled={getSingleProofData?.approvalStatus.includes('approved')}
            >
              <SvgIcon path="common/plus" /> Add New Version
            </KooperButton>
          </div>
          {getVersionsList?.map(({name, createdAt, createdBy, description, links, option, files}, index) => {
            return (
              <div style={{borderBottom: '1px solid rgb(226, 231, 242)'}}>
                <KooperTable>
                  <KooperTableBody>
                    <KooperTableRow
                      className="tableLink"
                      onClick={() => setActiveAccordion(activeAccordion === index ? '-1' : index)}
                    >
                      <KooperTableCell>
                        {activeAccordion === index ? (
                          <SvgIcon path="common/down" style={{marginRight: 8}} />
                        ) : (
                          <FiChevronRight style={{marginRight: 8}} />
                        )}
                      </KooperTableCell>
                      <KooperTableCell>
                        <KooperHeader as="h5">{name} &nbsp; &nbsp;</KooperHeader>
                      </KooperTableCell>
                      <KooperTableCell>{`${get(['firstName'], createdBy)} ${get(['lastName'], createdBy)}`}</KooperTableCell>
                      <KooperTableCell>{getFormattedDate(createdAt)}</KooperTableCell>
                    </KooperTableRow>
                  </KooperTableBody>
                </KooperTable>
                <div
                  style={{
                    display: activeAccordion === index ? '' : 'none',
                  }}
                >
                  <div style={{display: 'flex', marginBottom: '10px'}}>
                    <span style={{margin: '0px 50px 0px 118px'}}>Description:-</span>
                    <p className="kooper-des">{description}</p>
                  </div>
                  {option === 'link' && (
                    <div style={{display: 'flex'}}>
                      <span style={{margin: '0px 90px 0px 118px'}}>Links:- </span>
                      <p>
                        {Object.entries(links).map(([key, data], index) => {
                          return (
                            <>
                              <a
                                href={
                                  getSingleProofData?.option?.includes('file') && data.includes('https://')
                                    ? data
                                    : `https://${data}`
                                }
                                target="_blank"
                                rel="noreferrer"
                              >
                                <p title={data}>{wrapText(data, 80)}</p>
                              </a>
                            </>
                          )
                        })}
                        <br />
                      </p>
                    </div>
                  )}
                  {option === 'file' && (
                    <div style={{display: 'flex'}}>
                      <span style={{padding: '0px 90px 0px 118px'}}>Files:- </span>
                      <p>
                        {files.map(({fileName, link}) => {
                          return (
                            <a href={link} target="_blank" rel="noreferrer">
                              <p title={fileName}>{wrapText(fileName, 80)}</p>
                            </a>
                          )
                        })}
                      </p>
                      <br />
                    </div>
                  )}
                </div>
              </div>
            )
          })}
        </KooperGridColumn>

        <KooperGridColumn width={6} className="ml-0">
          <KooperCardContent className="pt-0 m-2">
            <KooperCard fluid>
              <KooperCardContent className="p-0">
                <KooperAccordion styled className="kooper-accordion">
                  <KooperAccordionTitle
                    active={activeIndex === 1}
                    index={1}
                    onClick={(e, titleProps) => {
                      const {index} = titleProps
                      const newIndex = activeIndex === index ? -1 : index
                      setActiveIndex(newIndex)
                    }}
                  >
                    {activeIndex === 1 ? <SvgIcon path="common/down" /> : <FiChevronRight />}
                    Reviewers & Approvers
                    <KooperTooltip
                      content="Due date"
                      position="top center"
                      size="mini"
                      trigger={
                        <p
                          className="kooper-lead"
                          style={{display: 'flex', fontWeight: '600', position: 'absolute', right: 20}}
                        >
                          <FiCalendar />
                          {getFormattedDate(getSingleProofData?.dueDate)}
                        </p>
                      }
                    />
                  </KooperAccordionTitle>

                  <KooperAccordionContent active={activeIndex === 1} className="pt-0">
                    <div
                      className="d-flex projects-listing-assign mt-0"
                      style={{justifyContent: 'flex-start', alignItems: 'start', paddingLeft: '20px'}}
                    >
                      <div>
                        <div className="user-position">Approvers</div>
                        <div className="user-list">
                          {getApproverDetails?.map(({firstName, lastName}) => {
                            return (
                              <KooperTooltip
                                content={`${firstName} ${lastName}`}
                                position="top center"
                                size="mini"
                                trigger={<span className="circle-user">{`${firstName[0]}${lastName[0]}`}</span>}
                              />
                            )
                          })}
                        </div>
                      </div>
                      <div>
                        <div className="user-position">Reviewers</div>
                        <div className="user-list">
                          {getReviewerDetails?.map(({firstName, lastName}) => {
                            return (
                              <KooperTooltip
                                content={`${firstName} ${lastName}`}
                                position="top center"
                                size="mini"
                                trigger={<span className="circle-user">{`${firstName[0]}${lastName[0]}`}</span>}
                              />
                            )
                          })}
                        </div>
                      </div>
                    </div>
                    <KooperLabel style={{marginLeft: '20px'}} className="kooper-label mt-3" color="red">
                      {startCase(getSingleProofData?.approvalStatus)}
                    </KooperLabel>
                  </KooperAccordionContent>
                </KooperAccordion>
              </KooperCardContent>
            </KooperCard>

            {getRemarksDetails && (
              <KooperGridColumn width={4} style={{marginBottom: '18px'}}>
                <KooperCardContent className="pt-0">
                  {getRemarksDetails?.map(({remark, remarkedAt, remarkedBy}) => {
                    return (
                      <KooperCard fluid>
                        <KooperCardContent>
                          <div className="d-flex">
                            <KooperCardHeader>
                              <div
                                className="d-flex proof-listing-assign"
                                style={{justifyContent: 'flex-start', fontSize: '30px'}}
                              >
                                <div>
                                  <KooperTooltip
                                    content={`${remarkedBy?.firstName} ${remarkedBy?.lastName}`}
                                    position="top center"
                                    size="mini"
                                    trigger={<span>{`${remarkedBy?.firstName[0]}${remarkedBy?.lastName[0]}`}</span>}
                                  />
                                </div>
                                <p className="kooper-lead">{`${remarkedBy?.firstName} ${remarkedBy?.lastName}`}</p>
                                <p className="kooper-lead ml-3 mb-1">{getFormattedDate(remarkedAt)}</p>
                              </div>
                            </KooperCardHeader>
                          </div>
                          <p className="ml-5">{remark}</p>
                        </KooperCardContent>
                      </KooperCard>
                    )
                  })}
                </KooperCardContent>
              </KooperGridColumn>
            )}

            {getCommentsList && (
              <KooperGridColumn style={{marginBottom: '160px'}} width={4}>
                <KooperCardContent className="pt-0">
                  {getCommentsList?.map(({comment, createdAt, id, firstName, lastName}) => {
                    return (
                      <KooperCard fluid>
                        <KooperCardContent>
                          <div className="d-flex">
                            <KooperCardHeader>
                              <div
                                className="d-flex proof-listing-assign"
                                style={{justifyContent: 'flex-start', fontSize: '30px'}}
                              >
                                <div>
                                  <KooperTooltip
                                    content={`${firstName} ${lastName}`}
                                    position="top center"
                                    size="mini"
                                    trigger={<span>{`${firstName[0]}${lastName[0]}`}</span>}
                                  />
                                </div>
                                <p className="kooper-lead">{`${firstName} ${lastName}`}</p>
                                <p className="kooper-lead ml-3 mb-1">{getFormattedDate(createdAt)}</p>
                              </div>
                            </KooperCardHeader>
                            <div className="d-flex">
                              <KooperTooltip
                                content="Delete"
                                position="top center"
                                size="mini"
                                trigger={
                                  <KooperButton
                                    className="mr-0"
                                    icon
                                    onClick={() => {
                                      setIdToDelete(id)
                                      setIsDeleteModalOpen(true)
                                    }}
                                  >
                                    <SvgIcon path="common/delete" />
                                  </KooperButton>
                                }
                              />
                            </div>
                          </div>
                          <div className="form-description ml-5">
                            <p>{comment}</p>
                          </div>
                        </KooperCardContent>
                      </KooperCard>
                    )
                  })}
                </KooperCardContent>
              </KooperGridColumn>
            )}

            {!getCommentsList && !getSingleProofData?.approvalStatus.includes('approved') && (
              <div className="feedbackMsg">
                <img style={{height: '100px', width: '100px'}} src={msgBox} alt="comment" />
                <h3 className="mb-0 mt-0">No feedback to read</h3>
                <p>Click on comment to leave valuable feedback</p>
              </div>
            )}

            {!getSingleProofData?.approvalStatus.includes('approved') && (
              <KooperGridColumn
                style={{position: 'absolute', bottom: '0px', right: '0', left: '0', margin: '0 18px'}}
                width={4}
              >
                <KooperCardContent className="pt-0">
                  <KooperForm className="errorLabel">
                    <KooperFormField>
                      <Controller
                        name="comment"
                        render={({onChange, value}) => (
                          <KooperFormTextArea
                            maxLength={160}
                            type="text"
                            value={value}
                            placeholder="Add a comment"
                            onChange={e => {
                              onChange(e.target.value)
                            }}
                            error={
                              errors.comment && {
                                content: removeDoubleQuotes(errors.comment.message),
                              }
                            }
                          />
                        )}
                        control={control}
                      />
                    </KooperFormField>
                    <div style={{textAlign: 'right', margin: '18px 0'}}>
                      <KooperButton basic onClick={() => reset({comment: ''})}>
                        Cancel
                      </KooperButton>
                      <KooperButton
                        className="ml-2"
                        primary
                        onClick={handleSubmit(data => dispatch(addComment(editId, data)))}
                      >
                        Send
                      </KooperButton>
                    </div>
                  </KooperForm>
                </KooperCardContent>
              </KooperGridColumn>
            )}
          </KooperCardContent>
        </KooperGridColumn>
      </KooperGrid>
      {isApproveStatusModal && (
        <ApproveStatusModal
          isApproveStatusModal={isApproveStatusModal}
          setIsApproveStatusModal={setIsApproveStatusModal}
          approveStatus={approveStatus}
          editId={editId}
        />
      )}
      {isVersionModalOpen && (
        <AddNewVersionModal
          isVersionModalOpen={isVersionModalOpen}
          setIsVersionModalOpen={setIsVersionModalOpen}
          editId={editId}
          option={getSingleProofData?.option}
        />
      )}
      {isDeleteModalOpen && (
        <DeleteModal
          idTobeDeleted={idToDelete}
          deleteAction={deleteComment}
          isModalOpen={isDeleteModalOpen}
          setIsModalOpen={setIsDeleteModalOpen}
          modalText="Are you sure you want to delete ?"
          type={DELETE_COMMENT}
        />
      )}
    </div>
  )
}

export default ApprovePage
