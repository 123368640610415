/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useHistory} from 'react-router'
import {FiFileText} from 'react-icons/fi'
import {MdEmail} from 'react-icons/md'
import _ from 'lodash'
import {
  KooperButton,
  KooperCard,
  KooperCardContent,
  KooperCheckbox,
  KooperDropdown,
  KooperDropdownItem,
  KooperDropdownMenu,
  KooperHeader,
  KooperHeaderSubheader,
  KooperLabel,
  KooperMenu,
  KooperMenuItem,
  KooperModal,
  KooperModalActions,
  KooperModalContent,
  KooperModalHeader,
  KooperTable,
  KooperTableBody,
  KooperTableCell,
  KooperTableHeader,
  KooperTableHeaderCell,
  KooperTablePlaceholder,
  KooperTableRow,
  KooperTooltip,
} from 'kooper-library'

import {
  DELETE_CAMPAIGN,
  DELETE_SMS_CAMPAIGN,
  GET_CAMPAIGN_LIST,
  UPDATE_CAMPAIGN,
  UPDATE_CAMPAIGN_STATUS,
  UPDATE_SMS_CAMPAIGN_STATUS,
} from 'actions/types'
import {
  deleteCampaign,
  deleteSmsCampaign,
  getCampaignList,
  updateCampaignStatus,
  updateSmsCampaignStatus,
} from 'actions/campaign'
import {get, GetFormattedDate, startCase} from 'utils/helper'
import create from 'assets/create-user.svg'
import DeleteModal from 'components/common/DeleteModal'
import CreateFirst from 'components/common/CreateFirst'
import useApiLoader from 'hooks/pure/useApiLoader'
import PageLoader from 'components/common/PageLoader'
import useUserPermissions from 'hooks/pure/useUserPermissions'
import LockPermissionTooltip from 'components/common/LockPermissionTooltip'
import SvgIcon from 'components/common/SvgIcon'
import {CAMPAIGN_TYPES} from 'constants/enums'

const Campaign = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  const [isStatusPopupOpen, setStatusPopupOpen] = useState(false)
  const [statusActivation, setStatusActivation] = useState(false)
  const [statusToEdited, setStatusToEdited] = useState(0)
  const [statusToEditType, setStatusToEditType] = useState('1')
  const [isSmsCampaign, setIsSmsCampaign] = useState(false)
  const [isDelete, setIsDelete] = useState(false)
  const [userIdToDelete, setUserIdToDelete] = useState(0)

  const {isLoadingData, type = [], successLabels = []} = useSelector(state => state.apiReducer)
  const {campaignList = []} = useSelector(state => state.campaign)

  const {isLoading} = useApiLoader({label: GET_CAMPAIGN_LIST})
  const {salesAndCrmAccess} = useUserPermissions()
  const campaignsAccess = salesAndCrmAccess?.campaigns

  useEffect(() => {
    if (campaignsAccess) {
      dispatch(getCampaignList())
    }
  }, [campaignsAccess, dispatch])

  useEffect(() => {
    if (
      successLabels.includes(DELETE_CAMPAIGN) ||
      successLabels.includes(UPDATE_CAMPAIGN) ||
      successLabels.includes(UPDATE_CAMPAIGN_STATUS) ||
      successLabels.includes(UPDATE_SMS_CAMPAIGN_STATUS) ||
      successLabels.includes(DELETE_SMS_CAMPAIGN)
    ) {
      dispatch(getCampaignList())
    }
  }, [dispatch, successLabels])

  const RenderDropdown = ({style}) => {
    return (
      <div className="mailActivity">
        <KooperDropdown icon="chevron down" style={style} button text="Create Campaign">
          <KooperDropdownMenu className="creCampgDd">
            <LockPermissionTooltip isFeatureAccessDenied={!campaignsAccess}>
              <KooperDropdownItem onClick={() => campaignsAccess && history.push(`/campaigns/message`)}>
                <div className="cmpIcon">
                  <SvgIcon path="settings/sms" />
                </div>
                <div className="cmpContent">
                  <h5>Message Campaign</h5>
                  <p className="kooper-lead">Send messages that drive people towards specific goals</p>
                </div>
              </KooperDropdownItem>
            </LockPermissionTooltip>

            <LockPermissionTooltip isFeatureAccessDenied={!campaignsAccess}>
              <KooperDropdownItem onClick={() => campaignsAccess && history.push(`/campaigns/email`)}>
                <div className="cmpIcon">
                  <MdEmail />
                </div>
                <div className="cmpContent">
                  <h5>Email Campaign</h5>
                  <p className="kooper-lead">Deliver high-context emails served hot, not cold</p>
                </div>
              </KooperDropdownItem>
            </LockPermissionTooltip>

            <LockPermissionTooltip isFeatureAccessDenied={!campaignsAccess}>
              <KooperDropdownItem onClick={() => campaignsAccess && history.push(`/campaigns/sms`)}>
                <div className="cmpIcon">
                  <SvgIcon path="settings/sms" />
                </div>
                <div className="cmpContent">
                  <h5>SMS Campaign</h5>
                  <p className="kooper-lead">Send SMS that drive people towards specific goals</p>
                </div>
              </KooperDropdownItem>
            </LockPermissionTooltip>
          </KooperDropdownMenu>
        </KooperDropdown>
      </div>
    )
  }

  if (isLoading && campaignsAccess) {
    return <PageLoader />
  }

  if (!campaignList.length) {
    return (
      <CreateFirst
        header="Campaigns"
        subHeader="Create professional captivating marketing campaigns to bring more leads and improve conversion rate"
        imageSrc={create}
        hideButton
        dropdown={<RenderDropdown style={{minWidth: '166px'}} />}
        Feature1="Schedule campaigns"
        list1="Schedule campaign to sent it at optimal time"
        Feature2="Campaign Templates"
        list2="Build professionally campaign template for more conversion rate"
        Feature3="Targeted Marketing"
        list3="Create targeted campaigns to reach specific audiences"
      />
    )
  }

  const renderCampaignList = () => {
    if (isLoadingData && type.includes(GET_CAMPAIGN_LIST)) {
      return <KooperTablePlaceholder columns={9} rows={7} />
    }
    if (!isLoadingData && campaignList.length === 0) {
      return (
        <KooperTableRow>
          <KooperTableCell colSpan="9" className="emptyValue">
            No Data Available
          </KooperTableCell>
        </KooperTableRow>
      )
    }
    return campaignList.map((list, index) => (
      <KooperTableRow
        className="tableLink"
        key={`${list?.id}${index}`.toString()}
        onClick={() => {
          if (list.type === CAMPAIGN_TYPES.MESSAGE) {
            return history.push(`campaigns/message/${list.id}`)
          }
          if (list.type === CAMPAIGN_TYPES.EMAIL) {
            return history.push(`campaigns/email/${list.id}`)
          }
          if (list.type === CAMPAIGN_TYPES.SMS) {
            return history.push(`campaigns/sms/${list.id}`)
          }
        }}
      >
        <KooperTableCell>{index + 1}</KooperTableCell>
        <KooperTableCell>
          <KooperHeader as="h5">{startCase(list.name)}</KooperHeader>
        </KooperTableCell>
        <KooperTableCell>
          {list.type === CAMPAIGN_TYPES.MESSAGE && (
            <KooperLabel color="blue" className="kooper-label" style={{fontSize: '11px'}}>
              MESSAGE
            </KooperLabel>
          )}
          {list.type === CAMPAIGN_TYPES.EMAIL && (
            <KooperLabel color="yellow" className="kooper-label" style={{fontSize: '11px'}}>
              Email
            </KooperLabel>
          )}
          {list.type === CAMPAIGN_TYPES.SMS && (
            <KooperLabel color="green" className="kooper-label" style={{fontSize: '11px'}}>
              SMS
            </KooperLabel>
          )}
        </KooperTableCell>
        <KooperTableCell>{get(['sent'], list, 0)}</KooperTableCell>
        <KooperTableCell>{get(['read'], list, 0)}</KooperTableCell>
        <KooperTableCell>{get(['reply'], list, 0)}</KooperTableCell>
        <KooperTableCell>
          <KooperHeader className="camplistHeader" as="h5">
            {list.user}
            <KooperHeaderSubheader>
              {get(['createdAt'], list) ? GetFormattedDate(get(['createdAt'], list)) : '-'}
            </KooperHeaderSubheader>
          </KooperHeader>
        </KooperTableCell>
        <KooperTableCell>
          <KooperCheckbox
            toggle
            checked={get(['status'], list)}
            onChange={(e, value) => {
              e.preventDefault()
              e.stopPropagation()
              setStatusPopupOpen(true)
              setStatusToEditType(list.type)
              setStatusToEdited(list.id)
              setStatusActivation(value.checked)
            }}
          />
        </KooperTableCell>
        <KooperTableCell>
          <KooperTooltip
            content="Delete"
            position="top center"
            size="mini"
            trigger={
              <KooperButton
                basic
                icon
                className="tableLink-delete-icon"
                onClick={e => {
                  setIsDelete(true)
                  if (list.type === CAMPAIGN_TYPES.SMS) {
                    setIsSmsCampaign(true)
                  } else {
                    setIsSmsCampaign(false)
                  }
                  setUserIdToDelete(list.id)
                  e.stopPropagation()
                }}
              >
                <SvgIcon path="common/delete" />
              </KooperButton>
            }
          />
        </KooperTableCell>
      </KooperTableRow>
    ))
  }

  return (
    <>
      <KooperMenu vertical secondary className="kooper-menu">
        <KooperMenuItem
          className="liveView"
          onClick={() => history.push('/campaigns')}
          active={window.location.pathname === '/campaigns'}
        >
          <SvgIcon path="settings/users" /> All Campaign
        </KooperMenuItem>
      </KooperMenu>

      <div className="kooper-side-wrap">
        <div className="page-header">
          <div className="page-title">
            <div className="page-titleIcon">
              <FiFileText />
              <h5>Campaigns ({_.size(campaignList) !== 0 && _.size(campaignList)})</h5>
            </div>
          </div>
          <div className="page-action">
            <RenderDropdown />
          </div>
        </div>

        <KooperCard fluid className="mt-0">
          <KooperCardContent className="p-0">
            <KooperTable basic selectable className="mt-0">
              <KooperTableHeader>
                <KooperTableRow>
                  <KooperTableHeaderCell>#</KooperTableHeaderCell>
                  <KooperTableHeaderCell>Name</KooperTableHeaderCell>
                  <KooperTableHeaderCell>Type</KooperTableHeaderCell>
                  <KooperTableHeaderCell>Sent</KooperTableHeaderCell>
                  <KooperTableHeaderCell>Seen</KooperTableHeaderCell>
                  <KooperTableHeaderCell>Reply</KooperTableHeaderCell>
                  <KooperTableHeaderCell>Created by</KooperTableHeaderCell>
                  <KooperTableHeaderCell>Active/In-Active</KooperTableHeaderCell>
                  <KooperTableHeaderCell>Action</KooperTableHeaderCell>
                </KooperTableRow>
              </KooperTableHeader>
              <KooperTableBody>{renderCampaignList()}</KooperTableBody>
            </KooperTable>
          </KooperCardContent>
        </KooperCard>

        {isStatusPopupOpen && (
          <KooperModal
            closeIcon={<SvgIcon path="common/close" className="closeIcon" />}
            size="tiny"
            centered={false}
            open={isStatusPopupOpen}
            onClose={() => setStatusPopupOpen(false)}
          >
            <KooperModalHeader >Change Status</KooperModalHeader>
            <KooperModalContent>Are you sure you want change status ?</KooperModalContent>
            <KooperModalActions>
              <KooperButton basic onClick={() => setStatusPopupOpen(false)}>
                Cancel
              </KooperButton>
              <KooperButton
                primary
                onClick={() => {
                  if ([CAMPAIGN_TYPES.MESSAGE, CAMPAIGN_TYPES.EMAIL].includes(statusToEditType)) {
                    dispatch(
                      updateCampaignStatus(statusToEdited, {
                        status: statusActivation,
                      })
                    )
                    setStatusPopupOpen(false)
                  }
                  if (statusToEditType === CAMPAIGN_TYPES.SMS) {
                    dispatch(
                      updateSmsCampaignStatus(statusToEdited, {
                        status: statusActivation,
                      })
                    )
                    setStatusPopupOpen(false)
                  }
                }}
              >
                Confirm
              </KooperButton>
            </KooperModalActions>
          </KooperModal>
        )}

        {isDelete && (
          <DeleteModal
            isModalOpen={isDelete}
            onDelete={() =>
              isSmsCampaign ? dispatch(deleteSmsCampaign(userIdToDelete)) : dispatch(deleteCampaign(userIdToDelete))
            }
            setIsModalOpen={setIsDelete}
            modalText="Are you sure you want to delete campaign?"
          />
        )}
      </div>
    </>
  )
}

export default Campaign
