/* eslint-disable camelcase */
/* eslint-disable no-nested-ternary */
import React, {useCallback, useEffect, useRef, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useHistory} from 'react-router'
import {useForm} from 'react-hook-form'
import {joiResolver} from '@hookform/resolvers'
import Joi from 'joi'
import _ from 'lodash'
import {KooperButton, KooperTab} from 'kooper-library'

import {CREATE_CAMPAIGN} from 'actions/types'
import {createEmailCampaign} from 'actions/campaign'
import {getCampaignTemplateList} from 'actions/settings_productivity'
import {extractTextContentFromHTML} from 'utils/helper'
import {oneTimeEmailStepSchema, oneTimeEmailUserAudienceSchema} from 'validation/Marketing/campaign.schema'
import {CAMPAIGN_TYPES} from 'constants/enums'
import Configuration from './OneTimeEmailForm/Configuration'
import UserAudience from './OneTimeEmailForm/UserAudience'
import Messages from './OneTimeEmailForm/Messages'

const steps = [
  {name: 'Configuration', icon: 'cogs'},
  {name: 'User Audience', icon: 'users'},
  {name: 'Messages', icon: 'comments'},
]

const OneTimeEmailSteps = props => {
  const {setWarningModal} = props
  const dispatch = useDispatch()
  const history = useHistory()
  const editorRef = useRef(null)

  const [index, setIndex] = useState(0)
  const [disabledIndex, setDisabledIndex] = useState(0)
  const [campaignCreateData, setCampaignCreateData] = useState(0)
  const [campaignBy, setCampaignBy] = useState('bySegment')
  const [url, setUrl] = useState('')
  const [totalCsvContacts, setTotalCsvContacts] = useState(0)

  const {isLoadingData, successLabels = []} = useSelector(state => state.apiReducer)

  const {
    handleSubmit: handleSubmit1,
    control: control1,
    errors: errors1,
    clearErrors: clearErrors1,
    formState: {isDirty: isDirty1},
    watch: watch1,
  } = useForm({
    mode: 'onTouched',
    shouldUnregister: false,
    shouldFocusError: true,
    reValidateMode: 'onChange',
    resolver: joiResolver(oneTimeEmailStepSchema),
    defaultValues: {
      name: '',
      userId: '',
      timezone: '',
      scheduleWhen: 'later',
      scheduleTime: new Date(),
    },
  })

  const {
    handleSubmit: handleSubmit2,
    control: control2,
    errors: errors2,
    clearErrors: clearErrors2,
    setValue: setValue2,
    watch: watch2,
  } = useForm({
    mode: 'onChange',
    shouldFocusError: true,
    reValidateMode: 'onChange',
    submitFocusError: true,
    resolver: joiResolver(oneTimeEmailUserAudienceSchema),
    defaultValues: {
      campaignBy: '',
      segmentIds: [],
      labelBy: '',
      labelIds: [],
      csv: null,
      labelByCsv: '',
    },
  })

  const {
    handleSubmit: handleSubmit3,
    control: control3,
    errors: errors3,
  } = useForm({
    mode: 'onTouched',
    shouldFocusError: true,
    shouldUnregister: false,
    reValidateMode: 'onChange',
    resolver: joiResolver(
      Joi.object({
        campaign_email: Joi.object({
          subject: Joi.string().trim().min(1).label('subject').messages({
            'string.empty': `Subject cannot be an empty field`,
            'any.required': `Subject is required`,
            'string.min': `Subject must be at least 1 characters long`,
          }),
        }),
      })
    ),
    defaultValues: {campaign_email: {subject: ''}},
  })

  useEffect(() => {
    dispatch(getCampaignTemplateList())
  }, [dispatch])

  useEffect(() => {
    if (successLabels.includes(CREATE_CAMPAIGN)) {
      history.push(`/campaigns`)
    }
  }, [dispatch, history, successLabels])

  const submitData = data => {
    setWarningModal(false)
    if (index === 0) {
      setIndex(index + 1)
      setDisabledIndex(disabledIndex + 1)
      setCampaignCreateData({...campaignCreateData, ...data})
    } else if (index === 1) {
      setIndex(index + 1)
      setDisabledIndex(disabledIndex + 1)
      setCampaignCreateData({...campaignCreateData, ...data})
    } else if (index === 2) {
      const campaign_email = {
        ...(data?.campaign_email || {}),
      }

      editorRef.current.editor.exportHtml(({design, html}) => {
        campaign_email.textDesign = JSON.stringify(design)
        campaign_email.bodyHtml = html
        campaign_email.bodyText = extractTextContentFromHTML(html)

        setCampaignCreateData({...campaignCreateData, campaign_email})

        const payload = _.omitBy(campaignCreateData, value => !value)

        if (payload.csv) {
          dispatch(
            createEmailCampaign(
              {
                type: CAMPAIGN_TYPES.EMAIL,
                emailSMTPId: 5,
                ...payload,
                campaign_email: JSON.stringify(campaign_email),
                status: true,
              },
              'multipart/form-data'
            )
          )
        } else {
          dispatch(
            createEmailCampaign({
              type: CAMPAIGN_TYPES.EMAIL,
              emailSMTPId: 5,
              ...payload,
              campaign_email,
              status: true,
            })
          )
        }
      })
    }
  }

  const handleUnSaved = useCallback(
    _isDirty1 => {
      if (_isDirty1 || index > 0) setWarningModal(true)
    },
    [setWarningModal, index]
  )

  useEffect(() => {
    handleUnSaved(isDirty1)
    return () => {
      setWarningModal(false)
    }
  }, [handleUnSaved, isDirty1, setWarningModal])

  return (
    <div className="kooper-full-page">
      <div className="campaignNavbar">
        <div>
          <KooperButton basic inverted onClick={() => history.push('/campaigns')}>
            Exit
          </KooperButton>
          {index > 0 && (
            <KooperButton basic inverted onClick={() => setIndex(index - 1)} className="ml-2">
              Back
            </KooperButton>
          )}
        </div>
        <KooperTab
          key={index}
          panes={steps.map((item, i) => ({
            menuItem: {
              key: item.name,
              icon: item.icon,
              content: item.name,
              disabled: !(disabledIndex >= i && _.isEmpty(errors1) && _.isEmpty(errors2)),
            },
            render: null,
          }))}
          menu={{
            secondary: true,
            pointing: true,
            color: 'blue',
          }}
          activeIndex={index}
          onTabChange={(e, {activeIndex}) => {
            setIndex(activeIndex)
          }}
          renderActiveOnly={false}
        />
        <KooperButton
          primary
          disabled={isLoadingData}
          onClick={
            index === 0
              ? handleSubmit1(submitData)
              : index === 1
              ? handleSubmit2(submitData)
              : handleSubmit3(submitData)
          }
        >
          {index === steps.length - 1 ? 'Create' : 'Next'}
        </KooperButton>
      </div>
      <Configuration
        style={{display: index === 0 ? '' : 'none'}}
        control={control1}
        errors={errors1}
        clearErrors={clearErrors1}
        watch={watch1}
      />
      <UserAudience
        style={{display: index === 1 ? '' : 'none'}}
        control={control2}
        errors={errors2}
        clearErrors={clearErrors2}
        watch={watch2}
        setValue={setValue2}
        campaignBy={campaignBy}
        setCampaignBy={setCampaignBy}
        url={url}
        setUrl={setUrl}
        totalCsvContacts={totalCsvContacts}
        setTotalCsvContacts={setTotalCsvContacts}
      />
      <Messages
        {...props}
        style={{display: index === 2 ? '' : 'none', background: '#fff'}}
        control={control3}
        errors={errors3}
        editorRef={editorRef}
      />
    </div>
  )
}

export default OneTimeEmailSteps
