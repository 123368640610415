import React from 'react'
import {useHistory} from 'react-router-dom/cjs/react-router-dom.min'
import {FiDownload} from 'react-icons/fi'
import {
  KooperButton,
  KooperHeader,
  KooperLabel,
  KooperTable,
  KooperTableBody,
  KooperTableCell,
  KooperTableHeader,
  KooperTableHeaderCell,
  KooperTablePlaceholder,
  KooperTableRow,
  KooperTooltip,
} from 'kooper-library'

import {invoiceListing} from 'actions/billing'
import {USER_PLATFORM_V1} from 'actions/endpoint'
import {INVOICE_LISTING} from 'actions/types'
import SettingLayout from 'layouts/settingLayout'
import {get, getFormattedDate, startCase} from 'utils/helper'
import {getRouteState} from 'utils/local-storage'
import useLearnMoreUrl from 'hooks/pure/useLearnMoreUrl'
import SvgIcon from 'components/common/SvgIcon'
import useApiResponse from 'hooks/impure/useApiResponse'

const getBadgeColorStatus = status => {
  if (status === 'draft') {
    return ''
  }
  if (status === 'paid') {
    return 'green'
  }
}

const Invoices = () => {
  const history = useHistory()

  const {settingsLearnMoreUrl} = useLearnMoreUrl()
  const invoicesLearnMore = settingsLearnMoreUrl?.billing?.invoices?.learnMore

  const {isLoading, data: getListOfInvoiceData = []} = useApiResponse({
    action: invoiceListing,
    enabled: true,
    label: INVOICE_LISTING,
    storePath: 'billing.getListOfInvoiceData',
  })

  const renderTableBody = () => {
    if (isLoading) {
      return <KooperTablePlaceholder columns={7} row={4} />
    }
    if (!isLoading && getListOfInvoiceData.length === 0) {
      return (
        <KooperTableRow>
          <KooperTableCell colSpan="7" className="emptyValue">
            No Data Available
          </KooperTableCell>
        </KooperTableRow>
      )
    }
    return getListOfInvoiceData.map((invoice, index) => (
      <KooperTableRow
        key={invoice.id}
        className="tableLink"
        onClick={() =>
          history.push({
            pathname: `/settings/invoices/${invoice.id}`,
            state: {
              ...getRouteState(),
              invoiceName: `${get(['data', [0], 'plan'], invoice, '-')}`,
            },
          })
        }
      >
        <KooperTableCell>{index + 1}</KooperTableCell>
        <KooperTableCell>
          <KooperHeader as="h5">
            {startCase(get(['data', [0], 'plan'], invoice) || get(['data', [0], 'addon'], invoice))}
          </KooperHeader>
        </KooperTableCell>
        <KooperTableCell>{get(['data', [0], 'quantity'], invoice, 0)}</KooperTableCell>
        <KooperTableCell>{`${getFormattedDate(invoice.createdAt)}`}</KooperTableCell>
        <KooperTableCell>{get(['total'], invoice) / 100}</KooperTableCell>
        <KooperTableCell>
          <KooperLabel color={getBadgeColorStatus(get(['status'], invoice, 1))}>
            {startCase(get(['status'], invoice, '--'))}
          </KooperLabel>
        </KooperTableCell>
        <KooperTableCell>
          {invoice.gatewayInvoiceId && (
            <KooperTooltip
              content="Download"
              size="mini"
              position="top center"
              trigger={
                <KooperButton
                  icon
                  circular
                  basic
                  onClick={e => {
                    e.stopPropagation()
                    window.open(`${USER_PLATFORM_V1}/billing/invoices/download/${invoice.gatewayInvoiceId}`, '_blank')
                  }}
                >
                  <FiDownload />
                </KooperButton>
              }
            />
          )}
        </KooperTableCell>
      </KooperTableRow>
    ))
  }

  return (
    <SettingLayout
      icon={<SvgIcon path="settings/invoice" />}
      header="Invoices"
      subHeader="Manage and download your invoices."
      learnMoreUrl={invoicesLearnMore}
      table
      headerDivider
    >
      <KooperTable basic selectable>
        <KooperTableHeader>
          <KooperTableRow>
            <KooperTableHeaderCell>#</KooperTableHeaderCell>
            <KooperTableHeaderCell>Plan</KooperTableHeaderCell>
            <KooperTableHeaderCell>User</KooperTableHeaderCell>
            <KooperTableHeaderCell>Created at</KooperTableHeaderCell>
            <KooperTableHeaderCell>Total</KooperTableHeaderCell>
            <KooperTableHeaderCell>Status</KooperTableHeaderCell>
            <KooperTableHeaderCell>Invoices</KooperTableHeaderCell>
          </KooperTableRow>
        </KooperTableHeader>
        <KooperTableBody>{renderTableBody()}</KooperTableBody>
      </KooperTable>
    </SettingLayout>
  )
}

export default Invoices
