/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from 'react'
import {Controller, useForm} from 'react-hook-form'
import {MdLanguage} from 'react-icons/md'
import {useDispatch, useSelector} from 'react-redux'
import {Link} from 'react-router-dom'
import {
  KooperButton,
  KooperCard,
  KooperCardContent,
  KooperContainer,
  KooperForm,
  KooperFormField,
  KooperFormInput,
  KooperImage,
  KooperModal,
  KooperModalActions,
  KooperModalContent,
  KooperModalHeader,
} from 'kooper-library'
import {joiResolver} from '@hookform/resolvers'
import Joi from 'joi'
import {get, removeDoubleQuotes} from 'utils/helper'
import {domainVerification, forgotDomain} from 'actions'
import {FORGOT_DOMAIN, VERIFY_DOMAIN} from 'actions/types'
import {domainValidation} from 'validation'
import logo from 'assets/omni24-full-logo.png'
import SvgIcon from 'components/common/SvgIcon'

function DomainVerification() {
  const {handleSubmit, errors, control} = useForm({
    resolver: joiResolver(
      Joi.object({
        domain: domainValidation.required().messages({
          'string.empty': `Domain is not allowed to be empty field`,
          'any.required': `Domain  is a required field`,
          'string.min': `Domain should have a minimum length of 4 characters`,
          'string.max': `Maximum 20 characters allowed`,
        }),
      })
    ),
    defaultValues: {
      domain: '',
    },
  })

  const {
    handleSubmit: handleSubmit1,
    errors: errors1,
    control: control1,
    reset,
  } = useForm({
    mode: 'onTouched',
    shouldFocusError: true,
    reValidateMode: 'onChange',
    submitFocusError: true,
    shouldUnregister: false,
    resolver: joiResolver(
      Joi.object({
        reset: Joi.string()
          .email({tlds: {allow: false}})
          .required()
          .label('email'),
      })
    ),
    defaultValues: {reset: ''},
  })

  const dispatch = useDispatch()

  const [forgotModal, setForgotModal] = useState(false)

  const onDomainVerify = data => {
    const payload = {domain: data.domain}

    dispatch(domainVerification(payload))
  }

  const resetEmail = ({reset}) => {
    const data = {
      email: reset,
    }
    dispatch(forgotDomain(data))
  }

  const {domainVerifyData} = useSelector(state => state.loginUser)
  const {successLabels = []} = useSelector(state => state.apiReducer)

  useEffect(() => {
    if (successLabels.includes(FORGOT_DOMAIN)) {
      setForgotModal(false)
    }
    if (successLabels.includes(VERIFY_DOMAIN)) {
      window.location.replace(get(['domain'], domainVerifyData))
    }
  }, [successLabels])

  const renderForgotModal = () => {
    return (
      <KooperModal
        size="tiny"
        open={forgotModal}
        onClose={() => setForgotModal(false)}
        closeIcon={<SvgIcon path="common/close" className="closeIcon" />}
      >
        <KooperModalHeader>Forgot Domain ?</KooperModalHeader>
        <KooperModalContent>
          <h5 className="pb-0">Type your email address</h5>
          <p>Please mention your email , We will send your domain details</p>
          <KooperForm className="errorLabel">
            <KooperFormField>
              <Controller
                name="reset"
                render={({value, onChange, onBlur}) => (
                  <KooperFormInput
                    fluid
                    size="large"
                    className="labelError domain-postfix"
                    type="text"
                    onBlur={onBlur}
                    value={value}
                    onChange={e => onChange(e.target.value)}
                    error={
                      errors1.reset && {
                        content: removeDoubleQuotes(errors1.reset.message),
                      }
                    }
                  />
                )}
                control={control1}
              />
            </KooperFormField>
          </KooperForm>
        </KooperModalContent>
        <KooperModalActions>
          <KooperButton
            basic
            onClick={() => {
              setForgotModal(false)
              reset({reset: ''})
            }}
          >
            Cancel
          </KooperButton>
          <KooperButton primary content="Submit" onClick={handleSubmit1(resetEmail)} />
        </KooperModalActions>
      </KooperModal>
    )
  }

  return (
    <KooperContainer style={{background: '#f9fbfd', width: '100%', margin: 0}}>
      <div className="signInPage">
        <KooperImage src={logo} className="kooper-logo" />

        <div className="signInBody">
          <KooperCard className="loginCard domainVerification">
            <KooperCardContent>
              <h1>Log in to {process.env.REACT_APP_NAME}</h1>
              <p>Please provide your account domain below. If you are a new user, you can sign up for our free trial</p>
              <KooperForm className="errorLabel" onSubmit={handleSubmit(onDomainVerify)}>
                <Controller
                  name="domain"
                  control={control}
                  render={({value, onChange}) => (
                    <div className="domainInput">
                      <KooperFormInput
                        fluid
                        size="large"
                        className="labelError"
                        type="text"
                        icon={<MdLanguage />}
                        iconPosition="left"
                        placeholder="Domain"
                        value={value}
                        onChange={e => onChange(e.target.value)}
                        error={
                          errors.domain && {
                            content: errors.domain.message,
                          }
                        }
                      />
                      <span className="domain-text">.kooper.io</span>
                    </div>
                  )}
                  rules={{
                    required: true,
                    minLength: 3,
                  }}
                />

                <KooperButton primary type="submit" fluid size="large">
                  Verify
                </KooperButton>
              </KooperForm>
              <div className="remMe pt-0" style={{justifyContent: 'flex-end'}}>
                <span className="mt-3" onClick={() => setForgotModal(!forgotModal)}>
                  Forgot Domain?
                </span>
              </div>
            </KooperCardContent>
          </KooperCard>
          {forgotModal && renderForgotModal()}
        </div>
        <p className="kooper-lead mt-4">
          Don't have an account? <Link to="/signup">Sign up</Link>
        </p>
      </div>
    </KooperContainer>
  )
}

export default DomainVerification
