/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import {joiResolver} from '@hookform/resolvers'
import {setMetaData} from 'actions'
import {addNoteInContacts, editContact, getConversation} from 'actions/inbox'
import {createTagSettings, getTags} from 'actions/settings_productivity'
import {
  cleanUpObject,
  get,
  getFormattedDate,
  GetFormattedDate,
  getFormattedTime,
  getPermissionTooltipContent,
  removeDoubleQuotes,
  secondsToMoment,
} from 'utils/helper'
import UserImage from 'components/common/UserImage'
import KooperCustomDropdown from 'components/common/KooperCustomDropdown'
import {getMetadataInfo, setMetadataInfo} from 'utils/local-storage'
import _ from 'lodash'
import React, {useEffect, useRef, useState} from 'react'
import {Controller, useForm} from 'react-hook-form'
import {FiChevronRight} from 'react-icons/fi'
import {MdFiberManualRecord} from 'react-icons/md'
import www from 'assets/www.svg'
import {useDispatch, useSelector} from 'react-redux'
import office from 'assets/office.svg'
import {Link} from 'react-router-dom'
import {
  KooperAccordion,
  KooperAccordionContent,
  KooperAccordionTitle,
  KooperButton,
  KooperCard,
  KooperCardContent,
  KooperChipInput,
  KooperDivider,
  KooperForm,
  KooperFormField,
  KooperFormInput,
  KooperHeader,
  KooperHeaderContent,
  KooperHeaderSubheader,
  KooperIcon,
  KooperImage,
  KooperList,
  KooperListContent,
  KooperListDescription,
  KooperListHeader,
  KooperListItem,
  KooperPopup,
  KooperRadio,
  KooperStatistic,
  KooperStatisticGroup,
  KooperStatisticLabel,
  KooperStatisticValue,
  KooperTooltip,
} from 'kooper-library'
import web from 'assets/web.png'
import CreateLabel from 'components/common/CreateLabel'
import {
  ADD_LABEL_CONTACT,
  ADD_NOTE_CONTACTS,
  CREATE_TAG,
  DELETE_ENTRIES,
  EDIT_CONTACT,
  GET_CONTACT_DETAILS,
  GET_TAGS,
  SEND_MAIL,
  UPDATE_TIME_TRACKING_ENTRY,
} from 'actions/types'
import {getContactDetails} from 'actions/customers'
import {customerProfileSchema} from 'validation/Customers/contacts.schema'
import UpdateModal from 'components/common/UpdateModal'
import DeleteModal from 'components/common/DeleteModal'
import {deleteTimeTrackingEntry, getEnteriesByCoversation, updateTimeEntry} from 'actions/time_tracking'
import useUserPermissions from 'hooks/pure/useUserPermissions'
import SvgIcon from 'components/common/SvgIcon'
import useApiResponse from 'hooks/impure/useApiResponse'
import {INBOX_TYPES} from 'constants/enums'

export const CustomerProfile = props => {
  const {contactId, setCustomerProfile, conversationType, setSelectedConversation, type} = props

  const dispatch = useDispatch()
  const ref2 = useRef()

  const {
    errors,
    control,
    reset,
    handleSubmit,
    formState: {isDirty},
  } = useForm({
    mode: 'onTouched',
    shouldFocusError: true,
    reValidateMode: 'onChange',
    resolver: joiResolver(customerProfileSchema),
    defaultValues: {
      name: '',
      email: '',
      phone: '',
      company: '',
      website: '',
    },
  })

  const [noteText, setNoteText] = useState('')
  const [activeIndex, setActiveIndex] = useState(-1)
  const [activeTag, setActiveTag] = useState(0)
  const [activeConversation, setActiveConversation] = useState(0)
  const [labelOptionsState, setLabelOptionsState] = useState([])
  const [newLabelModal, setNewLabelModal] = useState(false)
  const [count, setCount] = useState(3)
  const [urls, setUrls] = useState([])
  const [listOfLable, setListOfLabel] = useState([])
  const [stateMetaData, setStateMetaData] = useState(getMetadataInfo())
  const [deleteEntryId, setDeleteEntryId] = useState(null)
  const [updateEntryId, setUpdateEntryId] = useState(null)
  const [taskdata, setTaskData] = useState('')

  const {getContactDetails: {labels: contactTags} = {}, getContactDetails: contactDetails = []} = useSelector(
    state => state.contacts
  )
  let location
  if (contactDetails.city && contactDetails.state && contactDetails.country) {
    location = `${get(['city'], contactDetails)}, ${get(['state'], contactDetails)}, ${get(
      ['country'],
      contactDetails
    )}`
  } else {
    location = 'Unknown'
  }

  const {conversation = {}} = useSelector(state => state.emailInbox)
  const {successLabels = []} = useSelector(state => state.apiReducer)
  const {timeTrackingConversationEnteries = {}, timeTrackingData = {}} = useSelector(state => state.timeTracking)

  const {conversationsPermissions} = useUserPermissions()
  const manageConversationPermissions = conversationsPermissions?.manage_conversations

  const handleSetMetaData = data => {
    setStateMetaData(data)
    setMetadataInfo(data)
  }

  const userProfile = [
    'Tags',
    'Conversations',
    'Notes',
    ...(conversationType === INBOX_TYPES.WEB ? ['Browsing History', 'Device Details'] : []),
  ]

  const getPageUrls = () => {
    setUrls([])
    const sliceOf3 = _.slice(conversation?.contact?.pageVisits, count, count + 3)
    const sliceUrl = _.map(sliceOf3, value => value.pageUrl)
    setUrls([...urls, ...sliceUrl])
    setCount(count + 3)
  }

  useEffect(() => {
    if (successLabels.includes(CREATE_TAG) || successLabels.includes(SEND_MAIL)) {
      dispatch(getContactDetails(contactId))
    }
    if (successLabels.includes(UPDATE_TIME_TRACKING_ENTRY) || successLabels.includes(DELETE_ENTRIES)) {
      dispatch(getEnteriesByCoversation(conversation.id))
    }
  }, [successLabels, contactId, dispatch, conversation?.id])

  useEffect(() => {
    const id = conversation?.id

    if (id) {
      dispatch(getEnteriesByCoversation(id))
    }
  }, [conversation?.id, dispatch])

  const renderNotes = () => {
    const notesList = get(['notes'], contactDetails, [])

    if (notesList.length > 0) {
      return notesList
        .filter((list, index) => index < 4)
        .map(list => (
          <KooperListItem>
            <KooperListContent floated="right">
              <span>{GetFormattedDate(get(['createdAt'], list, ''))}</span>
            </KooperListContent>

            <KooperListHeader>{get(['note'], list, '')}</KooperListHeader>
            <KooperListDescription>By : {get(['agent'], list, '')}</KooperListDescription>
          </KooperListItem>
        ))
    }
  }

  useEffect(() => {
    if (successLabels.includes(GET_CONTACT_DETAILS)) {
      reset({
        name: `${get(['firstName'], contactDetails, '')} ${get(['lastName'], contactDetails, '')}`,
        email: get(['email'], contactDetails, ''),
        phone: get(['phone'], contactDetails, ''),
        company: get(['company', 'name'], contactDetails, ''),
        website: get(['website'], contactDetails, ''),
      })
      setCount(3)
      setUrls([])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [successLabels])

  useEffect(() => {
    if (successLabels.includes(ADD_LABEL_CONTACT)) {
      dispatch(getContactDetails(contactId))
    }
    if (successLabels.includes(ADD_NOTE_CONTACTS)) {
      dispatch(getContactDetails(contactId))
      setNoteText('')
    }
  }, [successLabels, contactId, dispatch])

  useApiResponse({
    label: GET_TAGS,
    storePath: 'settingsProductivity.tagList',
    onSuccess: tagList => {
      const list = tagList?.map(({id, label, color}) => ({
        key: id,
        value: id,
        text: label,
        label: {
          style: {background: color},
          empty: true,
        },
      }))

      setLabelOptionsState(list || [])
    },
  })

  useEffect(() => {
    const formated = (contactTags || []).map(label => ({
      value: label.id,
      text: label.slug,
      color: label.color,
    }))
    setListOfLabel(formated)
  }, [contactTags])

  useEffect(() => {
    if (successLabels.includes(EDIT_CONTACT)) {
      dispatch(getContactDetails(contactId))
    }
  }, [successLabels, contactId, dispatch, conversation?.id])

  useEffect(() => {
    if (contactId) {
      dispatch(getContactDetails(contactId))
    }
  }, [contactId, dispatch])

  return (
    <div className={`customerProfile ${type}`} style={{padding: 8}}>
      <KooperCard fluid id="editProfile">
        <KooperCardContent className="info">
          <KooperButton
            className="clsPrf"
            icon
            onClick={() => {
              setCustomerProfile(false)
              window.localStorage.setItem('sidebarToggle', false)
            }}
          >
            <SvgIcon path="common/close" />
          </KooperButton>

          <KooperPopup
            trigger={
              <SvgIcon
                path="common/more-verticle"
                className="morePrf"
                onClick={e => {
                  e.preventDefault()
                  e.stopPropagation()
                }}
              />
            }
            className="es-more-dd"
            on="click"
            basic
            position="bottom center"
            pinned
          >
            <KooperList selection className="ddBox" verticalAlign="middle">
              <h3>User Profile</h3>
              <KooperDivider />
              {userProfile.map(userInfo => {
                return (
                  <KooperListItem key={userInfo}>
                    <KooperListContent
                      onClick={() => {
                        let users = _.get(stateMetaData, 'favCustomerProfileFields', [])
                        const check = _.get(stateMetaData, 'favCustomerProfileFields', []).includes(userInfo)

                        if (check) {
                          users = users.filter(agent => agent !== userInfo)
                        } else {
                          users = [...users, userInfo]
                        }
                        const data = {
                          favCustomerProfileFields: users,
                        }
                        handleSetMetaData({
                          ...stateMetaData,
                          favCustomerProfileFields: users,
                        })

                        dispatch(setMetaData(data))
                      }}
                    >
                      {userInfo}
                      <KooperRadio
                        toggle
                        checked={!_.get(stateMetaData, 'favCustomerProfileFields', []).includes(userInfo)}
                      />
                    </KooperListContent>
                  </KooperListItem>
                )
              })}
            </KooperList>
          </KooperPopup>

          <KooperForm
            className="userInfo errorLabel"
            onSubmit={handleSubmit(data => {
              if (!manageConversationPermissions) return
              const firstName = data?.name?.split(' ')?.[0]
              const lastName = data?.name?.split(' ')?.slice(3).join(' ')
              const payload = {...data, firstName, lastName}
              delete payload.name
              if (isDirty) {
                dispatch(editContact(cleanUpObject(payload), contactId))
              }
            })}
            ref={ref2}
          >
            <KooperHeader as="h4">
              <KooperIcon>
                <UserImage profile={contactDetails} />
              </KooperIcon>
              <KooperHeaderContent>
                <KooperFormField className="inbox-customer-editor mb-0" style={{width: '72%'}}>
                  <Controller
                    name="name"
                    control={control}
                    render={({value, onChange, onBlur}) => (
                      <KooperFormInput
                        value={value}
                        onChange={(e, {value}) => onChange(value)}
                        onBlur={e => {
                          onBlur(e)
                          ref2.current.handleSubmit()
                        }}
                        onKeyPress={e => {
                          if (e.key === 'Enter') {
                            e.target.blur()
                          }
                        }}
                        disabled={!manageConversationPermissions}
                        error={errors.name && {content: removeDoubleQuotes(errors.name.message)}}
                      />
                    )}
                  />
                </KooperFormField>

                {contactDetails?.online ? (
                  <KooperHeaderSubheader>
                    <div className="online-status">
                      <MdFiberManualRecord className="online" />
                      <span className="online">Online</span>
                    </div>
                  </KooperHeaderSubheader>
                ) : contactDetails?.lastSeenAt ? (
                  <KooperHeaderSubheader>
                    <div className="lastseen-status">
                      Last seen : {GetFormattedDate(contactDetails.lastSeenAt, true)}
                    </div>
                  </KooperHeaderSubheader>
                ) : null}
                <Link className="viewPrf" to={`/contacts/${contactId}`}>
                  View Profile
                </Link>
              </KooperHeaderContent>
            </KooperHeader>

            <KooperFormField className="mt-3 mb-2">
              <div className="inbox-customer-editor">
                <SvgIcon path="common/mail" />
                <Controller
                  name="email"
                  control={control}
                  render={({value, onChange, onBlur}) => (
                    <KooperFormInput
                      placeholder={!get(['email'], contactDetails) && 'Enter Email Address'}
                      defaultValue={value}
                      onChange={e => onChange(e.target.value)}
                      error={
                        errors.email && {
                          content: removeDoubleQuotes(errors.email.message),
                        }
                      }
                      onBlur={e => {
                        onBlur(e)
                        ref2.current.handleSubmit()
                      }}
                      onKeyPress={e => {
                        if (e.key === 'Enter') {
                          e.target.blur()
                        }
                      }}
                      disabled={!manageConversationPermissions}
                    />
                  )}
                />
              </div>
            </KooperFormField>

            <KooperFormField className={errors.phone ? 'mb-4' : 'mb-2'}>
              <div className="inbox-customer-editor">
                <SvgIcon path="common/call" />
                <Controller
                  name="phone"
                  render={({value, onChange, onBlur}) => (
                    <KooperFormInput
                      placeholder={!get(['phone'], contactDetails) && 'Enter phone number'}
                      defaultValue={value}
                      onChange={e => onChange(e.target.value)}
                      error={
                        errors.phone && {
                          content: removeDoubleQuotes(errors.phone.message),
                        }
                      }
                      onBlur={e => {
                        onBlur(e)
                        ref2.current.handleSubmit()
                      }}
                      onKeyPress={e => {
                        if (e.key === 'Enter') {
                          e.target.blur()
                        }
                      }}
                      disabled={!manageConversationPermissions}
                    />
                  )}
                  control={control}
                />
              </div>
            </KooperFormField>
            <KooperFormField className="mb-2">
              <div className="inbox-customer-editor">
                <img src={office} alt="office" />
                <Controller
                  name="company"
                  render={({value, onChange, onBlur}) => (
                    <KooperFormInput
                      placeholder={!get(['company', 'name'], contactDetails) && 'Enter company name'}
                      defaultValue={value}
                      onChange={e => onChange(e.target.value)}
                      onBlur={e => {
                        onBlur(e)
                        ref2.current.handleSubmit()
                      }}
                      onKeyPress={e => {
                        if (e.key === 'Enter') {
                          e.target.blur()
                        }
                      }}
                      disabled={!manageConversationPermissions}
                      error={
                        get(['company'], errors) && {
                          content: removeDoubleQuotes(get(['company', 'message'], errors)),
                        }
                      }
                    />
                  )}
                  control={control}
                />
              </div>
            </KooperFormField>
            <KooperFormField className="mb-2">
              <div className="inbox-customer-editor">
                <SvgIcon path="inbox/inbox-dashboard/website-url" />
                <Controller
                  name="website"
                  render={({value, onChange, onBlur}) => (
                    <KooperFormInput
                      defaultValue={value}
                      placeholder={!get(['website'], contactDetails) && 'Enter website URL'}
                      key={get(['website'], contactDetails, 'contact_website')}
                      onChange={e => onChange(e.target.value)}
                      onBlur={e => {
                        onBlur(e)
                        ref2.current.handleSubmit()
                      }}
                      onKeyPress={e => {
                        if (e.key === 'Enter') {
                          e.target.blur()
                        }
                      }}
                      disabled={!manageConversationPermissions}
                      error={
                        get(['website'], errors) && {
                          content: removeDoubleQuotes(get(['website', 'message'], errors)),
                        }
                      }
                    />
                  )}
                  control={control}
                />
              </div>
            </KooperFormField>
            {conversationType && conversationType !== INBOX_TYPES.SMS && (
              <KooperFormField className="mb-2">
                <div className="inbox-customer-editor">
                  <img src={www} alt="www" />
                  <KooperFormInput disabled value={location} />
                </div>
              </KooperFormField>
            )}
          </KooperForm>
        </KooperCardContent>
      </KooperCard>
      {!_.get(stateMetaData, 'favCustomerProfileFields', []).includes('Tags') && (
        <KooperCard fluid>
          <KooperCardContent className="p-0">
            <KooperAccordion styled className="kooper-accordion">
              <KooperAccordionTitle
                active={activeTag === 0}
                index={0}
                onClick={(e, titleProps) => {
                  const {index} = titleProps
                  const newIndex = activeTag === index ? -1 : index
                  setActiveTag(newIndex)
                }}
              >
                {!activeTag ? <SvgIcon path="common/down" /> : <FiChevronRight />}
                Tags
                <div className="countNo">({listOfLable?.length})</div>
              </KooperAccordionTitle>
              <KooperAccordionContent active={activeTag === 0}>
                <div>
                  {listOfLable === null || listOfLable.length === 0 ? (
                    <p className="kooper-lead mb-0 mr-3">No Tags</p>
                  ) : (
                    <KooperChipInput
                      labelProps={{basic: true}}
                      className="mr-3"
                      defaultValue={
                        listOfLable &&
                        listOfLable.map(tag => {
                          return {
                            id: tag.value,
                            text: <>{tag.text}</>,
                            color: tag.color,
                          }
                        })
                      }
                      onChange={value => {
                        if (!manageConversationPermissions) return
                        const valueIds = value.map(tag => tag.id)
                        dispatch(editContact({labelIds: valueIds}, contactId))
                      }}
                      hideInputField
                    />
                  )}
                  <KooperCustomDropdown
                    options={labelOptionsState}
                    multiple
                    dropdownProps={{
                      onClick: () => dispatch(getTags()),
                      upward: true,
                      icon: (
                        <KooperTooltip
                          content={
                            manageConversationPermissions
                              ? 'Add Tags'
                              : getPermissionTooltipContent({roleAccess: manageConversationPermissions})
                          }
                          size="mini"
                          position="top center"
                          trigger={
                            <KooperButton icon>
                              <SvgIcon path="common/plus" />
                            </KooperButton>
                          }
                        />
                      ),
                      className: 'tag-dropdown',
                    }}
                    menu={{scrolling: true}}
                    input={{
                      icon: 'search',
                      iconPosition: 'left',
                      className: 'search',
                      placeholder: 'Search',
                    }}
                    activeIndicator
                    value={listOfLable.map(labels => labels.value)}
                    item={{disabled: !manageConversationPermissions}}
                    onClick={value => {
                      if (manageConversationPermissions) {
                        dispatch(editContact({labelIds: value}, contactId))
                      }
                    }}
                    extraAction={{
                      content: '+ Add Tag',
                      onClick: () => manageConversationPermissions && setNewLabelModal(true),
                      disabled: !manageConversationPermissions,
                    }}
                  />
                </div>
              </KooperAccordionContent>
            </KooperAccordion>
          </KooperCardContent>
        </KooperCard>
      )}
      {!_.get(stateMetaData, 'favCustomerProfileFields', []).includes('Conversation') && (
        <KooperCard fluid>
          <KooperCardContent className="p-0">
            <KooperAccordion styled className="kooper-accordion">
              <KooperAccordionTitle
                active={activeConversation === 1}
                index={1}
                onClick={(e, titleProps) => {
                  const {index} = titleProps
                  const newIndex = activeConversation === index ? -1 : index
                  setActiveConversation(newIndex)
                }}
              >
                {activeConversation === 1 ? <SvgIcon path="common/down" /> : <FiChevronRight />}
                Conversations
                <div className="countNo">
                  {get(['conversations'], contactDetails, []).length > 0
                    ? `(${get(['conversations'], contactDetails, []).length})`
                    : ''}
                </div>
              </KooperAccordionTitle>
              <KooperAccordionContent className="p-0" active={activeConversation === 1}>
                {get(['conversations'], contactDetails, []).length === 0 ? (
                  <p className="kooper-lead mb-0 px-3">No Conversations </p>
                ) : null}
                <KooperList selection className="recentConv mb-2">
                  {get(['conversations'], contactDetails, []).length > 0
                    ? get(['conversations'], contactDetails, [])
                        .filter((conversation, index) => index < 4)
                        .map(conversation => (
                          <KooperListItem
                            onClick={() => {
                              dispatch(getConversation(conversation.id))
                              setSelectedConversation(conversation.id)
                            }}
                          >
                            <KooperListContent floated="right">
                              <span>
                                {`${get(['assignee', 'firstName'], conversation, '-')} ${get(
                                  ['assignee', 'lastName'],
                                  conversation,
                                  '-'
                                )}`}
                              </span>
                            </KooperListContent>

                            <KooperListHeader>
                              <span> {conversation.subject || ''} </span>
                            </KooperListHeader>
                            <KooperListDescription>
                              {GetFormattedDate(get(['updatedAt'], conversation, ''))}
                            </KooperListDescription>
                          </KooperListItem>
                        ))
                    : ''}
                </KooperList>
              </KooperAccordionContent>
            </KooperAccordion>
          </KooperCardContent>
        </KooperCard>
      )}
      {!_.get(stateMetaData, 'favCustomerProfileFields', []).includes('Notes') && (
        <KooperCard fluid>
          <KooperCardContent className="p-0">
            <KooperAccordion styled className="kooper-accordion">
              <KooperAccordionTitle
                active={activeIndex === 2}
                index={2}
                onClick={(e, titleProps) => {
                  const {index} = titleProps
                  const newIndex = activeIndex === index ? -1 : index
                  setActiveIndex(newIndex)
                }}
              >
                {activeIndex === 2 ? <SvgIcon path="common/down" /> : <FiChevronRight />}
                Notes
                <div className="countNo">
                  {get(['notes'], contactDetails, []).length > 0
                    ? `(${get(['notes'], contactDetails, []).length})`
                    : ''}
                </div>
              </KooperAccordionTitle>
              <KooperAccordionContent className="p-0 pb-2" active={activeIndex === 2}>
                <KooperForm className="mx-3 mb-2" size="tiny">
                  <KooperFormField>
                    <KooperFormInput
                      placeholder="Write note here..."
                      maxLength={30}
                      disabled={!manageConversationPermissions}
                      onChange={e => manageConversationPermissions && setNoteText(e.target.value)}
                      value={noteText}
                      onKeyPress={e => {
                        if (e.key === 'Enter') {
                          if (!manageConversationPermissions) return
                          const data = {
                            note: e.target.value,
                          }
                          if (e.target.value.length !== 0 && e.target.value.trim() !== '') {
                            dispatch(addNoteInContacts(contactId, data))
                          }
                        }
                      }}
                    />
                  </KooperFormField>
                </KooperForm>
                {/* {get(["notes"], contactDetails, []).length === 0 ? (
                <p className="kooper-lead px-4 mb-0">No Notes </p>
              ) : null} */}
                <KooperList selection className="recentConv">
                  {renderNotes()}
                </KooperList>
              </KooperAccordionContent>
            </KooperAccordion>
          </KooperCardContent>
        </KooperCard>
      )}
      {!_.get(stateMetaData, 'favCustomerProfileFields', []).includes('Browsing History') &&
        conversationType === INBOX_TYPES.WEB && (
          <KooperCard fluid>
            <KooperAccordion styled className="kooper-accordion">
              <KooperAccordionTitle
                active={activeIndex === 3}
                index={3}
                onClick={(e, titleProps) => {
                  const {index} = titleProps
                  const newIndex = activeIndex === index ? -1 : index
                  setActiveIndex(newIndex)
                }}
              >
                {activeIndex === 3 ? <SvgIcon path="common/down" /> : <FiChevronRight />}
                Browsing History
              </KooperAccordionTitle>
              <KooperAccordionContent active={activeIndex === 3}>
                <p>Reference Url</p>
                <KooperTooltip
                  position="top center"
                  size="mini"
                  content={get(['contact', 'referralUrl'], conversation, '--')}
                  trigger={
                    <a
                      href={get(['contact', 'referralUrl'], conversation, '--')}
                      className="urlLink"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {get(['contact', 'referralUrl'], conversation, '--')}
                    </a>
                  }
                />

                <p>Page Visit Count</p>
                {conversation?.contact?.pageVisits ? <h5>{conversation?.contact?.pageVisits.length} </h5> : '--'}

                <p>Page</p>
                {conversation?.contact?.pageVisits
                  ? conversation?.contact?.pageVisits.slice(0, 3).map((value, index) => {
                      return (
                        <KooperTooltip
                          position="top center"
                          size="mini"
                          content={value.pageUrl}
                          key={index}
                          trigger={
                            <a href={value.pageUrl} className="urlLink" target="_blank" rel="noopener noreferrer">
                              {value.pageUrl}
                            </a>
                          }
                        />
                      )
                    })
                  : '--'}
                {urls.map((value, index) => (
                  <KooperTooltip
                    position="top center"
                    size="mini"
                    content={value}
                    key={index}
                    trigger={
                      <a href={value} className="urlLink" target="_blank" rel="noopener noreferrer">
                        {value}
                      </a>
                    }
                  />
                ))}
                {conversation?.contact?.pageVisits?.length > count && (
                  <a className="m-0" style={{display: 'flex', justifyContent: 'flex-end'}} onClick={getPageUrls}>
                    View More...
                  </a>
                )}
              </KooperAccordionContent>
            </KooperAccordion>
          </KooperCard>
        )}
      {!_.get(stateMetaData, 'favCustomerProfileFields', []).includes('Device Details') &&
        conversationType === INBOX_TYPES.WEB && (
          <KooperCard fluid>
            <KooperAccordion styled className="kooper-accordion">
              <KooperAccordionTitle
                active={activeIndex === 5}
                index={5}
                onClick={(e, titleProps) => {
                  const {index} = titleProps
                  const newIndex = activeIndex === index ? -1 : index
                  setActiveIndex(newIndex)
                }}
              >
                {activeIndex === 5 ? <SvgIcon path="common/down" /> : <FiChevronRight />}
                Device Details
              </KooperAccordionTitle>
              <KooperAccordionContent active={activeIndex === 5}>
                <p>Device</p>
                <h5>
                  {conversation?.contact?.pageVisits && conversation?.contact?.pageVisits[0] ? (
                    <>
                      <KooperImage className="ui image" src={web} />

                      {`${get(['contact', 'pageVisits', '0', 'device'], conversation, '--')} , ${get(
                        ['contact', 'pageVisits', '0', 'browser'],
                        conversation,
                        '--'
                      )}`}
                    </>
                  ) : (
                    '--'
                  )}
                </h5>

                <p>OS</p>
                <h5>{get(['contact', 'pageVisits', '0', 'os'], conversation, '--')}</h5>

                <p>IP</p>
                <h5>
                  {get(['contact', 'pageVisits', '0', 'ip'], conversation)?.length > 0
                    ? get(['contact', 'pageVisits', '0', 'ip'], conversation)
                    : '--'}
                </h5>
              </KooperAccordionContent>
            </KooperAccordion>
          </KooperCard>
        )}

      {!_.get(stateMetaData, 'favCustomerProfileFields', []).includes('Time Tracking') &&
        timeTrackingData.isTimeTracking && (
          <KooperCard fluid>
            <KooperAccordion styled className="kooper-accordion">
              <KooperAccordionTitle
                active={activeIndex === 6}
                index={6}
                onClick={(e, titleProps) => {
                  const {index} = titleProps
                  const newIndex = activeIndex === index ? -1 : index
                  setActiveIndex(newIndex)
                }}
              >
                {activeIndex === 6 ? <SvgIcon path="common/down" /> : <FiChevronRight />}
                Time Tracking
              </KooperAccordionTitle>
              <KooperAccordionContent active={activeIndex === 6}>
                <KooperStatisticGroup className="cp-timeTrack">
                  <KooperStatistic color="green">
                    <KooperStatisticLabel>Total Duration</KooperStatisticLabel>
                    <KooperStatisticValue>
                      {secondsToMoment(
                        get(['trackEntries'], timeTrackingConversationEnteries, []).reduce(
                          (acc, item) => item.duration + acc,
                          0
                        )
                      )}
                    </KooperStatisticValue>
                  </KooperStatistic>
                  <KooperStatistic color="olive">
                    <KooperStatisticLabel>Total Bill</KooperStatisticLabel>
                    <KooperStatisticValue>
                      Rs : {get(['totalCost'], timeTrackingConversationEnteries, 0)}
                    </KooperStatisticValue>
                  </KooperStatistic>
                </KooperStatisticGroup>
                {get(['trackEntries'], timeTrackingConversationEnteries, []).map(entry => (
                  <div className="cp-timeTrack">
                    <KooperHeader as="h5">
                      {secondsToMoment(entry.duration)}
                      <KooperHeaderSubheader>
                        {getFormattedDate(entry.startTime)} {getFormattedTime(entry.startTime)}
                      </KooperHeaderSubheader>
                    </KooperHeader>
                    <KooperHeader as="h5">Rs. {entry.costAmount}</KooperHeader>
                    <KooperTooltip
                      content="Edit"
                      size="mini"
                      position="top center"
                      trigger={
                        <KooperButton
                          icon
                          onClick={() => {
                            setUpdateEntryId(entry.id)
                            setTaskData(entry)
                          }}
                        >
                          <SvgIcon path="common/edit2" />
                        </KooperButton>
                      }
                    />
                    <KooperTooltip
                      content="Delete"
                      size="mini"
                      position="top center"
                      trigger={
                        <KooperButton icon onClick={() => setDeleteEntryId(entry.id)}>
                          <SvgIcon path="common/delete" />
                        </KooperButton>
                      }
                    />
                  </div>
                ))}
              </KooperAccordionContent>
            </KooperAccordion>
          </KooperCard>
        )}

      {newLabelModal && (
        <CreateLabel
          isModalOpen={newLabelModal}
          setIsModalOpen={setNewLabelModal}
          createMethod={data => dispatch(createTagSettings(data))}
          contactIds={[contactId]}
        />
      )}

      {deleteEntryId && (
        <DeleteModal
          idTobeDeleted={deleteEntryId}
          deleteAction={deleteTimeTrackingEntry}
          isModalOpen={deleteEntryId}
          setIsModalOpen={setDeleteEntryId}
          type={DELETE_ENTRIES}
        />
      )}
      {updateEntryId && (
        <UpdateModal
          idTobeUpdated={updateEntryId}
          updateAction={updateTimeEntry}
          isModalOpen={updateEntryId}
          setIsModalOpen={setUpdateEntryId}
          setValue={taskdata}
          type={UPDATE_TIME_TRACKING_ENTRY}
        />
      )}
    </div>
  )
}
