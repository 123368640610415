/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from 'react'
import {useForm, Controller, useFieldArray} from 'react-hook-form'
import {useHistory, useParams} from 'react-router'
import {joiResolver} from '@hookform/resolvers'
import {useDispatch, useSelector} from 'react-redux'
import axios from 'axios'
import _ from 'lodash'
import {
  KooperAttachment,
  KooperButton,
  KooperCard,
  KooperCardContent,
  KooperContainer,
  KooperDatePicker,
  KooperDropdown,
  KooperForm,
  KooperFormField,
  KooperFormInput,
  KooperFormRadio,
  KooperFormSelect,
  KooperFormTextArea,
  KooperGrid,
  KooperGridColumn,
  KooperGridRow,
  KooperInput,
  KooperTable,
  KooperTableBody,
  KooperTableCell,
  KooperTableHeader,
  KooperTableHeaderCell,
  KooperTableRow,
  KooperTooltip,
} from 'kooper-library'
import {
  ATTACHMENT_POST,
  CREATE_EXPENSES,
  GET_ACCOUNT_CURRENCY,
  GET_ALL_TAG_LABEL,
  GET_EMPLOYEE_LIST,
  GET_EXPENSES_CATEGORY,
  GET_PROJECT_LIST,
  GET_SINGLE_EXPENSE,
  UPDATE_EXPENSE,
} from 'actions/types'
import {getAccountCurrency} from 'actions/setting_currency'
import {uploadAttachments} from 'actions/settings_productivity'
import {
  getEmployeeList,
  getExpensesCategory,
  getProjectList,
  createExpenses,
  getSingleExpenses,
  updateExpenses,
} from 'actions/expenses'
import {createTagSettings, getTagLabel} from 'actions/multiple_fields'
import {removeDoubleQuotes} from 'utils/helper'
import AttachmentPreview from 'components/common/AttachmentPreview'
import CreateLabel from 'components/common/CreateLabel'
import {createExpensesSchema} from 'validation/Hr/expenses.schema'
import {expenseStatus} from 'constants/hr/expense'
import SvgIcon from 'components/common/SvgIcon'
import useApiResponse from 'hooks/impure/useApiResponse'

const {CancelToken} = axios
const requestToken = CancelToken.source()

const CreateExpense = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const {id: editId} = useParams()

  const [ExpenseDate, setExpenseDate] = useState(null)
  const [EmployeeOption, setEmployeeOption] = useState([])
  const [ProjectOption, setProjectOption] = useState([])
  const [files, setFiles] = useState([])
  const [data, setData] = useState({
    attachments: [],
  })
  const [uploadedFiles, setUploadedFiles] = useState([])
  const [currencyListData, setcurrencyListData] = useState([])
  const [categoryList, setCategoryList] = useState([])
  const [newLabelModal, setNewLabelModal] = useState(false)
  const [labelOptionsState, setLabelOptionsState] = useState([])

  const {successData: attachmentSuccessData, attachmentList = []} = useSelector(state => state.emailInbox)
  const {successLabels = []} = useSelector(state => state.apiReducer)

  const initialCreateInfo = {
    name: '',
    expenseDate: null,
    docNumber: '',
    employeeId: null,
    labelIds: [],
    projectId: null,
    paidBy: 'employee',
    currencyId: '',
    status: '',
    attachmentIds: [],
    lists: [
      {
        categoryId: null,
        description: '',
        price: 0,
        discount: 0,
        tax: 0,
        total: 0,
      },
    ],
    taxAmount: 0,
    subTotal: 0,
    totalAmount: 0,
  }

  const {handleSubmit, errors, control, watch, reset, setValue} = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    resolver: joiResolver(createExpensesSchema),
    defaultValues: initialCreateInfo,
  })

  const {fields, append, remove} = useFieldArray({
    control,
    name: 'lists',
  })

  const watchCurrencyId = watch('currencyId')

  const {data: expensesData = {}} = useApiResponse({
    action: getSingleExpenses,
    payload: editId,
    dependency: [editId],
    enabled: editId,
    label: GET_SINGLE_EXPENSE,
    storePath: 'expenses.expensesData',
    onSuccess: () => {
      const {
        name,
        expenseDate,
        docNumber,
        employeeId,
        labelIds,
        projectId,
        paidBy,
        currencyId,
        status,
        attachments,
        lists,
        taxAmount,
        subTotal,
        totalAmount,
      } = expensesData
      setExpenseDate(expenseDate)
      setUploadedFiles(
        attachments?.map(({id, name, size, type}) => {
          return {id, name, size, type}
        })
      )
      reset({
        name,
        expenseDate,
        docNumber,
        employeeId,
        labelIds,
        projectId,
        paidBy,
        currencyId,
        status,
        attachmentIds: attachments?.map(({id}) => id),
        lists: lists?.map(({categoryId, description, discount, price, tax, total}) => {
          return {categoryId, description, discount, price, tax, total}
        }),
        taxAmount,
        subTotal,
        totalAmount,
      })
    },
  })

  const createNewLabel = () => {
    if (newLabelModal) {
      return (
        <CreateLabel
          isModalOpen={newLabelModal}
          setIsModalOpen={setNewLabelModal}
          createMethod={data => dispatch(createTagSettings(data))}
        />
      )
    }
  }

  useEffect(() => {
    if (successLabels.includes(CREATE_EXPENSES) || successLabels.includes(UPDATE_EXPENSE)) {
      history.push('/expenses')
    }
  }, [dispatch, history, successLabels])

  useApiResponse({
    action: getTagLabel,
    enabled: true,
    label: GET_ALL_TAG_LABEL,
    storePath: 'multipleFields.getTagList',
    onSuccess: getTagList => {
      const list = getTagList.map(label => ({
        value: label.id,
        text: label.label,
        key: label.id,
        label: {
          style: {background: label.color},
          empty: true,
          circular: true,
        },
      }))
      setLabelOptionsState(list)
    },
  })

  useEffect(() => {
    if (attachmentSuccessData === 'composeAttachment' && successLabels.includes(ATTACHMENT_POST)) {
      const newFiles = attachmentList.map(file => ({
        id: file.id,
        name: file.name,
        size: file.size,
      }))

      setData({
        ...data,
        attachments: [...data.attachments, ...newFiles],
      })
      dispatch({
        type: 'CLEAR_ATTACHMENT',
      })
    }
  }, [successLabels, attachmentList, attachmentSuccessData, data, dispatch])

  useApiResponse({
    action: getExpensesCategory,
    enabled: true,
    label: GET_EXPENSES_CATEGORY,
    storePath: 'expenses.getExpensesCategoryData',
    onSuccess: getExpensesCategoryData => {
      setCategoryList(
        getExpensesCategoryData.map(({category, id}) => {
          return {key: id, value: id, text: category}
        })
      )
    },
  })

  useApiResponse({
    label: GET_ACCOUNT_CURRENCY,
    enabled: true,
    storePath: 'settingCurrency.accountCurrencyList',
    action: getAccountCurrency,
    onSuccess: accountCurrencyList => {
      if (accountCurrencyList) {
        const currList = accountCurrencyList.map(list => ({
          key: list.base.id,
          value: list.currencyId,
          text: list.symbol,
        }))
        setcurrencyListData(currList)
      }
      if (accountCurrencyList) {
        const Bcur = accountCurrencyList.find(list => list.isBase && list)
        setValue('currencyId', Bcur?.currencyId)
      }
    },
  })

  useApiResponse({
    action: getEmployeeList,
    enabled: true,
    label: GET_EMPLOYEE_LIST,
    storePath: 'expenses.getEmployeeOptionData',
    onSuccess: getEmployeeOptionData => {
      const list = getEmployeeOptionData.map(employee => ({
        value: employee.id,
        text: `${employee.name}(${employee.employeeID})`,
        id: employee.id,
      }))
      setEmployeeOption(list)
    },
  })

  useApiResponse({
    action: getProjectList,
    enabled: true,
    label: GET_PROJECT_LIST,
    storePath: 'expenses.getProjectOptionData',
    onSuccess: getProjectOptionData => {
      const list = getProjectOptionData.map(project => ({
        value: project.id,
        text: project.title,
        id: project.id,
      }))
      setProjectOption(list)
    },
  })

  const getDiscountedPrice = (price, discount) => {
    const discountPrice = +((price * discount) / 100).toFixed(2)
    return price - discountPrice
  }

  const getTaxedPrice = (price, tax) => {
    const taxPrice = +((price * tax) / 100).toFixed(2)
    return taxPrice
  }

  const getSubTotal = () => {
    const allDiscountPrices = watch('lists').map(list => {
      const price = +(list.price || 0)
      const discount = +(list.discount || 0)

      const discoutedPrice = getDiscountedPrice(price, discount)
      return discoutedPrice
    })
    return allDiscountPrices.reduce((acc, currentPrice) => (acc += currentPrice), 0)
  }

  const getTax = () => {
    const allTaxPrices = watch('lists').map(list => {
      const price = +(list.price || 0)
      const discount = +(list.discount || 0)
      const tax = +(list.tax || 0)

      const discountedPrice = getDiscountedPrice(price, discount)
      const taxPrice = getTaxedPrice(discountedPrice, tax)
      return taxPrice
    })
    return allTaxPrices.reduce((acc, currentTaxPrice) => (acc += currentTaxPrice), 0)
  }

  const getTotal = () => {
    const allTotals = watch('lists').map(list => +list.total)
    return allTotals.reduce((acc, currentTotal) => (acc += currentTotal), 0)
  }

  const calculateTotal = index => {
    const price = +(watch(`lists[${index}].price`) || 0)
    const discount = +(watch(`lists[${index}].discount`) || 0)
    const tax = +(watch(`lists[${index}].tax`) || 0)

    const discountedPrice = getDiscountedPrice(price, discount)
    const taxPrice = getTaxedPrice(discountedPrice, tax)
    const total = +(discountedPrice + taxPrice).toFixed(2)
    setValue(`lists[${index}].total`, total)
  }

  const getCurrency = () => {
    return currencyListData.find(currency => currency.value === watchCurrencyId)?.text
  }

  const FieldList = () => {
    return (
      <>
        {fields.map(({id, categoryId, description, price, discount, tax, total}, i) => {
          return (
            <KooperTableRow key={id}>
              <KooperTableCell>
                <div className="d-flex">
                  {fields.length > 1 && (
                    <KooperButton className="m-0" icon onClick={() => remove(i)}>
                      <SvgIcon path="common/delete" />
                    </KooperButton>
                  )}
                  <KooperButton
                    icon
                    onClick={() =>
                      append({
                        categoryId: null,
                        description: '',
                        price: 0,
                        discount: 0,
                        tax: 0,
                        total: 0,
                      })
                    }
                  >
                    <SvgIcon path="common/plus" />
                  </KooperButton>
                </div>
              </KooperTableCell>
              <KooperTableCell>
                <Controller
                  name={`lists[${i}].categoryId`}
                  defaultValue={categoryId}
                  render={({value, onChange}) => (
                    <KooperFormSelect
                      search
                      fluid
                      options={categoryList}
                      value={value}
                      onChange={(e, {value}) => onChange(value)}
                      error={
                        errors?.lists?.[i]?.categoryId && {
                          content: removeDoubleQuotes(errors?.lists?.[i].categoryId?.message),
                        }
                      }
                    />
                  )}
                  control={control}
                />
              </KooperTableCell>
              <KooperTableCell>
                <KooperFormField>
                  <Controller
                    name={`lists[${i}].description`}
                    defaultValue={description}
                    render={({onChange, value}) => (
                      <KooperFormTextArea
                        maxLength={200}
                        style={{height: '39px', marginTop: '20px'}}
                        type="text"
                        value={value}
                        placeholder="Description"
                        onChange={e => {
                          onChange(e.target.value)
                        }}
                        error={
                          errors?.lists?.[i]?.description && {
                            content: removeDoubleQuotes(errors?.lists?.[i].description?.message),
                          }
                        }
                      />
                    )}
                    control={control}
                  />
                </KooperFormField>
              </KooperTableCell>
              <KooperTableCell>
                <Controller
                  name={`lists[${i}].price`}
                  control={control}
                  defaultValue={price}
                  render={({onChange, value}) => (
                    <KooperInput
                      style={{width: '70px'}}
                      value={value}
                      onChange={(e, {value}) => {
                        onChange(value)
                        calculateTotal(i)
                      }}
                      onBlur={e => !e.target.value && onChange(0)}
                      error={
                        errors?.lists?.[i]?.price && {
                          content: removeDoubleQuotes(errors?.lists?.[i].price?.message),
                        }
                      }
                    />
                  )}
                />
              </KooperTableCell>
              <KooperTableCell>
                <Controller
                  name={`lists[${i}].discount`}
                  control={control}
                  defaultValue={discount}
                  render={({onChange, value}) => (
                    <KooperInput
                      fluid
                      label={{basic: true, content: '%'}}
                      labelPosition="right"
                      style={{width: '90px'}}
                      value={value}
                      onChange={(e, {value}) => {
                        if (value < 0) {
                          onChange(0)
                        } else if (value >= 100) {
                          onChange(100)
                        } else {
                          onChange(value)
                        }
                        calculateTotal(i)
                      }}
                      onBlur={e => !e.target.value && onChange(0)}
                    />
                  )}
                />
              </KooperTableCell>
              <KooperTableCell>
                <Controller
                  name={`lists[${i}].tax`}
                  control={control}
                  defaultValue={tax}
                  render={({onChange, value}) => (
                    <KooperInput
                      fluid
                      label={{basic: true, content: '%'}}
                      labelPosition="right"
                      style={{width: '90px'}}
                      value={value}
                      onChange={(e, {value}) => {
                        if (value < 0) {
                          onChange(0)
                        } else if (value >= 100) {
                          onChange(100)
                        } else {
                          onChange(value)
                        }
                        calculateTotal(i)
                      }}
                      onBlur={e => !e.target.value && onChange(0)}
                    />
                  )}
                />
              </KooperTableCell>
              <KooperTableCell>
                <Controller
                  name={`lists[${i}].total`}
                  control={control}
                  defaultValue={total}
                  render={({value}) => <KooperInput value={value} disabled />}
                />
              </KooperTableCell>
            </KooperTableRow>
          )
        })}
      </>
    )
  }

  function submitForm(data) {
    data.taxAmount = getTax()
    data.totalAmount = getTotal()
    data.subTotal = getSubTotal()

    if (uploadedFiles?.length > 0) {
      data.attachmentIds = uploadedFiles.map(file => file.id)
    }

    const newList = _.omitBy(data, value => !!(value === '' || value === null || _.isEqual(value, [])))

    if (editId) {
      return dispatch(updateExpenses(editId, newList))
    }

    dispatch(createExpenses(newList))
  }

  return (
    <div className="kooper-full-page">
      <div className="campaignNavbar">
        <div>
          <KooperButton primary onClick={() => history.push('/expenses')}>
            Exit
          </KooperButton>
        </div>
        <h3 className="m-0">Create New Expense</h3>
        <div>
          {/* <KooperButton className="mr-3" primary>
            Save as Draft
          </KooperButton> */}
          <KooperButton primary onClick={handleSubmit(submitForm)}>
            {editId ? 'Update' : 'Create'}
          </KooperButton>
        </div>
      </div>

      <KooperForm className="errorLabel">
        <KooperContainer style={{width: '90%'}}>
          <KooperCard fluid>
            <KooperCardContent style={{padding: '18px'}}>
              <h3 className="mb-0">Expense Information</h3>
              <p className="mt-0 mb-4 card-description">Create expense by mentioning all the required information</p>

              <KooperGrid>
                <KooperGridRow>
                  <KooperGridColumn width="8">
                    <KooperFormField width="8">
                      <div className="info-header mb-1">
                        <label className="label-class">Expense Name</label>
                        <KooperTooltip
                          content="Mention name of the expense"
                          trigger={<SvgIcon path="common/question" />}
                          size="mini"
                          position="top center"
                        />
                      </div>
                      <Controller
                        name="name"
                        control={control}
                        render={({onChange, value}) => (
                          <KooperFormInput
                            maxLength={20}
                            type="text"
                            placeholder="Expense Name"
                            onChange={(e, {value}) => onChange(value)}
                            value={value}
                            error={errors.name && {content: removeDoubleQuotes(errors.name.message)}}
                          />
                        )}
                      />
                    </KooperFormField>

                    <KooperFormField width="8">
                      <div className="info-header mb-1">
                        <label className="label-class">Expense Date</label>
                        <KooperTooltip
                          content="Set the expense date"
                          trigger={<SvgIcon path="common/question" />}
                          size="mini"
                          position="top center"
                        />
                      </div>
                      <Controller
                        name="expenseDate"
                        render={({onChange, value}) => (
                          <KooperDatePicker
                            type="date"
                            value={typeof value === 'string' ? new Date(ExpenseDate) : ExpenseDate}
                            inputProps={{readOnly: true}}
                            time={false}
                            onChange={value => {
                              onChange(value)
                              setExpenseDate(value)
                            }}
                          />
                        )}
                        control={control}
                      />
                      {errors.expenseDate && (
                        <p className="errorLabelError">{removeDoubleQuotes(errors.expenseDate.message)}</p>
                      )}
                    </KooperFormField>

                    <KooperFormField width="8">
                      <div className="info-header mb-1">
                        <label>Document Number</label>
                        <KooperTooltip
                          content="Mention expense document number for reference"
                          trigger={<SvgIcon path="common/question" />}
                          size="mini"
                          position="top center"
                        />
                      </div>
                      <Controller
                        name="docNumber"
                        render={({value, onChange, onBlur}) => (
                          <KooperFormInput
                            fluid
                            type="text"
                            placeholder="docNumber.."
                            value={value}
                            onChange={e => {
                              onChange(e.target.value)
                            }}
                            onBlur={onBlur}
                            error={
                              errors?.docNumber && {
                                content: removeDoubleQuotes(errors?.docNumber?.message),
                              }
                            }
                          />
                        )}
                        control={control}
                      />
                    </KooperFormField>

                    <KooperFormField width="8" className="expense-file-input">
                      <label>Select Document</label>
                      <KooperAttachment
                        content={
                          <>
                            <SvgIcon path="common/attachment" /> Upload Attachment
                          </>
                        }
                        multiple
                        onChange={e => {
                          const data = {
                            file: e,
                          }
                          if (e.length > 0) {
                            dispatch(uploadAttachments(data, 'composeAttachment', requestToken))
                          }
                        }}
                        onClick={e => {
                          e.target.value = null
                        }}
                        fileList={files => {
                          setFiles(files)
                        }}
                      />
                      <AttachmentPreview
                        files={files}
                        uploadedFiles={uploadedFiles}
                        setUploadedFiles={setUploadedFiles}
                        success="composeAttachment"
                        cancelToken={requestToken}
                      />
                      <p className="kooper-lead pt-3">File upload section (Only pdf or docx support)</p>
                    </KooperFormField>
                  </KooperGridColumn>

                  <KooperGridColumn width="8">
                    <KooperFormField width="8">
                      <div className="info-header mb-1">
                        <label className="label-class">Employee</label>
                        <KooperTooltip
                          content="all expense is related to this employee"
                          trigger={<SvgIcon path="common/question" />}
                          size="mini"
                          position="top center"
                        />
                      </div>
                      <Controller
                        name="employeeId"
                        render={({onChange, value, onBlur}) => (
                          <KooperFormSelect
                            fluid
                            options={EmployeeOption}
                            selection
                            placeholder="Employee Id"
                            onBlur={onBlur}
                            value={value}
                            onChange={(e, {value}) => {
                              onChange(value)
                            }}
                            error={
                              errors?.employeeId && {
                                content: removeDoubleQuotes(errors?.employeeId?.message),
                              }
                            }
                          />
                        )}
                        control={control}
                      />
                    </KooperFormField>

                    <KooperFormField width="8">
                      <div className="info-header mb-1">
                        <label>Labels</label>
                        <KooperTooltip
                          content="label expenses for easy recognition"
                          trigger={<SvgIcon path="common/question" />}
                          size="mini"
                          position="top center"
                        />
                      </div>
                      <Controller
                        name="labelIds"
                        control={control}
                        render={({value, onChange}) => (
                          <KooperFormSelect
                            multiple
                            options={labelOptionsState}
                            value={value}
                            onChange={(e, {value}) => onChange(value)}
                          />
                        )}
                      />
                    </KooperFormField>

                    <KooperFormField>
                      <div className="info-header mb-1">
                        <label>Paid By</label>
                        <KooperTooltip
                          content="Select from whom the expense has been paid"
                          trigger={<SvgIcon path="common/question" />}
                          size="mini"
                          position="top center"
                        />
                      </div>
                      <Controller
                        name="paidBy"
                        control={control}
                        render={({value, onChange}) => (
                          <>
                            <KooperFormRadio
                              className="mb-2"
                              value="employee"
                              checked={value === 'employee'}
                              onChange={(e, {value}) => onChange(value)}
                              label="Employee (To be Reimbursed)"
                            />
                            <KooperFormRadio
                              value="company"
                              checked={value === 'company'}
                              onChange={(e, {value}) => onChange(value)}
                              label="Company"
                            />
                          </>
                        )}
                      />
                    </KooperFormField>

                    <KooperFormField width="8">
                      <div className="info-header mb-1">
                        <label>Projects</label>
                        <KooperTooltip
                          content="expense is related to this project"
                          trigger={<SvgIcon path="common/question" />}
                          size="mini"
                          position="top center"
                        />
                      </div>
                      <Controller
                        name="projectId"
                        render={({onChange, value, onBlur}) => (
                          <KooperFormSelect
                            fluid
                            options={ProjectOption}
                            selection
                            placeholder="Project Id"
                            onBlur={onBlur}
                            value={value}
                            onChange={(e, {value}) => {
                              onChange(value)
                            }}
                            error={
                              errors?.projectId && {
                                content: removeDoubleQuotes(errors?.projectId?.message),
                              }
                            }
                          />
                        )}
                        control={control}
                      />
                    </KooperFormField>

                    <KooperFormField width={8}>
                      <div className="info-header mb-1">
                        <label>Status</label>
                        <KooperTooltip
                          content="set status of the expense"
                          trigger={<SvgIcon path="common/question" />}
                          size="mini"
                          position="top center"
                        />
                      </div>
                      <Controller
                        name="status"
                        render={({onChange, value}) => (
                          <KooperDropdown
                            fluid
                            selection
                            options={expenseStatus}
                            placeholder="Status"
                            value={value}
                            onChange={(e, {value}) => {
                              onChange(value)
                            }}
                            error={
                              errors?.status && {
                                content: removeDoubleQuotes(errors?.status?.message),
                              }
                            }
                          />
                        )}
                        control={control}
                      />
                    </KooperFormField>
                  </KooperGridColumn>
                </KooperGridRow>
              </KooperGrid>
            </KooperCardContent>
          </KooperCard>

          <KooperCard fluid>
            <KooperCardContent style={{padding: '18px'}}>
              <h3 className="mb-0">Expense List</h3>
              <p className="mt-0 mb-4 card-description">
                Manage your expense list by setting currency and adding items
              </p>

              <KooperFormField width="2">
                <div className="info-header mb-1">
                  <label>Currency</label>
                  <KooperTooltip
                    content="Choose the currency type from the list"
                    trigger={<SvgIcon path="common/question" />}
                    size="mini"
                    position="top center"
                  />
                </div>
                <Controller
                  name="currencyId"
                  control={control}
                  render={({value, onChange}) => (
                    <KooperFormSelect
                      selection
                      fluid
                      search
                      placeholder="Select Currency"
                      options={currencyListData}
                      value={value}
                      onChange={(e, {value}) => onChange(value)}
                      error={
                        errors.currencyId && {
                          content: errors.currencyId.message,
                        }
                      }
                    />
                  )}
                />
              </KooperFormField>

              <KooperTable sortable basic selectable>
                <KooperTableHeader>
                  <KooperTableRow>
                    <KooperTableHeaderCell style={{width: 30}}>#</KooperTableHeaderCell>
                    <KooperTableHeaderCell>Category</KooperTableHeaderCell>
                    <KooperTableHeaderCell>Description</KooperTableHeaderCell>
                    <KooperTableHeaderCell>Price</KooperTableHeaderCell>
                    <KooperTableHeaderCell>Discount</KooperTableHeaderCell>
                    <KooperTableHeaderCell>Taxes</KooperTableHeaderCell>
                    <KooperTableHeaderCell>Total</KooperTableHeaderCell>
                  </KooperTableRow>
                </KooperTableHeader>
                <KooperTableBody>{FieldList()}</KooperTableBody>
              </KooperTable>

              <div style={{justifyContent: 'flex-end'}} className="d-flex pt-5">
                <div style={{borderRight: '1px solid #eee'}} className="pr-5 mr-5">
                  <span>Subtotal</span>
                  <h2 className="mt-0">{`${watchCurrencyId ? getCurrency() : ''} ${getSubTotal()}`}</h2>
                </div>
                <div style={{borderRight: '1px solid #eee'}} className="pr-5 mr-5">
                  <span>Sales Tax</span>
                  <h2 className="mt-0">{`${watchCurrencyId ? getCurrency() : ''} ${getTax()}`}</h2>
                </div>
                <div>
                  <span>Total</span>
                  <h1 className="mt-0">{`${watchCurrencyId ? getCurrency() : ''} ${getTotal()}`}</h1>
                </div>
              </div>
            </KooperCardContent>
          </KooperCard>
        </KooperContainer>

        {createNewLabel()}
      </KooperForm>
    </div>
  )
}

export default CreateExpense
