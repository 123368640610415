/* eslint-disable no-nested-ternary */
import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {Route, Switch, useHistory, useLocation, useParams} from 'react-router'
import {FiArchive} from 'react-icons/fi'
import {KooperDropdown, KooperDropdownItem, KooperDropdownMenu, KooperRating, KooperTab, KooperTabPane, KooperTooltip} from 'kooper-library'

import {
  DELETE_PROJECT,
  FAVOURITE_PROJECT,
  UPDATE_PROJECT,
  UPDATE_PROJECT_PEOPLE,
  UPDATE_PROJECT_STATUS,
} from 'actions/types'
import {
  cloneProject,
  deleteProject,
  favouriteProject,
  getProjectsList,
  getSingleProject,
  updateProjectStatus,
} from 'actions/projects'
import DeleteModal from 'components/common/DeleteModal'
import {get, getPermissionTooltipContent, startCase} from 'utils/helper'
import UnSavedWrap from 'components/hoc/UnSavedWrap'
import useUserPermissions from 'hooks/pure/useUserPermissions'
import {useRouteMatch} from 'react-router-dom/cjs/react-router-dom.min'
import {AiOutlineCheck} from 'react-icons/ai'
import SvgIcon from 'components/common/SvgIcon'
import useApiResponse from 'hooks/impure/useApiResponse'
import ProjectModal from './EditProjectModal'
import Discussions from './Steps/Discussion/Discussion'
import ManagePeopleModal from './Steps/ManagePeopleModal'
import Overview from './Steps/Overview'
import Tasks from './Steps/Tasks/Tasks'
import Notes from './Steps/Notes/Notes'
import Time from './Steps/Time/Time'
import ProjectFinance from './Steps/Finance/ProjectFinance'
import ProofsList from '../Proofs/ProofsList'
import RisksList from './Steps/Risks/RisksList'
import MilestonesList from './Steps/Milestones/MilestonesList'
import MilestoneView from './Steps/Milestones/MilestoneView'
import FormsList from './Steps/Forms/FormsList'

const steps = [
  {name: 'Overview', path: 'overview', icon: 'folder'},
  {name: 'Discussions', path: 'discussions', icon: 'talk'},
  {name: 'Tasks', path: 'tasks', icon: 'tasks'},
  {name: 'Notes', path: 'notes', icon: 'sticky note'},
  {name: 'Time', path: 'time', icon: 'clock'},
  {name: 'Finance', path: 'finance', icon: 'money bill alternate'},
  {name: 'Proofs', path: 'proofs', icon: 'edit outline icon'},
  {name: 'Risks', path: 'risks', icon: 'info circle'},
  {name: 'Milestones', path: 'milestones', icon: 'check circle'},
  {name: 'Forms', path: 'forms', icon: 'sticky note'},
]

const InsideSteps = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const {projectId} = useParams()
  const {pathname} = useLocation()
  const {url, path} = useRouteMatch()

  const currentPage = pathname.split('/')[3]
  const [isManagePeopleOpen, setIsManagePeopleOpen] = useState(false)
  const [isEditModalOpen, setIsEditModalOpen] = useState(false)
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)

  const {successLabels = []} = useSelector(state => state.apiReducer)
  const {projectData = {}} = useSelector(state => state.projects)
  const {projectsPermissions} = useUserPermissions()
  const manageProjectsPermissions = projectsPermissions?.manage_projects

  const {callAction: callGetSingleProjectAction} = useApiResponse({
    action: getSingleProject,
    payload: projectId,
    enabled: projectId,
    dependency: [projectId],
  })

  useEffect(() => {
    dispatch(getProjectsList())
  }, [dispatch])

  useEffect(() => {
    if (
      successLabels.includes(FAVOURITE_PROJECT) ||
      successLabels.includes(UPDATE_PROJECT_PEOPLE) ||
      successLabels.includes(UPDATE_PROJECT) ||
      successLabels.includes(UPDATE_PROJECT_STATUS)
    ) {
      callGetSingleProjectAction()
    }
  }, [successLabels, callGetSingleProjectAction])

  useEffect(() => {
    if (successLabels.includes(DELETE_PROJECT)) {
      setIsDeleteModalOpen(false)
      history.push('/projects')
    }
  }, [successLabels, history])

  return (
    <>
      <div className="project-steps-container">
        <KooperTab
          key={currentPage}
          panes={steps.map(item => ({
            menuItem: {
              key: item.name,
              icon: item.icon,
              content: item.name,
            },
            render: null,
          }))}
          menu={{color: 'blue', secondary: true, pointing: true}}
          activeIndex={steps.findIndex(item => item.path === currentPage)}
          onTabChange={(e, {activeIndex}) => {
            history.push(`${url}/${steps[activeIndex].path}`)
          }}
        />

        <Switch>
          <Route
            path={`${path}/overview`}
            exact
            render={props => (
              <KooperTabPane>
                <Overview {...props} />
              </KooperTabPane>
            )}
          />
          <Route
            path={`${path}/discussions`}
            exact
            render={props => (
              <KooperTabPane>
                <Discussions {...props} rolePermissions={manageProjectsPermissions} />
              </KooperTabPane>
            )}
          />
          <Route
            path={`${path}/tasks`}
            exact
            render={props => (
              <KooperTabPane>
                <Tasks {...props} rolePermissions={manageProjectsPermissions} />
              </KooperTabPane>
            )}
          />
          <Route
            path={`${path}/notes`}
            exact
            render={props => (
              <KooperTabPane>
                <UnSavedWrap>
                  <Notes {...props} rolePermissions={manageProjectsPermissions} />
                </UnSavedWrap>
              </KooperTabPane>
            )}
          />
          <Route
            path={`${path}/time`}
            exact
            render={() => (
              <KooperTabPane>
                <Time rolePermissions={manageProjectsPermissions} />
              </KooperTabPane>
            )}
          />
          <Route
            path={`${path}/finance`}
            render={() => (
              <KooperTabPane>
                <ProjectFinance />
              </KooperTabPane>
            )}
          />
          <Route
            path={`${path}/proofs`}
            render={() => (
              <KooperTabPane>
                <ProofsList projectId={projectId} />
              </KooperTabPane>
            )}
          />
          <Route
            path={`${path}/risks`}
            render={() => (
              <KooperTabPane>
                <RisksList projectId={projectId} />
              </KooperTabPane>
            )}
          />
          <Route
            exact
            path={`${path}/milestones`}
            render={() => (
              <KooperTabPane>
                <MilestonesList projectId={projectId} />
              </KooperTabPane>
            )}
          />
          <Route
            path={`${path}/milestones/:id`}
            render={() => (
              <KooperTabPane>
                <MilestoneView projectId={projectId} />
              </KooperTabPane>
            )}
          />
          <Route
            exact
            path={`${path}/forms`}
            render={() => (
              <KooperTabPane>
                <FormsList projectId={projectId} />
              </KooperTabPane>
            )}
          />
        </Switch>

        <div className="d-flex project-steps-title">
          <KooperTooltip
            content={
              manageProjectsPermissions
                ? get(['isFavourite'], projectData, false)
                  ? 'Favorite'
                  : 'Not favorite'
                : getPermissionTooltipContent({roleAccess: manageProjectsPermissions})
            }
            size="mini"
            position="top center"
            trigger={
              <KooperRating
                icon="star"
                rating={get(['isFavourite'], projectData, false) ? 1 : 0}
                size="large"
                onClick={() => {
                  if (!manageProjectsPermissions) return
                  const data = {
                    isFavourite: !projectData.isFavourite,
                  }
                  dispatch(favouriteProject(projectData.id, data))
                }}
              />
            }
          />
          <h5 className="d-flex">
            {startCase(get(['title'], projectData, ''))}
            {manageProjectsPermissions && (
              <KooperDropdown className="project-name-dropdown" icon="chevron down" direction="right">
                <KooperDropdownMenu>
                  <KooperDropdownItem onClick={() => setIsManagePeopleOpen(true)}>
                    <SvgIcon path="settings/users" /> Manage Users
                  </KooperDropdownItem>
                  <KooperDropdownItem onClick={() => setIsEditModalOpen(true)}>
                    <SvgIcon path="common/edit2" /> Edit
                  </KooperDropdownItem>
                  <KooperDropdownItem
                    onClick={() => {
                      setIsDeleteModalOpen(true)
                    }}
                  >
                    <SvgIcon path="common/delete" /> Delete
                  </KooperDropdownItem>
                  <KooperDropdownItem
                    onClick={() => {
                      let data
                      if (projectData.status === 'active') {
                        data = {status: 'archived'}
                      } else {
                        data = {status: 'active'}
                      }
                      dispatch(updateProjectStatus(projectId, data))
                    }}
                  >
                    {['active', 'inactive'].includes(projectData.status) ? (
                      <>
                        <FiArchive /> Archive
                      </>
                    ) : (
                      <>
                        <AiOutlineCheck /> Unarchive
                      </>
                    )}
                  </KooperDropdownItem>
                  <KooperDropdownItem onClick={() => dispatch(cloneProject(projectId))}>
                    <SvgIcon path="common/clone" /> Create a copy
                  </KooperDropdownItem>
                </KooperDropdownMenu>
              </KooperDropdown>
            )}
          </h5>
        </div>
      </div>

      {isManagePeopleOpen && (
        <ManagePeopleModal open={isManagePeopleOpen} toggle={setIsManagePeopleOpen} projectId={projectId} />
      )}
      {isEditModalOpen && <ProjectModal open={isEditModalOpen} toggle={setIsEditModalOpen} id={projectId} />}
      {isDeleteModalOpen && (
        <DeleteModal
          isModalOpen={isDeleteModalOpen}
          setIsModalOpen={setIsDeleteModalOpen}
          onDelete={() => dispatch(deleteProject({id: [projectId]}))}
          type={DELETE_PROJECT}
        />
      )}
    </>
  )
}

export default InsideSteps
