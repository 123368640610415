import React from 'react'
import {useHistory, useRouteMatch} from 'react-router-dom'
import {KooperMenu, KooperMenuItem} from 'kooper-library'
import {isActiveNavlink} from 'utils/helper'
import SvgIcon from 'components/common/SvgIcon'

const ProjectFinanceMenu = () => {
  const history = useHistory()
  const {url} = useRouteMatch()

  const menus = [
    {
      path: '/summary',
      title: 'Summary',
      icon: <SvgIcon path="finance/summary" />,
    },
    {
      path: '/budgets',
      title: 'Budgets',
      icon: <SvgIcon path="finance/budgets" />,
    },
    {
      path: '/rates',
      title: 'Rates',
      icon: <SvgIcon path="finance/rates" />,
    },
    {
      path: '/billing',
      title: 'Billing',
      icon: <SvgIcon path="finance/billing" />,
    },
  ]

  return (
    <KooperMenu vertical secondary className="projects-side-menu kooper-menu">
      {menus.map(({path, title, icon}) => (
        <KooperMenuItem key={path} active={isActiveNavlink(`${url}${path}`)} onClick={() => history.push(`${url}${path}`)}>
          {icon} {title}
        </KooperMenuItem>
      ))}
    </KooperMenu>
  )
}

export default ProjectFinanceMenu
