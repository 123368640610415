/* eslint-disable camelcase */
import React, {useRef, useState, useEffect, useCallback} from 'react'
import {joiResolver} from '@hookform/resolvers'
import {useSelector, useDispatch} from 'react-redux'
import {useHistory, useParams} from 'react-router'
import {useForm} from 'react-hook-form'
import _ from 'lodash'
import {KooperButton, KooperTab} from 'kooper-library'

import {GET_CAMPAIGN_DATA, UPDATE_CAMPAIGN} from 'actions/types'
import {getEmailCampaignData, updateEmailCampaign} from 'actions/campaign'
import {getCampaignTemplateList} from 'actions/settings_productivity'
import {extractTextContentFromHTML, get} from 'utils/helper'
import {oneTimeEmailSchema} from 'validation/Marketing/campaign.schema'
import Configuration from './OneTimeEmailForm/Configuration'
import UserAudience from './OneTimeEmailForm/UserAudience'
import Messages from './OneTimeEmailForm/Messages'

const steps = [
  {name: 'Configuration', icon: 'cogs'},
  {name: 'User Audience', icon: 'users'},
  {name: 'Messages', icon: 'comments'},
]

const OneTimeEmailTab = props => {
  const {setWarningModal} = props
  const {id} = useParams()
  const dispatch = useDispatch()
  const history = useHistory()
  const editorRef = useRef()

  const [activeIndex, setActiveIndex] = useState(0)
  const [campaignBy, setCampaignBy] = useState('bySegment')
  const [url, setUrl] = useState('')
  const [totalCsvContacts, setTotalCsvContacts] = useState(0)

  const {isLoadingData, successLabels = [], errorLabels = []} = useSelector(state => state.apiReducer)
  const {campaignData = {}} = useSelector(state => state.campaign)

  const initialValue = {
    name: get(['name'], campaignData, ''),
    userId: Number(get(['userId'], campaignData, 0)),
    type: Number(get(['type'], campaignData, 1)),
    campaign_email: campaignData?.campaign_email || {},
    timezone: campaignData?.timezone || new Date(),
    scheduleWhen: campaignData?.scheduleWhen || 'later',
    scheduleTime: campaignData?.scheduleTime ? new Date(campaignData?.scheduleTime) : new Date(),
    emailSMTPId: campaignData?.emailSMTPId,
    campaignBy: '',
    segmentIds: campaignData?.segmentIds || [],
    labelBy: campaignData?.labelBy || '',
    labelIds: campaignData?.labelIds || [],
    csv: null,
    labelByCsv: campaignData?.labelByCsv || '',
  }

  const {
    handleSubmit,
    control,
    reset,
    errors,
    clearErrors,
    setValue,
    watch,
    formState: {isDirty},
  } = useForm({
    mode: 'onTouched',
    shouldUnregister: false,
    shouldFocusError: true,
    reValidateMode: 'onChange',
    resolver: joiResolver(oneTimeEmailSchema),
    defaultValues: initialValue,
  })

  useEffect(() => {
    if (id) {
      dispatch(getEmailCampaignData(id))
    }
  }, [dispatch, id])

  useEffect(() => {
    dispatch(getCampaignTemplateList())
  }, [dispatch])

  useEffect(() => {
    if (successLabels.includes(GET_CAMPAIGN_DATA)) {
      const data = {
        name: get(['name'], campaignData, ''),
        userId: Number(get(['userId'], campaignData, 0)),
        type: Number(get(['type'], campaignData, 1)),
        campaign_email: _.omit(get(['campaign_email'], campaignData, {}), ['bodyHtml', 'bodyText', 'textDesign']),
        timezone: campaignData?.timezone,
        scheduleWhen: campaignData?.scheduleWhen,
        scheduleTime: new Date(campaignData?.scheduleTime),
        emailSMTPId: campaignData?.emailSMTPId,
        segmentIds: campaignData?.segmentIds || [],
        labelBy: campaignData?.labelBy || '',
        labelIds: campaignData?.labelIds || [],
        csv: campaignData.url,
        labelByCsv: campaignData?.labelByCsv || '',
      }
      setCampaignBy(campaignData?.campaignBy)
      if (campaignData.url && campaignData.fileName) {
        setUrl(campaignData.url)
      }
      if (campaignData.contactIds) setTotalCsvContacts(campaignData.contactIds.length)

      reset({...data, campaignBy: campaignData?.campaignBy})
    }
  }, [campaignData, reset, successLabels])

  useEffect(() => {
    if (errorLabels.includes(GET_CAMPAIGN_DATA)) {
      setWarningModal(false)
      history.push(`/campaigns`)
    }
  }, [errorLabels, history, props, setWarningModal])

  useEffect(() => {
    if (successLabels.includes(UPDATE_CAMPAIGN)) {
      history.push(`/campaigns`)
    }
  }, [history, setWarningModal, successLabels])

  const handleUnSaved = useCallback(
    _isDirty1 => {
      if (_isDirty1) {
        setWarningModal(true)
      } else {
        setWarningModal(false)
      }
    },
    [setWarningModal]
  )

  useEffect(() => {
    handleUnSaved(isDirty)
    return () => {
      setWarningModal(false)
    }
  }, [handleUnSaved, isDirty, setWarningModal])

  const submitData = data => {
    setWarningModal(false)

    const campaign_email = {...data?.campaign_email}
    editorRef?.current?.editor?.exportHtml(({design, html}) => {
      campaign_email.textDesign = JSON.stringify(design)
      campaign_email.bodyHtml = html
      campaign_email.bodyText = extractTextContentFromHTML(html)
      campaign_email.name = data.name

      const payload = _.omitBy(data, value => !value)
      if (payload.campaignBy === 'bySegment') {
        delete payload.labelIds
        delete payload.labelBy
        delete payload.csv
        delete payload.labelByCsv
      }
      if (payload.campaignBy === 'byTag') {
        delete payload.segmentIds
        delete payload.csv
        delete payload.labelByCsv
      }
      if (payload.campaignBy === 'byCsv') {
        delete payload.segmentIds
        delete payload.labelIds
        delete payload.labelBy
      }

      if (campaignData.id) {
        if (payload.csv) {
          dispatch(
            updateEmailCampaign(
              campaignData.id,
              {
                ...payload,
                campaign_email: JSON.stringify(campaign_email),
                type: campaignData.type,
                status: Boolean(campaignData.status),
                emailSMTPId: 5,
              },
              'multipart/form-data'
            )
          )
        } else {
          dispatch(
            updateEmailCampaign(campaignData.id, {
              ...payload,
              campaign_email,
              type: campaignData.type,
              status: Boolean(campaignData.status),
              emailSMTPId: 5,
            })
          )
        }
      }
    })
  }

  return (
    <div className="kooper-full-page">
      <div className="campaignNavbar">
        <KooperButton basic inverted onClick={() => history.push('/campaigns')}>
          Exit
        </KooperButton>

        <KooperTab
          key={activeIndex}
          panes={steps.map((item, i) => ({
            menuItem: {
              key: item.name,
              icon: item.icon,
              content: item.name,
            },
            render: null,
          }))}
          menu={{
            secondary: true,
            pointing: true,
            color: 'blue',
          }}
          activeIndex={activeIndex}
          onTabChange={(e, {activeIndex}) => {
            setActiveIndex(activeIndex)
          }}
          renderActiveOnly={false}
        />

        <KooperButton primary loading={isLoadingData} disabled={isLoadingData} onClick={handleSubmit(submitData)}>
          Save
        </KooperButton>
      </div>

      {activeIndex === 0 && <Configuration control={control} errors={errors} clearErrors={clearErrors} watch={watch} />}

      {activeIndex === 1 && (
        <UserAudience
          control={control}
          errors={errors}
          clearErrors={clearErrors}
          watch={watch}
          setValue={setValue}
          campaignBy={campaignBy}
          setCampaignBy={setCampaignBy}
          url={url}
          setUrl={setUrl}
          totalCsvContacts={totalCsvContacts}
          setTotalCsvContacts={setTotalCsvContacts}
          editId={id}
        />
      )}

      {activeIndex === 2 && (
        <Messages
          {...props}
          control={control}
          errors={errors}
          editorRef={editorRef}
          editorDefaultValue={campaignData?.campaign_email?.textDesign}
        />
      )}
    </div>
  )
}

export default OneTimeEmailTab
