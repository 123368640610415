/* eslint-disable no-unneeded-ternary */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/alt-text */
import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {Controller, useFieldArray, useForm} from 'react-hook-form'
import {joiResolver} from '@hookform/resolvers'
import {FaRegCalendarAlt} from 'react-icons/fa'
import {MdCheckCircle} from 'react-icons/md'
import _ from 'lodash'
import {
  KooperButton,
  KooperButtonGroup,
  KooperCard,
  KooperCardContent,
  KooperCheckbox,
  KooperForm,
  KooperFormCheckbox,
  KooperFormField,
  KooperFormGroup,
  KooperFormInput,
  KooperFormSelect,
  KooperFormTextArea,
  KooperGrid,
  KooperGridColumn,
  KooperImage,
  KooperInput,
  KooperLabel,
  KooperPopup,
  KooperRadio,
  KooperTooltip,
} from 'kooper-library'
import {UPDATE_APPEARENCE_WIDGET} from 'actions/types'
import {GetAppearenceWidget, GetInstallationWidget, UpdateAppearenceWidget} from 'actions/settings_messenger'
import SettingLayout from 'layouts/settingLayout'
import {getUserInfo} from 'utils/local-storage'
import wgt1 from 'assets/wgt1.svg'
import wgt2 from 'assets/wgt2.svg'
import wgt3 from 'assets/wgt3.svg'
import wgt4 from 'assets/wgt4.svg'
import wgt5 from 'assets/wgt5.svg'
import user from 'assets/user.jpg'
import logo from 'assets/omni24-full-logo.png'
import {get, removeDoubleQuotes} from 'utils/helper'
import {appearenceDefaultValue, appearenceSchema} from 'validation/Settings/messenger.schema'
import useUserPermissions from 'hooks/pure/useUserPermissions'
import useLearnMoreUrl from 'hooks/pure/useLearnMoreUrl'
import SvgIcon from 'components/common/SvgIcon'

const Appearance = ({setWarningModal}) => {
  const dispatch = useDispatch()
  const accountId = Number(getUserInfo().id)

  const {
    handleSubmit,
    control,
    watch,
    reset,
    errors,
    formState: {isDirty},
  } = useForm({
    mode: 'onTouched',
    shouldFocusError: true,
    reValidateMode: 'onChange',
    submitFocusError: true,
    shouldUnregister: false,
    resolver: joiResolver(appearenceSchema),
    defaultValues: appearenceDefaultValue,
  })

  const {
    fields: quickLinks,
    append,
    remove,
  } = useFieldArray({
    name: 'quickLinks',
    control,
  })

  const buttonIcon = watch('buttonIcon')
  const brandColor = watch('brandColor')
  const position = watch('position')
  const spacingRight = watch('spacingRight')
  const spacingBottom = watch('spacingBottom')
  const showMeeting = watch('showMeeting')
  const showWhatsapp = watch('showWhatsapp')
  const whatsappMsg = watch('whatsappMsg')
  const showFb = watch('showFb')
  const fbMsg = watch('fbMsg')
  const showInsta = watch('showInsta')
  const instaMsg = watch('instaMsg')
  const showQuickLinks = watch('showQuickLinks')

  const [colorType, setColorType] = useState('Gradient')

  const {successLabels = []} = useSelector(store => store.apiReducer)
  const {installation, appearence} = useSelector(store => store.settingsMessenger)
  const {settingsLearnMoreUrl} = useLearnMoreUrl()
  const appearanceLearnMore = settingsLearnMoreUrl?.messenger?.appearance?.learnMore

  const {channelsPermissions} = useUserPermissions()
  const manageMessangerPagesPermissions = channelsPermissions?.manage_messanger_pages

  useEffect(() => {
    if (!(installation && installation[0])) dispatch(GetInstallationWidget())
  }, [dispatch, installation])

  useEffect(() => {
    if (installation && installation[0]) {
      dispatch(GetAppearenceWidget(installation[0].id))
    }
  }, [dispatch, installation])

  useEffect(() => {
    if (appearence && appearence.id) {
      const data = {
        brandColor: appearence.brandColor
          ? JSON.parse(appearence.brandColor)
          : {
              color: 'linear-gradient(to right,#5eabff,#4c9df9,#3a90f3,#2782ec,#0d74e5)',
              primaryColor: '#0d74e5',
            },
        buttonIcon: get(['buttonIcon'], appearence, '1'),
        position: get(['position'], appearence, 'right'),
        disableChat: get(['disableChat'], appearence, false),
        showMeeting: get(['showMeeting'], appearence, false),
        spacingBottom: get(['spacingBottom'], appearence, 20),
        spacingRight: get(['spacingRight'], appearence, 20),
        showWhatsapp: get(['showWhatsapp'], appearence, false),
        whatsappLink: get(['whatsappLink'], appearence, ''),
        whatsappMsg: get(['whatsappMsg'], appearence, ''),
        showFb: get(['showFb'], appearence, false),
        fbLink: get(['fbLink'], appearence, ''),
        fbMsg: get(['fbMsg'], appearence, ''),
        showInsta: get(['showInsta'], appearence, false),
        instaLink: get(['instaLink'], appearence, ''),
        instaMsg: get(['instaMsg'], appearence, ''),
        showQuickLinks: get(['showQuickLinks'], appearence, false),
        // quickLinksHeading: get(['quickLinksHeading'], appearence, 'Useful Links'),
        quickLinks: appearence.quickLinks ? JSON.parse(appearence.quickLinks) : [{name: '', url: ''}],
      }
      reset(data)
    } else {
      reset(appearenceDefaultValue)
    }
  }, [appearence, reset])

  useEffect(() => {
    if (successLabels.includes(UPDATE_APPEARENCE_WIDGET)) {
      setWarningModal(false)
    }
  }, [successLabels, setWarningModal])

  useEffect(() => {
    if (isDirty) {
      setWarningModal(true)
    }
    return () => setWarningModal(false)
  }, [isDirty, setWarningModal])

  const resetData = () => {
    if (appearence && appearence.id) {
      const data = {
        brandColor: appearence.brandColor
          ? JSON.parse(appearence.brandColor)
          : {
              color: 'linear-gradient(to right,#5eabff,#4c9df9,#3a90f3,#2782ec,#0d74e5)',
              primaryColor: '#0d74e5',
            },
        buttonIcon: get(['buttonIcon'], appearence, '1'),
        position: get(['position'], appearence, 'right'),
        spacingRight: get(['spacingRight'], appearence, 20),
        spacingBottom: get(['spacingBottom'], appearence, 20),
        disableChat: get(['disableChat'], appearence, false),
        showWhatsapp: get(['showWhatsapp'], appearence, false),
        whatsappLink: get(['whatsappLink'], appearence, ''),
        whatsappMsg: get(['whatsappMsg'], appearence, ''),
        showFb: get(['showFb'], appearence, false),
        fbLink: get(['fbLink'], appearence, ''),
        fbMsg: get(['fbMsg'], appearence, ''),
        showInsta: get(['showInsta'], appearence, false),
        instaLink: get(['instaLink'], appearence, ''),
        instaMsg: get(['instaMsg'], appearence, ''),
        showQuickLinks: get(['showQuickLinks'], appearence, false),
        // quickLinksHeading: get(['quickLinksHeading'], appearence, 'Useful Links'),
        quickLinks: appearence.quickLinks ? JSON.parse(appearence.quickLinks) : [{name: '', url: ''}],
      }
      reset(data)
    } else {
      reset(appearenceDefaultValue)
    }
  }

  const updateData = data => {
    data.brandColor = JSON.stringify(data.brandColor)
    if (data.quickLinks) {
      data.quickLinks = JSON.stringify(data.quickLinks)
    }

    if (appearence.id) dispatch(UpdateAppearenceWidget(appearence.id, data))
    dispatch({type: UPDATE_APPEARENCE_WIDGET, data})
    setWarningModal(false)
  }

  const gradientColors = {
    grad1: {
      color: 'linear-gradient(to right,#5eabff,#4c9df9,#3a90f3,#2782ec,#0d74e5)',
      primaryColor: '#0d74e5',
    },
    grad2: {
      color: 'linear-gradient(-115deg, #20e2d7 0%, #f9fea5 100%)',
      primaryColor: '#20e2d7',
    },
    grad3: {
      color: 'linear-gradient(120deg, #f6d365 0%, #fda085 100%)',
      primaryColor: '#fda085',
    },
    grad4: {
      color: 'linear-gradient(to left, #09203f 0%, #537895 100%)',
      primaryColor: '#537895',
    },
    grad5: {
      color: 'linear-gradient(-255deg,#ac32e4 0%, #7918f2 48%,#4801ff 100%)',
      primaryColor: '#4801ff',
    },
    grad6: {
      color: 'linear-gradient(-255deg,#a445b2 0%,#d41872 52%,#ff0066 100%)',
      primaryColor: '#f06',
    },
    grad7: {
      color: 'linear-gradient(to right, #4facfe 0%, #00f2fe 100%)',
      primaryColor: '#00f2fe',
    },
    grad8: {
      color: 'linear-gradient(to right,#f78ca0 0%,#f9748f 19%,#fd868c 60%,#fe9a8b 100%)',
      primaryColor: '#f78ca0 ',
    },
  }

  const solidColor = {
    sol1: {color: '#2196f3', primaryColor: '#2196f3'},
    sol2: {color: '#f44336', primaryColor: '#f44336'},
    sol3: {color: '#e91e63', primaryColor: '#e91e63'},
    sol4: {color: '#9c27b0', primaryColor: '#9c27b0'},
    sol5: {color: '#3fb1b5', primaryColor: '#3fb1b5'},
    sol6: {color: '#3F51B5', primaryColor: '#3F51B5'},
    sol7: {color: '#ff9800', primaryColor: '#ff9800'},
    sol8: {color: '#000', primaryColor: '#000'},
  }

  const renderColors = () => {
    if (colorType === 'Gradient') {
      return (
        <Controller
          control={control}
          name="brandColor"
          render={({onChange, value}) => (
            <div className="gredColor">
              <div id="color1" className="colorBox greCol01" onClick={() => onChange(gradientColors.grad1)}>
                {value.color === gradientColors.grad1.color && <MdCheckCircle className="active" />}
              </div>
              <div id="color2" className="colorBox greCol02" onClick={() => onChange(gradientColors.grad2)}>
                {value.color === gradientColors.grad2.color && <MdCheckCircle className="active" />}
              </div>
              <div id="color3" className="colorBox greCol03" onClick={() => onChange(gradientColors.grad3)}>
                {value.color === gradientColors.grad3.color && <MdCheckCircle className="active" />}
              </div>
              <div id="color4" className="colorBox greCol04" onClick={() => onChange(gradientColors.grad4)}>
                {value.color === gradientColors.grad4.color && <MdCheckCircle className="active" />}
              </div>
              <div id="color5" className="colorBox greCol05" onClick={() => onChange(gradientColors.grad5)}>
                {value.color === gradientColors.grad5.color && <MdCheckCircle className="active" />}
              </div>
              <div id="color6" className="colorBox greCol06" onClick={() => onChange(gradientColors.grad6)}>
                {value.color === gradientColors.grad6.color && <MdCheckCircle className="active" />}
              </div>
              <div id="color7" className="colorBox greCol07" onClick={() => onChange(gradientColors.grad7)}>
                {value.color === gradientColors.grad7.color && <MdCheckCircle className="active" />}
              </div>
              <div id="color8" className="colorBox greCol08" onClick={() => onChange(gradientColors.grad8)}>
                {value.color === gradientColors.grad8.color && <MdCheckCircle className="active" />}
              </div>
            </div>
          )}
        />
      )
    }
    return (
      <Controller
        control={control}
        name="brandColor"
        render={({onChange, value}) => (
          <div className="gredColor">
            <div id="color1" className="colorBox solCol01" onClick={() => onChange(solidColor.sol1)}>
              {value.color === solidColor.sol1.color && <MdCheckCircle className="active" />}
            </div>
            <div id="color2" className="colorBox solCol02" onClick={() => onChange(solidColor.sol2)}>
              {value.color === solidColor.sol2.color && <MdCheckCircle className="active" />}
            </div>
            <div id="color3" className="colorBox solCol03" onClick={() => onChange(solidColor.sol3)}>
              {value.color === solidColor.sol3.color && <MdCheckCircle className="active" />}
            </div>
            <div id="color4" className="colorBox solCol04" onClick={() => onChange(solidColor.sol4)}>
              {value.color === solidColor.sol4.color && <MdCheckCircle className="active" />}
            </div>
            <div id="color5" className="colorBox solCol05" onClick={() => onChange(solidColor.sol5)}>
              {value.color === solidColor.sol5.color && <MdCheckCircle className="active" />}
            </div>
            <div id="color6" className="colorBox solCol06" onClick={() => onChange(solidColor.sol6)}>
              {value.color === solidColor.sol6.color && <MdCheckCircle className="active" />}
            </div>
            <div id="color7" className="colorBox solCol07" onClick={() => onChange(solidColor.sol7)}>
              {value.color === solidColor.sol7.color && <MdCheckCircle className="active" />}
            </div>
            <div id="color8" className="colorBox solCol08" onClick={() => onChange(solidColor.sol8)}>
              {value.color === solidColor.sol8.color && <MdCheckCircle className="active" />}
            </div>
            <div className="cusSolPic">
              <KooperFormInput
                type="color"
                name="color"
                id="color"
                value={value.color}
                onChange={e =>
                  onChange({
                    color: e.target.value,
                    primaryColor: e.target.value,
                  })
                }
              />
            </div>
          </div>
        )}
      />
    )
  }

  return (
    <SettingLayout
      fullWidth
      headerDivider
      icon={<SvgIcon path="settings/appearance" />}
      header="Appearance"
      subHeader="Customize your chat messenger appearance and give a brand look"
      learnMoreUrl={appearanceLearnMore}
      actionButton={{
        cancel: {
          content: 'Reset',
          onClick: resetData,
        },
        success: {
          content: 'Save',
          disabled: !isDirty,
          ...(manageMessangerPagesPermissions && {
            onClick: handleSubmit(updateData),
          }),
        },
      }}
      lockRole={!manageMessangerPagesPermissions}
    >
      <KooperGrid>
        <KooperGridColumn width={8}>
          <KooperForm className="errorLabel">
            <Controller
              control={control}
              name="buttonIcon"
              render={({onChange, value}) => (
                <KooperFormField
                  error={
                    errors.buttonIcon && {
                      content: errors.buttonIcon.message,
                    }
                  }
                >
                  <label>Button Icon</label>
                  <KooperButton
                    style={{
                      backgroundColor: brandColor.primaryColor,
                      boxShadow: `0 1px 2px ${brandColor.primaryColor}`,
                    }}
                    value="1"
                    onClick={(e, {value}) => onChange(value)}
                    className={`wgtBtn ${value === '1' && `active`}`}
                  >
                    <img src={wgt1} />
                  </KooperButton>
                  <KooperButton
                    style={{
                      backgroundColor: brandColor.primaryColor,
                      boxShadow: `0 1px 2px ${brandColor.primaryColor}`,
                    }}
                    value="2"
                    className={`wgtBtn ${value === '2' && `active`}`}
                    onClick={(e, {value}) => onChange(value)}
                  >
                    <img src={wgt2} />
                  </KooperButton>
                  <KooperButton
                    style={{
                      backgroundColor: brandColor.primaryColor,
                      boxShadow: `0 1px 2px ${brandColor.primaryColor}`,
                    }}
                    className={`wgtBtn ${value === '3' && `active`}`}
                    value="3"
                    onClick={(e, {value}) => onChange(value)}
                  >
                    <img src={wgt3} />
                  </KooperButton>
                  <KooperButton
                    style={{
                      backgroundColor: brandColor.primaryColor,
                      boxShadow: `0 1px 2px ${brandColor.primaryColor}`,
                    }}
                    className={`wgtBtn ${value === '4' && `active`} wgtBtn4`}
                    value="4"
                    onClick={(e, {value}) => onChange(value)}
                  >
                    <img src={wgt4} />
                  </KooperButton>
                  <KooperButton
                    style={{
                      backgroundColor: brandColor.primaryColor,
                      boxShadow: `0 1px 2px ${brandColor.primaryColor}`,
                    }}
                    className={`wgtBtn ${value === '5' && `active`} wgtBtn5`}
                    value="5"
                    onClick={(e, {value}) => onChange(value)}
                  >
                    <img src={wgt5} />
                  </KooperButton>
                </KooperFormField>
              )}
            />
            <KooperFormField>
              <Controller
                control={control}
                name="position"
                render={({onChange, value}) => (
                  <KooperFormField
                    error={
                      errors.position && {
                        content: errors.position.message,
                      }
                    }
                  >
                    <label>Button Icon Position</label>
                    <p className="kooper-lead">Position of the messenger icon button on your web page</p>
                    <KooperRadio
                      label="Left"
                      name="position"
                      value="left"
                      onChange={(e, {value}) => onChange(value)}
                      checked={value === 'left'}
                      className="mr-4"
                    />
                    <KooperRadio
                      label="Right"
                      name="position"
                      value="right"
                      onChange={(e, {value}) => onChange(value)}
                      checked={value === 'right'}
                    />
                  </KooperFormField>
                )}
              />
              <KooperGrid>
                <KooperGridColumn style={{width: '230px'}}>
                  <Controller
                    control={control}
                    name="spacingRight"
                    render={({onChange, value}) => (
                      <KooperFormInput
                        fluid
                        labelPosition="right"
                        type="number"
                        placeholder="40"
                        error={
                          errors.spacingRight && {
                            content: `${
                              position === 'right' ? 'rightSpacing' : 'leftSpacing '
                            }  must be less than or equal to 40`,
                          }
                        }
                      >
                        <KooperLabel basic>
                          {position === 'right' ? 'Right ' : 'Left '}
                          Spacing
                        </KooperLabel>
                        <input
                          type="text"
                          value={value}
                          onChange={(e, {value}) => onChange(value)}
                          onKeyPress={e => (e.key === 'Enter' ? e.preventDefault() : null)}
                        />
                        <KooperLabel className="px-3 basic">px</KooperLabel>
                      </KooperFormInput>
                    )}
                  />
                </KooperGridColumn>
                <KooperGridColumn style={{width: '240px'}}>
                  <Controller
                    control={control}
                    name="spacingBottom"
                    render={({onChange, value}) => (
                      <KooperFormInput
                        fluid
                        labelPosition="right"
                        type="number"
                        placeholder="40"
                        error={
                          errors.spacingBottom && {
                            content: `bottomSpacing must be less than or equal to 30`,
                          }
                        }
                      >
                        <KooperLabel basic>Bottom Spacing</KooperLabel>
                        <input
                          type="text"
                          value={value}
                          onChange={(e, {value}) => onChange(value)}
                          onKeyPress={e => (e.key === 'Enter' ? e.preventDefault() : null)}
                        />
                        <KooperLabel className="px-3 basic">px</KooperLabel>
                      </KooperFormInput>
                    )}
                  />
                </KooperGridColumn>
              </KooperGrid>
            </KooperFormField>
            <KooperFormField>
              <label>Brand Colour (Theme Colour)</label>
              <KooperButtonGroup>
                <KooperButton
                  active={colorType === 'Gradient'}
                  basic={colorType !== 'Gradient'}
                  color="blue"
                  onClick={() => setColorType('Gradient')}
                >
                  Gradient Color
                </KooperButton>
                <KooperButton
                  active={colorType === 'Solid'}
                  basic={colorType !== 'Solid'}
                  color="blue"
                  onClick={() => setColorType('Solid')}
                >
                  Solid Color
                </KooperButton>
              </KooperButtonGroup>
              {renderColors()}
            </KooperFormField>
            <KooperCard fluid className="switchBox">
              <KooperCardContent>
                <div className="d-flex">
                  <div>
                    <h5>Enable Conversation</h5>
                    <p className="kooper-lead">Start conversation directly from messenger</p>
                  </div>
                  <Controller
                    control={control}
                    name="disableChat"
                    render={({value, onChange}) => (
                      <KooperCheckbox
                        toggle
                        checked={value === false}
                        onChange={(e, value) => onChange(value.checked === true ? false : true)}
                      />
                    )}
                  />
                </div>
              </KooperCardContent>

              <KooperCardContent>
                <div className="d-flex">
                  <div>
                    <h5>Show Whatsapp</h5>
                    <p className="kooper-lead">Display Whatsapp section in Messenger</p>
                  </div>
                  <Controller
                    control={control}
                    name="showWhatsapp"
                    render={({onChange, value}) => (
                      <KooperFormCheckbox toggle checked={value} onChange={(e, {checked}) => onChange(checked)} />
                    )}
                  />
                </div>
                {showWhatsapp && (
                  <>
                    <KooperFormField className="mt-3 mb-1" style={{width: '400px'}}>
                      <label>Link</label>
                      <Controller
                        control={control}
                        name="whatsappLink"
                        render={({onChange, value}) => (
                          <KooperFormInput
                            maxLength={200}
                            placeholder="Enter whatsApp Link"
                            value={value}
                            onChange={(e, {value}) => onChange(value)}
                            error={
                              errors.whatsappLink && {
                                content: removeDoubleQuotes(errors.whatsappLink.message),
                              }
                            }
                          />
                        )}
                      />
                    </KooperFormField>
                    <KooperFormField style={{width: '400px'}}>
                      <label>Message</label>
                      <Controller
                        control={control}
                        name="whatsappMsg"
                        render={({onChange, value, onBlur}) => (
                          <KooperFormTextArea
                            maxLength={40}
                            placeholder="Enter whatsApp Message"
                            value={value}
                            onChange={(e, {value}) => onChange(value)}
                            onBlur={onBlur}
                            error={
                              errors.whatsappMsg && {
                                content: removeDoubleQuotes(errors.whatsappMsg.message),
                              }
                            }
                          />
                        )}
                      />
                    </KooperFormField>
                  </>
                )}
              </KooperCardContent>
              <KooperCardContent>
                <div className="d-flex">
                  <div>
                    <h5>Show Facebook</h5>
                    <p className="kooper-lead">Display Facebook section in Messenger</p>
                  </div>
                  <Controller
                    control={control}
                    name="showFb"
                    render={({onChange, value}) => (
                      <KooperFormCheckbox toggle checked={value} onChange={(e, {checked}) => onChange(checked)} />
                    )}
                  />
                </div>
                {showFb && (
                  <>
                    <KooperFormField className="mt-3 mb-1" style={{width: '400px'}}>
                      <label>Link</label>
                      <Controller
                        control={control}
                        name="fbLink"
                        render={({onChange, value}) => (
                          <KooperFormInput
                            maxLength={200}
                            placeholder="Enter facebook Link"
                            value={value}
                            onChange={(e, {value}) => onChange(value)}
                            error={
                              errors.fbLink && {
                                content: removeDoubleQuotes(errors.fbLink.message),
                              }
                            }
                          />
                        )}
                      />
                    </KooperFormField>
                    <KooperFormField style={{width: '400px'}}>
                      <label>Message</label>
                      <Controller
                        control={control}
                        name="fbMsg"
                        render={({onChange, value, onBlur}) => (
                          <KooperFormTextArea
                            maxLength={40}
                            placeholder="Enter facebook Message"
                            value={value}
                            onChange={(e, {value}) => onChange(value)}
                            onBlur={onBlur}
                            error={
                              errors.fbMsg && {
                                content: removeDoubleQuotes(errors.fbMsg.message),
                              }
                            }
                          />
                        )}
                      />
                    </KooperFormField>
                  </>
                )}
              </KooperCardContent>
              <KooperCardContent>
                <div className="d-flex">
                  <div>
                    <h5>Show Instagram</h5>
                    <p className="kooper-lead">Display Instagram section in Messenger</p>
                  </div>
                  <Controller
                    control={control}
                    name="showInsta"
                    render={({onChange, value}) => (
                      <KooperFormCheckbox toggle checked={value} onChange={(e, {checked}) => onChange(checked)} />
                    )}
                  />
                </div>
                {showInsta && (
                  <>
                    <KooperFormField className="mt-3 mb-1" style={{width: '400px'}}>
                      <label>Link</label>
                      <Controller
                        control={control}
                        name="instaLink"
                        render={({onChange, value}) => (
                          <KooperFormInput
                            maxLength={200}
                            placeholder="Enter instagram Link"
                            value={value}
                            onChange={(e, {value}) => onChange(value)}
                            error={
                              errors.instaLink && {
                                content: removeDoubleQuotes(errors.instaLink.message),
                              }
                            }
                          />
                        )}
                      />
                    </KooperFormField>
                    <KooperFormField style={{width: '400px'}}>
                      <label>Message</label>
                      <Controller
                        control={control}
                        name="instaMsg"
                        render={({onChange, value, onBlur}) => (
                          <KooperFormTextArea
                            maxLength={40}
                            placeholder="Enter instagram Message"
                            value={value}
                            onChange={(e, {value}) => onChange(value)}
                            onBlur={onBlur}
                            error={
                              errors.instaMsg && {
                                content: removeDoubleQuotes(errors.instaMsg.message),
                              }
                            }
                          />
                        )}
                      />
                    </KooperFormField>
                  </>
                )}
              </KooperCardContent>
              <KooperCardContent>
                <div className="d-flex">
                  <div>
                    <h5>Show Quick Links</h5>
                    <p className="kooper-lead">Add Quick links in live chat</p>
                  </div>
                  <Controller
                    control={control}
                    name="showQuickLinks"
                    render={({value, onChange}) => (
                      <KooperFormCheckbox toggle checked={value} onChange={(e, {checked}) => onChange(checked)} />
                    )}
                  />
                </div>
                {showQuickLinks && (
                  <>
                    {/* <KooperFormField className="mt-3">
                      <label>Heading</label>
                      <Controller
                        name="quickLinksHeading"
                        control={control}
                        render={({value, onChange}) => (
                          <KooperFormInput
                            placeholder="Enter Heading"
                            value={value}
                            onChange={(e, {value}) => onChange(value)}
                            error={
                              errors.quickLinksHeading && {
                                content: removeDoubleQuotes(errors.quickLinksHeading.message),
                              }
                            }
                          />
                        )}
                      />
                    </KooperFormField> */}
                    <div className="mt-3">
                      {quickLinks.map(({id, name, url}, index) => {
                        return (
                          <KooperFormGroup key={id}>
                            <KooperFormField width={7}>
                              <Controller
                                name={`quickLinks[${index}].name`}
                                control={control}
                                defaultValue={name}
                                render={({value, onChange}) => (
                                  <KooperFormInput
                                    maxLength={20}
                                    placeholder="Enter name"
                                    value={value}
                                    onChange={(e, {value}) => onChange(value)}
                                    error={
                                      _.get(errors, `quickLinks[${index}].name`) && {
                                        content: removeDoubleQuotes(errors.quickLinks[index].name.message),
                                      }
                                    }
                                  />
                                )}
                              />
                            </KooperFormField>
                            <KooperFormField width={7}>
                              <Controller
                                name={`quickLinks[${index}].url`}
                                control={control}
                                defaultValue={url}
                                render={({value, onChange}) => (
                                  <KooperFormInput
                                    maxLength={200}
                                    placeholder="Enter url"
                                    value={value}
                                    onChange={(e, {value}) => onChange(value)}
                                    error={
                                      _.get(errors, `quickLinks[${index}].url`) && {
                                        content: removeDoubleQuotes(errors.quickLinks[index].url.message),
                                      }
                                    }
                                  />
                                )}
                              />
                            </KooperFormField>
                            <KooperFormField width={2}>
                              {/* {quickLinks.length > 1 && ( */}
                              <KooperTooltip
                                size="mini"
                                position="top center"
                                content="Delete"
                                trigger={
                                  <KooperButton
                                    disabled={quickLinks.length > 1 ? false : true}
                                    icon
                                    onClick={() => remove(index)}
                                  >
                                    <SvgIcon path="common/delete" />
                                  </KooperButton>
                                }
                              />
                              {/* )} */}
                            </KooperFormField>
                          </KooperFormGroup>
                        )
                      })}
                      {quickLinks.length < 5 && (
                        <KooperTooltip
                          content="Add"
                          size="mini"
                          position="top center"
                          trigger={
                            <KooperButton
                              basic
                              icon
                              onClick={() =>
                                append({
                                  name: '',
                                  url: '',
                                })
                              }
                            >
                              <SvgIcon path="common/plus" />
                            </KooperButton>
                          }
                        />
                      )}
                    </div>
                  </>
                )}
              </KooperCardContent>
            </KooperCard>
          </KooperForm>
        </KooperGridColumn>

        <KooperGridColumn width={8}>
          <div className="kooper-widget">
            <div className="widget-body">
              <KooperCard fluid>
                <div className="dasWidget">
                  <div className="widgetHeader" style={{background: brandColor.color}}>
                    <img src={get(['profilePic'], appearence, '')} />
                    <h4>{get(['heading'], appearence, 'Kooper')}</h4>
                    <h6>{get(['subHeading'], appearence, 'This is kooper')}</h6>
                  </div>
                  <KooperCardContent className="widgetBody" style={{height: 'auto'}}>
                    <div
                      className="wigContBox"
                      style={{
                        marginTop: '-60px',
                        padding: '0',
                        borderColor: brandColor.primaryColor,
                      }}
                    >
                      <div className="textHeader">
                        <div>
                          <h5 style={{marginBottom: '0'}}>Your Conversations</h5>
                          <p className="textSubHeader">Team will contact you soon.</p>
                        </div>
                        <KooperPopup
                          content="as"
                          size="mini"
                          position="bottom center"
                          trigger={<KooperImage src={user} className="userImage-widget" />}
                        />
                      </div>
                      <div className="newConvBtn">
                        <KooperButton primary fluid style={{background: brandColor.color}}>
                          <SvgIcon path="settings/conversation" />
                          New Conversation
                        </KooperButton>
                      </div>
                    </div>

                    {showMeeting && (
                      <div
                        className="wigContBox"
                        style={{
                          padding: '18px 24px',
                          marginBottom: '0',
                          borderColor: brandColor.primaryColor,
                        }}
                      >
                        <h5>Meetings</h5>
                        <div className="meetBtn">
                          <KooperButton primary style={{background: brandColor.color}} fluid>
                            <FaRegCalendarAlt /> Book Now!
                          </KooperButton>
                        </div>
                      </div>
                    )}
                  </KooperCardContent>
                </div>
                <a className="kooper-branding-widget">
                  <img src={logo} /> we run on Kooper
                </a>
              </KooperCard>
            </div>
            <div
              style={
                position === 'right'
                  ? {
                      paddingRight: `${spacingRight}px`,
                      paddingBottom: `${spacingBottom}px`,
                    }
                  : position === 'left'
                  ? {
                      paddingLeft: `${spacingRight}px`,
                      paddingBottom: `${spacingBottom}px`,
                    }
                  : {}
              }
              className={position === 'right' ? `widget-footer-right` : `widget-footer-left`}
            >
              <KooperButton
                primary
                className="wgtBtn active mr-0"
                style={{
                  backgroundColor: brandColor.primaryColor,
                  boxShadow: `0 1px 2px ${brandColor.primaryColor}`,
                }}
              >
                <img
                  src={
                    buttonIcon === '1'
                      ? wgt1
                      : buttonIcon === '2'
                      ? wgt2
                      : buttonIcon === '3'
                      ? wgt3
                      : buttonIcon === '4'
                      ? wgt4
                      : buttonIcon === '5'
                      ? wgt5
                      : null
                  }
                />
              </KooperButton>
            </div>
          </div>
        </KooperGridColumn>
      </KooperGrid>
    </SettingLayout>
  )
}

export default Appearance
