/* eslint-disable no-nested-ternary */
import React, {useEffect, useState} from 'react'
import {useHistory} from 'react-router'
import {useDispatch, useSelector} from 'react-redux'
import {FaBriefcase} from 'react-icons/fa'
import create from 'assets/create.svg'
import _ from 'lodash'
import {
  KooperButton,
  KooperDropdown,
  KooperDropdownItem,
  KooperDropdownMenu,
  KooperHeader,
  KooperIcon,
  KooperTable,
  KooperTableBody,
  KooperTableCell,
  KooperTableHeader,
  KooperTableHeaderCell,
  KooperTablePlaceholder,
  KooperTableRow,
} from 'kooper-library'
import {deleteAnnouncement, getAllAnnouncements} from 'actions/Announcement'
import SearchComponent from 'components/common/SearchComponent'
import CreateFirst from 'components/common/CreateFirst'
import {DELETE_ANNOUNCEMENT, GET_ALL_ANNOUNCEMENT} from 'actions/types'
import DeleteModal from 'components/common/DeleteModal'
import {getFormattedDate, getFormattedTime, startCase} from 'utils/helper'
import useApiLoader from 'hooks/pure/useApiLoader'
import PageLoader from 'components/common/PageLoader'
import SvgIcon from 'components/common/SvgIcon'
import useApiResponse from 'hooks/impure/useApiResponse'
import AnnouncementSidemenu from './AnnouncementSidemenu'

const Announcements = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  const [deleteModal, setDeleteModal] = useState(false)
  const [editId, setEditId] = useState()
  const [currentListParams, setCurrentListParams] = useState({
    page: 1,
    sortBy: '',
    sortType: '',
    text: '',
  })

  const {isLoadingData, type, successLabels = []} = useSelector(state => state.apiReducer)

  const {isLoading} = useApiLoader({label: GET_ALL_ANNOUNCEMENT})

  const {data: getAllAnnouncementList = []} = useApiResponse({
    action: getAllAnnouncements,
    enabled: true,
    label: GET_ALL_ANNOUNCEMENT,
    storePath: 'announcement.getAllAnnouncementList',
  })

  useEffect(() => {
    const newCurrentListParams = _.omitBy(currentListParams, value => {
      if (value === 'all' || value === '') {
        return true
      }
      return false
    })

    dispatch(getAllAnnouncements(newCurrentListParams))
  }, [currentListParams, dispatch])

  useEffect(() => {
    if (successLabels.includes(DELETE_ANNOUNCEMENT)) {
      dispatch(getAllAnnouncements())
    }
  }, [successLabels, dispatch])

  const renderTableBody = () => {
    return getAllAnnouncementList.map(({id, title, createdBy, scheduledDateTime, unpublishDate}, index) => {
      return (
        <KooperTableRow
          key={id}
          className="tableLink"
          onClick={() => {
            history.push(`/announcements/update/${id}`)
          }}
        >
          <KooperTableCell>{index + 1}</KooperTableCell>
          <KooperTableCell>
            <KooperHeader as="h5">{startCase(title)}</KooperHeader>
          </KooperTableCell>
          {/* <KooperTableCell /> */}
          <KooperTableCell>{startCase(createdBy.name)}</KooperTableCell>
          <KooperTableCell>
            {scheduledDateTime ? `${getFormattedDate(scheduledDateTime)} ${getFormattedTime(scheduledDateTime)}` : '-'}
          </KooperTableCell>
          <KooperTableCell>{unpublishDate ? getFormattedDate(unpublishDate) : '-'}</KooperTableCell>
          {/* <KooperTableCell />
          <KooperTableCell /> */}
          <KooperTableCell>
            <KooperDropdown button icon={<SvgIcon path="common/more-verticle" />} className="icon" direction="left">
              <KooperDropdownMenu>
                <KooperDropdownItem
                  onClick={() => {
                    setEditId(id)
                    setDeleteModal(true)
                  }}
                >
                  <SvgIcon path="common/delete" /> Delete
                </KooperDropdownItem>
              </KooperDropdownMenu>
            </KooperDropdown>
          </KooperTableCell>
        </KooperTableRow>
      )
    })
  }

  if (isLoading) {
    return <PageLoader />
  }

  return (
    <>
      {getAllAnnouncementList?.length > 0 || currentListParams.text ? (
        <>
          <AnnouncementSidemenu />

          <div className="kooper-side-wrap">
            <div className="page-header">
              <div className="page-title">
                <div className="page-titleIcon">
                  <SvgIcon path="common/file" />
                  <h5>Announcement ({getAllAnnouncementList ? getAllAnnouncementList.length : 0})</h5>
                </div>
              </div>
              <div className="page-action">
                <SearchComponent onSuccess={value => setCurrentListParams({text: value})} />
                <KooperButton
                  content="Create Announcement"
                  primary
                  onClick={() => history.push('/announcements/create')}
                />
              </div>
            </div>

            <KooperTable basic>
              <KooperTableHeader>
                <KooperTableRow>
                  <KooperTableHeaderCell>#</KooperTableHeaderCell>
                  <KooperTableHeaderCell>Title</KooperTableHeaderCell>
                  {/* <KooperTableHeaderCell>Type</KooperTableHeaderCell> */}
                  <KooperTableHeaderCell>Created By</KooperTableHeaderCell>
                  <KooperTableHeaderCell>Published Date</KooperTableHeaderCell>
                  <KooperTableHeaderCell>UnPublished Date</KooperTableHeaderCell>
                  {/* <KooperTableHeaderCell>Acknowledged</KooperTableHeaderCell>
                  <KooperTableHeaderCell>Pending</KooperTableHeaderCell> */}
                  <KooperTableHeaderCell>Actions</KooperTableHeaderCell>
                </KooperTableRow>
              </KooperTableHeader>
              <KooperTableBody>
                {isLoadingData && type.includes(GET_ALL_ANNOUNCEMENT) ? (
                  <KooperTablePlaceholder columns={9} />
                ) : (getAllAnnouncementList || []).length > 0 ? (
                  renderTableBody()
                ) : (
                  <tr>
                    <td colSpan={9} className="emptyValue">
                      No Data Available
                    </td>
                  </tr>
                )}
              </KooperTableBody>
            </KooperTable>

            {deleteModal && (
              <DeleteModal
                isModalOpen={deleteModal}
                setIsModalOpen={setDeleteModal}
                modalText="Are you sure you want to delete ?"
                deleteAction={deleteAnnouncement}
                idTobeDeleted={editId}
                type={DELETE_ANNOUNCEMENT}
              />
            )}
          </div>
        </>
      ) : (
        <CreateFirst
          src={<FaBriefcase />}
          header="Announcement"
          subHeader="Announcements helps to communicate important updates or messages to employees within the organization"
          addFirst={() => history.push('/announcements/create')}
          buttonText="Create New Announcement"
          Feature1="Announcement Creation"
          Feature2="Target Audience"
          Feature3="Announcement Scheduling"
          list1="Create announcements with customizable content"
          list2="Set target audience for each announcement"
          list3="Schedule your announcement for the perfect time"
          imageSrc={create}
        />
      )}
    </>
  )
}

export default Announcements
