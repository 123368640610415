/* eslint-disable no-nested-ternary */
/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect, useRef} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {Link, useHistory} from 'react-router-dom'
import {Controller, useFieldArray, useForm} from 'react-hook-form'
import {joiResolver} from '@hookform/resolvers'
import {FiArrowUp, FiChevronRight, FiExternalLink, FiDollarSign} from 'react-icons/fi'
import _ from 'lodash'
import moment from 'moment'
import {
  KooperModal,
  KooperModalHeader,
  KooperModalContent,
  KooperButton,
  KooperHeader,
  KooperIcon,
  KooperLabel,
  KooperHeaderSubheader,
  KooperHeaderContent,
  KooperTab,
  KooperTabPane,
  KooperFormGroup,
  KooperForm,
  KooperFormField,
  KooperGrid,
  KooperGridColumn,
  KooperDropdown,
  KooperDropdownMenu,
  KooperDropdownItem,
  KooperAccordion,
  KooperAccordionContent,
  KooperAccordionTitle,
  KooperTooltip,
  KooperFormInput,
  KooperFeed,
  KooperFeedEvent,
  KooperFeedLabel,
  KooperCard,
  KooperCardContent,
  KooperFeedContent,
  KooperFeedSummary,
  KooperProgress,
  KooperFormTextArea,
  KooperFormSelect,
  KooperRating,
  KooperList,
  KooperListItem,
  KooperCheckbox,
  KooperListContent,
  KooperListHeader,
  KooperListDescription,
  KooperInput,
} from 'kooper-library'

import {
  createTagSettings,
  addCompanyPhone,
  updateCompanyPhone,
  deleteCompanyPhone,
  addCompanyEmail,
  updateCompanyEmail,
  deleteCompanyEmail,
  updateCompanyWebsite,
  addCompanyWebsite,
  deleteCompanyWebsite,
} from 'actions/multiple_fields'
import {editCompany, getSingleCompany, uploadAttachmentsCompany} from 'actions/company'
import {getContactList} from 'actions/contacts'
import {
  get,
  GetFormattedDate,
  removeDoubleQuotes,
  wrapText,
  KooperCountryList,
  getPermissionTooltipContent,
} from 'utils/helper'
import Tags from 'components/common/Tags'
import SocialIcons from 'components/pages/Socials/SocialIcon'
import {
  ADD_COMPANY_WEBSITE,
  ADD_EMAIL_COMPANY,
  ADD_NOTE_COMPANY,
  ADD_PHONE_COMPANY,
  ATTACHMENT_POST_COMPANY,
  CREATE_TAG,
  CREATE_TASK,
  DELETE_COMPANY_EMAIL,
  DELETE_COMPANY_PHONE,
  DELETE_COMPANY_WEBSITE,
  EDIT_COMPANY,
  EDIT_TASK,
  GET_SINGLE_COMPANY,
  UPDATE_COMPANY_WEBSITE,
  UPDATE_EMAIL_COMPANY,
  UPDATE_PHONE_COMPANY,
} from 'actions/types'
import AccountUserImage from 'components/common/AccountUserImage'
import CreateLabel from 'components/common/CreateLabel'
import AddNote from 'components/common/AddNote'
import {commonEmail, commonPhone, commonWebsite} from 'components/common/CommonDispatch'
import Website from 'components/common/Website'
import Email from 'components/common/Email'
import Phone from 'components/common/Phone'
import PreviewTimeline from 'components/common/PreviewTimeline'
import {editTaskActivities, getTaskActivities} from 'actions/activities'
import CreateTask from 'components/pages/Projects/Tasks/CreateTask'
import ViewTask from 'components/pages/Projects/Tasks/ViewTask'
import {updateCompanySchema} from 'validation/Customers/company.schema'
import useUserPermissions from 'hooks/pure/useUserPermissions'
import LockPermissionTooltip from 'components/common/LockPermissionTooltip'
import SvgIcon from 'components/common/SvgIcon'

const PreviewModal = props => {
  const refForm = useRef()
  const {isModalOpen, setIsModalOpen, getCompanyId} = props
  const dispatch = useDispatch()
  const history = useHistory()
  const [userOption, setUserOption] = useState([])
  const [isNoteOpen, setIsNoteOpen] = useState(false)
  const [isEmailOpen, setIsEmailOpen] = useState(false)
  const [isTaskOpen, setIsTaskOpen] = useState(false)
  const [typeList, setTypeList] = useState([])
  const [activeIndex, setActiveIndex] = useState(0)
  const [listOfLable, setListOfLabel] = useState([])
  const [newLabelModal, setNewLabelModal] = useState(false)
  const [description, setDescription] = useState('')
  const [isViewTaskOpen, setIsViewTaskOpen] = useState(false)

  const {singleCompany = {}, getTypeListData} = useSelector(state => state.companies)
  const {
    interactions: getAllInterActionsData = [],
    interactionTime = '',
    labels: getSingleCompanyTags,
    allActivities = [],
  } = singleCompany
  const {successLabels = []} = useSelector(state => state.apiReducer)

  const {contactsAndCompaniesPermissions, tasksPermissions} = useUserPermissions()
  const manageContactsCompanyPermissions = contactsAndCompaniesPermissions?.manage_contacts_company
  const manageTasksPermissions = tasksPermissions?.manage_tasks

  const initialCreateInfo = {
    name: '',
    domain: '',
    emails: [],
    phone: [],
    websites: [],
    description: '',
    assigneeId: null,
    contactTypeId: null,
    address: {
      streetAddress: '',
      locality: '',
      state: '',
      postalCode: null,
      country: '',
    },
    labelIds: [],
  }

  const {
    handleSubmit,
    errors,
    formState: {dirtyFields},
    control,
    reset,
    getValues,
  } = useForm({
    mode: 'onTouched',
    reValidateMode: 'onChange',
    shouldFocusError: true,
    resolver: joiResolver(updateCompanySchema),
    defaultValues: initialCreateInfo,
  })
  const {
    fields: emailFields,
    append: appendEmail,
    remove: removeEmail,
  } = useFieldArray({
    control,
    name: 'emails',
  })
  const {
    fields: phoneFields,
    append: appendPhone,
    remove: removePhone,
  } = useFieldArray({
    control,
    name: 'phones',
  })
  const {
    fields: websiteFields,
    append: appendwebsite,
    remove: removeWebsite,
  } = useFieldArray({
    control,
    name: 'websites',
  })

  const {getUsersList = {}} = useSelector(state => state.settings)

  useEffect(() => {
    if (getCompanyId) {
      dispatch(getSingleCompany(getCompanyId))
    }
  }, [getCompanyId])

  // tags full code
  useEffect(() => {
    if (getSingleCompanyTags) {
      const formated = getSingleCompanyTags.map(label => ({
        value: label.id,
        text: label.label,
        color: label.color,
      }))
      setListOfLabel(formated || [])
    } else {
      setListOfLabel([])
    }
  }, [getSingleCompanyTags])

  const createNewLabel = () => {
    if (newLabelModal) {
      return (
        <CreateLabel
          isModalOpen={newLabelModal}
          setIsModalOpen={setNewLabelModal}
          createMethod={data => dispatch(createTagSettings(data))}
        />
      )
    }
  }

  // assignee code
  useEffect(() => {
    if (getUsersList) {
      const assigneeList = getUsersList.users.map(list => ({
        key: list.id,
        value: list.id,
        text: `${list.firstName} ${list.lastName}`,
      }))
      setUserOption(assigneeList)
    }
  }, [getUsersList])

  useEffect(() => {
    if (getTypeListData) {
      const companyList = getTypeListData.map(list => ({
        key: list.id,
        value: list.id,
        text: list.type,
      }))
      setTypeList(companyList)
    }
  }, [getTypeListData])

  const resetVal = {
    name: get(['name'], singleCompany, ''),
    domain: get(['domain'], singleCompany, ''),
    emails: get(['emails'], singleCompany, [{category: 'work', email: ''}]),
    phones: get(['phones'], singleCompany, [{category: 'work', phone: ''}]),
    websites: get(['websites'], singleCompany, [{category: 'work', url: ''}]),
    description: get(['description'], singleCompany, ''),
    address: {
      streetAddress: get(['address', 'streetAddress'], singleCompany, ''),
      locality: get(['address', 'locality'], singleCompany, ''),
      state: get(['address', 'state'], singleCompany, ''),
      country: get(['address', 'country'], singleCompany, ''),
      postalCode: get(['address', 'postalCode'], singleCompany, ''),
    },
    assigneeId: get(['assigneeId'], singleCompany),
    contactTypeId: get(['contactTypeId'], singleCompany),
  }

  useEffect(() => {
    if (successLabels.includes(GET_SINGLE_COMPANY)) {
      reset(resetVal)
    }
  }, [successLabels, reset])

  useEffect(() => {
    if (successLabels.includes(ADD_NOTE_COMPANY)) {
      setIsNoteOpen(false)
    }
    if (
      successLabels.includes(ADD_EMAIL_COMPANY) ||
      successLabels.includes(UPDATE_EMAIL_COMPANY) ||
      successLabels.includes(DELETE_COMPANY_EMAIL)
    ) {
      removeEmail()
      reset(resetVal, {dirtyFields: false})
    }
    if (
      successLabels.includes(ADD_PHONE_COMPANY) ||
      successLabels.includes(UPDATE_PHONE_COMPANY) ||
      successLabels.includes(DELETE_COMPANY_PHONE)
    ) {
      removePhone()
      reset(resetVal, {dirtyFields: false})
    }
    if (
      successLabels.includes(ADD_COMPANY_WEBSITE) ||
      successLabels.includes(UPDATE_COMPANY_WEBSITE) ||
      successLabels.includes(DELETE_COMPANY_WEBSITE)
    ) {
      removeWebsite()
      reset(resetVal, {dirtyFields: false})
    }
    if (
      successLabels.includes(ATTACHMENT_POST_COMPANY) ||
      successLabels.includes(CREATE_TASK) ||
      successLabels.includes(EDIT_TASK)
    ) {
      dispatch(getSingleCompany(getCompanyId))
    }
    if (successLabels.includes(CREATE_TAG)) {
      setNewLabelModal(false)
    }
    if (successLabels.includes(EDIT_COMPANY)) {
      reset(resetVal, {dirtyFields: false})
    }
  }, [successLabels, reset])
  const opportunitiesCardBody = () => {
    return (
      <KooperCardContent className="pt-0">
        <KooperForm >
          <KooperFormField>
            <KooperGrid className="text-center">
              <KooperGridColumn width="8" className="border-right">
                <label>Total Won</label>
                <p className="mb-0">$ 200</p>
              </KooperGridColumn>
              <KooperGridColumn width="8">
                <label>Win Rate</label>
                <p className="mb-0">10 %</p>
              </KooperGridColumn>
              <KooperGridColumn width="16">
                <KooperProgress progress="value" value={50} />
              </KooperGridColumn>
            </KooperGrid>
          </KooperFormField>
        </KooperForm>
        <p className="mb-2">Open</p>
        <KooperGrid>
          <KooperGridColumn width="8">
            <h6>500 Keyboards</h6>
            <p className="kooper-lead pb-2">Sales: Follow-up / Open</p>
          </KooperGridColumn>
          <KooperGridColumn width="8" className="text-right">
            <KooperTooltip
              content="External"
              size="mini"
              position="top center"
              trigger={
                <KooperButton color="light">
                  <FiExternalLink />
                </KooperButton>
              }
            />
            <KooperTooltip
              content="Delete"
              size="mini"
              position="top center"
              trigger={
                <KooperButton color="light">
                  <SvgIcon path="common/delete" />
                </KooperButton>
              }
            />
          </KooperGridColumn>
        </KooperGrid>
      </KooperCardContent>
    )
  }
  const formStateVal = dirtyFields

  const updateCompany = data => {
    const dirtyFields = _.pick(data, Object.keys(formStateVal))

    if (dirtyFields.emails) {
      commonEmail({
        dispatch,
        id: getCompanyId,
        data,
        singleData: singleCompany,
        formStateVal,
        addApi: addCompanyEmail,
        updateApi: updateCompanyEmail,
        removeEmail,
      })
    } else if (dirtyFields.phones) {
      commonPhone({
        dispatch,
        id: getCompanyId,
        data,
        singleData: singleCompany,
        formStateVal,
        addApi: addCompanyPhone,
        updateApi: updateCompanyPhone,
        removePhone,
      })
    } else if (dirtyFields.websites) {
      commonWebsite({
        dispatch,
        id: getCompanyId,
        data,
        singleData: singleCompany,
        formStateVal,
        addApi: addCompanyWebsite,
        updateApi: updateCompanyWebsite,
        removeWebsite,
      })
    } else if (!_.isEmpty(dirtyFields)) {
      dispatch(editCompany(dirtyFields, getCompanyId))
    }
  }

  const panes = [
    {
      menuItem: {key: 'details', icon: 'info', content: 'DETAILS'},
      render: () => (
        <KooperTabPane attached={false}>
          <KooperForm
            ref={refForm}
            className="errorLabel"
            {...(manageContactsCompanyPermissions && {
              onSubmit: handleSubmit(updateCompany),
            })}
          >
            <KooperFormGroup widths="equal">
              <KooperFormField>
                <label>Name</label>
                <Controller
                  control={control}
                  name="name"
                  render={({value, onChange, onBlur}) => (
                    <KooperFormInput
                      defaultValue={value}
                      placeholder="Name"
                      onChange={(e, {value}) => onChange(value)}
                      onBlur={e => {
                        onBlur(e)
                        refForm.current.handleSubmit()
                      }}
                      error={
                        errors.name && {
                          content: removeDoubleQuotes(errors.name.message),
                        }
                      }
                      onKeyPress={e => {
                        if (e.key === 'Enter') {
                          e.preventDefault()
                          e.target.blur()
                        }
                      }}
                      disabled={!manageContactsCompanyPermissions}
                    />
                  )}
                />
              </KooperFormField>
            </KooperFormGroup>
            <KooperFormGroup widths="equal">
              <KooperFormField>
                <label>Email Domain</label>
                <Controller
                  control={control}
                  name="domain"
                  render={({value, onChange, onBlur}) => (
                    <KooperFormInput
                      type="text"
                      defaultValue={value}
                      placeholder="Domain"
                      onChange={(e, {value}) => onChange(value)}
                      onBlur={e => {
                        onBlur(e)
                        refForm.current.handleSubmit()
                      }}
                      error={
                        errors.domain && {
                          content: removeDoubleQuotes(errors.domain.message),
                        }
                      }
                      onKeyPress={e => {
                        if (e.key === 'Enter') {
                          e.preventDefault()
                          e.target.blur()
                        }
                      }}
                      disabled={!manageContactsCompanyPermissions}
                    />
                  )}
                />
              </KooperFormField>
            </KooperFormGroup>
            <KooperFormGroup widths="equal">
              <KooperFormField>
                <Website
                  id={getCompanyId}
                  deleteWebsite={deleteCompanyWebsite}
                  refForm={refForm}
                  control={control}
                  Controller={Controller}
                  errors={errors}
                  websiteFields={websiteFields}
                  appendwebsite={appendwebsite}
                  removeWebsite={removeWebsite}
                  disabled={!manageContactsCompanyPermissions}
                />
              </KooperFormField>
            </KooperFormGroup>
            <KooperFormGroup widths="equal">
              <KooperFormField>
                <Email
                  id={getCompanyId}
                  deleteEmail={deleteCompanyEmail}
                  refForm={refForm}
                  control={control}
                  Controller={Controller}
                  errors={errors}
                  emailFields={emailFields}
                  appendEmail={appendEmail}
                  removeEmail={removeEmail}
                  disabled={!manageContactsCompanyPermissions}
                />
              </KooperFormField>
            </KooperFormGroup>
            <KooperFormGroup widths="equal">
              <KooperFormField>
                <Phone
                  id={getCompanyId}
                  deletePhone={deleteCompanyPhone}
                  refForm={refForm}
                  control={control}
                  Controller={Controller}
                  errors={errors}
                  phoneFields={phoneFields}
                  appendPhone={appendPhone}
                  removePhone={removePhone}
                  disabled={!manageContactsCompanyPermissions}
                />
              </KooperFormField>
            </KooperFormGroup>
            <KooperFormGroup widths="equal">
              <KooperFormField>
                <label>Address</label>
                <Controller
                  control={control}
                  name="address.streetAddress"
                  render={({value, onChange, onBlur}) => (
                    <KooperFormInput
                      placeholder="add address"
                      defaultValue={value}
                      onChange={(e, {value}) => onChange(value)}
                      onKeyPress={e => {
                        if (e.key === 'Enter') {
                          e.preventDefault()
                          e.target.blur()
                        }
                      }}
                      onBlur={e => {
                        onBlur(e)
                        refForm.current.handleSubmit()
                      }}
                      disabled={!manageContactsCompanyPermissions}
                    />
                  )}
                />
                <KooperGrid className="mt-2">
                  <KooperGridColumn width={8}>
                    <Controller
                      control={control}
                      name="address.locality"
                      render={({value, onChange, onBlur}) => (
                        <KooperFormInput
                          placeholder="Locality"
                          defaultValue={value}
                          onChange={(e, {value}) => onChange(value)}
                          onKeyPress={e => {
                            if (e.key === 'Enter') {
                              e.preventDefault()
                              e.target.blur()
                            }
                          }}
                          onBlur={e => {
                            onBlur(e)
                            refForm.current.handleSubmit()
                          }}
                          error={
                            errors.address?.locality && {
                              content: removeDoubleQuotes(errors.address?.locality.message),
                            }
                          }
                          disabled={!manageContactsCompanyPermissions}
                        />
                      )}
                    />
                  </KooperGridColumn>
                  <KooperGridColumn width={8}>
                    <Controller
                      control={control}
                      name="address.state"
                      render={({value, onChange, onBlur}) => (
                        <KooperFormInput
                          placeholder="State"
                          defaultValue={value}
                          onChange={(e, {value}) => onChange(value)}
                          onKeyPress={e => {
                            if (e.key === 'Enter') {
                              e.preventDefault()
                              e.target.blur()
                            }
                          }}
                          onBlur={e => {
                            onBlur(e)
                            refForm.current.handleSubmit()
                          }}
                          error={
                            errors.address?.state && {
                              content: removeDoubleQuotes(errors.address?.state.message),
                            }
                          }
                          disabled={!manageContactsCompanyPermissions}
                        />
                      )}
                    />
                  </KooperGridColumn>
                </KooperGrid>
                <KooperGrid>
                  <KooperGridColumn width={8}>
                    <Controller
                      control={control}
                      name="address.country"
                      render={({value, onChange, onBlur}) => (
                        <KooperDropdown
                          search
                          fluid
                          options={KooperCountryList}
                          selection
                          placeholder="Country"
                          value={value}
                          onChange={(e, {value}) => {
                            onChange(value)
                            refForm.current.handleSubmit()
                          }}
                          onKeyPress={e => {
                            if (e.key === 'Enter') {
                              e.preventDefault()
                              e.target.blur()
                            }
                          }}
                          error={
                            errors.address?.country && {
                              content: removeDoubleQuotes(errors.address?.country.message),
                            }
                          }
                          disabled={!manageContactsCompanyPermissions}
                        />
                      )}
                    />
                  </KooperGridColumn>
                  <KooperGridColumn width={8}>
                    <Controller
                      control={control}
                      name="address.postalCode"
                      render={({value, onChange, onBlur}) => (
                        <KooperFormInput
                          placeholder="Postal Code"
                          defaultValue={value}
                          onChange={(e, {value}) => onChange(value)}
                          onKeyPress={e => {
                            if (e.key === 'Enter') {
                              e.preventDefault()
                              e.target.blur()
                            }
                          }}
                          onBlur={e => {
                            onBlur(e)
                            refForm.current.handleSubmit()
                          }}
                          error={
                            errors.address?.postalCode && {
                              content: removeDoubleQuotes(errors.address?.postalCode.message),
                            }
                          }
                          disabled={!manageContactsCompanyPermissions}
                        />
                      )}
                    />
                  </KooperGridColumn>
                </KooperGrid>
              </KooperFormField>
            </KooperFormGroup>
            <KooperFormGroup widths="equal">
              <KooperFormField>
                <label>Description</label>
                <Controller
                  control={control}
                  name="description"
                  render={({value, onChange, onBlur}) => (
                    <KooperFormTextArea
                      placeholder="Write description"
                      value={value}
                      onChange={(e, {value}) => onChange(value)}
                      onBlur={e => {
                        onBlur(e)
                        refForm.current.handleSubmit()
                      }}
                      onKeyPress={e => {
                        if (e.key === 'Enter' && e.shiftKey) {
                          setDescription(`${description}\t`)
                        } else if (e.key === 'Enter') {
                          e.target.blur()
                        }
                      }}
                      disabled={!manageContactsCompanyPermissions}
                    />
                  )}
                />
              </KooperFormField>
            </KooperFormGroup>
          </KooperForm>
        </KooperTabPane>
      ),
    },
    {
      menuItem: {key: 'timeline', icon: 'calendar', content: 'TIMELINE'},
      render: () => (
        <KooperTabPane attached={false}>
          <PreviewTimeline allActivities={allActivities} />
        </KooperTabPane>
      ),
    },
    {
      menuItem: {key: 'related', icon: 'list', content: 'RELATED'},
      render: () => (
        <KooperTabPane attached={false}>
          <KooperForm
            ref={refForm}
            className="editForm errorLabel"
            {...(manageContactsCompanyPermissions && {
              onSubmit: handleSubmit(updateCompany),
            })}
          >
            <KooperFormGroup widths="equal">
              <KooperFormField>
                <label className="kooper-lead">Last Contacted</label>
                <h5 className="m-0">{interactionTime ? GetFormattedDate(interactionTime) : null}</h5>
              </KooperFormField>
              <KooperFormField>
                <label className="kooper-lead">Interactions</label>
                <h5 className="m-0">{getAllInterActionsData.length > 0 ? getAllInterActionsData.length : null}</h5>
              </KooperFormField>
              <KooperFormField>
                <label className="kooper-lead">Inactive For</label>
                <h5 className="m-0">{interactionTime ? moment(interactionTime).fromNow(true) : '-'}</h5>
              </KooperFormField>
            </KooperFormGroup>
            <KooperFormGroup widths="equal">
              <KooperFormField>
                <label>Contact Type</label>
                <Controller
                  control={control}
                  name="contactTypeId"
                  render={({value, onChange, onBlur}) => (
                    <KooperFormSelect
                      placeholder="Contact Type"
                      options={typeList}
                      fluid
                      search
                      selection
                      value={value}
                      onChange={(e, {value}) => {
                        onChange(value)
                        refForm.current.handleSubmit()
                      }}
                      onKeyPress={e => {
                        if (e.key === 'Enter') {
                          e.preventDefault()
                          e.target.blur()
                        }
                      }}
                      disabled={!manageContactsCompanyPermissions}
                    />
                  )}
                />
              </KooperFormField>
            </KooperFormGroup>
            <KooperFormGroup widths="equal">
              <KooperFormField>
                <label>Assignee</label>
                <Controller
                  control={control}
                  name="assigneeId"
                  render={({value, onChange, onBlur}) => (
                    <KooperDropdown
                      fluid
                      selection
                      options={userOption}
                      value={value}
                      onChange={(e, {value}) => {
                        onChange(value)
                        refForm.current.handleSubmit()
                      }}
                      onKeyPress={e => {
                        if (e.key === 'Enter') {
                          e.preventDefault()
                          e.target.blur()
                        }
                      }}
                      disabled={!manageContactsCompanyPermissions}
                    />
                  )}
                />
              </KooperFormField>
            </KooperFormGroup>
          </KooperForm>

          <KooperCard fluid>
            <KooperCardContent className="p-0">
              <KooperAccordion styled className="kooper-accordion">
                <KooperAccordionTitle
                  active={activeIndex === 0}
                  index={0}
                  onClick={(e, titleProps) => {
                    const {index} = titleProps
                    const newIndex = activeIndex === index ? -1 : index
                    setActiveIndex(newIndex)
                  }}
                >
                  Deals
                  <span>{activeIndex === 0 ? <SvgIcon path="common/down" /> : <FiChevronRight />}</span>
                </KooperAccordionTitle>
                <KooperAccordionContent active={activeIndex === 0}>{opportunitiesCardBody()}</KooperAccordionContent>
              </KooperAccordion>
            </KooperCardContent>
          </KooperCard>

          <KooperCard fluid>
            <KooperCardContent className="p-0">
              <KooperAccordion styled className="kooper-accordion">
                <KooperAccordionTitle
                  active={activeIndex === 1}
                  index={1}
                  onClick={(e, titleProps) => {
                    const {index} = titleProps
                    const newIndex = activeIndex === index ? -1 : index
                    setActiveIndex(newIndex)
                  }}
                >
                  People ({_.size(_.get(singleCompany, 'contacts', 0))})
                  <span>{activeIndex === 1 ? <SvgIcon path="common/down" /> : <FiChevronRight />}</span>
                </KooperAccordionTitle>
                <KooperAccordionContent active={activeIndex === 1}>
                  {_.size(_.get(singleCompany, 'contacts', 0)) === 0 ? (
                    <p className="kooper-lead mb-0">No People</p>
                  ) : (
                    _.get(singleCompany, 'contacts', 0).map(list => (
                      <>
                        <Link to={`/contact/${list.id}`} target="_blank">
                          <KooperCard link target="_blank">
                            <KooperCardContent>
                              <KooperFeed>
                                <KooperFeedEvent>
                                  <KooperFeedLabel>
                                    <AccountUserImage profile={list} />
                                  </KooperFeedLabel>
                                  <KooperFeedContent>
                                    <KooperFeedSummary>
                                      {`${_.get(list, 'firstName') || ''}  ${_.get(list, 'lastName') || ''}`}
                                      <br />
                                      {`${_.get(list, 'title') || ''} at ${_.get(list, 'company.name') || ''}`}
                                    </KooperFeedSummary>
                                  </KooperFeedContent>
                                </KooperFeedEvent>
                              </KooperFeed>
                            </KooperCardContent>
                          </KooperCard>
                        </Link>
                        <SvgIcon path="common/close" />
                        <FiDollarSign />
                      </>
                    ))
                  )}
                  {_.size(_.get(singleCompany, 'contacts', 0)) !== 0 && (
                    <KooperButton
                      onClick={() => {
                        history.push(`/contacts`, get(['id'], singleCompany))
                        dispatch(
                          getContactList({
                            getCompanyId: get(['id'], singleCompany),
                          })
                        )
                      }}
                    >
                      <KooperIcon name="external alternate" />
                      View in List
                    </KooperButton>
                  )}
                </KooperAccordionContent>
              </KooperAccordion>
            </KooperCardContent>
          </KooperCard>

          <KooperCard fluid>
            <KooperCardContent className="p-0">
              <KooperAccordion styled className="kooper-accordion">
                <KooperAccordionTitle
                  active={activeIndex === 2}
                  index={2}
                  onClick={(e, titleProps) => {
                    const {index} = titleProps
                    const newIndex = activeIndex === index ? -1 : index
                    setActiveIndex(newIndex)
                  }}
                >
                  Tags ({listOfLable.length !== 0 ? listOfLable.length : '0'})
                  <span>{activeIndex === 2 ? <SvgIcon path="common/down" /> : <FiChevronRight />}</span>
                </KooperAccordionTitle>

                <KooperAccordionContent active={activeIndex === 2} className="pt-0">
                  {listOfLable.length === 0 ? <p className="kooper-lead mb-0">No Tags</p> : null}

                  <Tags
                    id={getCompanyId}
                    data={singleCompany}
                    tagsUpdate={editCompany}
                    Controller={Controller}
                    control={control}
                    errors={errors}
                    getValues={getValues}
                    rolePermissions={manageContactsCompanyPermissions}
                  />
                </KooperAccordionContent>
              </KooperAccordion>
            </KooperCardContent>
          </KooperCard>

          <KooperCard fluid>
            <KooperCardContent className="p-0">
              <KooperAccordion styled className="kooper-accordion">
                <KooperAccordionTitle
                  active={activeIndex === 3}
                  index={3}
                  onClick={(e, titleProps) => {
                    const {index} = titleProps
                    const newIndex = activeIndex === index ? -1 : index
                    setActiveIndex(newIndex)
                  }}
                >
                  Tasks ({get(['tasks'], singleCompany, []).length})
                  <span>{activeIndex === 3 ? <SvgIcon path="common/down" /> : <FiChevronRight />}</span>
                </KooperAccordionTitle>

                <KooperAccordionContent active={activeIndex === 3}>
                  <KooperList className="recentConv">
                    {get(['tasks'], singleCompany, []).length > 0
                      ? get(['tasks'], singleCompany, [])
                          .slice(0, 3)
                          .map(task => (
                            <KooperListItem>
                              <KooperIcon
                                style={{
                                  paddingTop: '4px',
                                }}
                              >
                                <KooperCheckbox
                                  onClick={() => {
                                    if (!manageContactsCompanyPermissions) return
                                    let data = {}

                                    if (task.status === 'closed') {
                                      data = {
                                        status: 'open',
                                      }
                                    } else {
                                      data = {
                                        status: 'closed',
                                      }
                                    }

                                    dispatch(editTaskActivities(task.id, data))
                                  }}
                                  checked={task.status === 'closed'}
                                />
                              </KooperIcon>
                              <KooperListContent>
                                <KooperListHeader
                                  as="a"
                                  style={
                                    task.status === 'closed'
                                      ? {
                                          textDecoration: 'line-through',
                                        }
                                      : null
                                  }
                                  onClick={() => {
                                    if (manageContactsCompanyPermissions) {
                                      dispatch(getTaskActivities(task.id))
                                      setIsViewTaskOpen(true)
                                    }
                                  }}
                                >
                                  {task.subject ? wrapText(task.subject, 20) : ''}
                                  <span
                                    style={{
                                      float: 'right',
                                    }}
                                  >
                                    {get(['assignee'], task, '-')}
                                  </span>
                                </KooperListHeader>
                                {task.status === 'closed' ? null : (
                                  <KooperListDescription>
                                    Due:
                                    {GetFormattedDate(get(['endAt'], task, ''))}
                                  </KooperListDescription>
                                )}
                              </KooperListContent>
                            </KooperListItem>
                          ))
                      : ''}
                  </KooperList>
                  {get(['tasks'], singleCompany, []).length === 0 ? <p className="kooper-lead mb-0">No Task</p> : null}
                  <KooperTooltip
                    content={
                      manageContactsCompanyPermissions
                        ? 'Add Task'
                        : getPermissionTooltipContent({roleAccess: manageContactsCompanyPermissions})
                    }
                    size="mini"
                    position="top center"
                    trigger={
                      <KooperButton
                        className="mt-3 ml-0 delete-forecast"
                        icon
                        onClick={() => manageContactsCompanyPermissions && setIsTaskOpen(!isTaskOpen)}
                      >
                        <SvgIcon path="common/plus" />
                      </KooperButton>
                    }
                  />
                </KooperAccordionContent>
              </KooperAccordion>
            </KooperCardContent>
          </KooperCard>

          <KooperCard fluid>
            <KooperCardContent className="p-0">
              <KooperAccordion styled className="kooper-accordion">
                <KooperAccordionTitle
                  active={activeIndex === 4}
                  index={4}
                  onClick={(e, titleProps) => {
                    const {index} = titleProps
                    const newIndex = activeIndex === index ? -1 : index
                    setActiveIndex(newIndex)
                  }}
                >
                  Files ({_.size(_.get(singleCompany, 'attachments'))})
                  <span>{activeIndex === 4 ? <SvgIcon path="common/down" /> : <FiChevronRight />}</span>
                </KooperAccordionTitle>

                <KooperAccordionContent active={activeIndex === 4} className="pt-0">
                  <LockPermissionTooltip isRoleAccessDenied={!manageContactsCompanyPermissions}>
                    <KooperInput
                      id="fileUpload"
                      type="file"
                      multiple
                      disabled={!manageContactsCompanyPermissions}
                      onChange={e => {
                        const data = {
                          file: e.target.files,
                        }
                        dispatch(uploadAttachmentsCompany(getCompanyId, data))
                      }}
                    />
                  </LockPermissionTooltip>
                  {get(['attachments'], singleCompany, []).map(item => (
                    <div>{item.name}</div>
                  ))}
                  {_.size(_.get(singleCompany, 'attachments')) === 0 && <p className="kooper-lead">No Files</p>}
                </KooperAccordionContent>
              </KooperAccordion>
            </KooperCardContent>
          </KooperCard>
        </KooperTabPane>
      ),
    },
  ]

  return (
    <KooperModal
      closeIcon={<SvgIcon path="common/close" className="closeIcon" />}
      className="kooper-modal"
      open={isModalOpen}
      onClose={() => setIsModalOpen(!isModalOpen)}
    >
      <KooperModalHeader>Preview Companies</KooperModalHeader>
      <KooperModalContent scrolling style={{maxHeight: 'calc(100vh - 47.5px)'}}>
        <KooperHeader className="basicNameHeader mb-0">
          <KooperIcon>
            <AccountUserImage />
          </KooperIcon>
          <KooperHeaderContent>
            <h4 className="mb-0">{_.get(singleCompany, 'name') || ''}</h4>
            <div className="d-flex">
              <KooperTooltip
                size="mini"
                position="bottom center"
                content={
                  manageContactsCompanyPermissions
                    ? singleCompany.isFollowed
                      ? 'Remove Favorite'
                      : 'Add Favorite'
                    : getPermissionTooltipContent({roleAccess: manageContactsCompanyPermissions})
                }
                trigger={
                  <KooperRating
                    icon="star"
                    className="favStar"
                    onClick={() =>
                      manageContactsCompanyPermissions &&
                      dispatch(
                        editCompany(
                          {
                            isFollowed: !singleCompany.isFollowed,
                          },
                          getCompanyId
                        )
                      )
                    }
                    rating={singleCompany.isFollowed ? 1 : 0}
                  />
                }
              />
              <KooperTooltip
                size="mini"
                position="bottom center"
                content="Leads score to identify potential companies"
                trigger={
                  <KooperLabel size="small" circular color="green" className="leadScore">
                    65 <FiArrowUp />
                  </KooperLabel>
                }
              />
            </div>
            <KooperHeaderSubheader>
              {_.size(_.get(singleCompany, 'contacts', 0))} Contacts
              {_.get(singleCompany, 'domain') || ''}
              {`${_.get(singleCompany, 'address.state') || ''} ${_.get(singleCompany, 'address.country') || ''}`}
            </KooperHeaderSubheader>
          </KooperHeaderContent>
        </KooperHeader>
        <div className="d-flexb">
          <SocialIcons
            singleCompany={singleCompany}
            id={getCompanyId}
            identifier="companies"
            rolePermissions={manageContactsCompanyPermissions}
          />
          <div className="mailActivity">
            <KooperDropdown button icon="chevron down" text="More Action">
              <KooperDropdownMenu>
                <LockPermissionTooltip isRoleAccessDenied={!manageContactsCompanyPermissions}>
                  <KooperDropdownItem onClick={() => manageContactsCompanyPermissions && setIsNoteOpen(!isNoteOpen)}>
                    Add Note
                  </KooperDropdownItem>
                </LockPermissionTooltip>
                <LockPermissionTooltip isRoleAccessDenied={!manageContactsCompanyPermissions}>
                  <KooperDropdownItem onClick={() => manageContactsCompanyPermissions && setIsEmailOpen(!isEmailOpen)}>
                    Add Email
                  </KooperDropdownItem>
                </LockPermissionTooltip>
                <LockPermissionTooltip isRoleAccessDenied={!manageTasksPermissions}>
                  <KooperDropdownItem onClick={() => manageTasksPermissions && setIsTaskOpen(!isTaskOpen)}>
                    Add Task
                  </KooperDropdownItem>
                </LockPermissionTooltip>
              </KooperDropdownMenu>
            </KooperDropdown>
          </div>
        </div>
        <KooperTab className="preview-tabs" menu={{color: 'blue', secondary: true, pointing: true}} panes={panes} />
      </KooperModalContent>
      {isNoteOpen && <AddNote isNoteOpen={isNoteOpen} companyId={getCompanyId} setIsNoteOpen={setIsNoteOpen} />}
      {isTaskOpen && (
        <CreateTask
          setIsCreateTaskOpen={setIsTaskOpen}
          isCreateTaskOpen={isTaskOpen}
          conversation={get(['conversation'], props)}
          companyId={getCompanyId}
          isDataAvailabel
        />
      )}
      {createNewLabel()}
      {isViewTaskOpen && <ViewTask setIsViewTaskOpen={setIsViewTaskOpen} isViewTaskOpen={isViewTaskOpen} />}
    </KooperModal>
  )
}

export default PreviewModal
