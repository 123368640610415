/* eslint-disable no-nested-ternary */
import React, {useState, useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {FiExternalLink} from 'react-icons/fi'
import _ from 'lodash'
import {
  KooperButton,
  KooperTooltip,
  KooperDropdown,
  KooperTable,
  KooperTableBody,
  KooperTableCell,
  KooperTableHeader,
  KooperTableHeaderCell,
  KooperTableRow,
  KooperLabel,
  KooperTablePlaceholder,
  KooperDropdownItem,
  KooperDropdownMenu,
  KooperHeader,
  KooperIcon,
} from 'kooper-library'

import {deleteMeetings, getMeetingsList} from 'actions/meeting'
import {DELETE_MEETINGS, GET_MEETING_LIST, GET_USERS} from 'actions/types'
import DeleteModal from 'components/common/DeleteModal'
import {getPermissionTooltipContent, startCase} from 'utils/helper'
import Meetings from 'components/pages/Settings/Sales/Meetings/GoogleCalIntergation'
import CreateFirst from 'components/common/CreateFirst'
import create from 'assets/create.svg'
import {useHistory} from 'react-router'
import PageLoader from 'components/common/PageLoader'
import useApiLoader from 'hooks/pure/useApiLoader'
import useUserPermissions from 'hooks/pure/useUserPermissions'
import LockPermissionTooltip from 'components/common/LockPermissionTooltip'
import SvgIcon from 'components/common/SvgIcon'
import {getUsers} from 'actions/settings'
import useApiResponse from 'hooks/impure/useApiResponse'
import CreateMeetingModal from './CreateMeetingModal'
import EmbedModal from './EmbedModal'

const meetingType = [
  {key: 'All', value: 'all', text: 'All'},
  {key: 1, value: 'personal', text: 'Personal'},
  {key: 2, value: 'team', text: 'Team'},
]

const MeetingList = props => {
  const dispatch = useDispatch()
  const history = useHistory()

  const [isCreateModal, setIsCreateModal] = useState(false)
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
  const [isEmbedModalOpen, setIsEmbedModalOpen] = useState(false)
  const [singleIdForDelete, setSingleIdForDelete] = useState(null)
  const [userOption, setUserOption] = useState([])
  const [filter, setFilter] = useState({})
  const [authGoogle, setAuthGoogle] = useState(false)
  const [copyLink, setCopyLink] = useState('Copy')
  const [mtId, setMtId] = useState(null)

  const {meetingsList = {}} = useSelector(state => state.meetings)
  const {meetingList = [], calendarList = []} = meetingsList
  const {successLabels = [], isLoadingData, type} = useSelector(state => state.apiReducer)

  const {salesAndCrmAccess, meetingsPermissions} = useUserPermissions()
  const meetingsAccess = salesAndCrmAccess?.meetings
  const manageMeetingsPermissions = meetingsPermissions?.manage_meetings

  const {isLoading} = useApiLoader({label: GET_MEETING_LIST})

  useEffect(() => {
    if (successLabels.includes(GET_MEETING_LIST)) {
      setAuthGoogle(true)
      // dispatch(calendarList());
    }
  }, [successLabels])

  useApiResponse({
    action: getUsers,
    dependency: [meetingsAccess],
    enabled: meetingsAccess,
    label: GET_USERS,
    storePath: 'settings.getUsersList',
    onSuccess: getUsersList => {
      const list = getUsersList?.users?.map(user => ({
        key: user.id,
        value: user.id,
        text: `${user.firstName} ${user.lastName}`,
      }))
      setUserOption(list || [])
    },
  })

  const handleSingleDelete = id => {
    setIsDeleteModalOpen(!isDeleteModalOpen)
    setSingleIdForDelete(id)
  }

  const renderMeetingsList = () => {
    if (meetingList.length > 0) {
      return meetingList.map((list, index) => (
        <KooperTableRow
          // disabled={!list.googleSync}
          className="tableLink"
          onClick={() => {
            history.push({
              pathname: `/meeting/${list.id}`,
            })
          }}
        >
          <KooperTableCell className="pl-4">{index + 1}</KooperTableCell>
          <KooperTableCell className="prevCell ">
            <KooperHeader as="h5"> {startCase(list.meetingName)} </KooperHeader>
            <div className="PrevActPart" onClick={e => e.stopPropagation()}>
              <KooperLabel
                basic
                onClick={e => {
                  e.stopPropagation()
                  setCopyLink('Copied')
                  navigator.clipboard.writeText(list.url)
                }}
              >
                {copyLink} Link
              </KooperLabel>

              <a target="_blank" href={list.url} rel="noreferrer">
                <KooperButton
                  className="mx-3"
                  icon={
                    <KooperTooltip
                      content="External Link"
                      position="top center"
                      size="mini"
                      trigger={<FiExternalLink />}
                    />
                  }
                />
              </a>
            </div>
          </KooperTableCell>
          <KooperTableCell>
            {list.user ? `${_.get(list, 'user.firstName') || ''} ${_.get(list, 'user.lastName') || ''}` : '--'}
          </KooperTableCell>
          <KooperTableCell>{(list.type === 1 ? 'Personal' : list.type === 2 && 'Team') || '--'}</KooperTableCell>
          <KooperTableCell>{(list.meetingDuration || []).map(a => (a !== null ? `${a} min ` : '--'))}</KooperTableCell>
          <KooperTableCell>{list.views || 0}</KooperTableCell>
          <KooperTableCell>{list.booking || 0}</KooperTableCell>
          <KooperTableCell>{`${list.conversionRate || 0}%`}</KooperTableCell>
          <KooperTableCell>
            <KooperDropdown
              icon={<SvgIcon path="common/more-verticle" id="TooltipProductInbox" />}
              button
              className="icon"
              direction="left"
            >
              <KooperDropdownMenu>
                <KooperDropdownItem
                  onClick={() => {
                    setMtId(list.url)
                    setIsEmbedModalOpen(!isEmbedModalOpen)
                  }}
                >
                  <KooperIcon name="code" /> Embed
                </KooperDropdownItem>
                <LockPermissionTooltip isRoleAccessDenied={!manageMeetingsPermissions}>
                  <KooperDropdownItem
                    onClick={e => {
                      e.stopPropagation()
                      e.preventDefault()
                      if (manageMeetingsPermissions) {
                        handleSingleDelete(list.id)
                      }
                    }}
                  >
                    <KooperIcon name="trash alternate" /> Delete
                  </KooperDropdownItem>
                </LockPermissionTooltip>
              </KooperDropdownMenu>
            </KooperDropdown>
          </KooperTableCell>
        </KooperTableRow>
      ))
    }
  }

  useEffect(() => {
    if (meetingsAccess) {
      if (filter) {
        dispatch(getMeetingsList(filter))
      }
    }
  }, [meetingsAccess, filter, dispatch])

  if (isLoading && meetingsAccess) {
    return <PageLoader />
  }

  return (
    <>
      {_.size(calendarList) !== 0 ? (
        <>
          {_.size(meetingList) === 0 && _.isEmpty(filter) ? (
            <div>
              <CreateFirst
                Feature1="Manage multiple calendar meetings"
                Feature2="Configure meeting as per your requirement"
                Feature3="Automatic reminder before the meeting"
                list1="You can integrate multiple calendars in kooper sales so that you can create a full customisable meeting on our own specific email calendar"
                list2="Kooper sales offers a vast variety of customisations in meeting creation and hence you can set meeting settings as per your company requirements."
                list3="Get meeting reminders before the meeting for the smooth conduct of the scheduled meeting."
                addFirst={() => manageMeetingsPermissions && setIsCreateModal(!isCreateModal)}
                buttonText="Create Meeting"
                tooltip={!manageMeetingsPermissions}
                tooltipContent={getPermissionTooltipContent({roleAccess: manageMeetingsPermissions})}
                img={create}
                header="Meetings"
                subHeader="Kooper Sales gives you the feature to schedule and manage your meetings for multiple google calendars at one place"
              />
            </div>
          ) : (
            meetingList && (
              <div className="pageBodyWrap">
                <div className="pageHeader px-0">
                  <div className="pageTitle">
                    <div className="pageTitleIcon">
                      <SvgIcon path="settings/users" />
                      <h5>
                        All Meetings
                        {_.size(meetingList) !== 0 && `(${_.size(meetingList)})`}
                      </h5>
                    </div>
                  </div>
                  <div className="page-action">
                    <KooperTooltip
                      size="mini"
                      position="top center"
                      content="Meeting Type"
                      trigger={
                        <KooperDropdown
                          selection
                          defaultValue="all"
                          options={meetingType}
                          button
                          direction="left"
                          onChange={(e, {value}) => {
                            setFilter({
                              ...filter,
                              type: value,
                            })
                          }}
                        />
                      }
                    />
                    <KooperTooltip
                      size="mini"
                      position="top center"
                      content="Owner"
                      trigger={
                        <KooperDropdown
                          selection
                          defaultValue=" "
                          options={[
                            {
                              key: 'All Meetings',
                              value: ' ',
                              text: 'All Meetings',
                            },
                            ...userOption,
                          ]}
                          direction="left"
                          button
                          onChange={(e, {value}) => {
                            setFilter({
                              ...filter,
                              owner: value,
                            })
                          }}
                        />
                      }
                    />
                    <LockPermissionTooltip isRoleAccessDenied={!manageMeetingsPermissions}>
                      <KooperButton
                        primary
                        content="Create Meeting"
                        className={!manageMeetingsPermissions ? 'disabled-button' : ''}
                        onClick={() => manageMeetingsPermissions && setIsCreateModal(!isCreateModal)}
                      />
                    </LockPermissionTooltip>
                  </div>
                </div>
                <KooperTable basic selectable className="m-0">
                  <KooperTableHeader>
                    <KooperTableRow>
                      <KooperTableHeaderCell className="pl-4">#</KooperTableHeaderCell>
                      <KooperTableHeaderCell>Meeting Name</KooperTableHeaderCell>
                      <KooperTableHeaderCell>OWNER</KooperTableHeaderCell>
                      <KooperTableHeaderCell>TYPE</KooperTableHeaderCell>
                      <KooperTableHeaderCell>DURATION</KooperTableHeaderCell>
                      <KooperTableHeaderCell>VIEWS</KooperTableHeaderCell>
                      <KooperTableHeaderCell>BOOKING</KooperTableHeaderCell>
                      <KooperTableHeaderCell>CONVERSION RATE</KooperTableHeaderCell>
                      <KooperTableHeaderCell>Action</KooperTableHeaderCell>
                    </KooperTableRow>
                  </KooperTableHeader>
                  <KooperTableBody>
                    {isLoadingData && type.includes(GET_MEETING_LIST) ? (
                      <KooperTablePlaceholder columns={9} />
                    ) : meetingList.length > 0 ? (
                      renderMeetingsList()
                    ) : (
                      <tr>
                        <td colSpan="8" className="emptyValue">
                          No Data Available
                        </td>
                      </tr>
                    )}
                  </KooperTableBody>
                </KooperTable>
                {isDeleteModalOpen && (
                  <DeleteModal
                    isModalOpen={isDeleteModalOpen}
                    setIsModalOpen={setIsDeleteModalOpen}
                    modalText="Are you sure you want to delete ?"
                    deleteAction={deleteMeetings}
                    idTobeDeleted={singleIdForDelete}
                    type={DELETE_MEETINGS}
                  />
                )}
                <EmbedModal isModalOpen={isEmbedModalOpen} setIsModalOpen={setIsEmbedModalOpen} url={mtId} />
              </div>
            )
          )}
        </>
      ) : (
        _.size(calendarList) === 0 && <Meetings style={{padding: '60px'}} />
      )}
      {isCreateModal && <CreateMeetingModal isModalOpen={isCreateModal} setIsModalOpen={setIsCreateModal} {...props} />}
    </>
  )
}
export default MeetingList
