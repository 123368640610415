import React from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
import MyProfile from 'components/pages/Settings/MyProfile'
import UnSavedWrap from 'components/hoc/UnSavedWrap'
import Users from 'components/pages/Settings/MyOrganisation/Users/Users'
import UsersDetails from 'components/pages/Settings/MyOrganisation/Users/UsersDetails'
import {ChatMessenger} from 'components/pages/Settings/Channels/ChatMessenger'
import DocumentTitle from 'components/common/DocumentTitle'
import {ChatPages} from 'components/pages/Settings/Channels/ChatPages'
import {InboxTab} from 'components/pages/Settings/Channels/EmailInbox/InboxTab'
import {InboxEdit} from 'components/pages/Settings/Channels/EmailInbox/InboxEdit'
import Sms from 'components/pages/Settings/Channels/SMS/Sms'
import SmsDetail from 'components/pages/Settings/Channels/SMS/SmsDetail'
// import Twitter from 'components/pages/Settings/Channels/Twitter/Twitter'
// import TwitterDetails from 'components/pages/Settings/Channels/Twitter/TwitterDetails'
import Facebook from 'components/pages/Settings/Channels/Facebook/Facebook'
import FacebookDetails from 'components/pages/Settings/Channels/Facebook/FacebookDetails'
import FeedbackList from 'components/pages/Settings/Channels/Feedback/FeedbackList'
import Feedback from 'components/pages/Settings/Channels/Feedback/Feedback'
import Appearance from 'components/pages/Settings/Messenger/Appearance'
import Configure from 'components/pages/Settings/Messenger/Configure'
import Advanced from 'components/pages/Settings/Messenger/Advanced'
import OfflineExperience from 'components/pages/Settings/Messenger/OfflineExperience'
import Routing from 'components/pages/Settings/Automations/Routing/Routing'
import RoutingForm from 'components/pages/Settings/Automations/Routing/RoutingForm'
import BusinessHours from 'components/pages/Settings/Automations/BusinessHours/BusinessHours'
import BusinessHoursForm from 'components/pages/Settings/Automations/BusinessHours/BusinessHoursForm'
import SLA from 'components/pages/Settings/Automations/sla/Sla'
import SlaForm from 'components/pages/Settings/Automations/sla/SlaForm'
import ContactType from 'components/pages/Settings/DataManagement/Customization/ContactTypes'
import Tags from 'components/pages/Settings/Productivity/Tags'
import Currencies from 'components/pages/Settings/Sales/Currencies/Currencies'
import CurrenciesDetails from 'components/pages/Settings/Sales/Currencies/CurrenciesDetails'
import Deals from 'components/pages/Settings/DataManagement/Customization/Deals/Deals'
import LossReason from 'components/pages/Settings/DataManagement/Customization/LossReason'
import Template from 'components/pages/Settings/Productivity/TaskTemplates/Template'
import TemplateDetails from 'components/pages/Settings/Productivity/TaskTemplates/TemplateDetails'
import Source from 'components/pages/Settings/DataManagement/Customization/Sources'
import Goals from 'components/pages/Settings/Sales/Forecasting'
import EmailSurvey from 'components/pages/Settings/CustomerSatisfaction/EmailSurvey/EmailSurvey'
import EmailSurveyForm from 'components/pages/Settings/CustomerSatisfaction/EmailSurvey/EmailSurveyForm'
import ChatSurvey from 'components/pages/Settings/CustomerSatisfaction/ChatSurvey/ChatSurvey'
import ChatSurveyForm from 'components/pages/Settings/CustomerSatisfaction/ChatSurvey/ChatSurveyForm'
import AuditLogs from 'components/pages/Settings/Productivity/AuditLogs'
import Teams from 'components/pages/Settings/MyOrganisation/Teams/Teams'
import TeamsDetails from 'components/pages/Settings/MyOrganisation/Teams/TeamsDetails'
import AccountSettings from 'components/pages/Settings/MyOrganisation/AccountSettings'
import Imports from 'components/pages/Settings/DataManagement/Import'
import Exports from 'components/pages/Settings/DataManagement/Export'
import Activities from 'components/pages/Settings/DataManagement/Activities'
import ContactStatus from 'components/pages/Settings/DataManagement/Customization/ContactStatus'
import PrefixList from 'components/pages/Sales/Quotes/Prefix/PrefixList'
import EmailTemplate from 'components/pages/Settings/Productivity/EmailTemplates/EmailTemplate'
import EmailTemplateDetails from 'components/pages/Settings/Productivity/EmailTemplates/EmailTemplateDetails'
import ProjectCategories from 'components/pages/Settings/Projects/ProjectCategories'
import Meetings from 'components/pages/Settings/Sales/Meetings/GoogleCalIntergation'
import GoogleCalendar from 'components/pages/Settings/Sales/Meetings/GoogleCalendar'
import Holidays from 'components/pages/Settings/MyOrganisation/Holidays/Holidays'
import ScoringTab from 'components/pages/Settings/Sales/ScoringRules/ScoringTab'
import MyPlan from 'components/pages/Settings/Billing/MyPlan/MyPlan'
import Invoices from 'components/pages/Settings/Billing/Invoices/Invoices'
import InvoiceDetail from 'components/pages/Settings/Billing/Invoices/InvoiceDetail'
import useUserPermissions from 'hooks/pure/useUserPermissions'
import Roles from 'components/pages/Settings/MyOrganisation/Roles/Roles'
import RolesDetails from 'components/pages/Settings/MyOrganisation/Roles/RolesDetails'
import TimeTracking from 'components/pages/Settings/Automations/TimeTracking/TimeTracking'
import DeveloperApi from 'components/pages/Settings/ApiAndIntegrations/DeveloperApi'

const SettingRoutes = () => {
  const {
    clientManagementAccess,
    projectsAndCollaborationAccess,
    workflowAndAutomationAccess,
    generalAccess,
    teamAndUsersAccess,
  } = useUserPermissions()
  const contactFormsAccess = clientManagementAccess?.contactForms
  const projectsAccess = projectsAndCollaborationAccess?.projects
  const slaManagementAccess = workflowAndAutomationAccess?.slaManagement
  const taskTemplatesAccess = workflowAndAutomationAccess?.taskTemplates
  const chatSurveysAccess = generalAccess?.chatSurveys
  const customRolesAndPermisionAccess = teamAndUsersAccess?.customRolesAndPermision

  const settingsRoutes = [
    // My Settings
    {path: '/settings/my-profile', component: MyProfile, title: 'My Profile', unsavedWrap: true, exact: true},
    // My Organization
    {
      path: '/settings/account-settings',
      component: AccountSettings,
      title: 'Account Setting',
      unsavedWrap: true,
      exact: true,
    },
    {path: '/settings/users', component: Users, title: 'Users', unsavedWrap: true, exact: true},
    {path: '/settings/users/:id', component: UsersDetails, title: 'Users', unsavedWrap: true, exact: true},
    {path: '/settings/teams', component: Teams, title: 'Teams', unsavedWrap: true, exact: true},
    {path: '/settings/teams/:id', component: TeamsDetails, title: 'Teams', unsavedWrap: true, exact: true},
    {path: '/settings/roles', component: Roles, title: 'Roles', unsavedWrap: true, exact: true},
    {path: '/settings/roles/:roleId', component: RolesDetails, title: 'Roles', unsavedWrap: true, exact: true},
    {path: '/settings/holidays', component: Holidays, title: 'Holidays', unsavedWrap: true, exact: true},
    // Channels
    {path: '/settings/chat-messenger', component: ChatMessenger, title: 'Chat Messenger', exact: true},
    {path: '/settings/chat-pages', component: ChatPages, title: 'Chat Pages', unsavedWrap: true, exact: true},
    {path: '/settings/emails', component: InboxTab, title: 'Email', unsavedWrap: true, exact: true},
    {
      path: '/settings/emails/:inboxId',
      component: InboxEdit,
      title: 'Email',
      unsavedWrap: true,
      exact: true,
    },
    {path: '/settings/sms', component: Sms, title: 'SMS', unsavedWrap: true, exact: true},
    {path: '/settings/sms/:inboxId', component: SmsDetail, title: 'SMS', unsavedWrap: true, exact: true},
    // Commented out Twitter routes
    // {
    //   path: "/settings/twitter",
    //   component: Twitter,
    //   title: "Twitter",
    //   unsavedWrap: true,
    //   exact: true,
    // },
    // {
    //   path: "/settings/twitter/:id",
    //   component: TwitterDetails,
    //   title: "Twitter",
    //   unsavedWrap: true,
    //   exact: true,
    // },
    {path: '/settings/facebook', component: Facebook, title: 'Facebook', unsavedWrap: true, exact: true},
    {path: '/settings/facebook/:id', component: FacebookDetails, title: 'Facebook', unsavedWrap: true, exact: true},
    {path: '/settings/contact-form', component: FeedbackList, title: 'Contact Form', unsavedWrap: true, exact: true},
    {path: '/settings/contact-form/:id', component: Feedback, title: 'Contact Form', unsavedWrap: true, exact: true},
    // Messenger
    {path: '/settings/appearance', component: Appearance, title: 'Appearance', unsavedWrap: true, exact: true},
    {path: '/settings/configure', component: Configure, title: 'Configure', unsavedWrap: true, exact: true},
    {path: '/settings/advanced', component: Advanced, title: 'Advanced', unsavedWrap: true, exact: true},
    {path: '/settings/offline-experience', component: OfflineExperience, title: 'Offline Experience', exact: true},
    // Automations
    {path: '/settings/routing', component: Routing, title: 'Routing', unsavedWrap: true, exact: true},
    {path: '/settings/routing/:id', component: RoutingForm, title: 'Routing', unsavedWrap: true, exact: true},
    {
      path: '/settings/business-hours',
      component: BusinessHours,
      title: 'Business Hours',
      unsavedWrap: true,
      exact: true,
    },
    {
      path: '/settings/business-hours/:id',
      component: BusinessHoursForm,
      title: 'Business Hours',
      unsavedWrap: true,
      exact: true,
    },
    {path: '/settings/sla-policy', component: SLA, title: 'SLA Policy', unsavedWrap: true, exact: true},
    {path: '/settings/sla-policy/:id', component: SlaForm, title: 'SLA Policy', unsavedWrap: true, exact: true},
    {path: '/settings/time-tracking', component: TimeTracking, title: 'Time Tracking', unsavedWrap: true, exact: true},
    // productivity
    {path: '/settings/tags', component: Tags, title: 'Tags', exact: true},
    {
      path: '/settings/email-templates',
      component: EmailTemplate,
      title: 'Email Templates',
      unsavedWrap: true,
      exact: true,
    },
    {
      path: '/settings/email-templates/:id',
      component: EmailTemplateDetails,
      title: 'Email Templates',
      unsavedWrap: true,
      exact: true,
    },
    {path: '/settings/task-template', component: Template, title: 'Task Template', unsavedWrap: true, exact: true},
    {
      path: '/settings/task-template/:id',
      component: TemplateDetails,
      title: 'Task Template',
      unsavedWrap: true,
      exact: true,
    },
    {path: '/settings/audit-logs', component: AuditLogs, title: 'Audit Log', unsavedWrap: true, exact: true},
    // Sales
    {path: '/settings/currencies', component: Currencies, title: 'Currencies', unsavedWrap: true, exact: true},
    {
      path: '/settings/currencies/:id',
      component: CurrenciesDetails,
      title: 'Currencies',
      unsavedWrap: true,
      exact: true,
    },
    {path: '/settings/goals', component: Goals, title: 'Goals', unsavedWrap: true, exact: true},
    {path: '/settings/meetings', component: Meetings, title: 'Meetings', unsavedWrap: true, exact: true},
    {path: '/settings/meetings/calendar', component: GoogleCalendar, title: 'Calendar', unsavedWrap: true, exact: true},
    {path: '/settings/scoring', component: ScoringTab, title: 'Scoring rules', exact: true},
    // Data Management
    {
      path: '/settings/contact-status',
      component: ContactStatus,
      title: 'Contact Status',
      unsavedWrap: true,
      exact: true,
    },
    {path: '/settings/contact-types', component: ContactType, title: 'Contact Types', unsavedWrap: true, exact: true},
    {path: '/settings/sources', component: Source, title: 'Sources', exact: true},
    {path: '/settings/loss-reasons', component: LossReason, title: 'Loss Reasons', exact: true},
    {path: '/settings/deals', component: Deals, title: 'Deals', exact: true},
    {path: '/settings/activities', component: Activities, title: 'Activities', unsavedWrap: true, exact: true},
    {path: '/settings/quotes-prefix', component: PrefixList, title: 'Quotes Prefix', unsavedWrap: true, exact: true},
    {path: '/settings/import', component: Imports, title: 'Import', unsavedWrap: true, exact: true},
    {path: '/settings/export', component: Exports, title: 'Export', unsavedWrap: true, exact: true},
    // Projects
    {
      path: '/settings/project-categories',
      component: ProjectCategories,
      title: 'Project Categories',
      unsavedWrap: true,
      exact: true,
    },
    {path: '/settings/email-survey', component: EmailSurvey, title: 'Email Survey', unsavedWrap: true, exact: true},
    {
      path: '/settings/email-survey/:id',
      component: EmailSurveyForm,
      title: 'Email Survey',
      unsavedWrap: true,
      exact: true,
    },
    {path: '/settings/chat-survey', component: ChatSurvey, title: 'Chat Survey', unsavedWrap: true, exact: true},
    {
      path: '/settings/chat-survey/:id',
      component: ChatSurveyForm,
      title: 'Chat Survey',
      unsavedWrap: true,
      exact: true,
    },
    // Billing
    {path: '/settings/my-plan', component: MyPlan, title: 'My Plan', unsavedWrap: true, exact: true},
    {path: '/settings/invoices', component: Invoices, title: 'Invoices', unsavedWrap: true, exact: true},
    {
      path: '/settings/invoices/:invoiceId',
      component: InvoiceDetail,
      title: 'Invoices',
      unsavedWrap: true,
      exact: true,
    },
    {path: '/settings/developer-api', exact: true, component: DeveloperApi, title: 'Developer API', unsavedWrap: true},
  ]

  const renderRoutes = ({path, exact = false, component: Component, title, unsavedWrap = false}) => {
    return (
      <Route
        key={path}
        path={path}
        exact={exact}
        render={props => (
          <>
            <DocumentTitle title={title} settings />
            <UnSavedWrap unsavedWrap={unsavedWrap}>
              <Component {...props} />
            </UnSavedWrap>
          </>
        )}
      />
    )
  }

  const ConditionalRoute = ({
    path,
    exact = false,
    component: Component,
    condition = false,
    fallbackRoute,
    title,
    unsavedWrap = false,
  }) => {
    return (
      <Route
        path={path}
        exact={exact}
        render={props =>
          condition ? (
            <>
              <DocumentTitle title={title} settings />
              <UnSavedWrap unsavedWrap={unsavedWrap}>
                <Component {...props} />
              </UnSavedWrap>
            </>
          ) : (
            <Redirect to={fallbackRoute} />
          )
        }
      />
    )
  }

  const conditionalRoutes = [
    {
      path: '/settings/roles/:roleId',
      exact: true,
      component: RolesDetails,
      condition: customRolesAndPermisionAccess,
      fallbackRoute: '/settings/roles',
      title: 'Roles',
      unsavedWrap: true,
    },
    {
      path: '/settings/contact-form/:id',
      exact: true,
      component: Feedback,
      condition: contactFormsAccess,
      fallbackRoute: '/settings/contact-form',
      title: 'Contact Form',
      unsavedWrap: true,
    },
    {
      path: '/settings/sla-policy/:id',
      exact: true,
      component: SlaForm,
      condition: slaManagementAccess,
      fallbackRoute: '/settings/sla-policy',
      title: 'SLA Policy',
      unsavedWrap: true,
    },
    {
      path: '/settings/task-template/:id',
      exact: true,
      component: TemplateDetails,
      condition: taskTemplatesAccess,
      fallbackRoute: '/settings/task-template',
      title: 'Task Template',
      unsavedWrap: true,
    },
    {
      path: '/settings/chat-survey/:id',
      exact: true,
      component: ChatSurveyForm,
      condition: chatSurveysAccess,
      fallbackRoute: '/settings/chat-survey',
      title: 'Chat Survey',
      unsavedWrap: true,
    },
  ]

  return (
    <Switch>
      {settingsRoutes.map(renderRoutes)}

      {conditionalRoutes.map(route => (
        <ConditionalRoute key={route.path} {...route} />
      ))}

      <Route path="/settings/*">
        <Redirect to="/settings/my-profile" />
      </Route>
    </Switch>
  )
}
export default SettingRoutes
