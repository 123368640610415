/* eslint-disable no-use-before-define */
import React, {useEffect, useRef, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {FiChevronRight, FiExternalLink} from 'react-icons/fi'
import _ from 'lodash'
import moment from 'moment'
import {
  KooperAccordion,
  KooperAccordionContent,
  KooperAccordionTitle,
  KooperButton,
  KooperCard,
  KooperCardContent,
  KooperCheckbox,
  KooperForm,
  KooperFormField,
  KooperFormInput,
  KooperFormSelect,
  KooperGrid,
  KooperGridColumn,
  KooperIcon,
  KooperInput,
  KooperLabel,
  KooperList,
  KooperListContent,
  KooperListDescription,
  KooperListHeader,
  KooperListItem,
  KooperTooltip,
} from 'kooper-library'

import {editContact, uploadAttachmentsContact} from 'actions/contacts'
import {
  get,
  getFormattedDate,
  GetFormattedDate,
  getPermissionTooltipContent,
  removeDoubleQuotes,
  startCase,
  wrapText,
} from 'utils/helper'
import CreateTask from 'components/pages/Projects/Tasks/CreateTask'
import Tags from 'components/common/Tags'
import {editTaskActivities, getTaskActivities} from 'actions/activities'
import ViewTask from 'components/pages/Projects/Tasks/ViewTask'
import {addNoteInContacts} from 'actions/customers'
import useUserPermissions from 'hooks/pure/useUserPermissions'
import LockPermissionTooltip from 'components/common/LockPermissionTooltip'
import SvgIcon from 'components/common/SvgIcon'
import {useHistory} from 'react-router-dom'

const ContactSummary = props => {
  const {contactId, getValues, errors, handleSubmit, updateContact, control, Controller, noteText, setNoteText} = props

  const dispatch = useDispatch()
  const history = useHistory()

  const refForm = useRef()
  const [activeIndex, setActiveIndex] = useState(0)
  const [userOption, setUserOption] = useState([])
  const [statusOptionList, setStatusOptionList] = useState([])
  const [typeList, setTypeList] = useState([])
  const [isCreateTaskOpen, setIsCreateTaskOpen] = useState(false)
  const [isViewTaskOpen, setIsViewTaskOpen] = useState(false)

  const {singleContact = {}, getTypeListData, contactStatusListData} = useSelector(state => state.contacts)
  const {interactions: getAllInterActionsData = [], interactionTime} = singleContact

  const {getUsersList = {}} = useSelector(state => state.settings)

  const {contactsAndCompaniesPermissions, tasksPermissions} = useUserPermissions()
  const manageContactsCompanyPermissions = contactsAndCompaniesPermissions?.manage_contacts_company
  const manageTasksPermissions = tasksPermissions?.manage_tasks

  useEffect(() => {
    if (getUsersList) {
      const list = getUsersList?.users?.map(user => ({
        value: user.id,
        text: `${get(['firstName'], user, '')} ${get(['lastName'], user, '')}`,
        key: user.id,
      }))
      setUserOption(list || [])
    }
  }, [getUsersList])

  useEffect(() => {
    if (contactStatusListData) {
      const list = contactStatusListData.map(item => ({
        value: item.id,
        text: item.status,
        key: item.id,
      }))
      setStatusOptionList(list)
    }
  }, [contactStatusListData])

  useEffect(() => {
    if (getTypeListData) {
      const companyList = getTypeListData.map(list => ({
        key: list.id,
        value: list.id,
        text: list.type,
      }))
      setTypeList(companyList)
    }
  }, [getTypeListData])

  const renderNotes = () => {
    const notesList = get(['notes'], singleContact, [])

    if (notesList.length > 0) {
      return notesList
        .filter((list, index) => index < 4)
        .map(list => (
          <KooperListItem className="kooper-list-item">
            <KooperListContent className="kooper-list-content" floated="right">
              <span>{GetFormattedDate(get(['createdAt'], list, ''))}</span>
            </KooperListContent>

            <KooperListHeader className="kooper-list-header">{get(['note'], list, '')}</KooperListHeader>
            <KooperListDescription className="kooper-list-description">
              By: {get(['agent'], list, '')}
            </KooperListDescription>
          </KooperListItem>
        ))
    }
  }

  const renderCreateTaskModal = () => {
    if (isCreateTaskOpen) {
      return (
        <CreateTask
          setIsCreateTaskOpen={setIsCreateTaskOpen}
          isCreateTaskOpen={isCreateTaskOpen}
          conversation={get(['conversation'], props)}
          contactId={contactId}
          isDataAvailabel
        />
      )
    }
  }

  const renderSummary = () => {
    return (
      <KooperForm
        ref={refForm}
        className="editForm errorLabel"
        {...(manageContactsCompanyPermissions && {onSubmit: handleSubmit(updateContact)})}
      >
        <KooperFormField>
          <label>Last Contacted</label>
          <label className="kooper-lead">{interactionTime ? GetFormattedDate(interactionTime) : '-'}</label>
        </KooperFormField>
        <KooperFormField>
          <label>Interactions</label>
          <label className="kooper-lead">{getAllInterActionsData ? getAllInterActionsData.length : ''}</label>
        </KooperFormField>
        <KooperFormField>
          <label>Inactive For</label>
          <label className="kooper-lead">{interactionTime ? moment(interactionTime).fromNow() : '-'}</label>
        </KooperFormField>
        <KooperFormField>
          <div className="info-header">
            <label>Contact Type</label>
            <KooperTooltip
              trigger={<SvgIcon path="common/question" />}
              content="Select type of the contact"
              size="mini"
              psoition="top center"
            />
          </div>
          <Controller
            name="contactTypeId"
            render={({onChange, value, onBlur}) => (
              <KooperFormSelect
                fluid
                options={typeList}
                selection
                placeholder="Contact type"
                value={value}
                onChange={(e, {value}) => {
                  onChange(value)
                  refForm.current.handleSubmit()
                }}
                onBlur={onBlur}
                disabled={!manageContactsCompanyPermissions}
                error={
                  errors?.contactTypeId && {
                    content: removeDoubleQuotes(errors?.contactTypeId?.message),
                  }
                }
              />
            )}
            control={control}
          />
        </KooperFormField>
        <KooperFormField>
          <div className="info-header">
            <label>Status</label>
            <KooperTooltip
              trigger={<SvgIcon path="common/question" />}
              content="Set current Status of contact"
              size="mini"
              psoition="top center"
            />
          </div>
          <Controller
            name="statusId"
            render={({onChange, value, onBlur}) => (
              <KooperFormSelect
                search
                fluid
                options={statusOptionList}
                selection
                placeholder="Status"
                value={value}
                onChange={(e, {value}) => {
                  onChange(value)
                  refForm.current.handleSubmit()
                }}
                onBlur={onBlur}
                disabled={!manageContactsCompanyPermissions}
                error={
                  errors?.statusId && {
                    content: removeDoubleQuotes(errors?.statusId?.message),
                  }
                }
              />
            )}
            control={control}
          />
        </KooperFormField>
        <KooperFormField>
          <div className="info-header">
            <label>Assignee</label>
            <KooperTooltip
              trigger={<SvgIcon path="common/question" />}
              content="Assign an agent to this contact"
              size="mini"
              psoition="top center"
            />
          </div>
          <Controller
            name="assigneeId"
            render={({onChange, value, onBlur}) => (
              <KooperFormSelect
                search
                fluid
                options={userOption}
                selection
                placeholder="Owner"
                value={value}
                onChange={(e, {value}) => {
                  onChange(value)
                  refForm.current.handleSubmit()
                }}
                onBlur={onBlur}
                disabled={!manageContactsCompanyPermissions}
                error={
                  errors?.assigneeId && {
                    content: removeDoubleQuotes(errors?.assigneeId?.message),
                  }
                }
              />
            )}
            control={control}
          />
        </KooperFormField>
      </KooperForm>
    )
  }

  const getProjectLabelColor = status => {
    if (status === 'active') {
      return 'yellow'
    }
    if (status === 'inactive') {
      return 'red'
    }
    if (status === 'archived') {
      return 'green'
    }
  }

  return (
    <div className="callwidget-accord">
      <KooperCard fluid>
        <KooperCardContent>
          <div className="staticData">
            <div className="dataOne">
              <p>Open Conversations</p>
              {/* <h4>{get(['openConversations'], contactDetails, '-')}</h4> */}
              <h4>-</h4>
            </div>
            <div className="dataOne">
              <p>Total Conversations</p>
              <h4>-</h4>
              {/* <h4>{get(['totalConversations'], contactDetails, '-')}</h4> */}
            </div>
          </div>

          <div className="staticData">
            <div className="dataOne">
              <p>Open Tasks</p>
              <h4>-</h4>
              {/* <h4>{get(['openTasks'], contactDetails, '-')}</h4> */}
            </div>
            <div className="dataOne">
              <p>Total Tasks</p>
              <h4>-</h4>
              {/* <h4>{get(['totalTasks'], contactDetails, '-')}</h4> */}
            </div>
          </div>
        </KooperCardContent>
      </KooperCard>

      <KooperCard fluid>
        <KooperCardContent className="p-0">
          <KooperAccordion styled className="kooper-accordion">
            <KooperAccordionTitle
              active={activeIndex === 0}
              index={0}
              onClick={(e, titleProps) => {
                const {index} = titleProps
                const newIndex = activeIndex === index ? -1 : index
                setActiveIndex(newIndex)
              }}
            >
              {!activeIndex ? <SvgIcon path="common/down" /> : <FiChevronRight />}
              Summary
            </KooperAccordionTitle>
            <KooperAccordionContent active={activeIndex === 0}>{renderSummary()}</KooperAccordionContent>
          </KooperAccordion>
        </KooperCardContent>
      </KooperCard>

      <KooperCard fluid>
        <KooperCardContent className="p-0">
          <KooperAccordion styled className="kooper-accordion">
            <KooperAccordionTitle
              active={activeIndex === 1}
              index={1}
              onClick={(e, titleProps) => {
                const {index} = titleProps
                const newIndex = activeIndex === index ? -1 : index
                setActiveIndex(newIndex)
              }}
            >
              {activeIndex === 1 ? <SvgIcon path="common/down" /> : <FiChevronRight />}
              Deals{' '}
              {get(['deals'], singleContact, []).length > 0 ? `(${get(['deals'], singleContact, []).length})` : ''}
            </KooperAccordionTitle>
            <KooperAccordionContent active={activeIndex === 1}>
              <KooperCardContent className="pt-0">
                {singleContact?.deals?.length > 0 ? (
                  singleContact?.deals?.map(deal => (
                    <KooperGrid key={deal.id}>
                      <KooperGridColumn width="12">
                        {/* <h2>{deal.name}</h2>
                        <p className="kooper-lead pb-2">Sales: {deal.status}</p> */}
                        <KooperList selection className="recentConv">
                          <KooperListItem>
                            <KooperListHeader>{deal.name}</KooperListHeader>
                            <KooperListDescription>Sales: {deal.status}</KooperListDescription>
                          </KooperListItem>
                        </KooperList>
                      </KooperGridColumn>
                      {/* <KooperGridColumn width="4" className="text-right">
                        <KooperTooltip
                          content="External"
                          size="mini"
                          position="top center"
                          trigger={
                            <KooperButton icon className="bg-btn">
                              <FiExternalLink />
                            </KooperButton>
                          }
                        />
                        <KooperTooltip
                          content="Delete"
                          size="mini"
                          position="top center"
                          trigger={
                            <KooperButton icon className="bg-btn">
                              <SvgIcon path="common/delete" />
                            </KooperButton>
                          }
                        />
                      </KooperGridColumn> */}
                    </KooperGrid>
                  ))
                ) : (
                  <p>No Deals Attached</p>
                )}
              </KooperCardContent>
            </KooperAccordionContent>
          </KooperAccordion>
        </KooperCardContent>
      </KooperCard>

      <KooperCard fluid>
        <KooperCardContent className="p-0 tags-contact">
          <KooperAccordion styled className="kooper-accordion">
            <KooperAccordionTitle
              active={activeIndex === 2}
              index={2}
              onClick={(e, titleProps) => {
                const {index} = titleProps
                const newIndex = activeIndex === index ? -1 : index
                setActiveIndex(newIndex)
              }}
            >
              {activeIndex === 2 ? <SvgIcon path="common/down" /> : <FiChevronRight />}
              Tags ({_.size(_.get(singleContact, 'labelIds'))})
            </KooperAccordionTitle>
            <KooperAccordionContent active={activeIndex === 2} className="tags-attached">
              {_.size(_.get(singleContact, 'labelIds')).length === 0 ? (
                <p className="kooper-lead mb-0">No Tags</p>
              ) : null}
              <Tags
                id={contactId}
                data={singleContact}
                tagsUpdate={editContact}
                Controller={Controller}
                control={control}
                errors={errors}
                getValues={getValues}
                rolePermissions={manageContactsCompanyPermissions}
              />
            </KooperAccordionContent>
          </KooperAccordion>
        </KooperCardContent>
      </KooperCard>

      <KooperCard fluid>
        <KooperCardContent className="p-0">
          <KooperAccordion styled className="kooper-accordion">
            <KooperAccordionTitle
              active={activeIndex === 3}
              index={3}
              onClick={(e, titleProps) => {
                const {index} = titleProps
                const newIndex = activeIndex === index ? -1 : index
                setActiveIndex(newIndex)
              }}
            >
              {activeIndex === 3 ? <SvgIcon path="common/down" /> : <FiChevronRight />}
              Notes{' '}
              {get(['notes'], singleContact, []).length > 0 ? `(${get(['notes'], singleContact, []).length})` : ''}
            </KooperAccordionTitle>
            <KooperAccordionContent className="p-0 pb-2" active={activeIndex === 3}>
              <KooperForm className="mx-3 mb-2" size="tiny">
                <KooperFormField>
                  <KooperFormInput
                    maxLength={30}
                    placeholder="Write note here..."
                    disabled={!manageContactsCompanyPermissions}
                    onChange={e => manageContactsCompanyPermissions && setNoteText(e.target.value)}
                    value={noteText}
                    onKeyPress={e => {
                      if (e.key === 'Enter') {
                        if (!manageContactsCompanyPermissions) return
                        const data = {
                          note: e.target.value,
                        }

                        if (e.target.value.length !== 0 && e.target.value.trim() !== '') {
                          dispatch(addNoteInContacts(contactId, data))
                        }
                      }
                    }}
                  />
                </KooperFormField>
              </KooperForm>
              {/* {get(["notes"], singleContact, []).length === 0 ? (
                <p className="kooper-lead px-4 mb-0">No Notes </p>
              ) : null} */}
              <KooperList selection className="recentConv">
                {renderNotes()}
              </KooperList>
            </KooperAccordionContent>
          </KooperAccordion>
        </KooperCardContent>
      </KooperCard>

      <KooperCard fluid>
        <KooperCardContent className="p-0">
          <KooperAccordion styled className="kooper-accordion">
            <KooperAccordionTitle
              active={activeIndex === 4}
              index={4}
              onClick={(e, titleProps) => {
                const {index} = titleProps
                const newIndex = activeIndex === index ? -1 : index
                setActiveIndex(newIndex)
              }}
            >
              {activeIndex === 4 ? <SvgIcon path="common/down" /> : <FiChevronRight />}
              Tasks ({get(['tasks'], singleContact, []).length})
            </KooperAccordionTitle>
            <KooperAccordionContent active={activeIndex === 4}>
              <KooperList className="recentConv">
                {get(['tasks'], singleContact, []).length > 0
                  ? get(['tasks'], singleContact, [])
                      .slice(0, 3)
                      .map(task => (
                        <KooperListItem>
                          <KooperIcon
                            style={{
                              paddingTop: '4px',
                            }}
                          >
                            <KooperCheckbox
                              onClick={() => {
                                if (!manageTasksPermissions) return
                                let data = {}

                                if (task.status === 'closed') {
                                  data = {
                                    status: 'open',
                                  }
                                } else {
                                  data = {
                                    status: 'closed',
                                  }
                                }

                                dispatch(editTaskActivities(task.id, data))
                              }}
                              checked={task.status === 'closed'}
                            />
                          </KooperIcon>
                          <KooperListContent>
                            <KooperListHeader
                              as="a"
                              style={
                                task.status === 'closed'
                                  ? {
                                      textDecoration: 'line-through',
                                    }
                                  : null
                              }
                              onClick={() => {
                                if (manageTasksPermissions) {
                                  dispatch(getTaskActivities(task.id))
                                  setIsViewTaskOpen(true)
                                }
                              }}
                            >
                              {task.subject ? wrapText(task.subject, 20) : ''}
                              <span
                                style={{
                                  float: 'right',
                                  fontSize: 'small',
                                }}
                              >
                                {get(['assignee', 'firstName'], task, '')} {get(['assignee', 'lastName'], task, '')}
                              </span>
                            </KooperListHeader>
                            {task.status === 'closed' ? null : (
                              <KooperListDescription>
                                Due:
                                {GetFormattedDate(get(['endAt'], task, ''))}
                              </KooperListDescription>
                            )}
                          </KooperListContent>
                        </KooperListItem>
                      ))
                  : ''}
              </KooperList>
              {get(['tasks'], singleContact, []).length === 0 ? <p className="kooper-lead mb-0">No Task</p> : null}
              <KooperTooltip
                content={
                  manageTasksPermissions
                    ? 'Add Task'
                    : getPermissionTooltipContent({roleAccess: manageTasksPermissions})
                }
                size="mini"
                position="top center"
                trigger={
                  <KooperButton
                    className="mt-3 ml-0 delete-forecast"
                    icon
                    onClick={() => manageTasksPermissions && setIsCreateTaskOpen(!isCreateTaskOpen)}
                  >
                    <SvgIcon path="common/plus" />
                  </KooperButton>
                }
              />
            </KooperAccordionContent>
          </KooperAccordion>
        </KooperCardContent>
      </KooperCard>

      <KooperCard fluid>
        <KooperCardContent className="p-0">
          <KooperAccordion styled className="kooper-accordion">
            <KooperAccordionTitle
              active={activeIndex === 5}
              index={5}
              onClick={(e, titleProps) => {
                const {index} = titleProps
                const newIndex = activeIndex === index ? -1 : index
                setActiveIndex(newIndex)
              }}
            >
              {activeIndex === 5 ? <SvgIcon path="common/down" /> : <FiChevronRight />}
              Files ({_.size(_.get(singleContact, 'attachments'))})
            </KooperAccordionTitle>

            <KooperAccordionContent active={activeIndex === 5} className="pt-0">
              {get(['attachments'], singleContact, []).map(item => (
                <li>{item.name}</li>
              ))}
              {_.size(_.get(singleContact, 'attachments')) === 0 && <p className="kooper-lead">No Files</p>}
              <LockPermissionTooltip isRoleAccessDenied={!manageContactsCompanyPermissions}>
                <KooperInput
                  fluid
                  id="fileUpload"
                  type="file"
                  multiple
                  disabled={!manageContactsCompanyPermissions}
                  onChange={e => {
                    const data = {
                      file: e.target.files,
                    }
                    dispatch(uploadAttachmentsContact(contactId, data))
                  }}
                />
              </LockPermissionTooltip>
            </KooperAccordionContent>
          </KooperAccordion>
        </KooperCardContent>
      </KooperCard>

      <KooperCard fluid>
        <KooperCardContent className="p-0">
          <KooperAccordion styled className="kooper-accordion">
            <KooperAccordionTitle
              active={activeIndex === 6}
              index={6}
              onClick={(e, titleProps) => {
                const {index} = titleProps
                const newIndex = activeIndex === index ? -1 : index
                setActiveIndex(newIndex)
              }}
            >
              {activeIndex === 6 ? <SvgIcon path="common/down" /> : <FiChevronRight />}
              Purchase Order ({get(['purchaseorder'], singleContact, []).length})
            </KooperAccordionTitle>
            <KooperAccordionContent active={activeIndex === 6}>
              <KooperCardContent className="p-0">
                {get(['purchaseorder'], singleContact, []).length === 0 ? (
                  <p className="kooper-lead mb-4">No Purchase Order</p>
                ) : (
                  get(['purchaseorder'], singleContact, []).map(PO => {
                    return (
                      <>
                        <p>
                          <strong>PO Number: </strong>
                          {PO.poNumber}
                        </p>
                        <p>
                          <strong>Subject: </strong>
                          {PO.subject}
                        </p>
                        <p>
                          <strong>Status:</strong>
                          {PO.status}
                        </p>
                      </>
                    )
                  })
                )}
              </KooperCardContent>
            </KooperAccordionContent>
          </KooperAccordion>
        </KooperCardContent>
      </KooperCard>

      <KooperCard fluid>
        <KooperCardContent className="p-0">
          <KooperAccordion styled className="kooper-accordion">
            <KooperAccordionTitle
              active={activeIndex === 7}
              index={7}
              onClick={(e, titleProps) => {
                const {index} = titleProps
                const newIndex = activeIndex === index ? -1 : index
                setActiveIndex(newIndex)
              }}
            >
              {activeIndex === 7 ? <SvgIcon path="common/down" /> : <FiChevronRight />}
              Sales Order ({get(['salesorder'], singleContact, []).length})
            </KooperAccordionTitle>
            <KooperAccordionContent active={activeIndex === 7}>
              <KooperCardContent className="p-0">
                {get(['salesorder'], singleContact, []).length === 0 ? (
                  <p className="kooper-lead mb-4">No Sales Order</p>
                ) : (
                  get(['salesorder'], singleContact, []).map(SO => {
                    return (
                      <>
                        <p>
                          <strong>SO Number: </strong>
                          {SO.SoNumber}
                        </p>
                        <p>
                          <strong>Subject: </strong>
                          {SO.subject}
                        </p>
                        <p>
                          <strong>Status:</strong>
                          {SO.status}
                        </p>
                      </>
                    )
                  })
                )}
              </KooperCardContent>
            </KooperAccordionContent>
          </KooperAccordion>
        </KooperCardContent>
      </KooperCard>

      <KooperCard fluid>
        <KooperCardContent className="p-0">
          <KooperAccordion styled className="kooper-accordion">
            <KooperAccordionTitle
              active={activeIndex === 8}
              index={8}
              onClick={(e, titleProps) => {
                const {index} = titleProps
                const newIndex = activeIndex === index ? -1 : index
                setActiveIndex(newIndex)
              }}
            >
              {activeIndex === 8 ? <SvgIcon path="common/down" /> : <FiChevronRight />}
              Invoice ({get(['invoices'], singleContact, []).length})
            </KooperAccordionTitle>
            <KooperAccordionContent active={activeIndex === 8}>
              <KooperCardContent className="p-0">
                {get(['invoices'], singleContact, []).length === 0 ? (
                  <p className="kooper-lead mb-4">No Invoices</p>
                ) : (
                  get(['invoices'], singleContact, []).map(invoice => {
                    return (
                      <>
                        <p>
                          <strong>Invoice Number: </strong>
                          {invoice.invoiceNumber}
                        </p>
                        <p>
                          <strong>Subject: </strong>
                          {invoice.subject}
                        </p>
                        <p>
                          <strong>Status:</strong>
                          {invoice.status}
                        </p>
                      </>
                    )
                  })
                )}
              </KooperCardContent>
            </KooperAccordionContent>
          </KooperAccordion>
        </KooperCardContent>
      </KooperCard>

      <KooperCard fluid>
        <KooperCardContent className="p-0">
          <KooperAccordion styled className="kooper-accordion">
            <KooperAccordionTitle
              active={activeIndex === 9}
              index={9}
              onClick={(e, titleProps) => {
                const {index} = titleProps
                const newIndex = activeIndex === index ? -1 : index
                setActiveIndex(newIndex)
              }}
            >
              {activeIndex === 9 ? <SvgIcon path="common/down" /> : <FiChevronRight />}
              Project ({get(['projects'], singleContact, []).length})
            </KooperAccordionTitle>
            <KooperAccordionContent active={activeIndex === 9}>
              {get(['projects'], singleContact, []).length === 0 ? (
                <p className="kooper-lead mb-4">No Projects</p>
              ) : (
                <KooperList>
                  {get(['projects'], singleContact, []).map(project => (
                    <KooperListItem key={project.id}>
                      <KooperListContent>
                        <KooperListContent floated="right">
                          <FiExternalLink
                            style={{cursor: 'pointer'}}
                            onClick={() => history.push(`/projects/${project.id}/overview`)}
                          />
                        </KooperListContent>
                        <KooperListHeader>{startCase(project.title)}</KooperListHeader>
                        <KooperListDescription>
                          {getFormattedDate(project.startDate)} - {getFormattedDate(project.endDate)}
                        </KooperListDescription>
                        <KooperLabel color={getProjectLabelColor(project.status)} className="kooper-label">
                          {project.status}
                        </KooperLabel>
                      </KooperListContent>
                    </KooperListItem>
                  ))}
                </KooperList>
              )}
            </KooperAccordionContent>
          </KooperAccordion>
        </KooperCardContent>
      </KooperCard>
      {renderCreateTaskModal()}
      {isViewTaskOpen && <ViewTask setIsViewTaskOpen={setIsViewTaskOpen} isViewTaskOpen={isViewTaskOpen} />}
    </div>
  )
}

export default ContactSummary
