/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useHistory} from 'react-router'
import {Controller, useForm} from 'react-hook-form'
import {joiResolver} from '@hookform/resolvers'
import _ from 'lodash'
import Editor from 'react-simple-code-editor'
import {highlight, languages} from 'prismjs'
import {
  KooperButton,
  KooperDropdown,
  KooperForm,
  KooperFormField,
  KooperFormInput,
  KooperFormTextArea,
  KooperList,
  KooperListContent,
  KooperListHeader,
  KooperListItem,
  KooperRadio,
  KooperTab,
  KooperTabPane,
  KooperTooltip,
} from 'kooper-library'
import {CREATE_FEEDBACK_FORM, EDIT_FEEDBACK_FORM, SINGLE_FEEDBACK_FORM} from 'actions/types'
import {getAllMailInbox} from 'actions/inbox'
import {createFeedbackForm, editFeedbackForm, getFeedbackForm, getSingleFeedbackForm} from 'actions/settings_channels'
import SettingLayout from 'layouts/settingLayout'
import {copyToClipboardWithoutInput, get, removeDoubleQuotes} from 'utils/helper'
import {formFieldArray} from 'constants/variables'
import {feedbackSchema} from 'validation/Settings/channels/feedback.schema'
import useUserPermissions from 'hooks/pure/useUserPermissions'
import LockPermissionTooltip from 'components/common/LockPermissionTooltip'
import useLearnMoreUrl from 'hooks/pure/useLearnMoreUrl'
import SvgIcon from 'components/common/SvgIcon'
import {INBOX_TYPES} from 'constants/enums'

const SetupForm = props => {
  const {listMailBox, isActive, setWarningModal, toggleModal, id} = props

  const dispatch = useDispatch()

  const {
    handleSubmit,
    errors,
    control,
    reset,
    formState: {isDirty},
  } = useForm({
    mode: 'onTouched',
    shouldFocusError: true,
    reValidateMode: 'onChange',
    submitFocusError: true,
    shouldUnregister: false,
    resolver: joiResolver(feedbackSchema),
    defaultValues: {
      headerText: '',
      subHeaderText: '',
      submitText: '',
      submitMsg: '',
      allowFields: {
        email: true,
        name: true,
        message: true,
        subject: true,
        phone: false,
      },
      captcha: false,
      attachments: false,
      inboxId: -1,
    },
  })

  const {isLoadingData, successLabels = [], type = []} = useSelector(state => state.apiReducer)
  const {feedbackForm} = useSelector(state => state.settingsChannels)

  const {channelsPermissions} = useUserPermissions()
  const manageContactFormPermissions = channelsPermissions?.manage_contact_forms

  const resetData = () => {
    if (feedbackForm) {
      const fields = feedbackForm?.params?.allowFields.reduce((acc, curr) => {
        return {...acc, [curr]: true}
      }, {})
      const resetValues = {
        ...feedbackForm?.params,
        allowFields: fields,
        inboxId: feedbackForm?.inboxId,
        submitMsg: feedbackForm?.submitMsg,
        submitText: feedbackForm?.submitText,
        headerText: feedbackForm?.headerText,
        subHeaderText: feedbackForm?.subHeaderText,
      }
      reset(resetValues)
      setWarningModal(false)
    }
  }

  useEffect(() => {
    if (successLabels.includes(SINGLE_FEEDBACK_FORM)) {
      resetData()
    }
    if (successLabels.includes(CREATE_FEEDBACK_FORM)) {
      dispatch(getFeedbackForm())
      toggleModal(false)
    }
  }, [successLabels, dispatch, toggleModal])

  useEffect(() => {
    if (id) {
      resetData()
    }
  }, [id])

  useEffect(() => {
    if (id && isDirty) {
      setWarningModal(isDirty)
    }
    return () => setWarningModal(false)
  }, [isDirty, id, setWarningModal])

  const submitForm = data => {
    const active = _.keys(_.pickBy(data.allowFields))
    const newContactForm = {...data, allowFields: active, isActive}
    if (id) {
      dispatch(editFeedbackForm(feedbackForm.id, newContactForm))
    } else {
      dispatch(createFeedbackForm(newContactForm))
    }
  }

  return (
    <KooperForm className="inlineForm mt-4 errorLabel">
      <KooperFormField required width={8}>
        <label>Heading</label>
        <Controller
          name="headerText"
          control={control}
          render={({value, onChange, onBlur}) => (
            <KooperFormInput
              maxLength={20}
              value={value}
              onBlur={onBlur}
              onChange={e => onChange(e.target.value)}
              error={
                errors.headerText && {
                  content: removeDoubleQuotes(errors.headerText.message),
                }
              }
            />
          )}
        />
      </KooperFormField>
      <KooperFormField required width={8}>
        <label>Subheading</label>
        <Controller
          name="subHeaderText"
          control={control}
          render={({value, onChange, onBlur}) => (
            <KooperFormInput
              maxLength={20}
              value={value}
              onBlur={onBlur}
              onChange={e => onChange(e.target.value)}
              error={
                errors.subHeaderText && {
                  content: removeDoubleQuotes(errors.subHeaderText.message),
                }
              }
            />
          )}
        />
      </KooperFormField>
      <KooperFormField width={8}>
        <div className="info-header">
          <label>Query Inbox</label>
          <KooperTooltip
            content="Inbox in which you want to receive queries via contact form."
            size="mini"
            position="top center"
            trigger={<SvgIcon path="common/question" />}
          />
        </div>
        <Controller
          control={control}
          name="inboxId"
          render={({onChange, value, onBlur}) => (
            <KooperDropdown
              selection
              value={value}
              options={listMailBox}
              onChange={(e, {value}) => {
                onChange(value)
              }}
              onBlur={onBlur}
            />
          )}
        />
      </KooperFormField>
      <KooperFormField>
        <label>Fields</label>
        <KooperList relaxed className="swtichList">
          {formFieldArray.map(item => {
            return (
              <KooperListItem key={item.value}>
                <KooperListContent>
                  <KooperListHeader>
                    {item.title}
                    <Controller
                      defaultValue={false}
                      control={control}
                      name={item.outSider ? `${item.value}` : `allowFields.${item.value}`}
                      render={({onChange, value}) => (
                        <KooperRadio
                          toggle
                          checked={value}
                          value={value}
                          disabled={item.isDisable}
                          onChange={(e, {checked}) => {
                            onChange(checked)
                          }}
                        />
                      )}
                    />
                  </KooperListHeader>
                </KooperListContent>
              </KooperListItem>
            )
          })}
        </KooperList>
      </KooperFormField>
      <KooperFormField required width={8}>
        <label>Submit Button Text</label>
        <Controller
          name="submitText"
          control={control}
          render={({value, onChange, onBlur}) => (
            <KooperFormInput
              maxLength={20}
              placeholder="Submit"
              value={value}
              onChange={e => onChange(e.target.value)}
              onBlur={onBlur}
              error={
                errors.submitText && {
                  content: removeDoubleQuotes(errors.submitText.message),
                }
              }
            />
          )}
        />
      </KooperFormField>
      <KooperFormField required width={8}>
        <label>Submit Message</label>
        <Controller
          name="submitMsg"
          control={control}
          render={({value, onChange, onBlur}) => (
            <KooperFormTextArea
              maxLength={200}
              value={value}
              onBlur={onBlur}
              placeholder="Thank you for your feedback"
              onChange={e => onChange(e.target.value)}
              error={
                errors.submitMsg && {
                  content: removeDoubleQuotes(errors.submitMsg.message),
                }
              }
            />
          )}
        />
      </KooperFormField>

      <div className="setActionBtn">
        <KooperButton basic className="mr-3" onClick={() => (id ? resetData : toggleModal(false))}>
          Cancel
        </KooperButton>
        <LockPermissionTooltip isRoleAccessDenied={!manageContactFormPermissions}>
          <KooperButton
            primary
            loading={isLoadingData && type.includes(EDIT_FEEDBACK_FORM)}
            {...(manageContactFormPermissions && {
              onClick: handleSubmit(data => submitForm(data)),
            })}
            className={!manageContactFormPermissions ? 'disabled-button' : ''}
            disabled={!isDirty || (isLoadingData && type.includes(EDIT_FEEDBACK_FORM))}
          >
            {id ? 'Update' : 'Create'}
          </KooperButton>
        </LockPermissionTooltip>
      </div>
    </KooperForm>
  )
}

const Feedback = props => {
  const {match, toggleModal, setWarningModal} = props
  const {id} = match.params

  const dispatch = useDispatch()
  const history = useHistory()

  const [isActive, setIsActive] = useState(true)
  const [listMailBox, setListMailBox] = useState([])
  const [code, setCode] = useState('')
  const [copySuccess, setCopySuccess] = useState('COPY')

  const {successLabels = []} = useSelector(state => state.apiReducer)
  const {inbox: mailBoxList = []} = useSelector(state => state.mailInbox)
  const {feedbackForm} = useSelector(state => state.settingsChannels)
  const {settingsLearnMoreUrl} = useLearnMoreUrl()
  const contactFormAddLearnMore = settingsLearnMoreUrl?.channels?.contactForm?.addLearnMore
  const contactFormUpdateLearnMore = settingsLearnMoreUrl?.channels?.contactForm?.updateLearnMore
  const embedCodeLearnMore = settingsLearnMoreUrl?.channels?.contactForm?.embedCodeLearnMore

  useEffect(() => {
    if (id) {
      dispatch(getSingleFeedbackForm(id))
    } else {
      dispatch(getFeedbackForm())
    }
    dispatch(getAllMailInbox())
  }, [dispatch, id])

  useEffect(() => {
    if (mailBoxList) {
      setListMailBox(
        mailBoxList
          .filter(inbox => (inbox.type === INBOX_TYPES.EMAIL ? inbox.emailVerified : true))
          .map(inbox => {
            return {
              value: inbox.id,
              key: inbox.id,
              text: inbox.name,
            }
          })
      )
    }
  }, [mailBoxList])

  useEffect(() => {
    if (successLabels.includes(SINGLE_FEEDBACK_FORM)) {
      setCode(feedbackForm.code)
      setIsActive(get(['isActive'], feedbackForm, false))
    }
    if (successLabels.includes(EDIT_FEEDBACK_FORM)) {
      setWarningModal(false)
      dispatch({type: SINGLE_FEEDBACK_FORM, payload: {}})
      history.goBack()
    }
  }, [successLabels, listMailBox, dispatch, feedbackForm, history, setWarningModal])

  const panes = [
    {
      menuItem: {
        key: 'setup',
        icon: 'clipboard list',
        content: 'Setup',
      },
      render: () => (
        <KooperTabPane attached={false}>
          <SetupForm {...props} id={id} listMailBox={listMailBox} isActive={isActive} />
        </KooperTabPane>
      ),
    },
    {
      menuItem: {
        key: 'embed-code',
        icon: <SvgIcon path="settings/embed-code" />,
        disabled: !id,
        content: 'Embed Code',
      },
      render: () => (
        <KooperTabPane attached={false}>
          <h3 className="mt-4">Embed code</h3>
          <p className="lead mb-4">
            Please copy and paste the code below anywhere before the body tag in the html template of your site or every
            page where you'd like to provide the feedback form , For more detailed explanation of setting this up
            <a href={embedCodeLearnMore}>Learn More</a>
          </p>
          <div className="codeTextView">
            <Editor
              disabled
              value={decodeURIComponent(code)}
              highlight={code => highlight(code, languages.html)}
              padding={10}
              textareaId="codeArea"
              style={{
                border: '1px solid var(--border-color)',
                fontFamily: 'monospace',
                fontSize: 16,
                outline: 0,
                background: '#f9fbfd',
                borderRadius: 10,
              }}
            />
            <div className="setActionBtn">
              <KooperButton
                size="tiny"
                primary
                onClick={() => copyToClipboardWithoutInput(decodeURIComponent(code), setCopySuccess)}
              >
                {copySuccess}
              </KooperButton>
            </div>
          </div>
        </KooperTabPane>
      ),
    },
  ]

  const handleActive = () => {
    setIsActive(!isActive)
  }

  return (
    <SettingLayout
      header="Contact Form"
      headerbackbtn={{
        onClick: () => {
          if (id) {
            setWarningModal(false)
            history.push('/settings/contact-form')
          } else {
            toggleModal(false)
          }
        },
      }}
      subHeader="Setup and embed contact form for customers to connect"
      learnMoreUrl={id ? contactFormUpdateLearnMore : contactFormAddLearnMore}
      headerRadioButton={{
        checked: isActive,
        onChange: handleActive,
        name: 'feedbackform',
      }}
      table={false}
      headerDivider
    >
      <KooperTab menu={{secondary: true, pointing: true, color: 'blue'}} panes={panes} />
    </SettingLayout>
  )
}

export default Feedback
