import React, {useEffect, useMemo, useState} from 'react'
import {Gantt} from 'gantt-task-react'
import 'gantt-task-react/dist/index.css'
import {FaFolder} from 'react-icons/fa'
import {
  KooperAccordion,
  KooperAccordionContent,
  KooperAccordionTitle,
  KooperButton,
  KooperButtonGroup,
  KooperCard,
  KooperCardContent,
  KooperTable,
  KooperTableBody,
  KooperTableCell,
  KooperTableHeader,
  KooperTableHeaderCell,
  KooperTableRow,
} from 'kooper-library'
import {FiChevronDown, FiChevronRight} from 'react-icons/fi'
import moment from 'moment'
import {useDispatch, useSelector} from 'react-redux'
import {getProjectsPlanningWorkloadsList, updateProjectsTasksProgress} from 'actions/resource_planning'
import {
  GET_PROJECTS_PLANNING_WORKLOADS_LIST,
  UPDATE_PROJECTS_TASKS_LIST_SINGLE_TASK,
  UPDATE_PROJECTS_TASKS_PROGRESS,
} from 'actions/types'
import {getFormattedDate, startCase} from 'utils/helper'
import {updateProjectTaskListSingleTask} from 'actions/projects'
import SvgIcon from 'components/common/SvgIcon'

export const getStartEndDateForProject = (tasks, projectId) => {
  const projectTasks = tasks.filter(t => t.project === projectId)
  let {start} = projectTasks[0]
  let {end} = projectTasks[0]

  for (let i = 0; i < projectTasks.length; i += 1) {
    const task = projectTasks[i]
    if (start.getTime() > task.start.getTime()) {
      start = task.start
    }
    if (end.getTime() < task.end.getTime()) {
      end = task.end
    }
  }
  return [start, end]
}

const CustomTooltipContent = ({task}) => {
  return (
    <KooperCard>
      <KooperCardContent>
        <strong>{`${startCase(task.name)}: ${getFormattedDate(task.start)} to ${getFormattedDate(task.end)}`}</strong>
        {task.end.getTime() - task.start.getTime() !== 0 && (
          <p>{`Duration: ${moment(task.end).diff(moment(task.start), 'days')} day(s)`}</p>
        )}

        <p>Progress: {task.progress} %</p>
        {task.type === 'task' && (
          <>
            <p>Estimate time: {task.estimatedTime}</p>
            <p>Actual time: {task.actualWorkedTime}</p>
          </>
        )}
      </KooperCardContent>
    </KooperCard>
  )
}

const Workload = () => {
  const dispatch = useDispatch()

  const [users, setUsers] = useState([])
  const [userActiveIndex, setUserActiveIndex] = useState(0)
  const [viewMode, setViewMode] = useState('Week')
  const userId = users[userActiveIndex]?.id

  const columnWidth = useMemo(() => {
    let width = 60
    if (viewMode === 'Month') {
      width = 400
    } else if (viewMode === 'Week') {
      width = 300
    }
    return width
  }, [viewMode])

  const {successLabels = []} = useSelector(state => state.apiReducer)
  const {projectsPlanningWorkloadsList = []} = useSelector(state => state.resourcePlanning)

  useEffect(() => {
    dispatch(getProjectsPlanningWorkloadsList())
  }, [dispatch])

  useEffect(() => {
    if (successLabels.includes(GET_PROJECTS_PLANNING_WORKLOADS_LIST)) {
      setUsers(
        projectsPlanningWorkloadsList.map(workload => ({
          ...workload,
          tasks: workload.tasks.map(task => ({
            ...task,
            start: new Date(task.start),
            end: new Date(task.end),
          })),
        }))
      )
    }
  }, [successLabels, projectsPlanningWorkloadsList])

  useEffect(() => {
    if (
      successLabels.includes(UPDATE_PROJECTS_TASKS_PROGRESS) ||
      successLabels.includes(UPDATE_PROJECTS_TASKS_LIST_SINGLE_TASK)
    ) {
      dispatch(getProjectsPlanningWorkloadsList())
    }
  }, [successLabels, dispatch])

  const handleTaskChange = task => {
    const {name, project, id, start, end} = task
    const data = {title: name, startDate: start, endDate: end}
    dispatch(updateProjectTaskListSingleTask({project, taskId: id, data}))
  }

  const handleProgressChange = task => {
    const {id, project, progress} = task
    const data = {progressRate: progress}
    dispatch(updateProjectsTasksProgress(project, id, data))
  }

  const handleProjectClick = projectId => {
    const userOldTasks = users.find(u => u.id === userId)?.tasks || []
    const userNewTasks = userOldTasks.map(t => (t.id === projectId ? {...t, hideChildren: !t.hideChildren} : t))
    setUsers(users.map(u => (u.id === userId ? {...u, tasks: userNewTasks} : u)))
  }

  return (
    <div className="pageBodyWrap">
      <div className="pageHeader px-0">
        <div className="pageTitle">
          <div className="pageTitleIcon">
            <FaFolder />
            <h5>Workload </h5>
          </div>
        </div>
        <div className="pageAction">
          <KooperButtonGroup basic className="button-group">
            <KooperButton active={viewMode === 'Day'} onClick={() => setViewMode('Day')}>
              Daily
            </KooperButton>
            <KooperButton active={viewMode === 'Week'} onClick={() => setViewMode('Week')}>
              Weekly
            </KooperButton>
            <KooperButton active={viewMode === 'Month'} onClick={() => setViewMode('Month')}>
              Monthly
            </KooperButton>
          </KooperButtonGroup>
        </div>
      </div>

      <KooperAccordion>
        {users.map((user, index) => (
          <React.Fragment key={user.id}>
            <KooperCard fluid>
              <KooperCardContent>
                <KooperAccordionTitle
                  active={userActiveIndex === index}
                  index={index}
                  style={{display: 'flex', alignItems: 'center'}}
                  onClick={(e, titleProps) => {
                    const {index} = titleProps
                    const newIndex = userActiveIndex === index ? -1 : index

                    setUserActiveIndex(newIndex)
                  }}
                >
                  {userActiveIndex === index ? <SvgIcon path="common/down" /> : <FiChevronRight />} {user.name}
                </KooperAccordionTitle>
                <KooperAccordionContent active={userActiveIndex === index} className="pl-4">
                  {user.tasks?.length ? (
                    <div className="workload-content">
                      <div className="workload-table-container">
                        <KooperTable basic fixed>
                          <KooperTableHeader>
                            <KooperTableRow>
                              <KooperTableHeaderCell style={{width: '200px'}}>Name</KooperTableHeaderCell>
                              <KooperTableHeaderCell style={{width: '100px'}}>Start</KooperTableHeaderCell>
                              <KooperTableHeaderCell style={{width: '100px'}}>End</KooperTableHeaderCell>
                              <KooperTableHeaderCell style={{width: '100px'}}>Days</KooperTableHeaderCell>
                              <KooperTableHeaderCell style={{width: '100px'}}>Progress</KooperTableHeaderCell>
                            </KooperTableRow>
                          </KooperTableHeader>
                          <KooperTableBody>
                            {user.tasks.map(task => (
                              <React.Fragment key={`${user.id}user-${task.id}task`}>
                                {task.type === 'project' && (
                                  <KooperTableRow onClick={() => handleProjectClick(task.id)}>
                                    <KooperTableCell className="d-flex-js">
                                      {!task.hideChildren ? (
                                        <SvgIcon path="common/down" className="mr-1" />
                                      ) : (
                                        <FiChevronRight className="mr-1" />
                                      )}{' '}
                                      <strong>{task.name}</strong>
                                    </KooperTableCell>
                                    <KooperTableCell>{getFormattedDate(task.start)}</KooperTableCell>
                                    <KooperTableCell>{getFormattedDate(task.end)}</KooperTableCell>
                                    <KooperTableCell>{moment(task.end).diff(moment(task.start), 'days')}</KooperTableCell>
                                    <KooperTableCell>{task.progress}</KooperTableCell>
                                  </KooperTableRow>
                                )}
                                {task.type === 'task' && !user.tasks.find(t => t.id === task.project)?.hideChildren && (
                                  <KooperTableRow
                                    style={{
                                      display: !user.tasks.find(t => t.id === task.project)?.hideChildren ? '' : 'none',
                                    }}
                                  >
                                    <KooperTableCell style={{paddingLeft: '25px'}}>{task.name}</KooperTableCell>
                                    <KooperTableCell>{getFormattedDate(task.start)}</KooperTableCell>
                                    <KooperTableCell>{getFormattedDate(task.end)}</KooperTableCell>
                                    <KooperTableCell>{moment(task.end).diff(moment(task.start), 'days')}</KooperTableCell>
                                    <KooperTableCell>{task.progress}</KooperTableCell>
                                  </KooperTableRow>
                                )}
                              </React.Fragment>
                            ))}
                          </KooperTableBody>
                        </KooperTable>
                      </div>
                      <div className="workload-chart-container">
                        <Gantt
                          tasks={user.tasks}
                          viewMode={viewMode}
                          onDateChange={handleTaskChange}
                          // onDelete={handleTaskDelete}
                          onProgressChange={handleProgressChange}
                          // onDoubleClick={handleDblClick}
                          // onSelect={handleSelect}
                          // onExpanderClick={handleExpanderClick}
                          onExpanderClick={() => {}}
                          listCellWidth=""
                          columnWidth={columnWidth}
                          headerHeight={46.74}
                          rowHeight={49.36}
                          fontFamily="Inter, Helvetica Neue, Arial, Helvetica, sans-serif"
                          fontSize="13px"
                          todayColor="#d4dbef"
                          TooltipContent={CustomTooltipContent}
                        />
                      </div>
                    </div>
                  ) : (
                    <p>No Data Available</p>
                  )}
                </KooperAccordionContent>
              </KooperCardContent>
            </KooperCard>
          </React.Fragment>
        ))}
      </KooperAccordion>
    </div>
  )
}

export default Workload
