/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, {useEffect, useState} from 'react'
import {useHistory} from 'react-router'
import {useDispatch, useSelector} from 'react-redux'
import _ from 'lodash'
import {
  KooperButton,
  KooperDropdown,
  KooperDropdownItem,
  KooperDropdownMenu,
  KooperForm,
  KooperFormField,
  KooperHeader,
  KooperIcon,
  KooperModal,
  KooperModalActions,
  KooperModalContent,
  KooperModalHeader,
  KooperTable,
  KooperTableBody,
  KooperTableCell,
  KooperTableHeader,
  KooperTableHeaderCell,
  KooperTablePlaceholder,
  KooperTableRow,
  KooperTooltip,
} from 'kooper-library'
import SearchComponent from 'components/common/SearchComponent'
import {deleteAppraisalTemplate, getAllAppraisalTemplate} from 'actions/appraisal'
import CreateFirst from 'components/common/CreateFirst'
import {FaBriefcase} from 'react-icons/fa'
import create from 'assets/create.svg'
import {DELETE_APPRAISAL_TEMPLATE, GET_ALL_APPRAISAL_TEMPLATE} from 'actions/types'
import DeleteModal from 'components/common/DeleteModal'
import {getFormattedDate, startCase} from 'utils/helper'
import useApiLoader from 'hooks/pure/useApiLoader'
import PageLoader from 'components/common/PageLoader'
import SvgIcon from 'components/common/SvgIcon'
import useApiResponse from 'hooks/impure/useApiResponse'
import AppriasalSidemenu from './AppraisalSidemenu'

const AppraisalTemplate = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  const [deleteModal, setDeleteModal] = useState(false)
  const [editId, setEditId] = useState()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [currentListParams, setCurrentListParams] = useState({
    page: 1,
    sortBy: '',
    sortType: '',
    text: '',
  })

  const {isLoadingData, type, successLabels = []} = useSelector(state => state.apiReducer)

  const {isLoading} = useApiLoader({label: GET_ALL_APPRAISAL_TEMPLATE})

  const {data: getAllAppraisalTemplateList = []} = useApiResponse({
    action: getAllAppraisalTemplate,
    enabled: true,
    label: GET_ALL_APPRAISAL_TEMPLATE,
    storePath: 'appraisal.getAllAppraisalTemplateList',
  })

  useEffect(() => {
    const newCurrentListParams = _.omitBy(currentListParams, value => {
      if (value === 'all' || value === '') {
        return true
      }
      return false
    })

    dispatch(getAllAppraisalTemplate(newCurrentListParams))
  }, [currentListParams, dispatch])

  useEffect(() => {
    if (successLabels.includes(DELETE_APPRAISAL_TEMPLATE)) {
      dispatch(getAllAppraisalTemplate())
    }
  }, [successLabels, dispatch])

  const EditReviewerModal = () => {
    return (
      <KooperModal
        size="small"
        closeIcon={<SvgIcon path="common/close" className="closeIcon" />}
        open={isModalOpen}
        onClose={() => setIsModalOpen(!isModalOpen)}
      >
        <KooperModalHeader>Edit Reviewers</KooperModalHeader>
        <KooperModalContent>
          <h3 className="mb-0">Appraisal template</h3>
          <p className="mt-0 mb-4">Lorem ipsum dolor sit amet consectetur adipisicing elit.</p>

          <div className="appraisalFormName">
            <h5>Appraisal Form Name:</h5>
            <span>Test</span>
          </div>
          <div className="appraisalFormDescription">
            <h5>Appraisal Form Description:</h5>
            <span>Description</span>
          </div>
          <div className="appraisalFormDescription">
            <h5>Number of Selected Reviewers:</h5>
            <span>2</span>
          </div>

          <KooperForm>
            <KooperFormField>
              <label>Employees</label>
              <KooperDropdown selection>
                <KooperDropdownMenu>
                  <KooperDropdownItem>Flow 1</KooperDropdownItem>
                  <KooperDropdownItem>Flow 2</KooperDropdownItem>
                  <KooperDropdownItem>Flow 3</KooperDropdownItem>
                </KooperDropdownMenu>
              </KooperDropdown>
            </KooperFormField>
          </KooperForm>
        </KooperModalContent>
        <KooperModalActions>
          <KooperButton basic onClick={() => setIsModalOpen(false)}>
            Cancel
          </KooperButton>
          <KooperButton color="blue">Add</KooperButton>
        </KooperModalActions>
      </KooperModal>
    )
  }

  const renderTableBody = () => {
    return getAllAppraisalTemplateList.map(({id, name, createdAt}, index) => {
      return (
        <KooperTableRow
          key={id}
          className="tableLink"
          onClick={() => {
            history.push(`/appraisal/update/${id}`)
          }}
        >
          <KooperTableCell>{index + 1}</KooperTableCell>
          <KooperTableCell>
            <KooperHeader as="h5">{startCase(name)}</KooperHeader>
          </KooperTableCell>
          {/* <KooperTableCell>{description}</KooperTableCell> */}
          <KooperTableCell>
            <div className="d-flex" style={{justifyContent: 'flex-start'}}>
              <span className="mr-3">2 reviewers</span>
              <KooperTooltip
                trigger={<SvgIcon path="common/edit2" onClick={() => setIsModalOpen(true)} />}
                content="Edit"
                size="mini"
                position="top center"
              />
            </div>
          </KooperTableCell>
          <KooperTableCell>{getFormattedDate(createdAt)}</KooperTableCell>
          <KooperTableCell>{getFormattedDate(createdAt)}</KooperTableCell>
          <KooperTableCell>
            <KooperDropdown button icon={<SvgIcon path="common/more-verticle" />} className="icon" direction="left">
              <KooperDropdownMenu>
                <KooperDropdownItem
                  onClick={() => {
                    setEditId(id)
                    setDeleteModal(true)
                  }}
                >
                  <KooperIcon name="trash alternate" /> Delete
                </KooperDropdownItem>
                <KooperDropdownItem>
                  <KooperTooltip
                    trigger={
                      <span onClick={() => history.push(`/appraisal/send/${id}`)}>
                        <SvgIcon path="common/send" /> Send Appraisal
                      </span>
                    }
                    content="Send Appraisal"
                    size="mini"
                    position="top center"
                  />
                </KooperDropdownItem>
              </KooperDropdownMenu>
            </KooperDropdown>
          </KooperTableCell>
        </KooperTableRow>
      )
    })
  }

  if (isLoading) {
    return <PageLoader />
  }

  return (
    <>
      {getAllAppraisalTemplateList?.length > 0 || currentListParams.text ? (
        <>
          <AppriasalSidemenu />

          <div className="kooper-side-wrap">
            <div className="page-header">
              <div className="page-title">
                <div className="page-titleIcon">
                  <SvgIcon path="common/file" />
                  <h5>Appraisal Templates ({getAllAppraisalTemplateList ? getAllAppraisalTemplateList?.length : 0})</h5>
                </div>
              </div>
              <div className="page-action">
                <SearchComponent onSuccess={value => setCurrentListParams({text: value})} />
                <KooperButton content="Create Template" primary onClick={() => history.push('/appraisal/create')} />
              </div>
            </div>

            <KooperTable sortable basic selectable>
              <KooperTableHeader>
                <KooperTableRow>
                  <KooperTableHeaderCell>#</KooperTableHeaderCell>
                  <KooperTableHeaderCell>Name</KooperTableHeaderCell>
                  {/* <KooperTableHeaderCell>Description</KooperTableHeaderCell> */}
                  <KooperTableHeaderCell>Number of Reviewers</KooperTableHeaderCell>
                  <KooperTableHeaderCell>Created Date</KooperTableHeaderCell>
                  <KooperTableHeaderCell>Revised Date</KooperTableHeaderCell>
                  <KooperTableHeaderCell>Actions</KooperTableHeaderCell>
                </KooperTableRow>
              </KooperTableHeader>
              <KooperTableBody>
                {isLoadingData && type.includes(GET_ALL_APPRAISAL_TEMPLATE) ? (
                  <KooperTablePlaceholder columns={8} />
                ) : (getAllAppraisalTemplateList || []).length > 0 ? (
                  renderTableBody()
                ) : (
                  <tr>
                    <td colSpan={8} className="emptyValue">
                      No Data Available
                    </td>
                  </tr>
                )}
              </KooperTableBody>
            </KooperTable>
            <EditReviewerModal />
            {deleteModal && (
              <DeleteModal
                isModalOpen={deleteModal}
                setIsModalOpen={setDeleteModal}
                modalText="Are you sure you want to delete ?"
                deleteAction={deleteAppraisalTemplate}
                idTobeDeleted={editId}
                type={DELETE_APPRAISAL_TEMPLATE}
              />
            )}
          </div>
        </>
      ) : (
        <CreateFirst
          src={<FaBriefcase />}
          header="Appraisal"
          subHeader="Companies are also regular customers who can have a relationship of deals"
          addFirst={() => history.push('/appraisal/create')}
          buttonText="Create Appraisal Template"
          Feature1="Detailed timeline for all activities"
          Feature2="Sync across whole application"
          Feature3="Increase brand and company value"
          list1="View all timeline data for every company activity"
          list2="All changes are synchronized across the whole application so there is no need to manually update any where"
          list3="Proper company management helps in increasing brand and company value"
          imageSrc={create}
        />
      )}
    </>
  )
}

export default AppraisalTemplate
